<template>
  <v-dialog
    :persistent="getUserFiles.length > 0"
    v-model="uploadAssetDialog"
    width="100%"
    height="110%"
    max-width="1000px"
  >
    <!-- Upload Assets Button in side panel. -->
    <template v-slot:activator="{ props}" v-model="dialog">
      <v-btn
        v-bind="props"
        style="width: 100%"
        variant="outlined"
        rounded
        color="primary"
      >
        <v-icon class="pr-1" size="small">{{ icons.upload}}</v-icon>
        Upload Assets
      </v-btn>
      
    </template>

    <!-- Drag and Drop Dialoge. -->
    <v-card
      @drop.prevent="onDrop($event)"
      @dragover.prevent="dragover = true"
      @dragenter.prevent="dragover = true"
      @dragleave.prevent="dragover = false"
      :class="{ 'grey-lighten-2': dragover }"
    >
      <!-- Upload Assets Dialoge title. -->
      <v-card-title
        v-if="getUserFiles.length === 0"
        class="bg-primary d-flex justify-center text-white"
      >
        <v-icon color="white" class="mr-4" size="small" me-auto>{{ icons.cloudUpload }}</v-icon>
        <span me-auto>Upload Assets</span>
        <v-spacer></v-spacer>
        <HelpButton :lexiconItem="lexicon.upload" :iconColor="'white'" class="pr-2"/>
        <v-icon @click="clearUserFiles" > {{icons.cancel}} </v-icon>
      </v-card-title>

      <!-- Review Uploads Dialoge title. -->
      <v-card-title v-else class="bg-primary text-white">
        <v-icon color="white" class="mr-4" size="small">{{ icons.cloudUpload }}</v-icon>
        Review Uploads
      </v-card-title>

      <!-- Dialoge Body. -->
      <v-container fluid>

        <!-- Drag and Drop Area. -->
        <v-row
          v-if="getUserFiles.length === 0"
          style="border: dashed 2px rgb(var(--v-theme-darkGrey))"
          class="d-flex flex-column ma-1 pa-3"
          density="compact"
          align="center"
          justify="center"
        >
          <v-icon
            color="primary"
            :class="[dragover ? 'mt-2, mb-6' : 'mt-5']"
            size="60"
          >
            {{ icons.cloudUpload }}
          </v-icon>
          <div class="text-center">
            <span class="text-body-1 font-weight-black"
              >Drag and drop files here</span
            >
            <br />
            (json, 3dm, svg, geojson, png...)
          </div>
          <span class="py-2">or</span>
          <v-btn @click="onFileUpload" size="large" class="bg-primary text-white"
            >Browse Files</v-btn
          >
          <input
            class="d-none"
            ref="uploader"
            type="file"
            multiple="multiple"
            @change="uploadFile($event)"
          />
        </v-row>

        <!-- Upload Review Area.-->
        <div v-else>

          <!-- File Type Specification Alert. -->
          <v-alert
            class="ma-1 py-2"
            close-text="Close Alert"
            color="secondary"
            closable
          >
            <div>
              <span>Select how each file should be used in the notebook<br />
                <ul>
                  <li>
                    <small>
                      <a :href="lexicon.data.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.data.plural}}</a> <i>(.json, .csv)</i>
                      can be visualized with multiple Widgets.
                    </small>
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.model.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.model.plural}}</a> <i>(.3dm)</i>
                      are viewable in the 3D Widget
                    </small>
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.drawing.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.drawing.plural}}</a> <i>(.svg)</i>
                      are viewable in the 2D Widget.
                    </small>
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.map.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.map.plural}}</a> <i>(.geojson)</i>
                      are viewable in the Map Widget.
                    </small> 
                  </li>
                  <li>
                    <small>
                      <a :href="lexicon.image.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.image.plural}}</a> <i>(.jpg, .jpeg, .png, .bmp, .gif)</i>
                      are viewable in the Image Grid Widget.
                    </small> 
                  </li>
                  <li>
                    <small>All other supported <a :href="lexicon.file.documentation" target="_blank" style="font-weight: bold; color: white">{{lexicon.file.plural}}</a>
                      formats can be added as Attachments to a Notebook.
                    </small>
                  </li>
                </ul>
              </span>
            </div>
          </v-alert>

          <!-- Table -->
           <!-- v-if="displayTable"-->
           <v-table
            
            class="pt-2"
            density="compact"
            :key="groupUpdateKey"
            :disable-pagination="true"
            :hide-default-footer="true"
            max-height="300px"
          >

          <!-- Table Headers. -->
            <thead>
              <tr>
                <th v-for="header in uploadFilesHeaders" :key="header.text">
                  {{header.text}}
                </th>
              </tr>
            </thead>

            <!-- Table Body -->
            <tbody>

              <!-- Each Row. -->
              <tr v-for="(file, index) in getUserFiles" :key="getFileUpdateKey">

                <!-- Cancel Column. -->
                <td style="width: 4%">
                  <template v-if="file.isValidFile === false">
                    <v-icon  color="danger">{{ icons.error }}</v-icon>
                  </template>
                  <template v-else-if="file.uploadPercentage < 100 && file.uploadPercentage !== null">
                    <v-icon color="primary" icon="mdi:mdi-timer-sand"></v-icon>
                  </template>
                  <template v-else-if="file.uploadPercentage === 100">
                    <v-icon color="primary" @click="removeUserFile(file)">{{ icons.check }}</v-icon>
                  </template>
                  <template v-else>
                    <v-icon  color="darkGrey" @click="removeUserFile(file)">{{ icons.cancel }}</v-icon>
                  </template>
                </td>

                <!-- File Name Column. -->
                <td class="file-name-cell text-truncate">
                  <v-icon size="x-small" color="darkGrey" :key="fileIconKey+index">{{ file.icon }}</v-icon>
                  <small>
                    &nbsp; {{ file.name }}
                    <v-tooltip activator="parent" location="top">{{file.name}}</v-tooltip>
                  </small>
                  <div>
      
                    <v-progress-linear
                      class="mt-0"
                        v-if="file.uploadPercentage !== null"
                        height="8"
                        rounded
                        v-model="file.uploadPercentage"
                        :key="getFileUpdateKey"
                        :color="file.isValidFile ? 'secondary' : 'danger'"
                      >
                        <small
                          style="
                            color: white;
                            font-size: xx-small;
                            font-weight: 500;
                          "
                        >
                          {{ Math.ceil(file.uploadPercentage) }}%</small
                        >
                    </v-progress-linear>
                  </div>
                </td>        
                  
                <!-- Data Type Column (Dropdown). -->
                <td style="width: 15%">
                  <v-select
                      :key="dataTypeKey"
                      :items="file.dataTypeOptions"
                      v-model="file.dataType"
                      @update:modelValue="updateDataType"
                  >
                </v-select>
                </td>
            
              </tr>
            </tbody>
          </v-table>
          <!-- <div v-else class="pt-5 d-flex align-center justify-center">
              <ProgressCircular>Loading Files</ProgressCircular>
          </div> -->

          <!-- Bottom Buttons. -->
          <div class="d-flex pt-5 justify-end">
            <template v-if="getFileUploadDone">
              <v-btn
              color="primary"
              @click="closeUploadDialog"
              >Done</v-btn
            >
            </template>
            <template v-else>
              <v-btn
              class="mr-2"
              :disabled="cancelUploadDisabled"
              @click="cancelUpload"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="uploadAssetLoading"
              @click="startUpload"
            >
              Upload
            </v-btn>
            </template>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import PillButton from "@/components/ui/PillButton.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue"
import HelpButton from "../components/ui/HelpButton.vue";
import {storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";
import {useDataUploadStore } from "../store/DataUploadStore";

export default {
  components: { PillButton, ProgressCircular, HelpButton },
  setup() {

    const dataUploadStore = useDataUploadStore()
    const { 
      getUserFiles,
      getUploadStatus,
      getFileUpdateKey,
      getFileUploadDone,
    } = storeToRefs(dataUploadStore);

    const { 
      getDataTypeOptions,
      ingestUserFiles,
      startUpload,
      removeUserFile,
      clearUserFiles,
    } = dataUploadStore;


    const notebookPropsStore = useNotebookPropsStore()
    const {
      getSelectedNavigationTab,
      getSelectedPage,
    } = storeToRefs(notebookPropsStore)

    const ellipseStore = useEllipseStore()
    const {
      getNotebook
    } = storeToRefs(ellipseStore)

    const assetsStore = useAssetsStore()
    const {
      getAllAssetsInNotebook,
      getSelectedPageDatasets,
      getSelectedPageDataset,
    } = storeToRefs(assetsStore)

    return {
      notebookPropsStore,
      getSelectedNavigationTab,
      getAllAssetsInNotebook,
      getSelectedPageDatasets,
      getSelectedPageDataset,
      getSelectedPage,
      getNotebook,
      lexicon,
      icons,
      getUserFiles,
      getUploadStatus,
      ingestUserFiles,
      startUpload,
      removeUserFile,
      clearUserFiles,
      getDataTypeOptions,
      getFileUpdateKey,
      getFileUploadDone
    }
  },
  data() {
    return {
      options () {
        return {
          offset: -60,
          duration: 300,
        }
      },
      dragover: false,
      uploadedFiles: [],
      uploadAssetDialog: false,
      uploadFilesHeaders: [
        { text: "", value: "", align: "center" },
        { text: "File Name", value: "name", align: "center" },
        { text: "Type", value: "dataType", align: "center" },
      ],
      uploadAssetLoading: false,
      groupUpdateKey: 0,
      dataTypeKey:1,
      fileIconKey: 0,
      dialog: false,
    };
  },
  computed: {
    cancelUploadDisabled() {
      return this.getUploadStatus; 
    },  
    fileUpdateKey() {
      return this.getFileUpdateKey;
    },
    displayTable() {
      return this.uploadedFiles.length > 0;
    },
  },
    created() {
    if (
      this.getAllAssetsInNotebook.datasets.length === 0 &&
      this.getAllAssetsInNotebook.drawings.length === 0 &&
      this.getAllAssetsInNotebook.models.length === 0 &&
      this.getSelectedNavigationTab === "globalSources"
    ) {
      this.uploadAssetDialog = true;
    }
  },
  destroyed() {
    this.clearUserFiles();
  },
  methods: {
    cancelUpload() {
      this.uploadAssetDialog = false;
      this.clearUserFiles();
    },
    closeUploadDialog() {
      this.clearUserFiles();
      this.uploadAssetDialog = false;
    },
    updateDataType() {
      this.dataTypeKey+=1;
    },
    onFileUpload() {
      this.$refs.uploader.click();
    },
    async uploadFile(e) {
      this.ingestUserFiles(e.target.files);
    },
    async onDrop(e) {
      this.dragover = false;
      this.ingestUserFiles(e.dataTransfer.files);
    },
  }
};

</script>
<style scoped>

</style>

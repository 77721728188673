<template>
  <v-card rounded class="d-flex align-center elevation-0" 
    :style="isSelectedPage ? 'border: 1px solid rgb(var(--v-theme-primary))' : 'border: none'"
    @click="newPageClicked()">
    <!-- Expansion Button -->
    <v-btn v-if="page.parent == null" density="compact" variant="plain" icon @click.stop="togglePageExpand" size="25" style="z-index: 5">
      <v-icon v-if="page.childNodes.length>0" size="x-small" :color="isSelectedPage ? 'primary' : 'black'">
        {{isExpanded ? 'fa-solid fa-caret-down' : 'fa-solid fa-caret-right'}}
      </v-icon>
    </v-btn>

    <!-- Page Status -->
    <v-tooltip location="top">
      <template v-slot:activator="{props}" >
        <div :class="page.parent ? 'ml-2' : ''">
          <v-icon size="20" class="mr-1" v-bind="props" v-if="!publishLoading"
            :color="userIsOwner ? 'primary' : 'darkSlate'" 
          >
            {{ page.isPublic ? lexicon.page.iconPublished : lexicon.page.iconUnPublished }}
          </v-icon>
          <v-progress-circular v-else :size="20" color="primary" class="mr-1" indeterminate />
        </div>
      </template>
      {{getPageStatusText}}
    </v-tooltip>

    <!-- Page Name -->
    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <span v-bind="props" v-if="page.name" class="text-caption font-weight-medium text-truncate">{{
          page.name }}
          </span>        
        <v-badge
          v-if="page.childNodes.length>0 && !isExpanded"
          color="darkGrey"
          :content="page.childNodes.length"
          inline
          >
          <v-tooltip location="top" activator="parent"><span>{{page.childNodes.length}} Sub Pages</span></v-tooltip>
        </v-badge>
      </template>
      <span v-if="userIsOwner">Drag to reorder Pages</span>
      <span v-else>Do not have access to drag page.</span>
    </v-tooltip>

    <v-spacer></v-spacer>

    <!-- Save Button -->
    <v-btn v-if="isSelectedPage" :disabled="saveChangesIconState"
      :loading="saveLoading" @click="savePageChanges" size="x-small" icon
      variant="text">
      <v-tooltip location="top" activator="parent">Save Changes</v-tooltip>
      <v-icon size="18" :color="userIsOwner ? 'primary' : 'darkSlate'" icon dark>
        {{ icons.save }}
      </v-icon>
    </v-btn>

    <!-- Share Button -->
    <span>
      <PageSharing :page="page" />
      <v-tooltip location="top" :disabled="shareIconState" activator="parent">
        <span>{{page.users.length}} {{ page.users.length == 1? 'User' : 'Users' }}</span>
      </v-tooltip>
    </span>

    <!-- menu options -->
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" :disabled="!showMenuOption(200)" icon variant="text" size="x-small">
          <v-icon size="18" :color="userIsOwner ? 'primary' : 'darkSlate'" class="mx-1">
            {{ icons.menu}}
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item
            v-for="menuItem in menuItems"
            :key="menuItem.title"
            @click="menuItem.handler"
            v-show="menuItem.showCondition"
          >
            <template v-slot:prepend>
              <v-icon :color="menuItem.iconColor" >{{menuItem.icon}}</v-icon>
            </template>
            <v-list-item-title >{{menuItem.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-card>

  <EditData 
    :dialog="dialog"
    @toggle="dialog = !dialog"
    @actionClicked="dialogAction"
    :textInput="dialogTextInput"
    :actionName="dialogActionName"
    :textLabel="dialogTextLabel"
    :loading="dialogLoading"
    >{{dialogTitle}}
  </EditData>

  <AreYouSure
    :dialog="areYouSureDialog"
    @toggle="areYouSureDialog = !areYouSureDialog"
    :disabled=null
    :loading="areYouSureLoading"
    @actionClicked="areYouSureAction"
  >{{ areYouSureMessage }}
  </AreYouSure>
</template>

<script>
import PageSharing from "@/components/popUps/PageSharing.vue";
import PillButton from "@/components/ui/PillButton.vue";
import AreYouSure from "@/components/popUps/AreYouSure.vue";
import ProgressCircular from "@/components/ui/ProgressCircular.vue";
import EditData from "@/components/popUps/EditData.vue"
import * as utils from "@/utilities";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: {
    PageSharing,
    PillButton,
    AreYouSure,
    ProgressCircular,
    EditData
  },
  props: ["page", "isExpanded"],
  setup(){
    const notebookPropsStore = useNotebookPropsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getGlobalProperty,
      getPagesStructuredData,
      getAllPageStates,
      getWidgets,
    } = storeToRefs(notebookPropsStore)
    const {
      getSelectedLayoutId,
      getUser,
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      getSelectedPageDrawings,
      getSelectedPageModels,
    } = storeToRefs(assetsStore)
    const {
      setWidgets,
      setGlobalProperty,
      setSelectedPage,
      setPagesStructuredData,
      setLoadingScreen
    } = notebookPropsStore
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore
    const {
      setSelectedLayoutId
    } = ellipseStore
    return {notebookPropsStore, assetsStore,
      getGlobalProperty, getSelectedPageDrawings, getSelectedPageModels, getPagesStructuredData,
      getAllPageStates, getNotebook, getWidgets, getSelectedLayoutId, getUser,
      setWidgets, setGlobalProperty, setAllAssetsInNotebook, setSelectedPage,  
      setSelectedLayoutId, setPagesStructuredData, setLoadingScreen, setSelectedPageDataset, 
      lexicon, messages, icons
    }
  },
  data() {
    return {
      publishLoading: false,
      saveLoading: false,

      dialog: false,
      dialogTitle: "",
      dialogAction: null,
      dialogActionName: "",
      dialogTextLabel: "",
      dialogTextInput: "",
      dialogLoading: false,

      areYouSureDialog: false,
      areYouSureLoading: false,
      areYouSureAction: null,
      areYouSureMessage: "",
    }
  },
  emits: ['toggleExpand'],
  computed: {
    menuItems(){
      return [
        {
          title: this.page.isPublic ? this.messages.unpublish : this.messages.publish,
          icon: this.page.isPublic ? this.lexicon.page.iconUnPublished : this.lexicon.page.iconPublish,
          iconColor: 'primary',
          handler: this.publishUnPublishPage,
          showCondition: this.showMenuOption(400)
        },
        {
          title: this.messages.addSubPage,
          icon: this.lexicon.page.iconSub,
          iconColor: 'black',
          handler: this.clickOnAddSubPage,
          showCondition: this.showMenuOption(400) && !this.page.parent
        },
        {
          title: this.lexicon.duplicate,
          icon: this.icons.duplicate,
          iconColor: 'black',
          handler: this.clickOnDuplicatePage,
          showCondition: this.showMenuOption(200)
        },
        {
          title: this.lexicon.rename,
          icon: this.icons.rename,
          iconColor: 'black',
          handler: this.clickOnEditPage,
          showCondition: this.showMenuOption(500)
        },
        {
          title: this.lexicon.del,
          icon: this.icons.remove,
          iconColor: 'black',
          handler: () => this.clickOnPurgePage('delete'),
          showCondition: this.showDeleteMenu(500) && this.userIsOwner && this.getAllPageStates.length > 1
        },
        {
          title: this.lexicon.cancel,
          icon: this.icons.cancel,
          iconColor: 'black',
          handler: () => this.clickOnPurgePage('remove'),
          showCondition: this.showMenuOption(200) && !this.userIsOwner && this.getAllPageStates.length > 1
        }
      ]
    },
    getPageStatusText(){
      if (this.page.isPublic && this.userIsOwner) {
        return "Owned and Published";
      } else if (this.page.isPublic && !this.userIsOwner) {
        return "Shared and Published";
      } else if (!this.page.isPublic && this.userIsOwner) {
        return "Owned and Unpublished";
      } else if (!this.page.isPublic && !this.userIsOwner) {
        return "Shared and Unpublished";
      }
    },
    saveChangesIconState() {
      const hasPagePermissions = this.page.userPermissions.permissions >= 400;
      const canEditPublicPage = 
        this.page.isPublic && 
        (this.getNotebook.userPermissions.permissions >= 400 || 
        this.getNotebook.createdBy.user._id === this.getUser._id);

      return !(hasPagePermissions || this.userIsOwner || canEditPublicPage);
    },
    shareIconState() {
      if (this.page.isPublic) {
        return true;
      }
      const hasPagePermissions = this.page.userPermissions.permissions >= 200;
      return !(hasPagePermissions || this.userIsOwner);
    },
    isSelectedPage(){
      return this.page._id == this.$route.params.pageId;
    },
    userIsOwner(){
      if (this.page.createdBy.user._id === this.getUser._id) return true
      else return false
    },
  },
  created(){
  },
  mounted(){
  },
  methods: {
    togglePageExpand(){
      this.$emit('toggleExpand')
    },
    async publishUnPublishPage() {
      this.publishLoading = true
      if (this.page.isPublic) {
        let unPublishPage = await this.$auth.$api.get(`/api/page/${this.page._id}/unpublish`);
      } else {
        let publishPage = await this.$auth.$api.get(`/api/page/${this.page._id}/publish`);
      }
      await this.setPagesStructuredData(this.$route.params.id);
      this.publishLoading = false
    },
    showMenuOption(permissionLevel){
      const hasPagePermissions = this.page.userPermissions.permissions >= permissionLevel;
      const canEditPublicItem = 
        this.page.isPublic &&
        (this.getNotebook.userPermissions.permissions >= permissionLevel ||
        this.getNotebook.createdBy.user._id === this.getUser._id);

      return hasPagePermissions || this.userIsOwner || canEditPublicItem;
    },
    showDeleteMenu(permissionLevel){
      const hasItemPermissions = this.page.userPermissions.permissions >= permissionLevel;
      return hasItemPermissions || this.userIsOwner;
    },
    async savePageChanges(){
      this.saveLoading = true
      await utils.saveCurrentState()
      this.snackbarColor = "primary";
      this.snackbarMessage = "Page Saved";
      this.snackbar = true;
      this.saveLoading = false;
    },
    clickOnAddSubPage(){
      this.dialogTitle = "Add Sub-Page" 
      this.dialogAction = this.createNewSubPage
      this.dialogTextLabel = "Name"
      this.dialogActionName = "Add"
      this.dialog = true
    },
    clickOnEditPage(){
      this.dialogTitle = "Rename Page"
      this.dialogAction = this.renamePage
      this.dialogTextLabel = "Name"
      this.dialogTextInput = this.page.name
      this.dialogActionName = "Save"
      this.dialog = true
    },
    clickOnDuplicatePage(){
      this.dialogTitle = "Duplicate Page"
      this.dialogAction = this.duplicatePage
      this.dialogTextLabel = "Name"
      this.dialogActionName = "Copy"
      this.dialog = true
    },
    clickOnPurgePage(purgeType){
      if (purgeType == 'delete'){
        this.areYouSureMessage = "Are you sure you want to delete the Page?"
      } 
      if (purgeType == 'remove'){
        this.areYouSureMessage = "Are you sure you want to remove the Page?"
      }
      this.areYouSureAction = () => this.purgePage(purgeType)
      this.areYouSureDialog = true
    },
    async renamePage(newPageName){
      this.dialogLoading = true;
      let postData = {
        name: newPageName,
      };
      let renamedPage = await this.$auth.$api.post(
        `/api/page/${this.page._id}/edit-info`,
        postData
      );
      await this.setPagesStructuredData(this.$route.params.id);
      this.resetDialogInputs()
    },
    async createNewSubPage(newPageName){
      this.dialogLoading = true;
      let postData = {
        name: newPageName,
        clone: false,
        parent: this.page,
        notebook: this.$route.params.id,
      };
      let newPageOrSection = await this.$auth.$api.post(`/api/pages/new`, postData);
      await this.setPagesStructuredData(this.$route.params.id);
      await this.newPageClicked(newPageOrSection.data.data)
      this.resetDialogInputs()
    },
    async duplicatePage(newPageName){
      this.dialogLoading = true
      let pageState = this.getAllPageStates.find(
        (p) => p.page === this.page._id
      );
      let clonedWidgets = pageState.widgets.map((e) => {
        return {
          x: e.x,
          y: e.y,
          w: e.w,
          h: e.h,
          i: e.i,
          minW: e.minW,
          minH: e.minH,
          local_setting: {
            is_locked: e.local_setting.is_locked,
            foreground_color: e.local_setting.foreground_color,
          },
          content: e.content,
          instance_setting: e.instance_setting,
        };
      });
      let postData = {
        pageId: this.page._id,
        name: newPageName,
        parent: this.page.parent,
        clone_state: "current_state",
        layout: this.getSelectedLayoutId,
        notebook: this.$route.params.id,
        widgets: clonedWidgets,
        currentGlobalProperties: pageState.global_setting,
      }  
      delete postData.currentGlobalProperties._id;

      let result = await this.$auth.$api.post(`/api/pages/clone-page`, postData);
      
      utils.emptyPreviousState();
      
      await this.setPagesStructuredData(this.$route.params.id);
      this.$router
        .push(
          "/notebook/" +
            this.getNotebook._id +
            "/page/" +
            result.data.data.page._id
        )
        .catch((err) => {});

      await utils.clickOnPageFunction(result.data.data.page);
    },
    async purgePage(purgeType){
      this.areYouSureLoading = true
      let pageIsChild = this.page.parent
      if (purgeType == 'delete'){
        let route = pageIsChild ? 
        `/api/page/${this.page._id}/delete` : 
        `/api/page/${this.page._id}/delete-section`
        
        await this.$auth.$api.delete(route)
          .then(async response => {
            console.log('response', response)
            await this.setPagesStructuredData(this.$route.params.id);
          })
          .catch((e) => {
            console.log("Error", e);
          });
      }
      else {
        let route = `/api/page/${this.page._id}/remove-self`
        this.$auth.$api.post(`/api/page/${this.page._id}/remove-self`)
          .then(async (remove_self) => {
            console.log("removeSelf", remove_self);
            await this.setPagesStructuredData(this.$route.params.id);
          })
          .catch((err) => {
            console.log("Err in remove", err);
          });
      }
      this.areYouSureLoading = false
      this.areYouSureDialog = false
      this.areYouSureAction = null
      this.areYouSureMessage = ""
    },
    async newPageClicked(newPage) {
      if (!newPage) newPage = this.page
      let pageType = this.$route.name;
      this.$router
        .push("/notebook/" + this.getNotebook._id + '/' + pageType + '/' + newPage._id)
        .catch((err) => {});
      await utils.clickOnPageFunction(newPage);
    },
    resetDialogInputs(){
      this.dialogTitle = ""
      this.dialogAction = null
      this.dialogActionName = "",
      this.dialogTextLabel = ""
      this.dialogTextInput = ""
      this.dialogLoading = false
      this.dialog = false
    },
  }
}
</script>

<template>
  <v-dialog :model-value="dialog" max-width="500">
    <v-card>
      <v-card-title>
        <slot></slot>
      </v-card-title>
      <div class="mt-2">
        <v-text-field
          class="popUpTextField"
          variant="outlined"
          hide-details
          v-model="text"
          :label="textLabel"
        ></v-text-field>
        <v-textarea
          v-if="description"
          class="popUpTextField"
          variant="outlined"
          hide-details
          v-model="description"
          rows="2"
          label="Description"
        ></v-textarea>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-white"
          color="darkGrey"
          @click="cancel"
          >{{ lexicon.cancel }}</v-btn
        >
        <v-btn
          dark
          color="primary"
          @click="$emit('actionClicked', text)"
          :disabled="disabled || text == ''"
          :loading="loading"
          >{{ actionName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";

export default {
  setup(){
    return { lexicon };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    loading: {
        type: Boolean,
        required: true
    },
    textLabel: {
      type: String,
      required: false
    },
    descriptionSlot: {
      type: String,
      required: false
    },
    actionName: {
      type: String,
      required: false,
      default: 'Save'
    },
    textInput: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      description:"",
      text: ""
    }
  },
  watch:{
    textInput:{
      deep: true,
      immediate: true,
      handler: function(newVal, oldVal) {
        this.text = newVal;
      }
    },
    descriptionSlot:{
      deep: true,
      immediate: true,
      handler: function(newVal, oldVal) {
        this.description = newVal;
      }
    },
  },
  mounted(){
  },
  methods: {
    cancel(){
      this.text = ""
      this.$emit('toggle')
    }
  }
}
</script>
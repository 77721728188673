<template>
  <div class="sidePanelMargin">
    <AssetsManage :assetType="'3D_widget'" :description="description" />
  </div>
</template>

<script>
import AssetsManage from "./SidePanelAssetsManage.vue";

export default {
  props: {
    description:{
      type:String, 
      default:""
  }},
  components: { AssetsManage },
  setup() {
  },
  computed: {
  },
  async mounted() {},
};
</script>

<template>
  <div class="sidePanelMargin">
    <div class="sidePanelHeader">
      <!-- Tab Header. -->
      <div class="sidePanelHeaderInner">
        <span class="header">Comments</span>

        <!-- Refresh Button. -->
        <v-btn class="ml-0" size="small" icon variant="text" @click="refreshComments">
          <v-icon color="secondary" size="small" :class="{ 'rotate-icon': loadingComments }">
            {{ icons.refresh }}
          </v-icon>
          <v-tooltip activator="parent" location="top">Refresh</v-tooltip>
        </v-btn>
      </div>

      <!-- Add Comment And Cancel Buttons. -->
      <div>
        <v-btn :disabled="getNotebook.userPermissions.permissions <= 100" v-if="!showEmptyCommentCard" size="small" color="primary" class="mt-1" @click="createComment()"> 
            <v-icon small>{{ icons.addComment }}</v-icon>&nbsp; Add Comment
        </v-btn>
        <v-btn v-else size="small" variant="outlined" color="darkSlate" class="mt-1" @click="cancelComment()"
          ><v-icon small>{{ icons.cancel }}</v-icon>&nbsp; Cancel</v-btn>
      </div>
    </div>

    <!-- Divider. -->
    <div class="divider">
      <v-divider></v-divider>
    </div>

    <!-- Comment Filtering Buttons. -->
    <div class="d-flex justify-space-between">
      <v-chip-group
        selected-class="text-primary"
        v-model="activeFilters" 
        multiple
        @update:modelValue="filterComments">

        <v-chip 
          v-for="filter in allFilters" :value="filter"
          :key="filter" size="small" filter>
          {{filter}}
        </v-chip>
      </v-chip-group>
    </div>

    <div style="height: 10px"></div>

    <!-- Parent Empty Comment Card-->
    <EmptyCommentCard
      v-if="showEmptyCommentCard"
      @commentAdded="commentAdded"
      @cancelClick="cancelComment"
    ></EmptyCommentCard>


    <!-- Array of comments -->
    <div v-if="filteredComments.length">
      <!-- Parent comments card -->
      <v-card
        v-for="(comment, index) in filteredComments"
        :key="index"
        class="commentCard"
        :class="isSelected(comment) ? 'selected' : ''"
        elevation="0"
        >
        <CommentCard
          :commentInstance="comment"
        ></CommentCard>

        <template v-if="comment.childNodes.length > 0 && comment.showReplies">
          <div v-for="(childComment, index) in comment.childNodes" :key="index">
            <!-- Child Comment -->
            <CommentCard
              :commentInstance="childComment"
            ></CommentCard>
          </div>
        </template>
      </v-card>

    </div>
  </div>
</template>

<script>
import CommentCard from "@/components/ui/CommentCard.vue";
import EmptyCommentCard from "@/components/ui/EmptyCommentCard.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useCommentsStore} from "@/store/CommentsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";
import * as icons from "@/utilities/EllipseIcons.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";

export default {
  components: {
    CommentCard,
    EmptyCommentCard
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const commentsStore = useCommentsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedElements,
      getSelectedPage,
    } = storeToRefs(notebookPropsStore)
    const {
      getAllComments,
      getSelectedComment,
      getSelectedCommentFromFeed,
    } = storeToRefs(commentsStore)
    const {
      getUser,
      getNotebook,
    } = storeToRefs(ellipseStore)
    const { 
      updateAllCommentsInStore,
      setSelectedComment,
      setSelectedCommentFromFeed,
    } = commentsStore
    return {notebookPropsStore, commentsStore,
      getSelectedElements, getSelectedPage, getNotebook, getUser,
      getSelectedComment, getAllComments, getSelectedCommentFromFeed,
      updateAllCommentsInStore, setSelectedComment, setSelectedCommentFromFeed, icons, lexicon
    }
  },
  data() {
    return {
      showEmptyCommentCard: false,
      loadingComments: false,
      selectionElementFilter: false,
      ownedCommentsFilter: false,
      pageCommentsFilter: false,
      selectedComment: "",
      selectedChildComment: "",
      filteredComments: [],
      activeFilters: [],
      allFilters: ['Pinned Elements', 'Owned', 'Page']
    };
  },
  computed: {
  },
  async created() {
    this.loadingComments = true
    await this.refreshComments();
    if (this.getSelectedCommentFromFeed) {
      this.setSelectedComment(this.getSelectedCommentFromFeed)
    }
    this.filteredComments = this.getAllComments
    this.loadingComments = false
    console.log('allComments', this.filteredComments)
  },
  mounted(){
    this.commentsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'updateAllCommentsInStore') {
          this.filterComments()
        }
      })
    })
  },
  computed: {
  },  
  methods: {
    filterComments(){
      this.loadingComments = true
      if ((this.activeFilters && this.activeFilters.length == 0)) {
        this.filteredComments = this.getAllComments
        this.loadingComments = false
        return 
      }
      
      var filteredComments = this.getAllComments
      this.activeFilters.forEach((filter, index) => {
        if (filter == 'Pinned Elements'){
          let curComments = []
          filteredComments.forEach(comment => {
            if (comment.elements.length > 0){
              curComments.push(comment)
            }
          })
          filteredComments = curComments
        }
        if (filter == 'Owned'){
          let curComments = []
          filteredComments.forEach(comment => {
            if (comment.createdBy._id == this.getUser._id){
              curComments.push(comment)
            }
          })
          filteredComments = curComments
        }
        if (filter == 'Page'){
          let curComments = []
          filteredComments.forEach(comment => {
            comment.page.forEach(page => {
              if (page == this.getSelectedPage._id){
                curComments.push(comment)
              }
            })
          })
          filteredComments = curComments
        }
      });
      this.filteredComments = filteredComments
      this.loadingComments = false
    },
    isSelected(comment){
      if (this.getSelectedComment && this.getSelectedComment._id == comment._id) return true
    },
    async refreshComments() {
      this.loadingComments = true;
      await this.updateAllCommentsInStore();
      this.loadingComments = false;
    },
    async createComment() {
      this.showEmptyCommentCard = true;
    },
    async cancelComment() {
      this.showEmptyCommentCard = false;
    },
    async commentAdded() {
      this.updateAllCommentsInStore()
      this.showEmptyCommentCard = false;
    },
  },
};
</script>

<style scoped>
.commentCard {
  margin-bottom: 4px !important;
  padding: 0px;
  border: 1px solid rgb(var(--v-theme-darkGrey));
}
.commentCard.selected {
  border: 2px solid rgb(var(--v-theme-primary));
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.rotate-icon {
  animation: spin 1s linear infinite;
}
</style>

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getInstance } from "../auth/index";
import router from "@/router";
export const useEllipseStore = defineStore('EllipseStore', () =>{

  //State Variables
  const notebooks = ref(null)
  const notebook = ref(null)
  const layouts = ref([])
  const selectedLayoutId = ref(null)
  const templates = ref([])
  const user = ref(null)

  //Getters
  const getNotebooks = computed(() => notebooks.value)
  const getNotebook = computed(() => notebook.value)
  const getLayouts = computed(() => layouts.value)
  const getSelectedLayoutId = computed(() => selectedLayoutId.value)
  const getTemplates = computed(() => templates.value)
  const getUser = computed(() => user.value)

  //Actions
  async function setNotebooks() {
    const authService = getInstance();
    let all_notebooks = await authService.$api.get("/api/notebooks/get-all");
    notebooks.value = all_notebooks.data.data.notebooks;
  }
  async function setNotebook(data) {
    const authService = getInstance();
    let params = {urlType : data.urlType}
    await authService.$api.get(`/api/notebook/${data.id}/info`,{ params })
    .then(async (returnNotebook) => {
      if(data.urlType === "Visitor"){
        returnNotebook.data.notebook.userPermissions.permissions = 100;
        user.value =  returnNotebook.data.user;
      }
      notebook.value = returnNotebook.data.notebook;
    })
    .catch((e) => {
      console.log("Error", e);
      router.push("/401").catch(err => { });
    });
  }
  async function setLayouts(userId) {
    const authService = getInstance();
    let l = await authService.$api.get(`/api/layout/${userId}/get_user_layout`);
    layouts.value = l.data.data.layout;
  }
  async function setTemplates() {
    const authService = getInstance();
    let allTemplates = await authService.$api.get(`/api/templates/get_templates`);
    templates.value = allTemplates.data.data;
  }
  async function setSelectedLayoutId(layoutId) {
    selectedLayoutId.value = layoutId
  }
  async function setUser(newUser) {
    user.value = newUser
  }


  return {
    notebooks,
    notebook,
    layouts,
    selectedLayoutId,
    templates,
    user,

    getNotebooks,
    getNotebook,
    getLayouts,
    getSelectedLayoutId,
    getTemplates,
    getUser,

    setNotebooks,
    setNotebook,
    setLayouts,
    setSelectedLayoutId,
    setTemplates,
    setUser,
  }
})
export class MapStyleManager {
    constructor() {
      this.validTypes = ['default', 'highlighted', 'selected']

      // Default style properties
      this.defaultColor = '#343a40';  // Default to black
      this.highlightedColor = '#7C4DFF';    // Default to red
      this.selectedColor = '#6200EA'; // Default to green
      this.outlineColor = '#E91E63';    // Default to black
  
      this.defaultOpacity = 1; // For fill layers
      this.defaultRadius = 5;  // For circle layers
      this.defaultWidth = 2;   // For line layers

    }
  
    // Setters for colors and styles
    setDefaultColor(color) {
      this.defaultColor = color;
    }
  
    setHighlightColor(color) {
      this.highlightedColor = color;
    }
  
    setSelectedColor(color) {
      this.selectedColor = color;
    }

    setOutlineColor(color) {
      this.outlineColor = color;
    }

    setDefaultOpacity(opacity) {
      this.defaultOpacity = opacity;
    }
  
    setDefaultRadius(radius) {
      this.defaultRadius = radius;
    }
  
    setDefaultWidth(width) {
      this.defaultWidth = width;
    }
  
    // Style retrieval methods
    default(layerType) {
      return this.getPaint('default', layerType);
      //return this.getStyle(layerType, this.defaultColor);
    }
  
    highlighted(layerType) {
      return this.getPaint('highlighted', layerType);
      //return this.getStyle(layerType, this.highlightedColor);
    }
  
    selected(layerType) {
      return this.getPaint('selected', layerType);
      //return this.getStyle(layerType, this.selectedColor);
    }

    getStyleProfile(styleType){
      switch (styleType) {
        case 'default':
          return {
            color: this.defaultColor,
            opacity: this.defaultOpacity,
            width: this.defaultWidth,
            radius: this.defaultRadius, 
          }
        case 'highlighted':
          return {
            color: this.highlightedColor,
            outlineColor: this.outlineColor,
            opacity: this.defaultOpacity,
            width: this.defaultWidth,
            radius: this.defaultRadius*2,
          }
        case 'selected':
          return {
            color: this.selectedColor,
            opacity: this.defaultOpacity,
            width: this.defaultWidth,
            radius: this.defaultRadius*1.5,
          }
        default:
          throw new Error('Invalid color type specified.');
      }
    }

    getPaint(styleType, layerType){
      if(this.validTypes.includes(styleType) == false) throw new Error('Invalid style type specified.');

      let styleProfile = this.getStyleProfile(styleType)
      let paint = {}
      switch (layerType) {
        case 'fill':
          if(styleProfile.color) paint['fill-color'] = styleProfile.color;
          if(styleProfile.opacity) paint['fill-opacity'] = styleProfile.opacity;
          if(styleProfile.outlineColor) paint['fill-outline-color'] = styleProfile.outlineColor;
          if(styleProfile.outlineColor) paint['fill-antialias'] = true;
          return paint
        case 'line':
          if(styleProfile.color) paint['line-color'] = styleProfile.color;
          if(styleProfile.width) paint['line-width'] = styleProfile.width;
          return paint
        case 'circle':
          if(styleProfile.color) paint['circle-color'] = styleProfile.color;
          if(styleProfile.radius) paint['circle-radius'] = styleProfile.radius;
      }
    }

    getColor(colorType){
      switch (colorType) {
        case 'default':
          return this.defaultColor;
        case 'highlighted':
          return this.highlightedColor;
        case 'selected':
          return this.selectedColor;
        default:
          throw new Error('Invalid color type specified.');
      }
    }
  
    getStyle(layerType, color) {
      switch (layerType) {
        case 'fill':
          return {
            'fill-color': color,
            'fill-opacity': 0.6
          };
        case 'line':
          return {
            'line-color': color,
            'line-width': this.defaultWidth
          };
        case 'circle':
          return {
            'circle-color': color,
            'circle-radius': this.defaultRadius
          };
        default:
          throw new Error('Invalid layer type specified.');
      }
    }

    getStyleType(layerType, colorType) {
      let color = this.getColor(colorType);
      return this.getStyle(layerType, color);
    }

    getMapboxLayerType(geometryType){
      const mapboxLayerType = {
        Point: "circle",
        LineString: "line",
        Polygon: "fill",
        MultiPolygon: "fill",
        MultiPoint: "circle",
      }
      return mapboxLayerType[geometryType]
    }
  }
  
  // Usage example:
//   const styleManager = new MapStyleManager();
//   styleManager.setDefaultColor('#FFA500'); // Change default color to orange
  
//   console.log(styleManager.default('circle'));
//   console.log(styleManager.highlighted('fill'));
  
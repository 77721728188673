<template>
  <v-card style="border-right: !important; width:240px;">
    <v-list>
      <template v-for="(item, i) in items" :key="i" >
        <v-list-item :value="item" :to="item.route" v-if="isPublic(item.status)" link>
            <template v-slot:prepend>
              <v-icon size="small" :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title class="pl-1">{{ item.text }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>

    <v-divider></v-divider>

    <v-list>
      <v-list-item v-for="(item, i) in externalitems" :key="i" :href="item.link" :target="'_blank'" link >
          <template v-slot:prepend>
            <v-icon color="primary" :icon="item.icon"></v-icon>
          </template>
          <v-list-item-title > {{item.text}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  components: { },
    props: ["private"],
    setup() {
    return { 
      lexicon,
      icons
    }
  },
  data(){
    return {
    selectedItem: 0,
    items: [
      { text: "Notebooks", icon: lexicon.notebook.icon , route: "/dashboard/notebooks" , status:false},
      { text: "Templates", icon: lexicon.template.icon , route: "/dashboard/templates" , status:false },
      { text: "Page Layouts", icon: lexicon.layout.icon , route: "/dashboard/layouts" , status:false },
      { text: "Downloads", icon: icons.download , route: "/pluginDownloads" , status:false },
      { text: "About", icon: icons.about , route: "/about" , status:true },
    ],
    externalitems:[
      { text: "User Guide", icon: icons.userGuide , link: "https://core-studio.gitbook.io/ellipse/" },
      { text: "Contact", icon: icons.contact , link:"mailto:CORE@ThorntonTomasetti.com" },
    ],
    showFeed: true,
    }
  },
  methods: {
    isPublic(status) {
      if(this.private)return status;
      return true;
    },
  },
};
</script>
<style scoped></style>

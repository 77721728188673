<template>
  <!-- Gradient Selection -->
  <GradientDropdown 
    :message="message"
    :currentPalette="getGlobalColorByGradient" 
    @new-palette-set="setGlobalPalette"
    :denseMode="denseMode"
    >
  </GradientDropdown>
</template>

<script>
import GradientDropdown from "../ui/GradientDropdown.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

export default {
  components: {GradientDropdown},
  props: {
    message:{
      type:String,
      default:"Gradient"
    },
    denseMode:{
      type:Boolean, 
      default:true
    }
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getGlobalColorByGradient,
    } = storeToRefs(notebookPropsStore)
    const {
      updateColorByProperties
    } = dataGraphicsStore
    return {notebookPropsStore, getGlobalColorByGradient, updateColorByProperties}
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    setGlobalPalette(newPalette) {
      // If the selected gradient is new, update value in store
      let isNewGradient = !this.arrayEquals(this.getGlobalColorByGradient, newPalette);
      if (isNewGradient){
        this.updateColorByProperties({gradient: newPalette});
      }
    },
    arrayEquals(a, b) {
      return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
    },
  },
};
</script>
<style scoped>
.col {
    padding: 0px !important
}
</style>
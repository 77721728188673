import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { getInstance } from "../auth/index";
import {useNotebookPropsStore} from "@/store/NotebookPropsStore.js"
import {useEllipseStore} from "@/store/EllipseStore.js"
import router from "@/router";

export const useCommentsStore = defineStore('comments', () =>{

  //State Variables
  const allComments = ref([])
  const selectedCommentFromFeed = ref(null)
  const selectedComment = ref(null)
  
  //Getters
  const getAllComments = computed(() => allComments.value)
  const getSelectedCommentFromFeed = computed(() => selectedCommentFromFeed.value)
  const getSelectedComment = computed(() => selectedComment.value)

  //Actions
  async function updateAllCommentsInStore() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    let params = {
      notebook: ellipseStore.getNotebook._id,
      //page: notebookPropsStore.getSelectedPage._id,
      urlType: router.currentRoute._value.name
    };
    const authService = getInstance();
    let returnedComments = await authService.$api.get(
      `/api/comments/get-all-comments-structured`, {params});
    allComments.value = returnedComments.data.data.reverse();
  }
  async function setSelectedCommentFromFeed(comment) {
    selectedCommentFromFeed.value = comment
  }
  async function setSelectedComment(comment) {
    selectedComment.value = comment
  }

  return {
    allComments,
    selectedCommentFromFeed,
    selectedComment,
    getAllComments,
    getSelectedCommentFromFeed,
    getSelectedComment,
    updateAllCommentsInStore,
    setSelectedCommentFromFeed,
    setSelectedComment
  }
})
<template>
  <!-- Data Table Widget properties -->
  <div>
    <v-container>    

      <v-row width="100%">
        <div class="text-caption pt-0">
          <strong>Attributes</strong>
        </div>
        <v-divider/>
      </v-row>

      <!-- Data Set Size -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <ChipGroup
          :options="data_headers" 
          :selections="selection" 
          @table-changed="updatedSelection" 
        />
      </v-row>


      <v-row width="100%">
        <div class="text-caption pt-0">
          <strong>Display</strong>
        </div>
        <v-divider/>
      </v-row>

      <!-- highlight toggle -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.showHighlights"
          label="Show Highlights"
          color="primary"
        />
      </v-row>

      <!-- Filters toggle -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.showFilters"
          label="Show Filters"
          color="primary"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";
import { storeToRefs } from 'pinia';


export default {
  components:{
    ChipGroup,
  },
  props:{
    getSelectedWidget:{},
  },
  setup() {
  },
  data:()=>({
    selection: [],
    data_headers: [],
    file_headers: [
      'name',
      'type',
      'size',
      //'dateUploaded',
      //'uploadedBy'
    ],
    loaded: false,
  }),
  computed:{
  },
  async mounted(){
    if(this.getSelectedWidget.content == 'viewFileAttachments'){
      await this.loadTableHeaders();
    }
  },
  watch:{
    'getSelectedWidget':{
      deep: true,
      immediate: true,
      handler: function() {
        if(this.getSelectedWidget.content == 'viewFileAttachments'){
          this.loadTableHeaders();
        }       
      }
    }
  },
  methods:{
    updatedSelection(selected){
      this.selection = selected;
      this.getSelectedWidget.instance_setting.data.selectedAttrs = selected
    },
    async loadTableHeaders(){
      this.loaded = false;
      this.data_headers = this.file_headers;

      if (this.getSelectedWidget.instance_setting.data) { 
        if (this.getSelectedWidget.instance_setting.data.selectedAttrs.length) {
          this.selection =
          this.getSelectedWidget.instance_setting.data.selectedAttrs;
        }
      } else {
        this.selection = this.file_headers;
      }
      this.loaded = true;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="d-flex">
    <v-dialog v-model="addMentionsDialog" width="500">
      <template v-slot:activator="{ props}">
        <v-list density="compact" class="pa-0">
          <v-list-item class="pa-0" v-bind="props">
            <template v-slot:prepend>
                <v-icon>{{ lexicon.user.iconAdd }}</v-icon> 
              </template>
              <v-list-item-title>Add User</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      
      <v-card>
        <v-card-title class="">
          <v-icon size="small" class="mr-2" color="primary">{{ lexicon.user.icon }}</v-icon> 
          Add Mentions
        </v-card-title>
        <div class="d-flex align-center">
          <v-text-field
            v-model="email"
            label="Add Mention"
            placeholder="Add Mention"
            :rules="emailRules"
            clearable
            clear-icon="mdi:mdi-close-circle"
            class="ml-8"
            color="primary"
            variant="underlined"
          >
          </v-text-field>
          <v-btn
            :disabled="emailCheck"
            :loading="addUserLoading"
            class="mr-8 ml-2"
            icon
            elevation="0"
            size="x-small"
            color="primary"
            @click="addUser"
            ><v-icon>{{ icons.plus }}</v-icon>
            <v-tooltip activator="parent" location="top" :disabled="!emailCheck">
              Please Enter Valid Email Id
            </v-tooltip>
          </v-btn>
        </div>
        <div class="mx-5">
          <v-table
            style="min-height: 10vh; max-height: 40vh; overflow-y: auto"
            densit="compact"
          >
            <tbody>
              <tr v-for="(user, index) in mentionsUsersArray" :key="index">
                <td style="width: 10px">
                  <v-icon color="primary" size="x-small">{{ icons.plus }}</v-icon>
                </td>
                <td class="pa-1">
                  <span :class="user.delete ? 'text-decoration-line-through' : 'none'">{{ user.email }}</span>
                </td>
                <td>
                  <v-btn variant="text" icon @click="toggleRemoveMentions(index)">
                    <v-icon size="x-small"
                    >{{
                      user.delete
                        ? "fa-solid fa-arrow-rotate-right"
                        : "fa-solid fa-xmark"
                    }}</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <div class="d-flex justify-end">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-5 my-5" :loading="saveMentionsLoading" 
              @click="saveMentions" color="primary">
              Done
            </v-btn>
          </v-card-actions>
        </div>
        <v-snackbar
          v-model="snackbar"
          :timeout="2000"
          absolute
          location="bottom"
          color="error"
        >
          {{ message }}
          </v-snackbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {useCommentsStore} from "@/store/CommentsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";
import { storeToRefs } from 'pinia';
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: ['comment'],
  data() {
    return {
      addMentionsDialog: false,
      saveMentionsLoading: false,
      emailRules: [
        (v) =>
          v === "" ||
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) ||
          "E-mail must be valid",
      ],
      email: "",
      message: "",
      addUserLoading: false,
      snackbar: false,
      mentionsUsersArray: [],
      editedRemovedIndex: -1,
    };
  },
  setup() {
    const commentsStore = useCommentsStore()
    const ellipseStore = useEllipseStore()
    
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      getSelectedComment,
    } = storeToRefs(commentsStore)
    const {
      updateAllCommentsInStore
    } = commentsStore
    return { commentsStore,
      getSelectedComment, getNotebook,
      updateAllCommentsInStore, icons, lexicon
    }
  },
  computed: {
    emailCheck() {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.email)) {
        return false;
      } else return true;
    },
  },
  created() {},
  mounted() {
    if (JSON.stringify(this.getSelectedComment) !== "{}") {
      this.getMentionsUsers();
    }
  },

  methods: {
    getMentionsUsers() {
      this.mentionsUsersArray = [];
      this.getSelectedComment.mentions.forEach((e) => {
        let obj = {
          email: e.email,
          id: e._id,
          delete: false,
        };
        this.mentionsUsersArray.push(obj);
      });
    },
    async addUser() {
      this.addUserLoading = true;
      var temp = this.getSelectedComment.mentions.find((u) => {
        if (u.email) {
          if (u.email.toLowerCase() === this.email.toLowerCase()) return true;
        } else return false;
      });
      if (!temp) {
        let result = this.getNotebook.users.find(user => {
          let notebookUserEmail = user.email ? user.email : user.user.email
          if (notebookUserEmail.toLowerCase() === this.email.toLowerCase())
            return user
        });
        if (result) {
          let obj = {
            email: this.email.toLowerCase(),
            id: result.user ? result.user._id : result._id,
            delete: false,
          };
          this.mentionsUsersArray.push(obj);
          this.email = "";
        } else {
          this.message = "This page is not shared with this user";
          this.snackbar = true;
        }
      } else {
        this.message = "The user is already added";
        this.snackbar = true;
      }
      this.addUserLoading = false;
    },
    async saveMentions() {
      this.saveMentionsLoading = true;
      let mentionsIds = this.mentionsUsersArray
        .filter((obj) => {
          return !obj.delete;
        })
        .map((obj) => {
          return obj.id;
        });
      let fileIds = this.comment.files.map((f) => {
        return f._id;
      });
      let commentCopy = JSON.parse(JSON.stringify(this.comment))
      commentCopy.mentions = mentionsIds;
      commentCopy.files = fileIds;
      
      let editComment = await this.$auth.$api.post(
        `/api/comment/${this.comment._id}/edit-info`,
        commentCopy
      );
      await this.updateAllCommentsInStore()
      this.saveMentionsLoading = false;
      this.addMentionsDialog = false;

      for (let i = 0; i < this.mentionsUsersArray.length; i++) {
        if (this.mentionsUsersArray[i].delete) {
          this.mentionsUsersArray.splice(i, 1);
        }
      }
      this.$emit('mentionsSaved', this.getSelectedComment)
    },
    async toggleRemoveMentions(index) {
      this.editedRemovedIndex = index;
      this.mentionsUsersArray[this.editedRemovedIndex].delete =
        !!!this.mentionsUsersArray[this.editedRemovedIndex].delete;
    },
  },
};
</script>

<style scoped>
</style>

<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col class="pa-0 ma-0" lg="6" md="12">
        <div class="d-flex" style="height: 10vh">
          <span class="d-flex align-center">
            <v-checkbox
              class="pl-2"
              hide-details
              v-model="checkbox1"
              label="Admins"
            ></v-checkbox
            ><span class="pl-1">({{ adminCount }})</span>
            <v-checkbox
              class="ml-15"
              hide-details
              v-model="checkbox2"
              label="Users"
            ></v-checkbox
            ><span class="pl-1">({{ userCount }})</span></span
          >
        </div>
        <v-data-table
          :items-per-page="10"
          fixed-header
          :headers="headers"
          :items="getAllUsers"
          :hide-default-footer="true"
          :disable-pagination="true"
          return-object
        >
          <template v-slot:item="{ item }">
            <tr
              class="pt-5"
              @click="onUserSelected(item)"
              style="cursor: pointer"
            >
              <td
                :class="
                  selectedName === item.columns.name
                    ? 'font-weight-bold'
                    : ''
                "
              >
                {{ item.columns.name }}
              </td>
              <td
                :class="
                  selectedEmail === item.columns.email ? 'font-weight-bold' : ''
                "
              >
                {{ item.columns.email }}
              </td>
               <td>
                 <v-chip class="justify-center" color="white" :style="item.columns.permission ? `background-color: success; width:80px;` : `background: darkGrey; width:80px;`"> 
                 {{ 
                    item.columns.permission ? "Admin" : "User "
                  }} </v-chip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col class="pl-5" v-if="selectedUser.permissions" lg="4" md="12">
        <div class="d-flex" style="padding-top: 50px">
          <div class="pt-2">
            <v-avatar height="60" width="60">
              <img :src="selectedUser.picture" />
            </v-avatar>
          </div>
          <div class="pl-5 pt-3">
            <span class="text-body">{{ selectedUser.name }}</span>
            <br />
            <span class="text-caption">{{ selectedUser.email }}</span>
          </div>
        </div>
        <div class="pt-5">
          <v-expansion-panels
            density="compact"
            v-model="panel"
            :disabled="disabled"
            multiple
          >
            <v-expansion-panel  elevation="0">
              <v-expansion-panel-title  class="text-h6 font-weight-bold dense-expansion-panel">
                Status</v-expansion-panel-title
              >
              <v-expansion-panel-text class="pl-5 text-caption">
                <div>
                  <v-radio-group density="compact" hide-details v-model="active" column>
                    <v-radio
                      label="Admin"
                      :disabled="ifUser"
                      :value="0"
                      @change="setAdmin()"
                    ></v-radio>
                    <v-radio
                    class="pt-1"
                      label="User"
                      :disabled="ifUser"
                      :value="1"
                      @change="setUser()"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import { useEllipseStore } from "@/store/EllipseStore.js";
export default {
  setup() {
    const ellipseStore = useEllipseStore()
    const {
      getUser
    } = storeToRefs(ellipseStore)
    return {
      getUser
    }
  },
  data() {
    return {
      active:'',
      itemsPerPage: 5,
      selectedName: "",
      selectedEmail: "",
      column: null,
      panel: [0],
      disabled: false,
      selectedUser: {},
      checkbox1: true,
      checkbox2: true,
      headers: [
        {
          title: "Name",
          align: "start",
          sortable: false,
          key: "name",
          width: "200px",
        },
        { title: "Email", key: "email", width: "200px" },
        { title: "Status", key: "permission", width: "100px" },
      ],
      getAllUsers:[]
    };
  },
  computed: {
    ifUser: {
      get() {
        if (this.getUser.permissions.admin) {
          return false;
        } else {
          return true;
        }
      },
      set(newValue) {
        return;
      },
    },
    adminCount() {
      if(this.getAllUsers.length){
        let a = this.getAllUsers.filter((e) => e.permissions.admin);
        return a.length;
      }
    },
    userCount() {
      if(this.getAllUsers.length){
        let a = this.getAllUsers.filter((e) => !e.permissions.admin);
        return a.length;
      }

    },
  },
  async mounted() {
    await this.getAllusersFunc();
  },
  methods: {
    async getAllusersFunc(){
      let users  = await this.$auth.$api.get("/api/users/all-users");
      users.data.data.forEach(e => {
        if(e.permissions.admin){
          e["permission"] = true;
        }else{
          e["permission"] = false;
        }
      })
      this.getAllUsers = users.data.data;
      //console.log("!this.getAllUsers", this.getAllUsers)
    },
    async onUserSelected(item) {
      const proxy1 = new Proxy(item, {});
      this.selectedUser = JSON.parse(JSON.stringify(item)).value;
      this.selectedName = this.selectedUser.name;
      this.selectedEmail = this.selectedUser.email;
      if(this.selectedUser.permissions.admin){
        this.active = 0;
      }else{
        this.active = 1;
      }
 
    },
    async setAdmin() {
      //console.log("setAdmin")
      this.active = 0;
      let updateAdmin = await this.$auth.$api.post(
        `/api/user/${this.selectedUser._id}/make-admin`
      );
      await this.getAllusersFunc();
    },

    async setUser() {
      //console.log("setUser")
      this.active = 1;
      let updateuser = await this.$auth.$api.post(
        `/api/user/${this.selectedUser._id}/make-user`
      );
      await this.getAllusersFunc();
    },
  },
};
</script>
<style scoped>
/* expansion panel header padding */
.v-expansion-panel--active > .v-expansion-panel-title {
  min-height: 0px !important;
}
.v-expansion-panel-title {
  padding: 10px 10px !important;
}

.v-expansion-panel--active:not(:first-child),
.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}
.v-expansion-panel-text__wrap {
  padding: 0px 0px 10px !important;
  flex: 1 1 auto;
  max-width: 100%;
}

.v-input--selection-controls {
  margin-top: 5px !important;
  padding-top: 5px !important;
}
</style>

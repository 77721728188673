import { createVuetify } from 'vuetify/dist/vuetify.js';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/styles'
import { aliases, fa } from 'vuetify/iconsets/fa'

import { mdi } from 'vuetify/iconsets/mdi'
import { md } from 'vuetify/iconsets/md'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components: {
    ...components,
  },
  directives,
  icons: {
    defaultSet: 'fa', aliases,
    sets: {
      fa, mdi, md
    }
  },
  defaults: {
    VMenu: {
      VList: {density: "compact"},
      VListItem: {
        VIcon: {size: "x-small", class: "mx-2"},
        VListItemTitle: {style: "font-size:12px !important", class: "mr-2"},
      },
    },
    VSwitch: {
      color: 'primary',
      density: 'compact',
      hideDetails: true,
    },
    VRow: {
      class: 'ma-0 pa-0',
    },
    VCol: {
      class: 'ma-0 pa-0',
    },
    VColorPicker: {
      elevation: 0,
    },
    VCombobox: {
      density: 'compact',
      hideDetails: true,
      variant: 'underlined',
    },
    VSelect: {
      density: 'compact',
      hideDetails: true,
      variant: 'underlined',
    },
    VRadioGroup: {
      hideDetails: true,
      color: 'primary',
      density: 'compact',
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#6200EA', //dark purple (98,0,234)
          secondary: '#7C4DFF', //light purple (124,77,255)
          tertiary: '#9575CD', //light purple (124,77,255)
          accent: '#FF9800', //orange (255,152,0)
          error: '#d40c37', //red  (212,12,55)
          success: '#6fba1e', //green (111,186,30)
          warning: '#ffbb00', //yellow (255,187,0)
          danger: '#E91E63', //pink 91E63 or red E53935 (233,30,99)
          darkGrey: '#757575', //matte gray (117,117,117)
          background: '#f7f7f7', // off white (247,247,247)
          darkSlate: "#343a40", // matte black ellipse (52,58,64)
        },
      }
    },
  },
});

export default vuetify;
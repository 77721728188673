<template>
  <!-- Image Widget Properties -->
  <div>
    <v-container fluid>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Content</strong></div><v-divider/></v-row>
      <div v-if="getSelectedWidget.instance_setting.data.isEmbed">
        <v-textarea class="pt-4" name="embedInput" label="Paste Embed Code Here" auto-grow variant="outlined" clearable
          v-model="getSelectedWidget.instance_setting.data.embed" color="primary"></v-textarea>
      </div>
      <div v-else>
        <v-text-field class="pt-4" name="urlInput" label="Paste Url Here" variant="outlined" clearable hide-details
          v-model="getSelectedWidget.instance_setting.data.address"></v-text-field>
        <small>Not all websites can be hosted. Sites must allow Cross-Origin Resource Sharing (CORS) and be whitelisted by our security policy</small>
      </div>

      <v-row width="100%"><div class="text-caption pt-0"><strong>Display Settings</strong></div><v-divider /></v-row>
      <v-switch class="pt-0 pl-2" v-model="getSelectedWidget.instance_setting.data.isEmbed" label="Embed" hide-details />
    </v-container>

  </div>
</template>

<script>
export default {
  props: {
    notebook: {},
    getSelectedWidget: {}
  },
  data: () => ({
  }),
  created() {
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped></style>
<template>

<v-menu v-if="getUser.preferences.helpers" offset-y :close-on-content-click="false" open-on-hover activator="parent">
          <v-card :title="title" :subtitle="message" variant="outlined">
            <v-card-actions>
              <v-btn color="secondary" :href="link" target="_blank"> Learn More</v-btn>
            </v-card-actions>
          </v-card>

        </v-menu>

</template>

<script>
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
props: {
icon: {
  type: String,
  required: false,
  default: null,
},
message: {
  type: String,
  required: false,
  default: "Coming Soon..."
},
title: {
  type: String,
  required: false,
  default:"",
},
link: {
  type: String,
  required: false,
  default: false,
},
},
setup(){
const ellipseStore = useEllipseStore()
const {
  getUser,
} = storeToRefs(ellipseStore)
return {
  icons,getUser
}
},
mounted() {
},    
watch:{
},
methods: {
},

};
</script>
<style>
</style>
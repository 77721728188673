<template>
  <v-app>
    <v-layout >
      <!-- Nav Bar. -->
      <v-app-bar  
        color= white;  
        elevation="1" 
        light
        height="40"
      >
        <Navbar></Navbar>
      </v-app-bar>

      <!-- footer (v-layout order sensitivity is weird, so footer has to go before main and nav drawer)-->
      <Footer></Footer>

      <!-- Main Router View. -->
      <v-main
        scrollable 
        class="mainCustom"
        height="100%"
      >
          <!-- Default Route View. -->
          <router-view></router-view>
      </v-main>

      <!-- Side Panel Activated By Route. -->
      <v-navigation-drawer 
        v-if="$route.name === 'Page' || $route.name === 'Notebook' || $route.name === 'Visitor'"
        :rail="!toggleLeftNavigationDrawer"
        permanent
        width="350"
      >
          <!-- Open/Close Drawer Button. -->
          <v-btn 
            icon
            size="small"
            class="mx-0 pa-0" 
            @click.stop="toggleLeftNavigationDrawer = !toggleLeftNavigationDrawer"
            variant="plain"
            style="
              position: absolute; 
              top: 16px; 
              right: 4px; 
              z-index: 1000;"
          >
            <v-icon 
            size="large" 
              :color="toggleLeftNavigationDrawer ? 'background' : 'primary'" 
            > <!-- dafdaf-->  
                {{toggleLeftNavigationDrawer ? 'mdi:mdi-chevron-left' : 'mdi:mdi-chevron-right'}}
            </v-icon>
          </v-btn>

          <!-- Drawer Content. -->
          <div v-show="toggleLeftNavigationDrawer">
            <router-view name="panel"></router-view>
          </div>
      </v-navigation-drawer>
    </v-layout>
  </v-app>
</template>

<script>
import Navbar from "./components/TheNavbar";
import Footer from "./components/TheFooter";

export default {
  name: "App",

  components: {
    Navbar,
    Footer,
  },

  data: () => ({
    toggleLeftNavigationDrawer:true,
  }),
};
</script>
<style scoped>
.dashboard {
  display: grid;
  grid-template-rows: auto, auto, auto;
  height: 100vh;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
</style>
<style>
  /*Style Not Scoped because it targers a Veutify Generated Element. */
  .mainCustom > .v-main__scroller{
  overflow-x: hidden;
  background:white;
  }
</style>

<template>
  <div class="text-center">
    <v-dialog v-model="sharingDialog" width="700">
      <template v-slot:activator="{ props}">
        <v-btn 
          v-bind="props" 
          :disabled="shareIconState" 
          icon 
          size="x-small"
          variant="text"
          @click="sharingDialog = true"
          >
          <v-icon
            :color="
              page.createdBy.user._id === getUser._id
                ? 'primary'
                : 'darkSlate'
            "
            size="17"
            >{{ lexicon.user.iconAdd}}</v-icon
          >
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="">
          <v-icon class="mr-2" color="primary" size="x-small">{{ lexicon.user.iconUser}}</v-icon> 
          Add Users
        </v-card-title>
        <div class="d-flex align-center mt-5">
          <v-text-field
            v-model="email"
            label="Add People"
            placeholder="Add People"
            :rules="emailRules"
            clearable
            clear-icon="mdi:mdi-close-circle"
            class="ml-16"
            variant="underlined"
          >
          </v-text-field>

          <div>
            <v-btn
              :disabled="emailCheck"
              :loading="addUserLoading"
              class="mr-8 ml-2"
              icon
              elevation="0"
              size="x-small"
              color="primary"
              @click="addUser"
              ><v-icon>{{ icons.plus }}</v-icon>
              <v-tooltip location="top" activator="parent" :disabled="!emailCheck">
                Please Enter Valid Email Id
              </v-tooltip>
            </v-btn>
          </div>
        </div>
        <div class="mx-5">
          <v-table
            density="compact"
            style="min-height: 10vh; max-height: 40vh; overflow-y: auto;"
          >
              <tr v-for="(user, index) in formattedUsers" :key="index">
                <td style="width: 10px">
                  <v-icon color="primary" size="x-small">{{ lexicon.user.iconUser}}</v-icon>
                </td>
                <td class="pt-2" style="width: 400px">
                  <div class="pl-3 pb-3 font-weight-light" :class="user.delete ? 'text-decoration-line-through' : ''">
                    <v-text-field
                      :disabled="
                        (user.user ? user.user._id : user._id) ===
                          (page.createdBy.user._id &&
                            user.roles === 'Admin') ||
                        checkPermissionFunc(user.roles) ||
                        user.roles === 'Admin'
                      "
                      variant="solo" 
                      flat
                      hide-details="true"
                      density="compact"
                      v-model="user.email"
                      :rules="emailRules"
                    >
                    </v-text-field>
                  </div>
                </td>
                <td style="width: 150px; cursor:pointer !important;" >
                    <v-menu  :disabled="(user.user ? user.user._id : user._id) ===
                      (page.createdBy.user._id &&
                        user.roles === 'Admin') ||
                      checkPermissionFunc(user.roles) ||
                      user.roles === 'Admin'
                      ">
                          <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" :disabled="(user.user ? user.user._id : user._id) ===
                              (page.createdBy.user._id &&
                                user.roles === 'Admin') ||
                              checkPermissionFunc(user.roles) ||
                              user.roles === 'Admin'
                              "  variant="plain" text  :append-icon="lexicon.user.iconAdmin" >
                              {{ user.roles }} 
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              style="cursor:pointer;"
                              v-for="(item, index) in roleArrayState"
                              :key="index"
                              :disabled="item.disable"
                            >
                              <v-list-item-title @click="updateRole(user, item.name)">{{ item.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                  </td>
                <td>
                  <v-layout justify-center>
                    <td>
                      <v-icon
                        :disabled="
                          (user.user ? user.user._id : user._id) ===
                            (page.createdBy.user._id &&
                              user.roles === 'Admin') ||
                          checkPermissionFunc(user.roles) ||
                          user.roles === 'Admin'
                        "
                        class="ml-2"
                        color="primary"
                        @click="toggleDeleteUser(props, index, user)"
                      >
                        {{ user.delete ? icons.refresh : icons.cancel }}
                      </v-icon>
                    </td>
                  </v-layout>
                </td>
              </tr>
          </v-table>
        </div>
        <div class="d-flex justify-end">
          <v-card-actions> 
            <v-spacer></v-spacer>
            <v-btn class="mr-5 my-5" :loading="saveUserLoading" @click="saveUsers" color="primary">
              Done
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        absolute
        location="bottom"
        color="primary"
      >
        {{ message }}

        <template v-slot:action="{ props }">
          <v-btn v-bind="props" color="success" variant="text" @click="snackbar = false">
            <v-icon>{{ icons.cancel }}</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog persistent v-model="warningDialog" width="400">
        <v-card>
          <v-card-title class="text-h5"> Update Admin Role </v-card-title>
          <v-card-text
            >Are you sure you want to update Admin rights for the page? Your
            role will also be updated to Editor once you confirm.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkGrey" @click="cancelUpdatedRoles"> Cancel </v-btn>
            <v-btn color="primary" @click="updateRoleFunction"> Okay </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import * as roles from "@/utilities/roles.js"
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: ["page"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getUser
    } = storeToRefs(ellipseStore)
    const {
      setPagesStructuredData,
    } = notebookPropsStore
    return {getUser,
    setPagesStructuredData, lexicon, icons }
  },
  data() {
    return {
      snackbar: false,
      timeout: 2000,
      sharingDialog: false,
      addUserLoading: false,
      saveUserLoading: false,
      formattedUsers: [],
      editedItemIndex: -1,
      message: "",
      emailRules: [
        (v) =>
          v === "" ||
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) ||
          "E-mail must be valid",
      ],
      email: "",
      nameRules: [(v) => !!v || "Name is required"],
      roleArray: [],
      warningDialog: false,
      selectedUser: {},
    };
  },
  computed: {
    emailCheck() {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.email)) {
        return false;
      } else return true;
    },
    shareIconState() {
      if (this.page.isPublic) {
        return true;
      } else {
        if (
          this.page.userPermissions.permissions >= 200 ||
          this.page.createdBy.user._id === this.getUser._id
        ) {
          return false;
        } else return true;
      }
    },
    roleArrayState() {
      if (this.page) {
        return roles.permissions[this.page.userPermissions.permissions]
      }
    },
  },
  async mounted() {
    await this.getFormattedUsers();
  },
  methods: {
    async updateRole(user, role) {
      user.roles = role;
      if (user.roles === "Admin") {
        this.selectedUser = user;
        this.warningDialog = true;
      } else {
        this.selectedUser = user;
        await this.assignUpdatedRoles();
      }
    },
    async updateRoleFunction() {
      await this.assignUpdatedRoles();
      // downgrade admin role one step down
      let index1 = this.page.users.findIndex(
        (pUser) => pUser.user._id === this.getUser._id
      );
      this.page.users[index1].permissions = 400;
      this.getFormattedUsers();
      this.warningDialog = false;
    },
    cancelUpdatedRoles() {
      this.warningDialog = false;
      this.getFormattedUsers();
    },
    assignUpdatedRoles() {
      var objectToArray = Object.entries(roles.roles);
      let result = objectToArray.find((s) => s[0] === this.selectedUser.roles);
      let index = this.page.users.findIndex(
        (pUser) => pUser._id === this.selectedUser._id
      );
      this.page.users[index].permissions = result[1];
    },

    checkPermissionFunc(data) {
      let q = this.roleArray.some((e) => e.name === data && e.disable);
      return q;
    },
    getFormattedUsers() {
      this.formattedUsers = [];
      this.page.users.forEach((e) => {
        let objectToArray = Object.entries(roles.roles);
        let result = objectToArray.find((s) => s[1] === e.permissions);
        let obj = {
          user: e.user ? e.user : e._id,
          email: e.user ? e.user.email : e.email,
          roles: result[0],
          _id: e._id,
        };
        if (e.add && e.delete) {
          obj.delete = true;
        } else {
          if (e.add) {
            obj.add = true;
          }
          if (e.delete) {
            obj.delete = true;
          }
        }
        this.formattedUsers.push(obj);
      });
    },

    async addUser() {
      this.addUserLoading = true;
      var temp = this.page.users.find((u) => {
        if (u.email) {
          if (u.email === this.email) return true;
        } else if (u.user.email === this.email) return true;
        else return false;
      });
      if (!temp) {
        let newUser = await this.$auth.$api.post(`/api/page/${this.page._id}/add-user`, {
          email: this.email.toLowerCase(),
        });

        newUser.data.data.add = true;
        this.page.users.push(newUser.data.data);
        this.email = "";
        this.getFormattedUsers();
        this.message = "Assign Roles To Added User";
        this.snackbar = true;
      } else {
        this.email = "";
        this.message = "The user is already added";
        this.snackbar = true;
      }
      this.addUserLoading = false;
    },
    toggleDeleteUser(item, index) {
      this.editedItemIndex = index;
      this.page.users[this.editedItemIndex].delete =
        !!!this.page.users[this.editedItemIndex].delete;
      this.getFormattedUsers();
      if (this.page.users[this.editedItemIndex].delete) {
        delete this.page.users[this.editedItemIndex].add;
      } else {
        delete this.page.users[this.editedItemIndex].delete;
        this.page.users[this.editedItemIndex].add = true;
      }
    },
    async saveUsers() {
      this.saveUserLoading = true;

      let usersToSave = {
        users: this.page.users,
      };

      let pSaved = await this.$auth.$api.post(
        `/api/page/${this.page._id}/save-users`,
        usersToSave
      );

      this.message = "The users were saved successfully";
      this.snackbar = true;
      await this.setPagesStructuredData(this.$route.params.id);
      await this.getFormattedUsers();
      this.saveUserLoading = false;
      this.sharingDialog = false;
    },
  },
};
</script>

<style scoped>
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 25px !important;
}
</style>

<template>        
  <v-progress-linear
    v-if="show"
    class="pl-2"
    color="secondary"
    :value="uploadPercentage"
    height="20" 
  >
    <span style="color:white;"> {{ Math.ceil(uploadPercentage) }}%</span>
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    uploadPercentage: {
      type: Number,
      required: true
    },
  }
}
</script>

<style scoped></style>
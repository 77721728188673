<template>
  <div>
    <div class="sidePanelMargin">
      <div class="sidePanelHeader">
        <!-- Panel Title. -->
        <div class="sidePanelHeaderInner">
          <span class="header">{{ lexicon.layout.name }}s <HelpButton :lexiconItem="lexicon.layout"/></span>
        </div>

        <!-- Save Layout Button. -->
        <div>
          <v-btn size="small" color="primary" class="mt-1" @click="clickOnCreateLayout()"> 
              <v-icon small>{{ icons.save }}</v-icon>&nbsp; Add Layout
          </v-btn>
        </div>
      </div>
      <div class="divider">
        <v-divider></v-divider>
      </div>

    <!-- Panel Description. -->
    <HelpAlert :icon="lexicon.layout.icon" :message="messages.layoutAlert" />

    <v-row v-if="getLayouts.length"
      class="pa-2 text-center d-flex justify-center"
    >
      <v-col cols="12" md="12" class="ma-0 pa-0">
        <!-- Layout Cards List. -->
        <v-card class="droppable-element pa-0 mx-0 my-2 elevation-4" 
          v-for="layout in getLayouts" 
          :key="layout._id"  
          @click="selectLayout(layout)"
        >
        <!-- Layout Card Header. -->
          <v-card-title class="d-flex justify-space-between pl-4 pr-1 py-0">
            <!-- Layout Name. -->
            <span class="pt-1" style="font-weight: 400; font-size: small;">
                {{ layout.name }}
            </span>
            <!-- Layout Options Button. -->
            <div >
              <!-- Options Button. -->
              <v-menu offset-y>
                <!-- Menu Button. -->
                <template v-slot:activator="{ props}">
                  <v-btn v-bind="props" icon variant="text" size="small">
                    <v-icon size="small" color="darkSlate" >{{ icons.menu }}</v-icon>
                  </v-btn>
                </template>

                <!-- Menu Options. -->
                <div>
                  <v-list>
                    <!-- Modify. -->
                    <v-list-item @click="clickOnEditLayout(layout)">
                      <template v-slot:prepend>
                        <v-icon>{{ icons.edit}}</v-icon>
                      </template>
                      <v-list-item-title>{{ lexicon.edit }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickOnDeleteLayout(layout)">
                      <template v-slot:prepend>
                        <v-icon>{{ icons.remove }}</v-icon>
                      </template>
                      <v-list-item-title>{{ lexicon.del }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </div>
          </v-card-title>
          
          <!-- Layout Card Content. -->
          <v-card-text  
            class="pa-1 ma-0" 
            style="background: #F5F5F5;"
          >
            <v-divider></v-divider>
            <div class="rounded"
              style="height:160px; overflow: hidden ; color:rgb(var(--v-theme-darkGrey))"
            >
              
              <!-- Layout Preivew. -->
              <grid-layout ref="gridlayout"
                :layout="layout.widgets"
                :col-num="12" :row-height="13"
                :margin="[5,5]"
                :is-draggable="false"
                :is-resizable="false"
                :verticalCompact="false"
                :responsive="false"
                :use-css-transforms="true"
                :preventCollision="false"
              >

                <!-- Widgets. -->
                <grid-item 
                  v-for="item in layout.widgets"
                  :key="item.i" 
                  :x="item.x" 
                  :y="normalize(item.y, 0, 6)" 
                  :w="item.w"
                  :h="normalize(item.h, 0, 6)" 
                  :i="item.i"
                  :id="item._id"
                  class="d-flex justify-center align-center"
                  style="border:0px solid; 
                    color:rgb(var(--v-theme-darkGrey)); 
                    background-color: white; 
                    box-shadow: 1px 1px 2px rgb(var(--v-theme-darkGrey));  
                    border-radius: 1px;
                  " 
                >
                  <!-- Widget Icon. -->
                  <div class="widgetIconStyle">
                    <WidgetIcon 
                      :iconsize="widgetSize" 
                      :show="showWidget" 
                      :icon="widgetIcon(item.content)" 
                      :text="item.content"></WidgetIcon>
                  </div>
                  <!-- Widget Name. -->
                  <span style="font-size: x-small">{{ widgetName(item.content) }}</span>
                </grid-item>
              </grid-layout>
            </div>
            <v-tooltip location="bottom" activator="parent">
              <!-- See Properties Full Name Tooltip. -->
              <span>Click To Apply</span>
            </v-tooltip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    </div>
    <!-- Modify Layout dialog. -->
     <v-dialog persistent v-model="editLayoutDialog" :retain-focus="false" width="80%">
      <LayoutEditorDialog 
        :layoutToEdit="layoutToEdit"
        @toggle1="editLayoutDialog=!editLayoutDialog"
        ></LayoutEditorDialog>
    </v-dialog>
      
    <!-- Save Current Layout dialog. -->
    <v-dialog persistent v-model="createLayoutDialog" width="500">
      <v-card>
        <!-- Title. -->
        <v-card-title> 
            {{ messages.layoutSave }}
        </v-card-title>

        <!-- Save Layout Name Text Field. -->
        <v-card-text>
          <v-text-field 
            v-model="layoutName" 
            variant="outlined" 
            hide-details 
            :label="messages.layoutName"
            class="pt-2">
          </v-text-field>
        </v-card-text>
        
      <!-- Card Acction Buttons-->
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Cancel. -->
          <v-btn 
            @click="createLayoutDialog = false" 
            class="mx-2 text-white" 
            medium 
            color="darkGrey"
          >
            {{ lexicon.cancel }}
          </v-btn>

          <!-- Save. -->
          <v-btn @click="createLayout(false)" :loading="createLayoutLoadingCurrentState"
            :disabled="disabledCreateLayoutCurrentState || !this.layoutName" 
            class="mx-2" 
            medium 
            color="primary"
          >
            {{ lexicon.save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Apply Layout - Are you Sure Check. -->
    <v-dialog persistent v-model="applyLayoutDialog" width="500">
      <v-card>
        <v-card-title> Apply Layout</v-card-title>
        <div class="px-4">{{ messages.layoutAppl }}</div>
        <div class="d-flex justify-end pb-2">
          <v-card-actions>
            <v-btn @click="applyLayoutDialog = false" class="mx-2 text-white" medium color="darkGrey">
              {{ lexicon.cancel }}
            </v-btn>
            <v-btn @click="applyLayout"  :loading="applyLayoutLoading" medium color="secondary">
              {{ lexicon.confirm }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <!-- Delete Layout- Are you Sure Check. -->
    <AreYourSurePopUp 
      :dialog="deleteLayoutDialog"
      @toggle="deleteLayoutDialog=!deleteLayoutDialog"
      :onClick="deleteLayout"
      :loading="deleteLayoutLoading"
      >{{ messages.layoutDelete }}
    </AreYourSurePopUp>
    
  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../components/ui/WidgetIcon.vue"
import PillButton from "../components/ui/PillButton.vue";
import { GridLayout, GridItem } from "vue3-grid-layout-next";
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import LayoutEditorDialog from '../components/LayoutEditorDialog.vue';
import HelpButton from "../components/ui/HelpButton.vue";
import HelpAlert from "../components/ui/HelpAlert.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: {
    PillButton,
    GridLayout,
    GridItem,
    AreYourSurePopUp,
    LayoutEditorDialog,
    WidgetIcon,
    HelpButton,
    HelpAlert
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getWidgets,
      getSelectedPageStateId,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getLayouts,
      getUser,
    } = storeToRefs(ellipseStore)
    const {
      setGlobalProperty,
      setWidgets, 
      setWidgetsToDelete,
      setLoadingScreen
    } = notebookPropsStore
    const {
      setLayouts
    } = ellipseStore
    return {notebookPropsStore, 
      getWidgets, getSelectedPageStateId, getLayouts, getUser, getGlobalProperty,
    setGlobalProperty, setWidgets, setWidgetsToDelete, setLayouts, setLoadingScreen, lexicon, messages, icons}
  },
  data() {
    return {
      deleteLayoutDialog: false,
      deleteLayoutLoading: false,
      editLayoutLoading: false,
      editedItemIndex: -1,
      createLayoutDialog: false,
      createLayoutLoadingSaveState: false,
      createLayoutLoadingCurrentState: false,
      layoutName: '',
      disabledCreateLayoutSaveState: false,
      disabledCreateLayoutCurrentState: false,
      applyLayoutLoading:false,
      applyLayoutDialog:false,
      selectedLayout:{},
      editLayoutDialog:false,
      layoutToEdit:{},
      editlayoutLoading:false,
      showWidget:true,
      widgetSize:'10'

    }
  },
  async created() {
    await this.setLayouts(this.getUser._id);
  },
  computed: {
    getDashboardWidgets(){
      let dashboardWidgets = this.getWidgets.filter((widget)=>{
        if(  widget.instance_setting === undefined
          || widget.instance_setting.isPanelWidget === undefined
          || widget.instance_setting.isPanelWidget === null
          || widget.instance_setting.isPanelWidget === false)
        {
          return widget;
        }
      })
      return dashboardWidgets; 
    },
  },
  methods: {
    normalize(val, min ,max){
    let a = (val - min) / (max - min)
    return a;
   },
    async clickOnEditLayout(layout){
      this.layoutToEdit = layout;
      this.editLayoutDialog = true;
    },
    async clickOnCreateLayout() {
      this.createLayoutDialog = true;
    },
    async createLayout(value) {
      let copyLayoutState = "";
      this.createLayoutLoadingCurrentState = true;
      copyLayoutState = "current_state";
      this.disabledCreateLayoutSaveState = true;

      let postData = {
        layoutName: this.layoutName,
        copyLayout: copyLayoutState,
        pageStateId: this.getSelectedPageStateId,
        global_setting: this.getGlobalProperty.global_setting,
      }

      if (postData.copyLayout === "current_state") {
        let cloneWidgets = this.getDashboardWidgets.map((e) => {
          return {
            x: e.x,
            y: e.y,
            w: e.w,
            h: e.h,
            i: e.i,
            minW: e.minW,
            minH: e.minH,
            local_setting: {
              is_locked: e.local_setting.is_locked,
              foreground_color: e.local_setting.foreground_color,
            },
            content: e.content
          };
        });
        postData.widgets = cloneWidgets;
      }
      console.log("postData", postData)
      // / api / layout / create_layout:
      let newLayout = await this.$auth.$api.post(`/api/layout/create_layout`, postData);
      console.log("newLayout", newLayout)
      await this.setLayouts(this.getUser._id);
      this.layoutName = '';

      this.disabledCreateLayoutSaveState = false;
      this.createLayoutLoadingCurrentState = false;
      this.createLayoutDialog = false;
    },
    async selectLayout(layout){
      this.selectedLayout = layout;
      this.applyLayoutDialog = true;
    },
    async applyLayout(){
      this.setLoadingScreen(true);
      this.applyLayoutLoading = true;
      
      this.selectedLayout.widgets.forEach(async w => {
        delete w._id;
        w.new = true;
        w.page_state = this.getSelectedPageStateId;
        w.instance_setting = {
          data: null,
        };
        w.i =  Math.floor(1000000000000 + Math.random() * 9000000000000);
      });
      
      await this.setGlobalProperty(this.selectedLayout);
      await this.setWidgets(this.selectedLayout.widgets)
      await this.setWidgetsToDelete(this.getWidgets)
     
      this.applyLayoutLoading = false;
      this.applyLayoutDialog = false;
      this.setLoadingScreen(false);
    },
    clickOnDeleteLayout(item) {
      this.selectedLayout = item;
      this.editedItemIndex = this.getLayouts.indexOf(item);
      this.deleteLayoutDialog = true;
    },
    async deleteLayout() {
      this.deleteLayoutLoading = true;
      let route = `/api/layout/${this.selectedLayout._id}/delete_layout`;
      await this.$auth.$api.delete(route)
        .then(response => {
          console.log("response", response);
        })
        .catch((e) => {
          console.log("Error", e);
        });
      this.getLayouts.splice(this.editedItemIndex, 1);
      this.deleteLayoutLoading = false;
      this.deleteLayoutDialog = false;
    },
    widgetIcon(content) {
      let result = WidgetsCollection.returnWidgetDetails(content);
      return result.icon;
    },
    widgetName(content) {
      let result = WidgetsCollection.returnWidgetDetails(content);
      return result.text;
    },
  }

}
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.widgetIconStyle {
  position: absolute;
  z-index: 10;
  left: 2px !important;
  top: -3px !important;
}

.droppable-element {
  text-align: center;
  /* padding: 10px; */
  background: white;
}

.droppable-element:hover {
  -ms-transform: scale(1.02);
  /* IE 9 */
  -webkit-transform: scale(1.02);
  /* Safari 3-8 */
  transform: scale(1.03);
  color:rgb(var(--v-theme-primary));
}

.grid-stack-item {
  margin: 0px;
}

.grid-stack-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(var(--v-theme-darkGrey));
  background-color: rgb(var(--v-theme-background));
  font-weight: 600;
  box-shadow: 0 1px 3px 0 rgb(var(--v-theme-darkSlate), 0.1), 0 1px 2px 0 rgb(var(--v-theme-darkSlate), 0.06);
}
.text-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 0.8rem !important;
  letter-spacing: 0.02em !important;
  font-family: "Roboto", sans-serif !important;
}

</style>
<template>
  <!-- Comment Card -->
  <v-card
    flat
    rounded
    tile
    elevation="0"
    @click="clickOnCommentAndSelect()"
    class="commentCard"
    :class="isSelectedChildComment ? 'selected' : ''"
    :style="isChildComment ? 'background-color: #f7f7f7' : ''"
    >

    <div class="d-flex align-center pt-0">
      <span class="d-flex align-center">
          
        <!-- Pin to Element Button + Menu Dropdown. -->
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              v-if="!isChildComment"
              :disabled="getNotebook.userPermissions.permissions <= 100"
              v-bind="props"
              size="x-small"
              icon variant="text" 
              @click="clickOnComment"
              :color="commentInstance.elements.length ? 'primary' : 'darkGrey'"
              >
              <v-icon>{{ icons.pin }}</v-icon>
              <v-tooltip activator="parent" location="top">Pin to Element</v-tooltip>
            </v-btn>
          </template>
          
          <v-list>
            <v-list-item 
              v-for="pinMenuItem in pinMenuItems"
              :key="pinMenuItem.title"
              @click="pinMenuItem.handler"
            >
              <template v-slot:prepend>
                <v-icon :color="pinMenuItem.iconColor">{{ pinMenuItem.icon }}</v-icon> 
              </template>
              <v-list-item-title>{{ pinMenuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Attach Comment To Page Button -->
        <v-btn
          v-if="!isChildComment"
          :disabled="getNotebook.userPermissions.permissions <= 100"
          size="x-small"
          variant="text" 
          @click="attachDetachCommentToPage()"
          :loading="attachedCommentToPageLoading"
          icon
          >
          <v-icon :color="isAttachedToPage ? 'primary' : 'darkSlate'">
            {{ lexicon.page.iconPublished}}
          </v-icon>
          <v-tooltip activator="parent" location="top">
            <span> {{isAttachedToPage ? 'Attached' : 'Not attached' }} to Page</span>
          </v-tooltip>
        </v-btn>
      </span>

      <v-spacer></v-spacer>

      <!-- View Mode Buttons -->
      <!-- Add Users/Mentions -->
      <span>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-badge
              dot
              color="secondary"
              location="top end"
              :content="commentInstance.mentions.length"
              :model-value="commentInstance.mentions.length>0"
              >
                <v-btn
                  :disabled="getNotebook.userPermissions.permissions <= 100"
                  v-bind="props"
                  size="x-small"
                  icon variant="text" 
                  @click="clickOnComment()"
                  >
                  <v-icon color="darkSlate">{{ lexicon.user.icon }}</v-icon>
                  <v-tooltip activator="parent" location="top">{{ commentInstance.mentions.length }} Users</v-tooltip>
                </v-btn>
            </v-badge>
          </template>

          <v-card
            flat
            tile
          >
            <Mentions 
              @mentionsSaved="saveMentions" 
              :comment="commentInstance">
            </Mentions>
            <v-divider v-if="commentInstance.mentions.length > 0"></v-divider>
            <v-list class="py-0">
              <v-list-item v-for="mention in commentInstance.mentions" :key="mention._id">
                <template v-if="mention">
                  <v-icon class="pr-1" size="x-small" color="darkSlate">
                    {{ icons.at }}
                  </v-icon>
                  <span><small>{{ mention.name }}</small></span>
                </template>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <!-- Upload Attachments  -->
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-badge
            dot
            color="secondary"
            location="top end"
            :content="commentInstance.files.length"
            :model-value="commentInstance.files.length>0"
            >
              <v-btn
                :disabled="getNotebook.userPermissions.permissions <= 100"
                v-bind="props"
                @click="clickOnComment()"
                size="x-small"
                icon variant="text" 
              >
                <v-icon color="darkSlate">{{ lexicon.attachment.icon }}</v-icon>
                <v-tooltip activator="parent" location="top"> {{ commentInstance.files.length }} Files</v-tooltip>
              </v-btn>
            </v-badge>
          </template>
          
          <v-card class="v-card-dropdown">
            <v-list-item 
              color="primary"
              @click="clickOnUploadFileIcon()"
            >
              <template v-slot:prepend>
                <v-icon class="ml-2">{{ icons.upload }}</v-icon> 
              </template>
              <v-list-item-title>{{ lexicon.upload.name }}</v-list-item-title>
            </v-list-item>

            <template v-if="commentInstance.files.length>0">
              <v-divider></v-divider>
              <div v-for="file in commentInstance.files" :key="file._id">
                <div
                  class="dropdownDiv text-truncate"
                  @click="download(file)"
                >
                  <v-icon class="pr-1" size="x-small" color="darkGrey">{{utils.fileIcon(file.name)}}</v-icon>
                    {{ file.name }}
                </div>
              </div>
            </template>
          </v-card>
        </v-menu>

        <!-- Comment Status -->
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn
              :disabled="getNotebook.userPermissions.permissions <= 100"
              v-bind="props"
              @click="clickOnComment()"
              :loading="statusLoading"
              size="x-small"
              icon variant="text" 
              >
              <v-icon :color="commentInstance.status ? 
                statusList.find(e => e.status == commentInstance.status).color 
                : 'darkSlate'">
                {{ icons.circle }}
              </v-icon>
              <v-tooltip activator="parent" location="top">
                {{commentInstance.status ? 'Status: ' : 'Status'}} {{ commentInstance.status }}
              </v-tooltip>
            </v-btn>
          </template>

          <v-list>
            <v-list-item 
              v-for="status in statusList" 
              :key="status.color"
              @click="updateStatus(status.status)"
            >
              <template v-slot:prepend>
                  <v-icon class="ml-2" :color="status.color">{{ icons.circle }}</v-icon> 
              </template>
              <v-list-item-title class="mr-2">{{ status.status }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>

      <!-- Menu - Edit + Delete -->
      <v-menu offset-y >
        <template v-slot:activator="{ props }">
          <v-btn
            :disabled="getNotebook.userPermissions.permissions <= 100"
            v-bind="props"
            @click="clickOnComment()"
            size="x-small"
            icon variant="text" 
          >
            <v-icon>{{ icons.menu }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item 
            v-for="menuItem in menuItems"
            :key="menuItem.title"
            @click="menuItem.handler"
          >
            <template v-slot:prepend>
              <v-icon :color="menuItem.iconColor" class="ml-2">{{ menuItem.icon }}</v-icon> 
            </template>
            <v-list-item-title class="mr-2">{{ menuItem.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </div>

    <!-- Comment User Details -->
    <UserInfo
      :avatar="commentInstance.createdBy.picture"
      :name="commentInstance.createdBy.name"
      :timeStamp="$filters.momentTimestamp(commentInstance.createdAt)"
    ></UserInfo>
    
    <!-- Comment Text Area -->
    <v-textarea
      v-if="textAreaToEdit"
      placeholder="Add a comment here..."
      rows="1"
      v-model="commentInstance.comments"
      auto-grow
      flat
      hide-details
      color="grey"
      density="compact"
      style="font-size: small;"
      :class = "textAreaToEdit ? 'px-1' : ''"
      :readonly="!textAreaToEdit"
      :variant="textAreaToEdit ? 'outlined' : 'solo'"
    ></v-textarea>
    
    <v-sheet 
      v-else 
      class="textArea pl-3">
      {{commentInstance.comments}}
    </v-sheet>
      
    <!-- Mentions and Replies count  -->
    <div v-if="!isChildComment && !textAreaToEdit" class="d-flex justify-end pr-1 pl-2">
      <div v-show="commentInstance.childNodes.length>0 && commentInstance.showReplies" 
        style="width: 100%" class="align-center">
        <v-divider dark class="mt-4"></v-divider>
      </div>
      
      <div style="width: auto">
        <v-badge
          color="secondary"
          location="top end"
          :content="commentInstance.childNodes.length"
          :model-value="commentInstance.childNodes.length>0"
          size="x-small"
          offset-x="5"
          offset-y="2"
        >
          <v-btn @click="replyClicked()" size="small" variant="plain">
            <v-icon class="pr-1" color="darkSlate" small>
              {{ icons.reply }}
            </v-icon>
            <span>{{ lexicon.reply }}</span>
            <v-tooltip activator="parent" location="top">{{ commentInstance.childNodes.length }} Replies</v-tooltip>
          </v-btn>
        </v-badge>
      </div>
    </div>

    <!-- Edit Comments -->
    <div v-if="textAreaToEdit">
      <SaveCancel
        @save="editComment()"
        :loading="editCommentLoading"
        @cancel="cancelEditComment()"
      ></SaveCancel>
    </div>
  </v-card>

  <!-- Delete Comments -->
  <AreYouSure
    @actionClicked="deleteComment"
    :dialog="deleteCommentDialog"
    :disabled=null
    :loading="deleteCommentLoading"
    @toggle="deleteCommentDialog = !deleteCommentDialog"
    >Are you sure you want to delete the Comment?
  </AreYouSure>

  <!-- Child Empty Comment Card -->
  <EmptyCommentCard
    v-if="showEmptyCommentCard"
    @commentAdded="replyAdded"
    @cancelClick="cancelreplyComment"
    :commentId="commentInstance._id"
  ></EmptyCommentCard>
      
  <!-- Upload Files Dialog -->
  <v-dialog v-model="addFilesDialog" width="300">
    <v-card>
      <v-card-title class="d-flex bg-primary text-white">
        <v-icon size="small" color="white" class="mr-3">{{ lexicon.attachment.icon }}</v-icon>
        <span>Upload Attachments</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <div class="text-center">
            <v-icon size="x-large" color="secondary">{{ icons.cloudUpload }}</v-icon>
          </div>

          <div class="text-center"><b>Upload Files Below</b> </div>
          <div>
            <v-file-input
              variant="outlined"
              hide-details
              prepend-icon=""
              label="Click here to browse"
              v-model="uploadedFile"
              class="pt-1"
            ></v-file-input>

            <ProgressLinear
              :show="showFileUploadProgress"
              :uploadPercentage="uploadPercentage"
            ></ProgressLinear>
          </div>

          <div class="d-flex justify-end"></div>

          <div class="text-center py-2">
            <v-btn
              @click="addFilesDialog = false"
              color="darkGrey"
              class="mr-2"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="uploadFile"
              color="primary"
              class="mr-2"
              :loading="uploadLoading"
            >
              Upload
            </v-btn>
          </div>

        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- snack bar -->
  <v-snackbar
    location="bottom"
    absolute
    v-model="snackbar"
    :color="snackbarColor"
    timeout="2000"
  >
    {{ snackbarText }}
  </v-snackbar>
</template>

<script>
import * as utils from "@/utilities";
import Mentions from "@/components/SidePanelCommentsMentions.vue";
import PillButton from "@/components/ui/PillButton.vue";
import AreYouSure from "@/components/popUps/AreYouSure.vue";
import ProgressLinear from "@/components/ui/ProgressLinear.vue";
import UserInfo from "@/components/ui/UserInfo.vue";
import SaveCancel from "@/components/ui/SaveCancel.vue";
import Templates from "@/pages/Templates.vue";
import EmptyCommentCard from "@/components/ui/EmptyCommentCard.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useCommentsStore} from "@/store/CommentsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";
import * as icons from "@/utilities/EllipseIcons.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";

export default {
  components: {
    Mentions,
    PillButton,
    AreYouSure,
    ProgressLinear,
    UserInfo,
    SaveCancel,
    Templates,
    EmptyCommentCard,
  },
  props: ["commentInstance"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const commentsStore = useCommentsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedElements,
      getSelectedPage,
    } = storeToRefs(notebookPropsStore)
    const {
      getAllComments,
      getSelectedComment,
      getSelectedCommentFromFeed,
    } = storeToRefs(commentsStore)
    const {
      getUser,
      getNotebook,
    } = storeToRefs(ellipseStore)
    const { 
      updateAllCommentsInStore,
      setSelectedComment,
      setSelectedCommentFromFeed,
    } = commentsStore
    return {notebookPropsStore, commentsStore,
      getSelectedElements, getSelectedPage, getNotebook, getUser,
      getSelectedComment, getAllComments, getSelectedCommentFromFeed,
      updateAllCommentsInStore, setSelectedComment, setSelectedCommentFromFeed, icons, lexicon
    }
  },
  data() {
    return {
      showEmptyCommentCard: false,
      textAreaToEdit: false,
      editCommentLoading: false,
      deleteCommentDialog: false,
      deleteCommentLoading: false,
      commentToDelete: {},
      addFilesDialog: false,
      uploadedFile: null,
      showFileUploadProgress: false,
      uploadPercentage: 0,
      statusList: [
        { color: "primary", status: "To Do" },
        { color: "accent", status: "In Progress" },
        { color: "darkGrey", status: "Done" },
      ],
      statusLoading: false,
      tagClearSelectionLoading: false,
      tagSelectionLoading: false,
      attachedCommentToPageLoading: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "error",
      uploadLoading: false,

      pinMenuItems: [
        {
          title: "Pin To Element",
          icon: this.icons.pin,
          color: "primary",
          handler: this.tagSelections,
          loading: this.tagSelectionLoading,
        },
        {
          title: "Clear Tags",
          icon: this.icons.cancel,
          color: "darkSlate",
          handler: this.clearTags,
          loading: this.tagClearSelectionLoading,
        }
      ],
      menuItems: [
        {
          title: this.lexicon.edit,
          icon: this.icons.edit,
          iconColor: "darkSlate",
          handler: this.clickOnEdit,
        },
        {
          title: this.lexicon.del,
          icon: this.icons.remove,
          iconColor: "darkSlate",
          handler: this.clickOndelete,
        }
      ]
    };
  },
  computed: {
  },
  async created() {
  },
  mounted(){
  },
  watch:{
    getSelectedElements(){ 
      let selectedElementsSet = new Set(this.getSelectedElements);
      let commentElements = this.commentInstance.elements
      let hasIntersection = commentElements.some(element => selectedElementsSet.has(element));
      if (hasIntersection){
        this.clickOnComment()
      } else {
        if (this.isSelected) {
          this.setSelectedComment(null)
        }
      }
    }
  },
  computed: {
    isSelectedChildComment(){
      if (this.isChildComment){
        if (this.getSelectedComment && this.getSelectedComment._id == this.commentInstance._id) return true
      }
    },
    isSelected(){
      if (this.getSelectedComment && this.getSelectedComment._id == this.commentInstance._id) return true
    },
    isChildComment(){
      return this.commentInstance.parent
    },
    isAttachedToPage() {
      let result = this.commentInstance.page.find((e) => e === this.getSelectedPage._id);
      if (result) return true
    },
  },
  methods: {
    saveMentions(){
      this.textAreaToEdit = false;
    },
    async download(file) {
      this.snackbarColor = "primary";
      this.snackbarText = "Downloading File...";
      this.snackbar = true;
      await utils.downloadFile(file, this.getNotebook._id);
      this.snackbar = false;
    },
    clickOnCommentAndSelect() {
      this.setSelectedComment(this.commentInstance)
      if (this.commentInstance.elements.length > 0){
        this.notebookPropsStore.$patch({selectedElements: this.commentInstance.elements})
      }
    },
    clickOnComment(){
      this.setSelectedComment(this.commentInstance)
    },
    async attachDetachCommentToPage() {
      if (this.commentInstance.createdBy._id === this.getUser._id) {
        this.attachedCommentToPageLoading = true;
        let result = this.commentInstance.page.find((e) => e === this.getSelectedPage._id);
        let postData = {
          page: this.getSelectedPage._id,
        };
        if (result) {
          let detached = await this.$auth.$api.post(
            `/api/comment/${this.commentInstance._id}/detached-comment-from-page`,
            postData
          );
        } else {
          let attached = await this.$auth.$api.post(
            `/api/comment/${this.commentInstance._id}/attached-comment-to-page`,
            postData
          );
        }
        await this.updateAllCommentsInStore();
        this.attachedCommentToPageLoading = false;
      } else {
        this.snackbarText = "You do not have acesss for this.";
        this.snackbar = true;
      }
    },
    async tagSelections() {
      if (this.getSelectedElements.length > 0){
        this.tagSelectionLoading = true;
        let postData = {
          elements: this.getSelectedElements,
        };
        let assignids = await this.$auth.$api.post(
          `/api/comment/${this.commentInstance._id}/assign-element_ids`,
          postData
        );
        await this.updateAllCommentsInStore();
        this.tagSelectionLoading = false;
      }
    },
    async clearTags() {
      this.tagClearSelectionLoading = true;
      let unassign = await this.$auth.$api.post(
        `/api/comment/${this.commentInstance._id}/unassign-element_ids`
      );
      this.notebookPropsStore.$patch({selectedElements: []})
      await this.updateAllCommentsInStore();
      this.tagClearSelectionLoading = false;
    },
    async updateStatus(status) {
      this.statusLoading = true;

      let updateStatus = await this.$auth.$api.post(
        `/api/comment/${this.commentInstance._id}/update-status`,
        { status: status }
      );
      await this.updateAllCommentsInStore();
      this.statusLoading= false;
    },
    replyClicked() {
      if (!this.commentInstance.showReplies){
        this.commentInstance.showReplies = true
        if (!this.showEmptyCommentCard) this.showEmptyCommentCard = true
        else this.showEmptyCommentCard = false
      } 
      else {
        if (!this.showEmptyCommentCard) this.showEmptyCommentCard = true
        else {
          this.commentInstance.showReplies = false
          this.showEmptyCommentCard = false
        }
      }
    },
    async clickOnUploadFileIcon() {
      this.addFilesDialog = true;
    },
    async replyAdded(){
      await this.updateAllCommentsInStore()
      this.commentInstance.showReplies = true
      this.showEmptyCommentCard = false
    },
    async cancelreplyComment() {
      this.showEmptyCommentCard = false;
    },
    async clickOnEdit() {
      this.textAreaToEdit =true;
    },
    async editComment() {
      this.editCommentLoading = true;

      let editComment = await this.$auth.$api.post(
        `/api/comment/${this.commentInstance._id}/edit-info`,
        this.commentInstance
      );
      this.editCommentLoading = false;
      this.textAreaToEdit= false;
    },
    cancelEditComment() {
      this.textAreaToEdit = false;
    },
    clickOndelete() {
      this.commentToDelete = this.commentInstance;
      this.deleteCommentDialog = true;
    },
    async deleteComment() {
      this.deleteCommentLoading = true;
      let route = `/api/comment/${this.commentToDelete._id}/delete`;
      await this.$auth.$api.delete(route)
        .then(response => {
        })
        .catch((e) => {
        });

      await this.updateAllCommentsInStore();
      this.deleteCommentLoading = false;
      this.deleteCommentDialog = false;
    },
    async uploadFile() {
      this.uploadLoading = true
      let commentsToEdit = this.commentInstance;
      let selectedFolder = this.getNotebook.rootFolder;
      var file = this.uploadedFile[0];
      if (file != null) {
        let that = this;
        that.showFileUploadProgress = true;
        let urlPrefix = `/api/notebook/${this.getNotebook._id}/file`;
        let data = {
          notebook: this.getNotebook._id,
          folder: selectedFolder,
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          size: file.size,
          type: file.type || file.name.split(".")[1],
        };
        let tempPostRoute = `${urlPrefix}/create-temp/?urlType=${that.$route.name}`;
        that.uploadPercentage = 30;
        let newFile = await that.$auth.$api.post(tempPostRoute, data);
        newFile = newFile.data.data;
        let path = `path/${this.getNotebook._id}/`;
        that.uploadPercentage = 50;
        utils.uploadToS3(
          [file],
          path,
          newFile._id,
          this.getNotebook._id,
          async function (progresses, uploadedKey) {
            that.uploadPercentage = 70;
            if (uploadedKey) {
              newFile.s3Key = uploadedKey;
              newFile.allowAnonymousAccess = true;
              let savePostRoute = `${urlPrefix}/save/?urlType=${that.$route.name}`;
              let savedFile = await that.$auth.$api.post(savePostRoute, newFile);
              if (savedFile) {
                let fileIds = commentsToEdit.files.map((f) => {
                  return f._id;
                });
                fileIds.push(savedFile.data.data._id);
                let mentionsIds = commentsToEdit.mentions.map((m) => {
                  return m._id;
                });
                commentsToEdit.files = fileIds;
                commentsToEdit.mentions = mentionsIds;
                let editComment = await that.$auth.$api.post(
                  `/api/comment/${commentsToEdit._id}/edit-info`,
                  commentsToEdit
                );
                await this.updateAllCommentsInStore();
                that.uploadPercentage = 100;
                that.uploadedFile = null;
                that.showFileUploadProgress = false;
                that.uploadPercentage = 0;
                that.addFilesDialog = false;
                that.textAreaToEdit = false;
              }
            }
          }
        );
      }
      this.uploadLoading = false
    },
  },
};
</script>

<style scoped>
.v-list-item {
  padding: 0px !important;
}
.commentCard {
  padding: 2px;
  padding-bottom: 4px;
}
.commentCard.selected {
  border: 2px solid rgb(var(--v-theme-primary));
}
.textArea{
  padding-top: 4px;
  font-size: small;
  background: rgba(0, 0, 0, 0);;
}
</style>

<template>
  <div class="widgetPadding pa-0">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="widgetIcon && grid_item.instance_setting.data.image.s3Key === '' && !getEditMode" :icon="widgetIcon.icon" >No image selected</NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <Spinner :show="loadImages"></Spinner>
    <div
      style="height: 100%"
    >
      <span v-if="grid_item.instance_setting.data && grid_item.instance_setting.data.image.link != ''">
        <img
          :src="grid_item.instance_setting.data.image.link"
          :class="grid_item.instance_setting.data.selectedFit.style"
        />
      </span>
    </div>
  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import Spinner from "../../components/ui/Spinner.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: {WidgetIcon, NoDataWarning,WidgetTitle, Spinner},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getEditMode, getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    return {getEditMode, getGlobalProperty, getNotebook}
  },
  data() {
    return {
      image:{name:"",s3Key:""},
      selectedFit:"",
      loadImages: false,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  mounted() {
    this.verifyInstanceSettings();
  },
  methods:{
   async verifyInstanceSettings() {
      this.loadImages = true;
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!this.grid_item.instance_setting.data.image) this.grid_item.instance_setting.data.image = this.image;
      if (!this.grid_item.instance_setting.data.selectedFit) this.grid_item.instance_setting.data.selectedFit = this.selectedFit;

      if (!this.grid_item._id) {
        
      }
      if(this.grid_item._id && this.grid_item.instance_setting.data.image.s3Key){
            this.grid_item.instance_setting.data.image.link = "";
            let params = {urlType: this.$route.name};
            let viewFilePath = await this.$auth.$api.get(`/api/notebook/${this.getNotebook._id}/file/view?s3Key=${this.grid_item.instance_setting.data.image.s3Key}`, {params});
            this.grid_item.instance_setting.data.image.link = viewFilePath.data;
      }

      this.loadImages = false;

    },
  }
};
</script>
<style scoped>
.stretch {
  width: 100%;
  height: 100%;
  object-fit:fill;
}
.original {
  width: 100%;
  height: 100%;
  object-fit:none;
}
.fill {
  width: 100%;
  height: 100%;
  object-fit:cover;
}
.fit-a {
  width: 100%;
  height: 100%;
  object-fit:contain;
}
.fit-w{
  height: auto;
  width: 100%;
}
.fit-h{
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: auto;
}
.full-containx {
  width: 100%;
  height: auto;
}
.vertical-style{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <v-container fluid class=" pa-0 ma-0" style="overflow-y: auto;">
    <!-- Primary Content -->
    <v-table density="compact" class="" color="">
      <tbody>
        <!-- Categories -->
        <tr v-if="grid_item.instance_setting.data.hasCategory">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">{{ icons.categories }}</v-icon>
            <span class="property-header">Categories </span>
          </td>
          <td class="text-truncate property-item px-0">
            {{ getAttrHeaders.length }}
          </td>
          <v-tooltip activator="parent" location="bottom">The total number of unique categories shared between
            entries</v-tooltip>
        </tr>

        <!-- Elements -->
        <tr v-if="grid_item.instance_setting.data.hasElement">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1"  color="darkGrey">{{ icons.properties }}</v-icon>
            <span class="property-header">Entries </span>
          </td>
          <td class="text-truncate property-item px-0">
            {{ getAttrData.length }}
          </td>
          <v-tooltip activator="parent" location="bottom">The total number of entries in the page</v-tooltip>
        </tr>

        <!-- Color By -->
        <tr v-if="grid_item.instance_setting.data.hasColor">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1"  color="darkGrey">{{ icons.colorBy}}</v-icon>
            <span class="property-header">Color By </span>
            <span class="property-item">{{ colorName }} </span>
          </td>
          <td class="text-truncate property-item px-0">
            {{ colorCount }}
          </td>
          <v-tooltip activator="parent" location="bottom">{{ colorCount }} entities are being colored by the shared {{
            colorName }} category</v-tooltip>
        </tr>

        <!-- Label By -->
        <tr v-if="grid_item.instance_setting.data.hasLabel">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1"  color="darkGrey">{{ icons.labelBy}}</v-icon>
            <span class="property-header">Label By </span>
            <span class="property-item">{{ labelName }} </span>
          </td>
          <td class="text-truncate property-item px-0">
            {{ labelCount }}
          </td>
          <v-tooltip activator="parent" location="bottom">{{ labelCount }} entities are being labeled by the {{ labelName
          }} category</v-tooltip>
        </tr>

        <!-- Highlighted -->
        <tr v-if="grid_item.instance_setting.data.hasHighlight">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">{{ icons.highlight }}</v-icon>
            <span class="property-header">Highlighted </span>
          </td>
          <td class="text-truncate property-item px-0">
            {{ highlightedCount }}
          </td>
          <v-tooltip activator="parent" location="bottom">{{ highlightedCount }} entities are highlighted</v-tooltip>
        </tr>

        <!-- Selected -->
        <tr v-if="grid_item.instance_setting.data.hasSelect">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">{{ icons.cursor }}</v-icon>
            <span class="property-header">Selected </span>
          </td>
          <td class="text-truncate property-item px-0">
            {{ getSelectedElements.length }}
          </td>
          <v-tooltip activator="parent" location="bottom">{{ getSelectedElements.length }} entities are
            selected</v-tooltip>
        </tr>

        <!-- Filtering -->
        <tr v-if="grid_item.instance_setting.data.hasFilter">
          <td class="header-column pl-2 pr-0">
            <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">{{ icons.filter }}</v-icon>
            <span class="property-header">Filtered</span>
          </td>
          <td class="text-truncate property-item px-0">
              {{ numerator }} / {{ denominator }}
          </td>
          <v-tooltip activator="parent" location="bottom">{{ denominator - numerator }} entities of {{ denominator }} are
            filtered. <br> {{ numerator }} are visible</v-tooltip>
        </tr>

      </tbody>
    </v-table>
    </v-container>
  </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getFilterByData,
      getAttrData,
      getAttrHeaders,
      getColorByData,
      getLabelByData,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    return {
      dataGraphicsStore,
      notebookPropsStore,
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getFilterByData,
      getAttrData,
      getAttrHeaders,
      getColorByData,
      getLabelByData,
      getSelectedPageDatasets,
      getDatasetLoading,
      icons
    }
  },
  data: () => ({
    numerator: null,
    denominator: null,
    colorCount: 0,
    colorName: "None",
    labelCount: 0,
    labelName: "None",
    highlightedCount: 0,
    datasetSwitchingLoading: false,
    hasElement: true,
    hasCategory: true,
    hasColor: true,
    hasLabel: true,
    hasHighlight: true,
    hasSelect: true,
    hasFilter: true,
  }),
  created() {
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
    this.numerator = this.getFilterByData.graphicData ? this.getFilterByData.graphicData.length : this.getAttrData.length
    this.denominator = this.getAttrData.length;
    this.setColorValues(this.getColorByData);
    this.setLabelValues(this.getLabelByData);

    //Listen for changes to the notebookPropsStore
    this.notebookPropsStore.$onAction(({ name, args }) => {
      if (name === 'setHighlightedElements') {
        this.onNewFilterByUpdated()
      }
    })
    //Listen for changes to the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({ name, after }) => {
      after(() => {
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
      })
      after(() => {
        if (name === 'updateColorByProperties') {
          this.onNewFilterByUpdated()
        }
        if (name === "removeAllFilters"){
          this.numerator = this.getAttrData.length;
          this.denominator = this.getAttrData.length;
        }
        if (name === 'updateLabelByProperties') {
          this.onNewFilterByUpdated()
        }
      })
    })
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!('hasElement' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasElement = this.hasElement;
      if (!('hasCategory' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasCategory = this.hasCategory;
      if (!('hasColor' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasColor = this.hasColor;
      if (!('hasLabel' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasLabel = this.hasLabel;
      if (!('hasHighlight' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasHighlight = this.hasHighlight;
      if (!('hasSelect' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasSelect = this.hasSelect;
      if (!('hasFilter' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.hasFilter = this.hasFilter;


    },
    async onNewFilterByUpdated() {
      //check for graphicData existince
      this.numerator = this.getFilterByData.graphicData ? this.getFilterByData.graphicData.length : 0;

      this.setColorValues(this.getColorByData);
      this.setLabelValues(this.getLabelByData);
      this.setHighlightValues(this.getHighlightedElements);
    },
    setHighlightValues(filter) {
      this.highlightedCount = filter.length;
    },
    setLabelValues(filter) {

      this.labelName = "None";
      this.labelCount = 0;

      if (filter) {
        if (filter.attribute) this.labelName = filter.attribute;
        if (filter.graphicData) this.labelCount = filter.graphicData.ids.length;
      }
    },
    setColorValues(filter) {

      this.colorName = "None";
      this.colorCount = 0;

      if (filter) {
        if (filter.attribute) this.colorName = filter.attribute;
        if (filter.graphicData) this.colorCount = filter.graphicData.ids.length;
      }
    },
  },
};
</script>
<style scoped>
.results {
  font-size: xx-large;
  font-weight: 500;
  color: rgb(var(--v-theme-darkSlate));
}

.header-column {
  white-space: nowrap;
  color: rgb(var(--v-theme-darkGrey));
}

.category {
  font-size: small;
  font-weight: 300;
  color: rgb(var(--v-theme-darkSlate));
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}</style>
  
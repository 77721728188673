<template>
  <div class="widgetPadding pt-1 mt-0" style="height:100;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading" ></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <v-layout
      class="flexColumn"
      v-if="tableData && overAllTableHeaders.length > 0"
    >
      <v-data-table 
        v-if="overAllTableHeaders.length > 0"
        v-model:page="page"
        class=" py-0 my-0"
        :height="`${grid_item.h * 12 + (grid_item.h - 1) * 5 - 35}px`"
        :headers="overAllTableHeaders"
        :items-per-page="itemsPerPage"
        :items="tableData"
        :loading="tableLoading"
        hide-default-footer
        fixed-header
        no-data-text="No Data"
        density="compact"
        @mouseleave="removeHighlight"
      >
        <template v-slot:item="{ item }">
          <tr
            :style="
              getSelectedElements.includes(getItemValue(item)) ?
                `background: ${getGlobalProperty.global_setting.selection_color}; font-weight: bold;`
              : getHighlightedElements.includes(getItemValue(item)) ? 
                `background: ${getGlobalProperty.global_setting.highlight_color};`
              : selectionRange.includes(getItemValue(item)) ? 
                `background: ${getGlobalProperty.global_setting.highlight_color};`
              : isColorBy ? 
                `background: ${colorBy[getItemValue(item)]}; color: ${textBy[getItemValue(item)]};`
              : ''
            "
            class="truncate"
            @click.exact="clickEvent(getItemValue(item))"
            @click.ctrl="ctrlClickEvent(getItemValue(item))"
            @click.alt="altClickEvent(item)"
            @mouseover="hoverEvent(item, $event)"
          >

            <td
              class="truncate"
              style="width: auto; height: 26px; font-size: 12px; font-weight: 400; background: transparent"
              v-for="(attrKey, attrIdx) in overAllTableHeaders"
              :key="attrIdx"
            >
              {{ formatValue(getItemValue(item,attrKey.key)) }}
            </td>
            
            <v-tooltip location="bottom center" color="primary" activator="parent">
              <div v-for="(tooltip) in getTooltips(item)" :key="tooltip.key">
               <strong>{{tooltip.key}}</strong>{{" | "+ tooltip.value }}
              </div>
             </v-tooltip>

          </tr>
        </template >
        <template v-slot:bottom></template>
      </v-data-table>
      
      <!-- Footer with pagination. -->
      <div class="text-center d-flex" style="height:25px !important">
        <div style="width:70px" class="pl-2">
          <v-select
            :model-value="itemsPerPage"
            class="pa-2"
            hide-details
            density="compact"
            :items="itemsPerPageOptions"
            @update:model-value="itemsPerPage = parseInt($event, 10)">
          </v-select>
        </div>
        <div class="d-flex align-center pl-3">
          <small>{{page*itemsPerPage-itemsPerPage+1}}-{{page * itemsPerPage}} of {{tableData.length}}</small>
        </div>
        <v-pagination 
          class="d-flex align-center"
          v-model="page" 
          :length="pageCount" 
          density="compact"
          prev-icon= icons.arrowLeft
          next-icon= icons.arrowRight
        >
          <template v-slot:item></template>
        </v-pagination>
      </div>

    </v-layout>
    <NoDataWarning v-else :show="true">No Table Headers Selected</NoDataWarning>
  </div>
</template>
<script>
import {RGBToHex, setDigits, isDark} from "@/utilities";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import * as icons from "@/utilities/EllipseIcons.js";
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon,WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getHighlightedElements,
      getSelectedElements,
      getGlobalProperty,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getColorByData,
      getFilterByData,
      getAttrData,
      getAttrHeaderNames
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      setHighlightedElements,
      removeFromSelectedElements,
      addToSelectedElements,
      addRangeToSelectedElements
    } = notebookPropsStore
    return {notebookPropsStore, dataGraphicsStore, assetsStore,
    getHighlightedElements, getGlobalProperty, getSelectedElements, getSelectedPageDataset, getDatasetLoading,
    getSelectedPageDatasets, getEditMode, getColorByData, getFilterByData, getAttrData, getAttrHeaderNames,
    setHighlightedElements, removeFromSelectedElements, addToSelectedElements, addRangeToSelectedElements,
    icons, 
    }
  },
  data() {
    return {
      tableData: this.getAttrData,
      overAllTableHeaders: [],
      isColorBy: false,
      colorBy: null,
      textBy: null,
      page: 1,
      itemsPerPage: 50,
      itemsPerPageOptions: [50,100,250,500,1000],
      tableLoading:false,
      multiSelectStartIndex:-1,
      hoveredArrowIndex: -1,
      selectionRange: [],
    };
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    totalDataItems(){
      return this.getAttrData.length;
    },
    totalTableItems(){
      return this.tableData.length;
    },
    pageCount(){
      return Math.ceil(this.totalTableItems / this.itemsPerPage)
    },

  },
  created() {
    this.verifyInstanceSettings();
  },
  async mounted() {
    this.verifyInstanceSettings();
    if (this.getColorByData.graphicData) this.onNewColorByUpdated();
    await this.onNewAttributesReceived();
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.onNewColorByUpdated()
        }
        if (name === 'updateFilterByProperties') {
          this.onNewFilterByUpdated()
        }
        if (name === 'removeAllFilters'){
          this.tableData = this.getAttrData
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdated()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedAttrs": function () {
      this.updateHeaderEvent();
    },
  },
  beforeUnmount() {
  },
  methods: {
    verifyInstanceSettings() {
      if(!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data={};
      if(!this.grid_item.instance_setting.data.selectedAttrs) this.grid_item.instance_setting.data.selectedAttrs = this.getAttrHeaderNames;
    },
    async datasetUpdated(){
      await this.onNewAttributesReceived();
    },
    updateHeaderEvent(args){
      let headers = this.grid_item.instance_setting.data.selectedAttrs
      this.overAllTableHeaders = headers.map((e) => {
        return {
          title: e,
          key: e,
          align: "center",
          sortable: true,
        };
      });
    },
    updateOptions(updatedOptions){
      this.options = updatedOptions;
    },
    formatValue(data) {
        return setDigits(data);
    },
    async onNewAttributesReceived() {
      if (this.grid_item.instance_setting.data !== null) {
        if (this.grid_item.instance_setting.data.selectedAttrs.length) {
          let presentHeaders = this.getAttrHeaderNames.filter((element) =>
            this.grid_item.instance_setting.data.selectedAttrs.includes(
              element
            )
          );
          this.overAllTableHeaders = presentHeaders.map((e) => {
            return {
              title: e,
              key: e,
              align: "center",
              sortable: true,
            };
          });
        }
      } 
      else {
        this.overAllTableHeaders =
          this.getAttrHeaderNames.map((e) => {
            return {
              title: e,
              key: e,
              align: "center",
              sortable: true,
            };
          });
      }
      this.tableData = this.getAttrData;
    },
    clickEvent(ellipseId) {
      this.notebookPropsStore.$patch({selectedElements: [ellipseId]})
    },
    ctrlClickEvent(ellipseId){
      if(this.getSelectedElements.includes(ellipseId)){
        this.removeFromSelectedElements(ellipseId)
      }else{
        this.addToSelectedElements(ellipseId)
      }
    },
    getItemValue(item, key = 'ellipseId'){
      if(item){
        if(item['selectable']){
          if(item.selectable[key]){
            return item.selectable[key];
          }
        }
        if(item[key]) return item[key];
      }
    },
    getTooltips(item){
      let results = [];
      this.overAllTableHeaders.forEach(element => {
        let value = this.formatValue(this.getItemValue(item,element.key))
        if((value != undefined)&(value != null)&(value != ''))results.push({key:(element.key),value:(value)})
      });
      return results;
    },
    altClickEvent(item){
        //If First Alt-Click
        if (this.multiSelectStartIndex === -1) {
          this.multiSelectStartIndex = item.index;
          let ellipseId = this.getItemValue(item);
          if(ellipseId){
          if(!this.getSelectedElements.includes(ellipseId)){
            this.addToSelectedElements(ellipseId)
          }
        }
        } 
        //If Second Alt-Click
        else {
          //Get Index range of current table items
          const start = Math.min(this.multiSelectStartIndex, item.index);
          const end = Math.max(this.multiSelectStartIndex, item.index);
          
          //Get Ellipse Ids of items in range
          let selectedEllipseIds = [];
          for (let i = start; i <= end; i++) {
            selectedEllipseIds.push(this.tableData[i].ellipseId);
          }

          //Submit Ellipse Ids to store and reset multiSelectStartIndex
          this.addRangeToSelectedElements(selectedEllipseIds)
          this.multiSelectStartIndex = -1;
        }
    },
    hoverEvent(item, event) {
      if (event.shiftKey) {
        this.highlightItemsBetween(item);
      } else {
        this.selectionRange = [];
        this.multiSelectStartIndex = -1;
      }
          let ellipseId = this.getItemValue(item);
          if(ellipseId) this.setHighlightedElements([ellipseId])
    },
    removeHighlight() {
      this.setHighlightedElements([])
    },
    highlightItemsBetween(currentItem) {
      if (this.multiSelectStartIndex === -1) return;

      const startIndex = Math.min(this.multiSelectStartIndex, currentItem.index);
      const endIndex = Math.max(this.multiSelectStartIndex, currentItem.index);

      this.selectionRange = [];
      for (let i = startIndex; i <= endIndex; i++) {
        this.selectionRange.push(this.tableData[i].ellipseId);
      }
    },
    async onNewColorByUpdated() {
      this.isColorBy = false;
      this.colorBy = {};
      this.textBy = {};
      let opts = this.getColorByData.graphicData;
      if (!opts) return;

      // Iterate through the ids_inValidValue array and add them to the colorBy object with the default color
      opts.ids_inValidValue.forEach((id) => {
        this.colorBy[id] = '#ffffff'; 
        this.textBy[id] = '#000000'; 
      });

      for (let idx = 0; idx < opts.ids.length; idx++) {
        let rgbStr = opts.colors[idx];
        rgbStr = rgbStr.slice(4, rgbStr.length - 1);
        let rgbArr = rgbStr.split(",").map((s) => parseInt(s.trim()));
        let c = RGBToHex(...rgbArr);
        let ellId = opts.ids[idx];
        this.colorBy[ellId] = c;
        this.textBy[ellId] = isDark(opts.colors[idx]);
      }
      this.isColorBy = true;
    },
    async onNewFilterByUpdated() {
      this.tableData = this.getFilterByData.graphicData;
    },
    verifyHighlight(id){
      return this.getHighlightedElements.includes(id);
    }, 
  },
};
</script>
<style scoped>
  .truncate {
    max-width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-pagination :deep(.v-pagination__item){
    margin: 0px !important;
  }
  .v-input:deep( .v-field__input){
    width: 50px !important;
    margin-top: -8px !important;
    padding-top: 0px !important;
  }
  .v-input:deep(.v-field__append-inner){
    margin-top: -2px !important;
    margin-left: -10px !important;
    padding-top: 0px !important;
  }
  .v-input:deep(.v-field__outline) {
    position: relative !important;
  }
  .v-input:deep(.v-input__control) {
    height: 20px;
    margin-top: 0px !important
  }
  .v-input:deep(.v-select__selection-text){
    font-size: 12px !important;
    width: 50px
  }
  .v-input {
    padding: 0px !important
  }
  .v-table:deep(.v-data-table-header__content) {
    font-size: 12px !important;
    font-weight: 800 !important;
  }
</style>

<template>
    <!--Widget properties -->
    <div>
      <v-container>    

        <v-row width="100%"><div class="text-caption pt-0"><strong>Display Settings</strong></div><v-divider/></v-row>
        <div class="text-caption pb-0 pa-2">Grid Divisions</div>

          <v-row width="100%" class="pl-2">
            <v-slider
              v-model="getSelectedWidget.instance_setting.data.divisions"
              prepend-icon="mdi:mdi-view-column"
              min="1"
              max="18"
              step="1"
              thumb-label
              show-ticks="always"
              hide-details
              color="primary"
            ></v-slider>
          </v-row>
  
        <!-- Data Set Size -->
<v-row width="100%"><div class="text-caption pt-2"><strong>Tooltip Content</strong></div><v-divider/></v-row>
        <v-row width="100%" class="pl-2" v-if="loaded">
          <ChipGroup
            :options="getAttrHeaderNames" 
            :selections="selection" 
            @table-changed="updatedSelection" 
          />
        </v-row>

      </v-container>
    </div>
  </template>
  
  <script>
  import bus from "../../plugins/bus.js";
  import ChipGroup from "../ui/ChipGroup.vue";
  import * as icons from "@/utilities/EllipseIcons.js";
  
  import { storeToRefs } from 'pinia';
  import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
  
  export default {
    setup() {
    return {icons}
    },
    components:{
      ChipGroup,
    },
    props:{
      getSelectedWidget:{},
    },
    setup() {
      const dataGraphicsStore = useDataGraphicsStore()
      const {
        getAttrHeaderNames
      } = storeToRefs(dataGraphicsStore)
      return {getAttrHeaderNames}
    },
    data:()=>({
      selection: [],
      data_headers: [],
      loaded: false,
    }),
    computed:{
    },
    async mounted(){
      if(this.getSelectedWidget.content == 'viewImageGrid'){
        await this.loadTableHeaders();
      }
    },
    watch:{
      'getSelectedWidget':{
        deep: true,
        immediate: true,
        handler: function() {
            if(this.getSelectedWidget.content == 'viewImageGrid'){
                this.loadTableHeaders();
            }       
        }
      }
    },
    methods:{
      updatedSelection(selected){
        this.selection = selected;
        this.getSelectedWidget.instance_setting.data.filteredHeaders = selected;
        bus.emit("image-grid-changed",[this.getSelectedWidget._id, this.selection]);
      },
      async loadTableHeaders(){
        this.loaded = false;
        let i = 0;
        this.data_headers = this.getAttrHeaderNames.map((e) => {
          return { id: i++, name: e };
        });
  
        this.items = [
          {
            name: "Data Headers",
            children: this.data_headers,
          },
        ];
  
        if (this.getSelectedWidget.instance_setting.data) { 
          if (this.getSelectedWidget.instance_setting.data.filteredHeaders.length) {
            this.selection =
            this.getSelectedWidget.instance_setting.data.filteredHeaders;
          }
        } else {
          this.selection = this.getAttrHeaderNames;
        }
        this.loaded = true;
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
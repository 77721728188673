import { ref } from 'vue';
import createAuth0Client from "@auth0/auth0-spa-js";
import {useEllipseStore} from '@/store/EllipseStore.js';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = async (
  {
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    redirectUri = window.location.origin,
    axiosInstance,
    ...options
  }) => {
  if (instance) return instance;
  // The 'instance' is simply a Vue object
  instance = {
      loading:true,
      isAuthenticated:false,
      user:{},
      auth0Client:null,
      popupOpen:false,
      error:null,

      /** Authenticates the user using a popup window */
      async loginWithPopup(o) {
        instance.popupOpen = true;

        try {
          await instance.auth0Client.loginWithPopup(o);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          instance.popupOpen = false;
        }

        instance.user = await instance.auth0Client.getUser();
        instance.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        instance.loading = true;
        try {
          await instance.auth0Client.handleRedirectCallback();
          instance.user = await instance.auth0Client.getUser();
          instance.isAuthenticated = true;
        } catch (e) {
          instance.error = e;
        } finally {
          instance.loading = false;
        }
      },

      /** Update state vars as per auth state */
      async updateStateVars() {
        instance.isAuthenticated = await instance.auth0Client.isAuthenticated();
        instance.user = await instance.auth0Client.getUser();
        if (instance.isAuthenticated) {
          instance.token = await instance.auth0Client.getTokenSilently();
          instance.$api.defaults.headers.common['Authorization'] = `Bearer ${instance.token}`;
        } else {
          instance.token = null;
          instance.jwt = null;
          instance.$api.defaults.headers.common['Authorization'] = null;
          instance.$api.defaults.headers.common['IdToken'] = null;    
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return instance.auth0Client.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return instance.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return instance.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return instance.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return instance.auth0Client.logout(o);
      },
      async createAuth0client(){
        const ellipseStore = useEllipseStore();
        // Create a new instance of the SDK client using members of the given options object
        instance.auth0Client = await createAuth0Client({
          domain: options.domain,
          client_id: options.clientId,
          audience: options.audience,
          redirect_uri: redirectUri,
          useRefreshTokens: true,
          cacheLocation: 'localstorage'
        });
       
        try {
          // If the user is returning to the app after authentication..
          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            // handle the redirect and retrieve tokens
            const { appState } = await instance.auth0Client.handleRedirectCallback();
    
            await instance.updateStateVars();
            if (instance.isAuthenticated) {
              await instance.$api.post('/api/users', instance.user).then(returnedUser => {
                instance.userDB = returnedUser.data;
                ellipseStore.setUser(instance.userDB);
              }).catch((error) => {
                console.error("Error while hitting a backend api", error);
              });
            }
    
            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);
          }
        } catch (e) {
          instance.error = e;
        } finally {
          // Initialize the internal authentication state
          instance.isAuthenticated = await instance.auth0Client.isAuthenticated();
          instance.user = await instance.auth0Client.getUser();
          instance.loading = false;
          if(window.location.pathname.includes('visitor')){
            instance.isAuthenticated = true;
            instance.token = "";
            instance.$api.defaults.headers.common['Authorization'] = `Bearer ${""}`;
          }
        }
      }
    };
    
    // Assign the axios instance to the reactive instance
    instance.$api = axiosInstance;
    instance.createAuth0client();
    return instance;
  };

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  async install(app, options) {
    useAuth0(options).then((authInstance) => {
      app.config.globalProperties.$auth = authInstance;
    });
  },
};
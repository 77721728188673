<template>
  <!-- Rich Text Widget Properties -->
  <div>
    <v-container>
    <v-row width="100%">
      <v-col class="pa-0 ma-0">
        <div class="container borderLine pa-0 ma-0">

          <v-row width="100%"><div class="text-caption pt-2"><strong>Content</strong></div><v-divider/></v-row>

          <v-btn  
            color="primary" 
            style="position: absolute; right:20px" 
            size="x-small"
            icon variant="plain">
            <v-icon size="15">fa-solid fa-arrow-up-right-from-square</v-icon>
            <v-tooltip location="top" activator="parent">Expand Editor</v-tooltip>

            <v-dialog v-model="dialog" activator="parent" width="700">
              <v-card >
                <v-toolbar density="compact" color="darkSlate">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi:mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text >
                  <div class="container borderLine pa-0 ma-0">
                    <QuillEditor v-if="dialog" theme="snow" toolbar="full" ref="myQuilEditor" 
                      contentType="html"
                      v-model:content="getSelectedWidget.instance_setting.data.content">
                    </QuillEditor>
                  </div>
                </v-card-text>

              </v-card>
            </v-dialog>
          </v-btn>

          <QuillEditor theme="snow" toolbar="essential" ref="myQuilEditor" 
            contentType="html"
            v-model:content="getSelectedWidget.instance_setting.data.content">
          </QuillEditor>
        </div>
      </v-col>
    </v-row>
    </v-container>
  </div>

</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {
    QuillEditor
  },
  props: {
    getSelectedWidget: {}
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
}
</script>

<style scoped>
.container:deep(.ql-editor) {
  min-height: 200px !important ;
}
</style>
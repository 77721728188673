<template>
        <v-btn 
          v-if="userPreferencesHelpers" 
          variant="plain"  
          size="x-small" 
          icon  
          v-bind:href="lexiconItem.documentation" 
          target="_blank"
        >
          <v-icon :color="iconColor" size="16">
            {{ icons.information }}
          </v-icon>
          <v-tooltip 
            activator="parent" 
            location="right"
          > 
            Learn More About {{ lexiconItem.plural }}
          </v-tooltip>
        </v-btn>
</template>
<script>
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";
import { color } from "highcharts";

export default {
    props: {
    lexiconItem: {
      type: Object,
      required: true
    },
    iconColor:{
      type: String,
      required: false,
      default: "secondary"
    },
  },
  setup(){
    const ellipseStore = useEllipseStore()
    const {
      getUser,
    } = storeToRefs(ellipseStore)
    return {
      icons,getUser
    }
  },
  mounted() {
  },
  computed: {
    userPreferencesHelpers(){
      if(this.getUser){
        if(this.getUser.preferences){
          //this is a bool
          return this.getUser.preferences.helpers
        } else {
          return false
        }
      }
      else{
        return false
      }
    }
  },
  watch:{
  },
  methods: {
  },
};
</script>
<style>
</style>
<template>
  <div class="widgetPadding pa-0">
  <!-- Corner Icons for View and Edit Mode -->
  <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
  <!-- No Dataset Warning -->
  <NoDataWarning 
  :show="widgetIcon && grid_item.instance_setting.data.images.length === 0 && !getEditMode " 
  :icon= messages.widgetEmpty.icon>{{ messages.widgetEmpty.memo }}{{lexicon.image.plural}}</NoDataWarning>
  <!-- Widget Title Header -->
  <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>
  
  <Spinner :show="loadImages"></Spinner>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-carousel
      v-bind="props" 
      height="100%"
      width="100%"
      :hide-delimiters="grid_item.instance_setting.data.images.length==1"
      hide-delimiter-background
      :show-arrows="isHovering"
      >
        <v-carousel-item
          v-for="(item,i) in grid_item.instance_setting.data.images"
          :key="i"
          :src="item.link"
        ></v-carousel-item>
      </v-carousel>
    </template>
  </v-hover>

  </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../ui/WidgetIcon.vue"
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import Spinner from "../../components/ui/Spinner.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";
  
export default {
  components: {WidgetIcon, NoDataWarning, WidgetTitle, Spinner},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getEditMode,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
    } = notebookPropsStore
    return {getEditMode, getGlobalProperty, getNotebook, lexicon, messages}
  },
  data() {
    return {
      images:[],
      indices:[],
      files:[],
      loadImages: false,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  mounted() {
    this.verifyInstanceSettings();
  },
  methods:{
    async verifyInstanceSettings() {
      this.loadImages = true;

      if(!this.grid_item.instance_setting.data)this.grid_item.instance_setting.data={};

      if(!this.grid_item.instance_setting.data.images)this.grid_item.instance_setting.data.images = this.images;
      if(!this.grid_item.instance_setting.data.indices)this.grid_item.instance_setting.data.indices = this.indices;
      if(!this.grid_item.instance_setting.data.files)this.grid_item.instance_setting.data.files = this.files;

      if (!this.grid_item._id) {
        
      }

      if(this.grid_item._id && this.grid_item.instance_setting.data.images){
        for (let i = 0; i < this.grid_item.instance_setting.data.images.length; i++) {
          this.grid_item.instance_setting.data.images[i].link = "";
          let params = {urlType: this.$route.name};
          let viewFilePath = await this.$auth.$api.get(`/api/notebook/${this.getNotebook._id}/file/view?s3Key=${this.grid_item.instance_setting.data.images[i].s3Key}`,{params});
          this.grid_item.instance_setting.data.images[i].link = viewFilePath.data;
        }
      }

      this.loadImages = false;
      
    },
  }
};
</script>
<style scoped>

</style>
  
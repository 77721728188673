<template>
  <div>
    <div v-if="loadingFiles">
      <v-card flat class="loadingStyle d-flex align-center justify-center">
        <ProgressCircular>Loading Files</ProgressCircular>
      </v-card>
    </div>
    <div class="sidePanelHeader">
      <div class="sidePanelHeaderInner">
        <h4 class="ml-2 pt-1 text-capitalize sidePanelInner">
          <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.attachment.icon }}</v-icon>{{ name }}
        </h4>
      </div>
      <div>
        <PillButton :disabled="getNotebook.userPermissions.permissions <= 100" :show="true" :onClick="onFileUpload">
          <v-icon class="pr-1">
            {{ icons.upload }}
          </v-icon>
          Upload
        </PillButton>
        <input  class="d-none" ref="uploader" type="file" multiple="multiple" @change="uploadFile($event)" />
      </div>
    </div>
    <div>
      <div>
        <ProgressLinear :show="showFileUploadProgress" :uploadPercentage="uploadPercentage"></ProgressLinear>
      </div>
        <v-divider class="mb-1 divider"></v-divider>
      <div>
        <v-table density="compact">
          <tbody>
            <tr v-for="file in getAllFiles" :key="file.id">
              <td class="d-flex justify-space-between align-center">
                <div class="text-truncate" style="width: 230px">
                  <v-icon size="small" color="darkGrey">{{ file.icon }}</v-icon>
                  <span class="file_name ml-2">{{ file.name }}</span>
                  <v-tooltip activator="parent" location="top">{{file.name}}</v-tooltip>
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{ props}">
                    <v-btn v-bind="props" size="x-small" icon variant="text">
                      <v-icon size="medium">{{ icons.menu }}</v-icon>
                    </v-btn>
                  </template>
                    <v-list density="compact" style="font-size:12px;">
                      <v-list-item @click="download(file)">
                        <template v-slot:prepend>
                          <v-icon>{{ icons.download }}</v-icon>
                        </template>
                        <v-list-item-title>{{ lexicon.download }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>
    <v-snackbar 
    v-model="snackbar" 
      location="bottom" 
      absolute 
      :color="snackbarColor" 
      >
      <div class="text-center">
        {{ snackbarMessage }}
      </div>
    </v-snackbar>
  
  </div>
</template>

<script>
import axios from "axios";
import * as utils from "@/utilities";
import PillButton from "../components/ui/PillButton.vue";
import ProgressLinear from "../components/ui/ProgressLinear.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: { PillButton, ProgressLinear, ProgressCircular },
  props: {
    name: {
      type: String,
      default: "Assets"
    },
    buttonText: {
      type: String,
      default: "Add"
    },
    description: {
      type: String,
      default: ""
    }
  },
  setup(){
    const ellipseStore = useEllipseStore();
    const { 
      getNotebook 
    } = storeToRefs(ellipseStore);
    return { getNotebook, icons, lexicon };
  },
  data() {
    return {
      uploadFilename: null,
      showFileUploadProgress: false,
      uploadPercentage: 0,
      getAllFiles: [],
      loadingFiles: false,
      snackbarColor: "primary",
      snackbar: false,
      snackbarMessage: "",
    };
  },
  computed: {},
  async mounted() {
    await this.getRootfolderInfo();
  },
  methods: {
    async download(file) {
      this.snackbarMessage = "Downloading File...";
      this.snackbar = true;
      if(this.getNotebook == null){
        this.snackbarMessage = "Notebook not found";
        this.snackbarColor = "error";
        return;
      }
      await utils.downloadFile(file, this.getNotebook._id);
      this.snackbar = false;
    },
    async refreshComments() { },
    async getRootfolderInfo() {
      this.loadingFiles = true;
      if(this.getNotebook == null){
        this.snackbarMessage = "Notebook not found";
        this.snackbarColor = "error";
        return;
      }

      let rootFolderInfo = await this.$auth.$api.get(
        `/api/notebook/${this.getNotebook._id}/folder/${this.getNotebook.rootFolder}/child-folders`
      );
      // console.log("rootFolderInfo",rootFolderInfo)
      this.getAllFiles = rootFolderInfo.data.data;
      this.setFileIcons();
      this.loadingFiles = false;
    },
    setFileIcons() {

      for (let i = 0; i < this.getAllFiles.length; i++) {
        this.getAllFiles[i].icon = utils.fileIcon(this.getAllFiles[i].name);
      };

    },
    // when add button clicked, this triggers the input element, and which triggers uploadFile
    onFileUpload() {
      this.$refs.uploader.click();
    },
    async uploadFile(e) {
      // let file = e. .files[0];
      let selectedFolder = this.getNotebook.rootFolder;
       let uploadedFilesTos3 = 0; 

      let that = this;
      for await (const file of e.target.files) {
        if (file != null && file != "") {
          that.showFileUploadProgress = true;
          let urlPrefix = `/api/notebook/${this.getNotebook._id}/file`;
          let data = {
            notebook: this.getNotebook._id,
            folder: selectedFolder,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            size: file.size,
            type: file.type || file.name.split(".")[1],
          };
          let tempPostRoute = `${urlPrefix}/create-temp/?urlType=${that.$route.name}`;
          that.uploadPercentage = 10;
          let newFile = await that.$auth.$api.post(tempPostRoute, data);
          newFile = newFile.data.data;
          let path = `notebooks/${this.getNotebook._id}/files/`;
          let fileLengths = 90 / e.target.files.length;
          // that.uploadPercentage = 50;
          utils.uploadToS3(
            [file],
            path,
            newFile._id,
            this.getNotebook._id,
            async function (progresses, uploadedKey) {
              // that.uploadPercentage = 70;
              if (uploadedKey) {
                newFile.s3Key = uploadedKey;
                newFile.allowAnonymousAccess = true;
                let savePostRoute = `${urlPrefix}/save/?urlType=${that.$route.name}`;
                let savedFile = await that.$auth.$api.post(savePostRoute, newFile);
                uploadedFilesTos3++;
                that.uploadPercentage = Math.round(that.uploadPercentage + fileLengths);
                if (uploadedFilesTos3 === e.target.files.length) {
                  that.uploadPercentage = 100;
                  that.uploadFilename = null;
                  that.showFileUploadProgress = false;
                  that.uploadPercentage = 0;
                  await that.getRootfolderInfo();
                }
              }
            })
        }
      }
    }
  },
};
</script>

<style scoped>
.v-table :deep(.v-table__wrapper) {
  overflow: hidden !important;
}
.loadingStyle {
  width: 350px;
  height: 50vh;
  z-index: 50;
  position: absolute;
  opacity: 0.5;
}

.file_name {
  font-size: 13px;
  color: rgb(var(--v-theme-darkSlate));
}
</style>

<template>
    <!-- Filter By Card Properties -->
  <div >
    <div class="pb-2">
      <v-container>    
<v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>
        <v-row class="pt-4 ma-0 pl-2" width="100%">
          <!-- Attribute to Group Selection Dropdown -->
          <v-combobox
            v-model="getSelectedWidget.instance_setting.data.selectedAttribute"
            :items="getAttrHeaderOptions"
            :label="'Select Attribute ('+getAttrHeaderOptions.length+')'"
          />
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  props:{
    getSelectedWidget:{}
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getEditMode,
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    return {getEditMode, getAttrData, getAttrHeaderOptions}
  },
  computed:{
  },
  methods:{}
}
</script>
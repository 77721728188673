<template>
  <span>
    <div>
      <v-menu offset-y open-on-hover>

        <!-- Ellipse Icon-->
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" icon size="small" :href="!getUser ? '/' : '/dashboard/notebooks'">
            <v-img width="30" height="30" contain src="../assets/imgs/Ellipse_Clear_128.png">
            </v-img>
          </v-btn>
        </template>

        <!-- Nav Menu. -->
        <span v-if="this.$route.name !== 'Visitor' && this.$route.name !== '401'">
          <NavBarMenu :private="isPrivate()" />
        </span>
      </v-menu>
    </div>
  </span>

  <!-- Ellipse Header. -->
  <v-btn class="pl-2 ml-1" variant="plain" rounded href="/dashboard/notebooks"
    v-if="$route.path === '/' || dashboardPresent || PluginDownloadPresent || AccountPresent || SettingsPresent || AboutPresent">
    <span class="text-uppercase text-subtitle"> ELLIPSE</span>
    <span style="color:rgb(var(--v-theme-primary))" class="font-weight-light">&nbsp; alpha</span>
  </v-btn>

  <!-- Notebook Name Headder (LEFT). -->
  <v-btn rounded variant="plain" class="pl-2 ml-1 siteTitle" v-if="getNotebook">
    <span class="text-capitalize text-subtitle text-truncate">{{ getNotebook.name }}</span>
    <v-tooltip location="bottom" activator="parent"><span>{{ getNotebook.name }}</span></v-tooltip>
  </v-btn>
  <v-spacer></v-spacer>

  <!-- Page Name Headder (Center). -->
  <div v-if="getSelectedPage" class="pageTitle text-truncate" style="">
    {{ getSelectedPage.name }}
    <v-tooltip location="bottom" activator="parent"><span>{{ getSelectedPage.name }}</span></v-tooltip>
  </div>
  <v-spacer></v-spacer>

  <div v-if="getPagesStructuredData.length">
    <span class="px-0 mx-1" v-for="item in getPagesStructuredData" :key="item.name">
      <!-- With Sub-Pages. -->
      <v-menu v-if="item.childNodes.length > 0" open-on-hover offset-y :close-on-content-click="false">
        <!-- Notebook Section Button. -->
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" size="small" :color="getSelectedPage._id === item._id ? 'primary' : 'darkSlate'"
            elevation="0" class="text-subtitle-1 text-capitalize px-3" @click="onClickPage(item)">
            {{ item.name }}
            <v-icon right v-if="item.childNodes.length > 0">
              {{ icons.arrowDown }}
            </v-icon>
            <v-tooltip location="right" activator="parent"><span>{{ item.name }}</span></v-tooltip>
          </v-btn>
        </template>

        <!-- Sub-Page Dropdown. -->
        <v-list density="compact" style="cursor: pointer" class="px-0 mx-0">
          <v-list-item v-for="child in item.childNodes" :key="child.name" class="pa-0">
            <!-- Sub Page Nav.-->
            <v-btn size="small" :color="getSelectedPage._id === item._id ? 'primary' : 'darkSlate'" variant="text" block
              class="text-capitalize ma-0" @click="onClickPage(child)">
              {{ child.name }}
              <v-tooltip location="bottom" activator="parent"><span>{{ child.name }}</span></v-tooltip>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- No Sub-Pages. -->
      <v-btn v-else size="small" :color="getSelectedPage._id === item._id ? 'primary' : 'darkSlate'" elevation="0"
        class="text-subtitle-1 text-capitalize px-3" @click="onClickPage(item)">
        {{ item.name }}
        <v-icon right v-if="item.childNodes.length > 0">
          {{ icons.arrowDown }}
        </v-icon>
        <v-tooltip location="right" activator="parent"><span>{{ item.name }}</span></v-tooltip>
      </v-btn>
    </span>
  </div>

  <!-- Full Screen Button. -->
  <v-btn size="24" v-show="notebookPresent" variant="text" icon id="fullscreen" class="mr-1">
    <v-icon size="20">{{ icons.fullScreen }}</v-icon>
    <v-tooltip location="bottom" activator="parent">Full Screen</v-tooltip>
  </v-btn>

  <!-- Save Screen Capture. -->
  <v-btn size="24" v-show="notebookPresent" variant="text" color="darkSlate" icon id="imagecapture" class="mr-1">
    <v-icon size="18">{{ icons.screenCapture }}</v-icon>
    <v-tooltip location="bottom" activator="parent">Save Screen Capture</v-tooltip>
  </v-btn>

  <!-- Create Public Url-->
  <span v-if="getNotebook">
    <v-btn v-if="this.$route.name !== 'Visitor'"
      @click="createPublicUrlDialog = true" :color="getNotebook.isPublic ? 'primary' : 'darkSlate'" size="24"
      v-show="notebookPresent" icon class="mr-1">
      <v-icon size="20">{{ icons.shareURL }}</v-icon>
      <v-tooltip location="bottom" activator="parent">Share Public Url</v-tooltip>
    </v-btn>
  </span>


  <!-- Download Data. -->
  <v-btn size="24" v-show="notebookPresent" variant="text" color="darkSlate" icon id="downloaddata" class="mr-1">
    <v-icon size="18">mdi:mdi-download</v-icon>
    <v-tooltip location="bottom" activator="parent">Download Data</v-tooltip>

    <v-menu activator="parent" offset-y :close-on-content-click="false" >
      <v-sheet class="d-flex flex-column pa-3">
        <div class="text-caption pt-2"><strong>Filter Downloaded Data</strong></div>

        <v-btn-toggle v-model="downloadtype" variant="outlined" rounded density="compact" color="secondary" divided>
          <v-btn size="small">
            <v-icon icon="mdi:mdi-select-all"></v-icon> All
            <v-tooltip location="top" activator="parent">Download All Data</v-tooltip>
          </v-btn>
          <v-btn size="small">
            <v-icon icon="mdi:mdi-cursor-default"></v-icon> Selected
            <v-tooltip location="top" activator="parent">Download Selected Data</v-tooltip>
          </v-btn>
          <v-btn size="small">
            <v-icon icon="mdi:mdi-filter"></v-icon> Filtered
            <v-tooltip location="top" activator="parent">Download Filtered Data</v-tooltip>
          </v-btn>
        </v-btn-toggle>
        <v-divider class="my-2"></v-divider>
        <DownloadDataButton text="CSV" isCsv variant="outlined" class="ml-1" :source="downloadtypes[downloadtype]" hasDropdown/>
      </v-sheet>
    </v-menu>
  </v-btn>

  <SharePublicLink 
    :createPublicUrlDialog="createPublicUrlDialog"
    :notebook="getNotebook"
    @closed="SharePublicLinkDialogClosed"
  ></SharePublicLink>

  <!-- User And Login Managment. -->
  <div>
    <div>
      <div v-if="!getUser" class="pr-2">
        <v-btn class="text-capitalize text-white mr-3" color="primary" variant="outlined" @click="signUp"
          size="small">Sign Up</v-btn>
        <v-btn class="text-capitalize text-white mr-0" @click="login" size="small" color="primary"
          variant="elevated">Sign
          In</v-btn>
      </div>
      <div v-else>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon>
              <v-avatar size="30">
                <img v-if="getUser.picture" :src="getUser.picture" style="height:30px" />
                <span v-else>TT</span>
              </v-avatar>
            </v-btn>
          </template>

          <v-card v-if="this.$route.name !== 'Visitor'" class="mx-auto " min-width="200">
            <v-card-text class="pt-2 pb-0" style="height:70px">
              <v-row class="px-3 pt-3 text-subtitle-1 font-weight-medium text-capitalize">{{ getUser.name }}</v-row>
              <v-row class="px-3"><small><i>{{ getUser.email }}</i></small></v-row>
            </v-card-text>

            <v-list>
              <v-list-item v-if="getUser.permissions.admin" to="/manageUsers">
                <template v-slot:prepend>
                  <v-icon icon="mdi:mdi-account-group"></v-icon>
                </template>
                <v-list-item-title>Manage Users</v-list-item-title>
              </v-list-item>

              <v-list-item to="/account">
                <template v-slot:prepend>
                  <v-icon>{{ icons.account }}</v-icon>
                </template>
                <v-list-item-title>Account</v-list-item-title>
              </v-list-item>

              <v-list-item to="/settings">
                <template v-slot:prepend>
                  <v-icon>{{ icons.settings }}</v-icon>
                </template>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout">
                <template v-slot:prepend>
                  <v-icon color="secondary">{{ icons.logout }}</v-icon>
                </template>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
  </div>
  <v-snackbar v-model="copyUrl" :timeout="2000" absolute location="bottom" color="primary">
    <div class="text-center justify-center"> {{ message }}</div>
  </v-snackbar>
</template>

<script>
import NavBarMenu from "../components/TheNavBarMenu.vue";
import DownloadDataButton from "../components/ui/DownloadDataButton.vue";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

import * as utils from "@/utilities";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import SharePublicLink from "../components/popUps/SharePublicLink.vue";

export default {
  components: { NavBarMenu, DownloadDataButton, SharePublicLink },
  data: () => ({
    path: "",
    selectedItem: 0,
    admins: [
      {
        icon: lexicon.user.icon,
        title: "Manage Users",
        to: "/manageUsers",
      },
    ],
    copyUrl: false,
    message: "Public Url Copied to Clipboard",
    createPublicUrlDialog: false,
    downloadtypes: ['all', 'selected', 'filtered'],
    downloadtype: 0,
    qrCodeDataUrl: null,
  }),
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedPage,
    } = storeToRefs(notebookPropsStore)
    const {
      getUser,
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      setPagesStructuredData
    } = notebookPropsStore;
    const {
      setNotebook
    } = ellipseStore;
    return {
      notebookPropsStore,
      getSelectedPage, getNotebook, getUser, setNotebook,
      setPagesStructuredData, lexicon, icons
    }
  },
  computed: {
    notebookPresent() {
      this.path = this.$route.path;

      if (this.path.includes("notebook/")) {
        return true;
      } else {
        return false;
      }
    },
    dashboardPresent() {
      this.path = this.$route.path;

      if (this.path.includes("dashboard")) {
        return true;
      } else {
        return false;
      }
    },
    AboutPresent() {
      this.path = this.$route.path;
      if (this.path.includes("about")) {
        return true;
      } else {
        return false;
      }
    },
    AccountPresent() {
      this.path = this.$route.path;
      if (this.path.includes("account")) {
        return true;
      } else {
        return false;
      }
    },
    SettingsPresent() {
      this.path = this.$route.path;
      if (this.path.includes("settings")) {
        return true;
      } else {
        return false;
      }
    },
    PluginDownloadPresent() {
      this.path = this.$route.path;
      if (this.path.includes("pluginDownload")) {
        return true;
      } else {
        return false;
      }
    },
    getPagesStructuredData() {
      const itemFilter = (items) =>
        items
          .map((item) =>
            item.childNodes
              ? { ...item, childNodes: itemFilter(item.childNodes) }
              : item
          )
          .filter((item) => item.isPublic);
      let newArray = itemFilter(this.notebookPropsStore.getPagesStructuredData);
      return newArray;
    },
  },
  methods: {
    isPrivate() {
      if (!this.getUser) {
        if (this.$route.path === '/' || this.AboutPresent) return true;
        return false;
      }
      else {
        return false;
      }
    },
    async onClickPage(newPage) {
      if (this.getSelectedPage._id === newPage._id) return;
      let pageType = this.$route.name;
      this.$router.push("/notebook/" + this.getNotebook._id + '/' + pageType + '/' + newPage._id).catch(err => { });
      utils.emptyPreviousState();
      await this.setPagesStructuredData(this.$route.params.id);
      await utils.clickOnPageFunction(newPage);
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    signUp() {
      this.$auth.loginWithRedirect({ screen_hint: "signup" });
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    SharePublicLinkDialogClosed(){
      this.createPublicUrlDialog = false
      this.selectedNotebook = null
    }
  },
};
</script>
<style scoped>
.v-toolbar__content {
  padding: 16px !important
}

*:deep(.v-toolbar__content) {
  align-content: center;
  height: 50px;
  flex-wrap: wrap;
}

.v-list {
  padding: 5px 0 !important;
}

.v-list--dense .v-list-item {
  min-height: 35px !important;
}

.siteTitle {
  color: rgb(var(--v-theme-darkGrey));
  font-weight: 400;
  font-size: medium;
}

.pageTitle {
  color: rgb(var(--v-theme-darkSlate));
  font-weight: 500;
  font-size: large;
}

.pageNavigation {
  color: rgb(var(--v-theme-darkSlate));
  font-weight: 400;
  font-size: smaller;
}
</style>

// Essentially copy-paste from .NET

var desktopColors = {

	//Primary Color Set
	Transparent: [0, 0, 0, 0],
	TransparentWhite: [0, 255, 255, 255],
	White: [255, 255, 255, 255],
	Black: [255, 0, 0, 0],

	Red: [255, 255, 0, 0],
	Green: [255, 0, 255, 0],
	Blue: [255, 0, 0, 255],

	Cyan: [255, 0, 255, 255],
	Magenta: [255, 255, 0, 255],
	Yellow: [255, 255, 255, 0],


	//Transparency Grayscales
	VeryLightGrayFilter: [10, 0, 0, 0],
	LightGrayFilter: [50, 0, 0, 0],
	GrayFilter: [100, 0, 0, 0],
	DarkGrayFilter: [200, 0, 0, 0],

	//Solid Grayscales
	OffWhite: [255, 250, 250, 250],
	VeryLightGray: [255, 240, 240, 240],
	LightGray: [255, 211, 211, 211],
	WashedGray: [255, 170, 170, 170],
	Gray: [255, 128, 128, 128],
	Charcoal: [255, 80, 80, 80],
	DarkGray: [255, 64, 64, 64],
	VeryDarkGray: [255, 32, 32, 32],
};

export const desktop = Object.keys(desktopColors).reduce(function(dict, key) {
	var colorArr = desktopColors[key];
	dict[key.charAt(0).toLowerCase() + key.slice(1)] = 
    "rgba(" +   [colorArr[1], 
                colorArr[2], 
                colorArr[3], 
                Math.round(colorArr[0] * 100 / 255) / 100].join(",") 
    + ")";
	return dict;
}, {});
<template>
  <div>
    <v-hover v-slot="{ hover }">
    <v-card class="rounded-lg" height="250" @click="$emit('onNotebookClick')" :elevation="hover ? 5 : 2">
      <div class="pa-2">
        <div class="d-flex">
          <div :style="`position:relative;`">
            <v-avatar
              class="rounded-lg"
              color="darkGrey"
              tile
              style="height: 120px; width: 130px"
            >
              <v-img v-if="imageURL" :src="imageURL" cover></v-img>
              <v-img v-else src="../assets/imgs/notebookCardPlaceholder.png" cover></v-img>
            </v-avatar>
          </div>
          <div class="pl-2">
            <div class="d-flex notebook-property-div">
              <v-icon size="x-small">{{ lexicon.page.icon }}</v-icon>
              <div
                class = "notebook-property"
              >
                <span v-if="notebook.numPages.length">
                  {{ notebook.numPages[0].count }} {{ lexicon.page.plural }}</span
                ><span v-else>0 Pages</span>
              </div>
            </div>
            <div class="notebook-property-div">
              <v-icon  size="x-small">{{ lexicon.user.icon}}</v-icon>
              <div
                class = "notebook-property"
              >
                <span v-if="notebook.numUsers.length">
                  {{ notebook.numUsers[0].count }} {{lexicon.user.plural}}</span
                >
                <span v-else> 0 Users</span>
              </div>
            </div>
            <div class="notebook-property-div">
              <v-icon size="x-small">{{ lexicon.model.icon }}</v-icon>
              <div
                class = "notebook-property"
              >
                <span v-if="notebook.numModels.length">
                  {{ notebook.numModels[0].count }} {{lexicon.model.plural}}</span
                >
                <span v-else> 0 Models</span>
              </div>
            </div>
            <div class="notebook-property-div">
              <v-icon size="x-small">{{ lexicon.drawing.icon }}</v-icon>
              <div
                class = "notebook-property"
              >
                <span v-if="notebook.numDrawings.length">
                  {{ notebook.numDrawings[0].count }} {{lexicon.drawing.plural}}</span
                >
                <span v-else> 0 Drawings</span>
              </div>
            </div>
            <div class="notebook-property-div">
              <v-icon size="x-small">{{ lexicon.dataset.icon }}</v-icon>
              <div
                class = "notebook-property"
              >
                <span v-if="notebook.numDatasets.length">
                  {{ notebook.numDatasets[0].count }} {{lexicon.dataset.plural}}
                </span>
                <span v-else> 0 {{lexicon.dataset.plural}}</span>
              </div>
            </div>
            <div class="notebook-property-div">
              <v-icon size="x-small">{{ lexicon.file.icon }}</v-icon>
              <div
                class = "notebook-property"
              >
                <span v-if="notebook.numFiles.length">
                  {{ notebook.numFiles[0].count }} {{lexicon.file.plural}}
                </span>
                <span v-else> 0 {{lexicon.file.plural}}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="pt-2 pl-2 font-weight-bold text-body text-truncate">
            <span>{{ notebook.name }}</span>
            <v-tooltip location="top" activator="parent"><span>{{notebook.name}}</span></v-tooltip>
          </div>
          <div class="pl-2 font-weight-light text-caption text-truncate">
            <span>Owner: {{notebook.createdBy.user.name}}</span>
            <v-tooltip location="top" activator="parent"><span>{{notebook.createdBy.user.name}}</span></v-tooltip>
          </div>
          <div class="pl-2 pt-1 font-weight-light text-caption">
            <span>
            Modified: {{ $filters.momentTimestampDDMMYYYY(notebook.updatedAt) }}
            </span>
          </div>
          <div class="d-flex">
            <span><Sharing :notebook="notebook" :itemType="'button'" /></span>
            <v-spacer></v-spacer>
            <span>
              <v-menu offset-y>
                <template v-slot:activator="{ props}">
                  <v-btn v-bind="props" icon size="x-small" variant="text" >
                    <v-icon size="small" color="darkSlate">{{ icons.menu }}</v-icon>
                  </v-btn
                  >
                </template>
                <div>
                  <v-card
                    class="mx-auto"
                    max-width="250"
                  >
                    <v-list density="compact">
                      <v-list-item
                        v-for="(item, i) in optionsFilteredByPermission"
                        :key="i"
                        :value="item"
                        @click="$emit(item.action)"
                      >
                        <template v-slot:prepend>
                        <v-icon :icon="item.icon"></v-icon>
                        </template>
                        <v-list-item-title >{{item.text}}</v-list-item-title>
                      </v-list-item>
                      <Sharing :notebook="notebook" :itemtype="'list'"/>
                    </v-list>
                  </v-card>
                </div>
              </v-menu>
            </span>
          </div>
        </div>
      </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import Sharing from "./popUps/NotebookCardSharing.vue";
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: { Sharing, AreYourSurePopUp},
  props: ["notebook"],
  setup(){
    const ellipseStore = useEllipseStore();
    const { 
      getNotebooks 
    } = storeToRefs(ellipseStore);
    const {
      setNotebooks,
      setTemplates,
    } = ellipseStore;
    return { 
      getNotebooks,
      setNotebooks, 
      setTemplates, 
      lexicon, 
      messages,
      icons 
    };
  },
  data() {
    return {
      options: [
        {text: lexicon.rename , icon: icons.rename , action: 'onEditNotebookClick', minPermissionRequired: 300},
        {text: lexicon.duplicate , icon: icons.duplicate , action: 'OnCopyNotebookClick', minPermissionRequired: 300},
        {text: lexicon.del , icon: icons.remove , action: 'onDeleteNotebookClick', minPermissionRequired: 500},
        {text: messages.templateSave , icon: lexicon.template.icon, action: 'onCreateTemplateClick', minPermissionRequired: 100},
        {text: 'Set Cover Image' , icon: lexicon.image.icon, action: 'onSetImageClick', minPermissionRequired: 300},
        {text: 'Make Public' , icon: icons.shareURL, action: 'onMakePublic', minPermissionRequired: 300},
      ],
      imageURL: null
    };
  },
  watch: {
    notebook: {
      immediate: true, 
      deep: true,
      handler() {
        this.updateImageURL();
      }
    }
  },
  created(){
  },
  computed: {
    optionsFilteredByPermission(){
      let userPermissions = this.notebook.userPermissions.permissions
      return this.options.filter(option => userPermissions >= option.minPermissionRequired)
    }
  },
  methods: {
    async updateImageURL() {
      if (this.notebook.coverImage){
        try {
          let s3Key = this.notebook.coverImage.s3Key;
          let params = { urlType: this.$route.name };
          let response = await this.$auth.$api.get(
            `/api/notebook/${this.notebook._id}/file/view?s3Key=${s3Key}`,
            { params }
          );
          this.imageURL = response.data; // Set the resolved URL
        } catch (error) {
          console.error("Error fetching image URL:", error);
          this.imageURL = "../assets/imgs/notebookCardPlaceholder.png"; // Fallback
        }
      }
    }
  },
};
</script>

<style scoped>
  .notebook-property{
    font-size: 12px;
    padding-left: 4px;
    font-weight: 300;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .notebook-property-div{
    padding-top: 2px;
    display: flex;
    color: rgb(var(--v-theme-darkGrey));
  }
</style>

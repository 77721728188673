<template>
  <!-- Selected Element EllipseIDs. -->
  <div class="mb-0 mt-0" style="text-align:center;">

    <!-- v-tooltip displays a list of selected elements on hover -->
    <v-tooltip location="bottom">
      <template v-slot:activator="{ props}">
        <!-- If more than 1 selected element -->
        <div v-if="getSelectedElements.length > 1">
          <span
            v-bind="props"
            class="text-grey my-3 overflow-x smallText"
            style="hyphens: none; cursor:pointer;"
            id="menu-activator"
          >
            {{ getSelectedElements.length }} Elements Selected
          </span>
        </div>
        <!-- If only 1 selected element -->
        <div v-else>
          <span
            v-bind="props"
            class="text-grey my-3 mt-0 pt-0 overflow-x smallText"
          >
            {{ getSelectedElements[0] }}
          </span>
        </div>
      </template>
      <!-- Tooltip content -->
      <ul>
        <li v-for="(element, index) in getSelectedElements" :key="index">
          {{ element }}
        </li>
      </ul>
    </v-tooltip>

  </div>
  <v-divider></v-divider>

    <!-- Selected Element Properties Table. -->
    <AttributesList :variable="variableAttributesMsg" :truncate="truncateData"></AttributesList>

    <v-switch v-if="getSelectedElementsAttributes.length> 0" hide-details density="compact" class="pl-3" v-model="truncateData" flat label="Single Line"></v-switch>

</template>
<script>
import * as utils from "@/utilities"
import AttributesList from "./ui/AttributesList.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js"

export default {
  components:{ AttributesList 
  },
  props:[],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getSelectedElements,
      getGlobalColorByAttribute,
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaders,
    } = storeToRefs(dataGraphicsStore)
    const { 
      updateColorByProperties,
      updateLabelByProperties
    } = dataGraphicsStore
    return {notebookPropsStore, getSelectedElements, getGlobalColorByAttribute, getAttrHeaders,
    getAttrData, updateColorByProperties, updateLabelByProperties}
  },
  data(){
    return {
      truncateData: false,
      variableAttributesMsg: "varies",
    };
  },
  created(){
  },
  computed:{
    getSelectedElementsAttributes() {
      let idSet = new Set(this.getSelectedElements);
      // Filter the based on the presence of the ID in the Set
      return this.getAttrData.filter((obj) => idSet.has(obj.ellipseId));
    },
  },
  methods:{
  }
}
</script>

<style scoped>

.smallText {
  font-size: 10px;
  font-weight: 400;
}

</style>
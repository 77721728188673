<template>
  <v-dialog persistent
    :model-value="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        Select Cover Image
      </v-card-title>
      <v-card-text>
        <v-combobox 
          label="image"
          :key="comboboxKey"
          v-model="selectedImage"
          item-text="name"
          :items="imageFiles">
          <template v-slot:selection="{ item }">
            {{ item.name ? item.name : item.value.name }}
          </template>
          <template v-slot:item="{item, index}">
            <v-list-item @click="handleCoverImageSelection(item.value)">
              <v-list-item-title>
                <v-icon v-if="index==0">{{ icons.upload }}</v-icon>{{ item.value.name }}
              </v-list-item-title>
            </v-list-item>
            <v-divider v-if="index === 0 || index === imageFiles.length"></v-divider>
          </template>
        </v-combobox>
        <input
            class="d-none"
            ref="uploader"
            type="file"
            multiple="multiple"
            @change="uploadFile($event)"
          />
        <v-img v-if="uploadedImageUrl" :src="uploadedImageUrl"></v-img>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-white"
          color="darkGrey"
          @click="$emit('close')"
          >{{ lexicon.cancel }}</v-btn
        >
        <v-btn
          class="text-white"
          color="primary"
          :loading="loading"
          @click="saveCoverImage()"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js"
import * as icons from "@/utilities/EllipseIcons.js"
import { useEllipseStore } from "@/store/EllipseStore.js"
import * as utils from "@/utilities"

export default {
  setup(){
    const ellipseStore = useEllipseStore()
    const {
      setNotebooks,
    } = ellipseStore
    return { lexicon, icons, setNotebooks };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    notebook: {
      required: true
    },
  },
  watch: {
    "dialog": async function(){
      if (this.dialog == true) {
        await this.fetchImageFiles()
        if (this.notebook.coverImage){
          this.handleCoverImageSelection(this.notebook.coverImage)
        }
      }
    }
  },
  data() {
    return {
      imageFiles: [],
      uploadedImageUrl: null,
      currentUploadedFile: null,
      selectedImage: null,
      comboboxKey: 0,
      loading: false
    };
  },
  created(){
  },
  methods: {
    async fetchImageFiles(){
      let rootFolderInfo = await this.$auth.$api.get(
        `/api/notebook/${this.notebook._id}/folder/${this.notebook.rootFolder}/child-folders`
      );
      if (rootFolderInfo.data && rootFolderInfo.data.data){
        let files = rootFolderInfo.data.data
        let imageFiles = files.filter(file => file.type.includes('image'))
        this.imageFiles = [].concat({name: 'Upload New Image'}, imageFiles)
      }
    },
    async handleCoverImageSelection(item){
      if (item.name == 'Upload New Image'){
        this.selectFile()
      }
      else if (item.url){
        this.uploadedImageUrl = item.url
        this.selectedImage = {
          title: item.name,
          value: item
        }
        this.comboboxKey++
      }
      else {
        let url = await this.getExistingImageURL(item.s3Key)
        this.uploadedImageUrl = url
        this.selectedImage = {
          title: item.name,
          value: item
        }
        this.comboboxKey++
      }
    },
    selectFile() {
      this.$refs.uploader.click();
    },
    async uploadFile(e) {
      const file = e.target.files[0]
      if (file && file.type.includes('image')){
        this.currentUploadedFile = file
        let fileURL = URL.createObjectURL(file)
        this.uploadedImageUrl = fileURL
        this.selectedImage = {
          title: file.name,
          value: file
        }
        this.imageFiles.splice(1, 0, {name: file.name, url: fileURL})
        this.comboboxKey++
      }
    },
    async getExistingImageURL(s3Key){
      let params = {urlType: this.$route.name};
      let url = await this.$auth.$api.get(`/api/notebook/${this.notebook._id}/file/view?s3Key=${s3Key}`,{params});
      return url.data
    },
    async saveCoverImage(){
      if (!this.selectedImage) return

      this.loading = true
      
      try {
        const coverImage = this.selectedImage.value.s3Key
        ? this.selectedImage.value
        : await this.uploadToS3();

        await this.updateNotebookInfo(coverImage);
        this.resetSelection();
        this.loading = false
        this.$emit('close')
        this.$emit('imageChanged')
      } catch (error) {
        console.error("Error setting cover image:", error);
      } finally {
        this.loading = false
      }
    },
    resetSelection(){
      this.selectedImage = null;
      this.uploadedImageUrl = null;
    },
    async updateNotebookInfo(data){
      let postData = {
        coverImage: data
      }
      let route = `/api/notebook/${this.notebook._id}/set-cover-image`
      try {
        await this.$auth.$api.post(route, postData)
        await this.setNotebooks()
      } catch (error){
        console.log(error)
      }
    },  
    async uploadToS3() {
      return new Promise(async (resolve, reject) => {
        try {
          let file = this.currentUploadedFile;
          let that = this;
          let urlPrefix = `/api/notebook/${this.notebook._id}/file`;
          let data = {
            notebook: this.notebook._id,
            folder: this.notebook.rootFolder,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            size: file.size,
            groups: file.groups || [],
            type: file.type || file.name.split(".")[1],
          };

          let tempPostRoute = `${urlPrefix}/create-temp/?urlType=${that.$route.name}`;
          let newFile = await that.$auth.$api.post(tempPostRoute, data);
          if (newFile.status !== 201) {
            return reject(new Error("Error creating temp file"));
          }
          newFile = newFile.data.data;

          // Upload file to S3
          let path = `notebooks/${this.notebook._id}/files/`;
          await utils.uploadToS3(
            [file],
            path,
            newFile._id,
            this.notebook._id,
            async function (progresses, uploadedKey) {
              try {
                if (uploadedKey) {
                  newFile.s3Key = uploadedKey;
                  newFile.allowAnonymousAccess = true;

                  let savePostRoute = `${urlPrefix}/save/?urlType=${that.$route.name}`;
                  let savedFile = await that.$auth.$api.post(savePostRoute, newFile);
                  resolve(newFile)
                }
              } catch (error) {
                reject(error)
              }
            }
          );
        } catch (error) {
          reject(error)
        }
      });
    }
  }
}
</script>
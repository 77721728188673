<template>        
    <v-btn
        v-if="show"
        size="small"
        variant="outlined"
        rounded
        color="primary"
        class="rounded-xl px-3 mt-1"
        @click="onClick">
        <slot>ButtonText</slot>
    </v-btn>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      required: true
    },
    show: {
        type: Boolean,
        required: true
    }
  }
}
</script>

<style scoped></style>
<template>
  <v-row class="mt-0 ml-0" style="height:100%">
    <v-col class="pageHeight ma-0 pa-0" style="overflow-y:hidden" cols="2">
      <DashboardSideBar />
    </v-col>
    <v-col class="pageHeight ma-0 pa-0" cols="10">
      <router-view></router-view>
    </v-col>
  </v-row>
</template>

<script>
import DashboardSideBar from "../components/TheDashboardSideBar.vue";
export default {
  components: { DashboardSideBar },
  data() {
    return {};
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
</style>

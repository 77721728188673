<template>
  <div class="px-5 py-3" style="height: 100%">

      <span class="header"> {{ lexicon.template.plural }}
        <HelpButton :lexiconItem="lexicon.template" />           
        </span>


    <v-sheet>
    <v-container py-0 px-0 v-if="!viewList" fluid>
      <v-row class="d-flex flex-wrap" v-if="getTemplates">
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" :style="`cursor: pointer;`" class="px-2 py-2"
          v-for="template in getTemplates.slice().reverse()" :key="template._id">
          <TemplateCard :template="template" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
  </div>
</template>

<script>
import TemplateCard from "../components/TemplateCard.vue";
import NotebookItem from "../components/NotebookItem.vue";
import HelpButton from "../components/ui/HelpButton.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: { TemplateCard, NotebookItem, HelpButton },
  setup(){
    const ellipseStore = useEllipseStore()
    const {
      getTemplates,
    } = storeToRefs(ellipseStore)
    const {
      setTemplates,
    } = ellipseStore
    return {
      ellipseStore,
      getTemplates,
      setTemplates,
      lexicon,
      icons,
    }
  },
  data() {
    return {
      createNewNotebookDialog: false,
      notebookName: "",
      notebookDescription: "",
      createNotebookLoading: false,
      viewList: false,
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.setTemplates()
  },
  methods: {
  
  },
};
</script>

<style scoped>

#info-btn {
  position: absolute; 
  bottom: 1px; 
  left: 5px; 
  outline: none;
}

</style>

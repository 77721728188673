export const roles = {
  Admin: 500,
  Editor: 400,
  Author: 300,
  Contributor: 200,
  Viewer: 100
}

export const permissions = {
  500: [
    {
      name: "Admin",
    },
    {
      name: "Editor",
    },
    {
      name: "Author",
    },
    {
      name: "Contributor",
    },
    {
      name: "Viewer",
    },
  ],
  400: [
    {
      name: "Admin",
      disable: true,
    },
    {
      name: "Editor",
    },
    {
      name: "Author",
    },
    {
      name: "Contributor",
    },
    {
      name: "Viewer",
    },
  ],
  300: [
    {
      name: "Admin",
      disable: true,
    },
    {
      name: "Editor",
      disable: true,
    },
    {
      name: "Author",
    },
    {
      name: "Contributor",
    },
    {
      name: "Viewer",
    },
  ],
  200: [
    {
      name: "Admin",
      disable: true,
    },
    {
      name: "Editor",
      disable: true,
    },
    {
      name: "Author",
      disable: true,
    },
    {
      name: "Contributor",
    },
    {
      name: "Viewer",
    },
  ]
}
<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <span class="property-header pb-4">
        <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">fa-solid fa-sliders</v-icon>
        {{ grid_item.instance_setting.filters.length }} Value Sliders
    </span>

    <NoDataWarning :show="grid_item.instance_setting.filters.length === 0" :icon="widgetIcon.icon">Add Value Sliders from Widget Settings</NoDataWarning>
    
    <div class="pb-0"></div>
      <v-row class="pt-6" style="height:100vh; overflow-x: hidden; overflow-y: auto;">
        <v-col>
          <div
          v-for="(item, index) in grid_item.instance_setting.filters"
          :key="index">
          <v-slider
            v-model="item.values[0].value"
            thumb-label="always"
            :max="formatValue(item.max)"
            :min="formatValue(item.min)"
            hide-details
            density="compact"
            class="align-center pt-1 pb-6 pl-2 pr-3 mb-0"
            strict
            color="darkSlate"
            track-color="darkGrey"
            show-ticks="always"
            :ticks="getTickLabels(item.field)"
            tick-size="3"
            @update:modelValue="filterByValue"
          >

          <template v-slot:thumb-label>
            {{ Math.round(item.values[0].value) }}
          </template>

          <template v-slot:prepend>
            <h5 class="ml-0" style="width: 90px" >{{item.field}}<br> 
              <span class="font-weight-regular">{{formatValue(item.min)}} | {{formatValue(item.max)}}</span>
            </h5>
          </template>

          <template v-slot:tick-label="{ tick }">
            <span :style="{ fontSize: '12px' }">{{ tick.value }}</span>
          </template>


          <!-- Right Side Max. -->
          <template v-slot:append v-if="grid_item.instance_setting.data.inputVisible">
            <v-text-field
              v-model="item.values[0].value"
              class="mt-0 pt-0"
              hide-details
              single-line
              density="compact"
              variant="underlined"
              type="number"
              style="width: 60px"
              :rules="[v => (v >= item.min && v <= item.max) || `Value must be between ${item.min} and ${item.max}`]"
              @update:modelValue="filterByValue"
            ></v-text-field>
          </template>

          </v-slider>
          
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import {isNullOrUndefined} from "@/utilities"

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";
import * as utils from "@/utilities";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {Spinner, NoDataWarning, WidgetIcon, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeadersNumerical, getUniqueAttrByNumericalHeader,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      addFilter,
      updateFilterByProperties
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore,
    getEditMode, getAttrData, getAttrHeadersNumerical, getGlobalProperty, getSelectedPageDatasets, getDatasetLoading,
    updateFilterByProperties, getUniqueAttrByNumericalHeader, addFilter, updateFilterByProperties, lexicon, messages}
  },
  data() {
    return {
      inputVisible:false,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  watch: {
    "grid_item.instance_setting.data.filteredHeaders": function () {
      this.setSliderValues();
      this.filterByValue();
    },

    "grid_item.instance_setting.data.filterEmptyEntries": function () {
      this.filterByValue();
    },
	},
  mounted() {
    this.verifyInstanceSettings();
    this.filterByValue();
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.grid_item.instance_setting.data.filteredHeaders.forEach((item) => {
            this.evaluateSelectedField();
          });
        }
      })
    })
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetValue()
        }
      })
    })
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {
      //If a value is missing, populate with the default value. 
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!('filters' in this.grid_item.instance_setting)) this.grid_item.instance_setting.filters = [];
      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = [];
      if (!('sliderValues' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.sliderValues = [];
      this.setSliderValues();
    },
    formatValue(data) {
      return utils.setDigits(data);
    },
    getTickLabels(field){
      let tickLabels = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name == field).tickLabel
      return tickLabels
    },  
    datasetUpdated() {
      this.grid_item.instance_setting.data.filteredHeaders.forEach((item) => {
        this.evaluateSelectedField(item);
      });
    },
    setSliderValues() {
      let arrValues = [];
      this.getAttrHeadersNumerical.forEach((item) => {
        let range = evaluate.getRange(this.getUniqueAttrByNumericalHeader[item]);
        let uniqueValues = this.getUniqueAttrByNumericalHeader[item]
        const arrayFromSet = Array.from(uniqueValues);

        // Create an object from the array
        const setObject = arrayFromSet.reduce((obj, item, index) => {
          obj[item] = item; // Assign item to index key in the object
          return obj;
        }, {});
        let sliderValues = {
          name: item,
          type: "Value",
          min: range[0],
          max: range[1],
          tickLabel: setObject
        };
        arrValues.push(sliderValues);
      });
      this.grid_item.instance_setting.data.sliderValues = arrValues;
    },
    async filterByValue() {
      for (let header of this.grid_item.instance_setting.data.filteredHeaders) {
        // Find the corresponding sliderValue for the current item
        const i = this.grid_item.instance_setting.filters.findIndex(filter => filter.field === header);
        
        let filterObject 
        //Filter exists in filters
        if (i != -1) {
          filterObject = this.grid_item.instance_setting.filters[i]
          filterObject.filterName = 'Value Sliders'
        }
        //If slider does not exist in filters, add it
        else {
          const sliderValue = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header);
          filterObject = {
            filterType: "Value",
            filterName: 'Value Sliders',
            field: sliderValue.name,
            values: [{show: true, value: sliderValue.min}],
            min: sliderValue.min,
            max: sliderValue.max,
          };
          this.grid_item.instance_setting.filters.push(filterObject)

        }

        this.grid_item.instance_setting.filters.forEach(filterObject => {
          let allAttributeValuesSet = this.getUniqueAttrByNumericalHeader[filterObject.field];

          let allAttributeValuesArray = Array.from(allAttributeValuesSet)
          
          // Find the closest value in the array
          filterObject.values[0].value = allAttributeValuesArray.reduce((prev, curr) =>
            Math.abs(curr - filterObject.values[0].value) < Math.abs(prev - filterObject.values[0].value) ? curr : prev
          );
        });
       
        if (!isNullOrUndefined(filterObject.min) && !isNullOrUndefined(filterObject.max)) {
          // Find the corresponding array in getUniqueAttrByNumericalHeader
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
          if (shouldUpdate) await this.updateFilterByProperties()
        }
      }
      // Iterate through all the current filters and remove any that are not included in the current filtered headers
      this.grid_item.instance_setting.filters.forEach(async (filter, index) => {
        if (!this.grid_item.instance_setting.data.filteredHeaders.includes(filter.field)){
          //Remove filter from data graphics store
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filter}, true)
          if (shouldUpdate) await this.updateFilterByProperties()
          // Remove the filter from the filters array at the current index
          this.grid_item.instance_setting.filters.splice(index, 1)
        }
      })
    },
    resetValue(){
      this.grid_item.instance_setting.filters = [];
      this.grid_item.instance_setting.data.filteredHeaders = [];
      this.grid_item.instance_setting.data.sliderValues = [];
    },
  },
  async unmounted() {
  },
};
</script>
<style scoped>
.col{
  padding: 0px !important
}
.row{
  margin: 0px !important
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.space {
  margin-right: 5px;
  /* Adjust as needed */
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}
</style>

<template>
  <!-- Chart Widget Properties -->
  <div>

    <v-container>
      
      <v-row width="100%"><div class="text-caption pt-0"><strong>Plot Attributes</strong></div><v-divider/></v-row>

      <!-- Attribute X Axis Selection Dropdown -->
      <v-row width="100%" class="pt-4 pb-0 pl-2">
        <v-combobox 
          dense 
          hide-details 
          v-model="getSelectedWidget.instance_setting.data.selectedXAttr" 
          :items="getAttrHeaderOptions" item-title="name"
          :label="'Select X Attribute (' + getAttrHeaderOptions.length + ')'"
        />
      </v-row>

      <!-- Attribute Y Axis Selection Dropdown -->
      <v-row width="100%" class="pt-4 pb-0 pl-2">
          <v-combobox 
        dense 
        hide-details 
          v-model="getSelectedWidget.instance_setting.data.selectedYAttr" 
          :items="getAttrHeaderOptions" 
          :label="'Select Y Attribute (' + getAttrHeaderOptions.length + ')'"
          />
      </v-row>

      <!-- Attribute Radius Selection Dropdown -->
      <v-row width="100%" class="pt-4 pb-4 pl-2">
        <v-combobox 
          dense 
          hide-details 
          v-model="getSelectedWidget.instance_setting.data.selectedRAttr" 
          :items="getAttrHeadersNumerical" 
          :label="'Select Radius Attribute (' + getAttrHeadersNumerical.length + ')'"
        ></v-combobox>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Plot Display</strong></div><v-divider/></v-row>

      <!-- Dot Radius Selection -->
      <v-row width="100%" class="pa-0 pt-2 pl-2 ma-0">
        <!-- Min Radius -->
        <v-col lg="6"
          class="pb-0">
          <div class="text-caption pb-0">Min Radius | {{ getSelectedWidget.instance_setting.data.displaySettings.minRadius }}
          </div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.displaySettings.minRadius"
            prepend-icon="mdi:mdi-arrow-collapse-down"
            min="2"
            max="50"
            step="1"
            thumb-label
            hide-details
            color="primary"
          ></v-slider>
        </v-col>
        <!-- Max Radius -->
        <v-col lg="6"
          class="pb-0">
          <div class="text-caption"> Max Radius  | {{ getSelectedWidget.instance_setting.data.displaySettings.maxRadius }}</div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.displaySettings.maxRadius"
            prepend-icon="mdi:mdi-arrow-collapse-up"
            min="2"
            max="50"
            step="1"
            thumb-label
            hide-details
            color="primary"
          ></v-slider>
        </v-col>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>Plot Decorators</strong></div><v-divider/></v-row>

      <!-- Grid Lines -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- X Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.gridX"
            label="Grid X" />
        </v-col>
        <!-- Y Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.gridY"
            label="Grid Y" />
        </v-col>
      </v-row>

      <!-- Axis Lines -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- X Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.axisX"
            label="Axis X" />
        </v-col>
        <!-- Y Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.axisY"
            label="Axis Y" />
        </v-col>
      </v-row>

      <!-- User Inputs -->
      <v-row width="100%"><div class="text-caption pt-4"><strong>User Inputs</strong></div><v-divider/></v-row>
      <v-row class="mt-2">
        <v-select 
          label="Scrub Action"
          :items = "scrubActionOptions"
          v-model="getSelectedWidget.instance_setting.data.scrubAction">
        </v-select>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>Display Settings</strong></div><v-divider/></v-row>

      <!-- include filtered values in results -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.respondToFiltering"
          label="Respond to Filtering"
        />
      </v-row>

    </v-container>
  </div>
</template>
  
<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  props: {
      getSelectedWidget: {}
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderOptions,
      getAttrHeadersNumerical
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderOptions,getAttrHeadersNumerical}
  },
  data() {
    return {
      scrubActionOptions: ["Filter", "Select", "User Preference"]
    };
  },
  computed: {
    attrHeaders(){
      let i = 0;
      let newHeaders = this.getAttrHeaderOptions.map((e) => {
          return { id: i++, name: e };
      });
      return newHeaders;
    }
  },
  mounted(){
      //TODO - this should happen at created, then listen for updates to storeG
      //this.loadTableHeders();
  },
  methods: {}
}
</script>
  
<style scoped>
.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
}
</style>
<template>
  <div class="d-flex align-center justify-start">
      <v-avatar size="25" class="mx-1">
        <v-img cover :src="avatar" />
      </v-avatar>
    <div class="text-caption">
      <div class="text-truncate font-weight-bold ml-1" style="height: 16px">
        {{ name }}
      </div>
      <div class="text-truncate negMargin ml-1">
       <small></small> {{ timeStamp }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    timeStamp: {
        type: String,
        required: true,
    }
  },
};
</script>

<style scoped>
  .negMargin{
    margin: -1 0 -1 0 !important;
  }
</style>

<template>
  <v-dialog :model-value="createPublicUrlDialog" width="75%" persistent>
    <v-card v-if="notebook">
      <v-card-title class="bg-primary d-flex justify-center text-white"> 
        <span me-auto>Share Public Link</span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('closed')" > {{icons.cancel}} </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">

        <v-sheet class="d-flex pa-0">
          <v-sheet class=" me-auto align-self-end py-2">
            <v-switch 
              :disabled="notebook.userPermissions.permissions < 400 || this.noPublicPages"
              width="150px" :label="notebook.isPublic ? 'Public' : 'Private'" class="ml-4"
              :model-value="notebook.isPublic"
              @update:modelValue="togglePublicPrivateNotebook">
            </v-switch>
            <v-sheet :disabled="!notebook.isPublic" border rounded class="align-self-stretch ma-2 px-2 py-2">
              <v-btn v-if="notebook.isPublic" @click="copyPublicUrl" :prepend-icon="icons.duplicate" variant="text"
                class="ma-0 mr-2"> Copy
                <v-tooltip location="top" activator="parent">Copy Url</v-tooltip>
              </v-btn>
              <a class="text-wrap" style="word-break: break-word">
                <small v-if="notebook.isPublic">{{ publicUrl }}</small>
                <small v-else>link disabled</small>
              </a>
            </v-sheet>
          </v-sheet>
          <v-spacer></v-spacer>
          <v-sheet class="pa-0 mb-0 mt-3 mr-4">
            <img v-if="notebook.isPublic" :src="qrCodeDataUrl" alt="QR Code" width="150" height="150" />
          </v-sheet>
        </v-sheet>

        <v-alert v-if="noPublicPages" title="Warning: No Public Pages" color="error">
          There must be at least one public page in the notebook to create a public link.
        </v-alert>
        <template v-else>
          <v-alert v-if="notebook.isPublic" class="ma-2 text-caption" closable icon=icons.visible
            title="Warning: Public Notebook" color="warning">
            Anyone with this link will be able to view this notebook's published pages and contents their content.
          </v-alert>
          <v-alert v-else class="ma-2 text-caption" closable icon=icons.notVisible title="Warning: Private Notebook"
            color="background">
            The public link for this notebook has been disabled
          </v-alert>
        </template>
        

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import QRCode from "qrcode"

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

import * as utils from "@/utilities";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: ["createPublicUrlDialog", "notebook"],
  components: { QRCode },
  data: () => ({
    path: "",
    selectedItem: 0,
    admins: [
      {
        icon: lexicon.user.icon,
        title: "Manage Users",
        to: "/manageUsers",
      },
    ],
    copyUrl: false,
    message: "Public Url Copied to Clipboard",
    downloadtypes: ['all', 'selected', 'filtered'],
    downloadtype: 0,
    qrCodeDataUrl: null,
    publicUrl: null,
    noPublicPages: false
  }),
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedPage,
    } = storeToRefs(notebookPropsStore)
    const {
      getUser,
    } = storeToRefs(ellipseStore)
    const {
      setPagesStructuredData
    } = notebookPropsStore;
    const {
      setNotebook
    } = ellipseStore;
    return {
      notebookPropsStore,
      getSelectedPage, getUser, setNotebook,
      setPagesStructuredData, lexicon, icons
    }
  },
  created(){
    console.log(this.notebook)
  },
  watch: {
    createPublicUrlDialog: {
      deep: true,
      handler: async function (newVal, oldVal) {
        if (newVal == true){
          if (this.notebook){
            if (this.notebook.isPublic) {
              this.createPublicUrl()
            }
            else {
              let publicPageId = await this.getPublicPage()
              if (!publicPageId) this.noPublicPages = true
            }
          }
        }
        else {
          this.noPublicPages = null
          this.publicUrl = null
          this.qrCodeDataUrl = null
        }
      },
    },
  },
  mounted(){
  },   
  computed: {
  },
  methods: {
    createQrCode(url) {
      QRCode.toDataURL(url)
        .then(url => {
          this.qrCodeDataUrl = url;
        })
        .catch(err => {
          console.error(err)
        })
    },
    async togglePublicPrivateNotebook() {
      if (!this.notebook.isPublic){
        let urlCreated = await this.createPublicUrl()
        if (urlCreated){
          this.notebook.isPublic = true
          await this.updateNotebookInfo()
        }
      }
      else {
        this.notebook.isPublic = false
        this.qrCodeDataUrl = null
        this.publicUrl = null
        await this.updateNotebookInfo()
      }
      
    },
    async updateNotebookInfo(){
      let postBody = {
        isPublic: this.notebook.isPublic,
        urlType: this.$router.currentRoute._value.name
      };
      let updateNotebook = await this.$auth.$api.post(
        `/api/notebook/${this.notebook._id}/edit-info`,
        postBody
      );
    },
    async createPublicUrl() {
      let copyText = window.location.href.toLowerCase();
      let urlCreated = false
      if(copyText.includes('dashboard')){
        let baseURL = copyText.split('dashboard')[0]
        let pageId = await this.getPublicPage()
        if (pageId){
          this.publicUrl = `${baseURL}notebook/${this.notebook._id}/visitor/${pageId}`
          this.createQrCode(this.publicUrl)
          urlCreated = true
        }
        else {
          this.noPublicPages = true
        }
      }
      else {
        let result = copyText.replace("page", "visitor");
        this.createQrCode(result);
        this.publicUrl = result
        urlCreated = true
      }
      return urlCreated
    },
    async getPublicPage(){
      let params = {
        notebookId: this.notebook._id,
        urlType: 'Dashboard'
      };
      let all_pages = await this.$auth.$api.get(
        `/api/pages/get-all-for-notebook-structured`,{ params });
      if (all_pages && all_pages.data){
        for (const page of all_pages.data.data.pages){
          if (page.isPublic) {
            return page._id
          }
        }
      }
      else return null
    },
    copyPublicUrl() {
      navigator.clipboard.writeText(this.publicUrl);
      this.copyUrl = true;
    },
  },
};

</script>
<template>
  <!-- Left Panel Tab Buttons. -->
  <v-tabs @update:modelValue="clickOnNavigationTab" :model-value="getSelectedNavigationTab" mandatory
    :bg-color="getEditMode ? 'accent' : 'primary'" stacked next-icon="false" prev-icon="false"
    selected-class="selectedTab">
    <!-- Pages tab. -->
    <template v-if="!getEditMode">
      <v-tab value="pages" href="#pages" class="paneltab mx-1">
        <v-icon class="sidePanelTabIcon">
          {{ lexicon.page.icon }}
        </v-icon>
        <small class="pt-1 text-uppercase">Pages</small>
      </v-tab>
    </template>

    <!-- Comments tab. -->
    <template v-if="!getEditMode">
      <v-tab value="comments" href="#comments" class="paneltab mx-1">
        <v-icon class="sidePanelTabIcon">{{ icons.comment }}</v-icon>
        <small class="pt-1 text-uppercase">Comments</small>
      </v-tab>
    </template>

    <!-- Files Tab. -->
    <template v-if="!getEditMode">
      <v-tab 
        value="files" 
        href="#files" 
        class="paneltab mx-1"
      >
            <v-icon 
              class="sidePanelTabIcon">{{ lexicon.attachment.icon }}</v-icon>
        <small class="pt-1 text-uppercase">Attach</small>
      </v-tab>
    </template>

    <!-- Widgets Tab. -->
    <template v-if="getEditMode">
      <v-tab value="widgets" href="#widgets" class="paneltab mx-1">
        <v-icon class="sidePanelTabIcon">
          {{ lexicon.widget.icon }}
        </v-icon>
        <small class="pt-1 text-uppercase">Widgets</small>
      </v-tab>
    </template>

    <!-- Sources. -->
    <template v-if="getEditMode">
      <v-tab 
        value="globalSources" 
        href="#globalSources" 
        class="paneltab mx-1"
      >
            <v-icon 
              v-if="getSelectedWidget" 
              class="sidePanelTabIcon" 
            >
              {{ lexicon.asset.icon }}
            </v-icon>
            <v-icon 
              v-else  
              class="sidePanelTabIcon" 
            >
              {{ lexicon.asset.icon }}
            </v-icon>
        <small class="pt-1 text-uppercase">{{lexicon.asset.plural}}</small>
      </v-tab>
    </template>

    <!-- Settings. -->
    <v-tab v-if="getEditMode" value="globalProperties" href="#globalProperties" class="paneltab mx-1">
      <v-icon v-if="getSelectedWidget" class="sidePanelTabIcon"> {{ icons.setting }}
      </v-icon>
      <v-icon v-else class="sidePanelTabIcon">{{ icons.setting }}</v-icon>
      <small v-if="getSelectedWidget" class="pt-1 text-uppercase">Settings</small>
      <small v-else class="pt-1 text-uppercase">Settings </small>
    </v-tab>

    <!-- Models. -->
    <template v-if="getSelectedWidget && getEditMode">
      <v-tab value="modelsOrDrawings" href="#modelsOrDrawings" v-if="getSelectedWidget.content === 'viewer3d' || 
              getSelectedWidget.content === 'viewer2d' || 
              getSelectedWidget.content === 'viewerMap'" class="paneltab mx-1">
        <v-icon class="sidePanelTabIcon">
          {{ getWidgetAssetTabAttributes(getSelectedWidget.content).icon }}
          <!-- {{getSelectedWidget.content == 'viewer3d' ? 'fa-solid fa-cube fa-fw' : 'fa-solid fa-pen-nib fa-fw'}} -->
        </v-icon>
        <small class="pt-1 text-uppercase">
          {{ getWidgetAssetTabAttributes(getSelectedWidget.content).name }}
          <!-- {{getSelectedWidget.content == 'viewer3d' ? 'Models' : 'Drawings'}} -->
        </small>
        <v-tooltip location="bottom" activator="parent">
          <span>
            {{ getWidgetAssetTabAttributes(getSelectedWidget.content).description }}
            <!-- {{getSelectedWidget.content == 'viewer3d' ? 'Models' : 'Drawings'}} -->
          </span>
        </v-tooltip>
      </v-tab>
    </template>

    <!-- Layouts Tab. -->
    <template v-if="getEditMode && !getSelectedWidget">
      <v-tab value="layouts" href="#layouts" class="paneltab mx-1">
        <v-icon class="sidePanelTabIcon">{{ lexicon.layout.icon }}</v-icon>
        <small class="pt-1 text-uppercase">Layouts</small>
      </v-tab>
    </template>

    <!-- Properties. -->
    <v-tab v-if="!getEditMode" value="globalProperties" href="#globalProperties" class="paneltab mx-1">
      <v-icon class="sidePanelTabIcon">{{ icons.properties }}</v-icon>
      <small class="pt-1 text-uppercase">Info</small>
    </v-tab>
  </v-tabs>

  <!-- Left Panel Tab Content.-->
  <v-window :model-value="getSelectedNavigationTab">

    <!-- Widgets Tab Content. -->
    <v-window-item value="widgets">
      <div class="sidePanelMargin">
        <div class="sidePanelHeader">
          <div class="sidePanelHeaderInner">
            <span class="header">Widgets <HelpButton :lexiconItem="lexicon.widget"/></span>
          </div>
        </div>
        <div class="divider">
          <v-divider></v-divider>
        </div>
      </div>
      <div style="width: 100%">
        <Widgets />
      </div>
    </v-window-item>

    <!-- Layouts Tab Content. -->
    <v-window-item value="layouts">
      <div style="width: 100%">
        <LayoutTab />
      </div>
    </v-window-item>

    <!-- Global Data Sources Tab Content. -->
    <v-window-item value="globalSources">

      <div v-if="
        getEditMode &&
        getNotebook.userPermissions.permissions >= 400
      ">
        <div v-if="getGlobalProperty">

          <!-- Notebook Assets. -->
          <div v-if="
            JSON.stringify(getAllAssetsInNotebook) !== '{}' && getEditMode
          ">
            <div>
              <AllNotebookAssets />
            </div>
          </div>
        </div>
      </div>
    </v-window-item>

    <!-- Properties Tab Content. -->
    <v-window-item value="globalProperties">

      <!-- Properties Tab Content - Edit Mode. -->
      <div v-if="
        getEditMode &&
        getNotebook.userPermissions.permissions >= 400
      ">
        <div v-if="!getSelectedWidget && getGlobalProperty">

          <!-- Golbal Properties. -->
          <SettingsPage />
        </div>

        <!-- Selected Widget Properties. -->
        <div v-if="getSelectedWidget">
          <SettingsWidget />
        </div>
      </div>

      <!-- Properties Tab Content - View Mode. -->
      <div v-else class="sidePanelMargin">

        <!-- Properties Tab View Mode Header. -->
        <div class="sidePanelHeader">
          <div class="sidePanelHeaderInner">
            <!-- Selected Item Headder. -->
            <span v-if="getSelectedElements.length>0" class="header">Element Properties</span>

            <!-- No Item Selected Headder. -->
            <span v-else class="header">Page Properties  <HelpButton :lexiconItem="lexicon.page"/></span>
          </div>
        </div>
        <div class="divider">
          <v-divider class="pb-0 mb-0"></v-divider>
        </div>

        <div v-if="getNotebook" style="overflow: scroll;">

          <!-- Active Graphic Settings Chips -->
          <div class="ma-0 pa-0" style="width:100%">

            <!-- Clear Selection Chip. -->
            <v-chip v-if="getSelectedElements.length>0" class="pl-2 pr-2 ml-0 mr-1 my-0" color="secondary"
              size="x-small" closable :prepend-icon="icons.cursor" :close-icon="icons.clear"
              @click:close="clearSelection">
              <span class="text-truncate">
                {{ getSelectedElements.length}}
              </span>
              <v-tooltip location="top" activator="parent"><span> {{getSelectedElements.length}} Selected
                </span></v-tooltip>
            </v-chip>

            <!-- Clear Filter Chip. -->
            <v-chip v-if="getFilteredOutEllipseIds.length > 0" class="pl-2 pr-2 ml-0 mr-1 my-0" color="secondary" size="x-small" variant="outlined"
              :prepend-icon="icons.filter" closable @click:close="removeAllFilters" :close-icon="icons.clear" :key="filterKey">
              <span class="text-truncate">
                {{ getFilteredInEllipseIds.length}}
              </span>
              <v-tooltip location="top" activator="parent"><span> {{ getFilteredInEllipseIds.length }}
                Filtered / {{ getAttrData.length - getFilterByData.graphicData ? getFilterByData.graphicData.length : '' }} Remaining </span></v-tooltip>
            </v-chip>

            <!-- Active Colorby Chip. -->
            <v-chip v-if="getGlobalColorByAttribute !== 'None'" class="pl-2 pr-2 ml-0 mr-1 my-0" color="secondary"
              size="x-small" closable :prepend-icon="icons.colorBy" :close-icon="icons.clear"
              @click:close="clearColorByChip">
              <span class="text-truncate">
                &nbsp; {{ getGlobalColorByAttribute }}
              </span>
              <v-tooltip location="top" activator="parent"><span>Color By {{ getGlobalColorByAttribute
                  }}</span></v-tooltip>
            </v-chip>

            <!-- Active Label By Chip. -->
            <v-chip v-if="labelByChip" class="pl-2 pr-2 ml-0 mr-1 my-0" color="secondary" size="x-small" closable
              :prepend-icon="icons.labelBy" :close-icon="icons.clear" @click:close="clearLabelByChip">
              <span class="text-truncate">
                &nbsp; {{ getLabelByAttribute }}
              </span>
              <v-tooltip location="top" activator="parent"><span>Label By {{ getLabelByAttribute }}</span></v-tooltip>
            </v-chip>
          </div>
          <!-- View when Ellipse Element is Selected. -->
          <div v-if="!getEditMode" style="width:100%;">
            <v-tabs v-model="propertyNavigationDrawerTabs" density="compact" fixed-tabs
              selected-class="selectedPagePropTab">

              <!-- Element Properties: Links Tab. -->
              <v-tab value="settings" href="#settings" class="v-tab-sub mr-3">
                <v-icon size="small" left> {{icons.settings}} </v-icon>
                Settings
              </v-tab>

              <!-- Element Properties: Filter Tab. -->
              <v-tab value="elemctrl" href="#elemctrl" class="v-tab-sub mx-3">
                <v-icon size="small" left> {{icons.filter}} </v-icon>
                Filter
              </v-tab>

              <!-- Element Properties: Attributes Tab. -->
              <v-tab default value="elemattr" href="#elemattr" class="v-tab-sub ml-3">
                <v-icon size="small" left> {{lexicon.attribute.icon}} </v-icon>
                Attributes
              </v-tab>
            </v-tabs>

            <!-- Element Properties: Tab Content. -->
            <v-window v-model="propertyNavigationDrawerTabs">
              <!-- Element Properties: Filter Tab Content. -->
              <v-window-item value="elemrefs">
              </v-window-item>

              <v-window-item value="elemctrl">
                <!-- Panel Widgets. -->
                <SidePanelFilterTab />
              </v-window-item>

              <!-- Element Properties: Attribute Tab Content. -->
              <v-window-item value="elemattr">
                <div v-if="getSelectedElements.length>0">
                  <AssetsManage v-if="getGlobalProperty.global_setting.permissions_data_switch" :assetType="'data'" :icon="lexicon.dataset.icon" :showMenu=false class="py-2"/>
                  <SidePanelAttributeTab />
                </div>
                <div v-else>
                  <!-- Page Properties (when no ellipse Element is Selected. )-->
                  <!-- TODO this should be in it's own component. -->
                  <div>
                    <div class="text-truncate pt-4">
                      <v-icon class="mx-1 " color="darkGrey">{{lexicon.notebook.icon}}</v-icon>
                      <span class="properties-header">{{ getNotebook.name }}</span>
                    </div>
                    <div class="ml-8 mb-2"><small>{{ getNotebook.description }}</small></div>
                  </div>
                  <v-divider></v-divider>
                      <AssetsManage v-if="getGlobalProperty.global_setting.permissions_data_switch" :assetType="'data'" :icon="lexicon.dataset.icon" :showMenu=false class="py-2"/>
                  <v-table density="compact">
                    <tbody>
                      <template v-for="item in notebookAttributes" :key="item.header">
                        <tr>
                          <td class="property-header pl-2 pr-0">
                            <v-icon size="x-small" class="pr-1">{{ item.icon }}</v-icon>{{ item.header }}
                          </td>
                          <td class="text-truncate property-item px-0">
                            <span>
                              {{ item.attribute }}
                            </span>
                          </td>
                          <v-tooltip activator="parent" location="bottom">{{item.description}}</v-tooltip>
                        </tr>
                      </template>
                    </tbody>
                  </v-table>
                </div>           
              </v-window-item>
              
              <!-- Settings -->
              <v-window-item value="settings">
                <v-row class="pl-6 mt-8">
                  <v-col cols="6" class="pa-0"> Numeric Rounding </v-col>
                  <v-col cols="6" class="pa-0">
                    <v-slider
                      min="0"
                      max="24"
                      step="1"
                      show-ticks="always"
                      tick-size="2"
                      inverse-label
                      hide-details
                      color="primary"
                      :thumb-size="16"
                      :label="getGlobalProperty.global_setting.significant_digits.toString()"
                      :model-value="getGlobalProperty.global_setting.significant_digits"
                      @update:modelValue="setSignificantDigits($event)"
                    ></v-slider>
                  </v-col>
                </v-row>

                <v-divider class="my-2"></v-divider>

                <v-row class="px-6">
                  <v-col cols="8" class="d-flex align-center"> Show Widget Titles </v-col>
                  <v-col cols="4" class="d-flex justify-end">
                    <v-switch
                      v-model="getGlobalProperty.global_setting.show_widget_titles"
                    ></v-switch>
                  </v-col>
                </v-row>
                
                <v-divider class="my-2"></v-divider>

                <v-expansion-panels multiple class="pb-1 pt-1" density="compact">
                  <v-expansion-panel elevation="0">
                    <v-expansion-panel-title class="dense-expansion-panel"
                      >Background Color
                      <template v-slot:actions>
                        <v-icon
                          :color="getGlobalProperty.global_setting.background_color"
                          size="large"
                        >
                          {{ icons.circle }}
                        </v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="dense-expansion-panel">
                      <v-color-picker
                        v-model="getGlobalProperty.global_setting.background_color"
                        dot-size="4"
                        mode="hexa"
                        canvas-height="80"
                        width="252"
                        swatches-max-height="10"
                        @update:modelValue="setBackgroundColor()"
                      ></v-color-picker>
                    </v-expansion-panel-text>
                  </v-expansion-panel>

                  <v-expansion-panel elevation="0">
                    <v-expansion-panel-title class="dense-expansion-panel"
                      >Foreground Color
                      <template v-slot:actions>
                        <v-icon
                          :color="getGlobalProperty.global_setting.foreground_color"
                          size="large"
                        >
                          {{ icons.circle }}
                        </v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="dense-expansion-panel">
                      <v-color-picker
                        v-model="getGlobalProperty.global_setting.foreground_color"
                        dot-size="4"
                        mode="hexa"
                        canvas-height="80"
                        width="252"
                        swatches-max-height="10"
                        @update:modelValue="setForegroundColor()"
                      ></v-color-picker>
                    </v-expansion-panel-text>
                  </v-expansion-panel>

                  <v-expansion-panel elevation="0">
                    <v-expansion-panel-title class="dense-expansion-panel"
                      >Highlight Color
                      <template v-slot:actions>
                        <v-icon
                          :color="getGlobalProperty.global_setting.highlight_color"
                          size="large"
                        >
                          {{ icons.circle }}
                        </v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="dense-expansion-panel">
                      <v-color-picker
                        :model-value="getGlobalProperty.global_setting.highlight_color"
                        dot-size="4"
                        mode="hexa"
                        canvas-height="80"
                        width="252"
                        swatches-max-height="10"
                        @update:modelValue="setHighlightColor($event)"
                      ></v-color-picker>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel elevation="0">
                    <v-expansion-panel-title class="dense-expansion-panel"
                      >Selection Color
                      <template v-slot:actions>
                        <v-icon
                          :color="getGlobalProperty.global_setting.selection_color"
                          size="large"
                        >
                          {{ icons.circle }}
                        </v-icon>
                      </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="dense-expansion-panel">
                      <v-color-picker
                        :model-value="getGlobalProperty.global_setting.selection_color"
                        dot-size="4"
                        mode="hexa"
                        canvas-height="80"
                        width="252"
                        swatches-max-height="10"
                        @update:modelValue="setSelectionColor($event)"
                      ></v-color-picker>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>

              </v-window-item>
            </v-window>
          </div>
        </div>
      </div>

    </v-window-item>

    <!-- Comments Tab Content. -->
    <template v-if="!getEditMode || (getEditMode && !getSelectedWidget)">
      <v-window-item value="comments">
        <Comments />
      </v-window-item>
    </template>

    <!-- Files Tab Content. -->
    <v-window-item value="files">
      <div class="sidePanelMargin">

        <div class="sidePanelHeader">
          <div class="sidePanelHeaderInner">
            <!-- No Item Selected Headder. -->
            <span class="header">Attachments <HelpButton :lexiconItem="lexicon.attachment"/></span>
          </div>
        </div>
        <div class="divider">
          <v-divider class="pb-0 mb-0"></v-divider>
        </div>

        <AllFiles :name="'Attachments'" :button-text="'Upload'" />
      </div>
    </v-window-item>

    <!-- Pages Tab Content. -->
    <template v-if="!getEditMode">
      <v-window-item value="pages">
        <!-- TODO THERE ARE SOME TRICKY BUGS WITH V3 Migration and vuedraggable -->

        <SectionAndPages />

      </v-window-item>
    </template>

    <!-- If Widget Selected in edit Mode. -->
    <template v-if="getSelectedWidget && getEditMode">
      <!-- Models Tab Content. (If 3d viewer widget selectd) -->
      <v-window-item value="modelsOrDrawings"
        v-if="getSelectedWidget.content === 'viewer3d' || getSelectedWidget.content === 'viewer2d' || getSelectedWidget.content === 'viewerMap'">
        <WidgetModels v-if="getSelectedWidget.content === 'viewer3d'"
          :description="'Models to display in this Widget'" />
        <WidgetDrawings v-if="getSelectedWidget.content === 'viewer2d'"
          :description="'Drawings to display in this Widget'" />
        <WidgetMaps v-if="getSelectedWidget.content === 'viewerMap'"
          :description="'Gis layers to display in this Widget'" />
      </v-window-item>
    </template>
  </v-window>
</template>
<script>

import Widgets from "@/components/SidePanelWidgets.vue";
import SettingsWidget from "@/components/SidePanelSettingsWidget.vue";
import SettingsPage from "@/components/SidePanelSettingsPage.vue";
import SectionAndPages from "@/components/SidePanelPages.vue";
import WidgetDrawings from "./SidePanelWidgetDrawings.vue";
import WidgetModels from "./SidePanelWidgetModels.vue";
import WidgetMaps from "./SidePanelWidgetMaps.vue";
import Comments from "./SidePanelComments.vue";
import AllFiles from "./SidePanelAssetsAllFiles.vue";
import AllNotebookAssets from "./SidePanelAssets.vue";
import PillButton from "../components/ui/PillButton.vue";
import NoDataWarning from "../components/ui/NoDataWarning.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue";
import LayoutTab from "../components/LayoutsPanel.vue";
import GlobalGradientSelection from '../components/StateManagers/GlobalGradientSelection.vue';
import SidePanelFilterTab from "../components/SidePanelFilterTab.vue";
import SidePanelAttributeTab from "../components/SidePanelAttributeTab.vue";
import HelpButton from "../components/ui/HelpButton.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";
import AssetsManage from "./SidePanelAssetsManage.vue";

export default {
  inheritAttrs: false,
  components: {
    Widgets,
    SettingsWidget,
    SettingsPage,
    SectionAndPages,
    WidgetModels,
    WidgetDrawings,
    WidgetMaps,
    Comments,
    AllFiles,
    AllNotebookAssets,
    PillButton,
    NoDataWarning,
    ProgressCircular,
    LayoutTab,
    GlobalGradientSelection,
    SidePanelFilterTab,
    SidePanelAttributeTab,
    HelpButton,
    AssetsManage,
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getGlobalProperty,
      getGlobalColorByAttribute,
      getEditMode,
      getSelectedElements,
      getSelectedNavigationTab,
      getSelectedPage,
      getSelectedPageStateId,
      getSelectedWidget,
      getAllPageStates,
    } = storeToRefs(notebookPropsStore)
    const {
      setGlobalHighlightColor,
      setGlobalSelectionColor,
      setGlobalSignificantDigits
    } = notebookPropsStore
    const {
      getLabelByData,
      getFilterByData,
      getAttrData,
      getAttrHeaderNames,
      getFilteredInEllipseIds,
      getFilteredOutEllipseIds
    } = storeToRefs(dataGraphicsStore)
    const {
      getAllAssetsInNotebook,
      getSelectedPageDrawings,
      getSelectedPageModels,
      getSelectedPageDatasets,
      getSelectedPageDataset
    } = storeToRefs(assetsStore)
    const {
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      updateColorByProperties,
      updateLabelByProperties,
      updateFilterByProperties,
      removeAllFilters,
    } = dataGraphicsStore
    return {
      notebookPropsStore, 
      dataGraphicsStore, 
      assetsStore,
      getEditMode, 
      getSelectedElements, 
      getSelectedNavigationTab, 
      getSelectedWidget, 
      getGlobalProperty, 
      getGlobalColorByAttribute, 
      getLabelByData, 
      getFilterByData,
      getAttrData, 
      getAllPageStates,
      getAttrHeaderNames, 
      getAllAssetsInNotebook, 
      getSelectedPage, 
      getSelectedPageStateId,
      getNotebook, 
      getSelectedPageDrawings, 
      getSelectedPageModels, 
      getSelectedPageDatasets, 
      getSelectedPageDataset, 
      getFilteredOutEllipseIds,
      getFilteredInEllipseIds,
      setGlobalHighlightColor,
      setGlobalSelectionColor,
      setGlobalSignificantDigits,
      updateColorByProperties, 
      updateLabelByProperties,
      removeAllFilters, 
      updateFilterByProperties, 
      lexicon, 
      icons
    }
  },
  data() {
    return {
      visualizeAlert: true,
      propertyNavigationDrawerTabs: "elemattr",
      notebookAttributes: [],
      saveCurrentStateLoadingAndDisabled: false,
      isFullscreen: true,
      labelByChip: false,
      filterChip: false,
      truncateData: false,
      filterKey: 0
    };
  },
  computed: {
    getLabelByAttribute() {
      return this.getLabelByData.attribute
    },
    isFiltered(){
      var status = false;
      if(this.getFilterByData){
        if(this.getFilterByData.graphicData){
          status = this.getFilterByData.graphicData.length>0;
        }
      }
      return status;
    },
  },
  async created() {},
  mounted(){
    //Listen for changes to the dataGraphicsStore
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.updateColorByChip()
        }
        if (name === 'updateLabelByProperties') {
          this.updateLabelByChip()  
        }
        if (name === 'updateFilterByProperties') {
          this.updateFilterChip()  
          this.filterKey++
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "setSelectedPageDataset" ||
            name === "setAllAssetsInNotebook"){
          this.updatePropertyAttributes();
        }
      })
    })
  },
  methods: {
    updatePropertyAttributes(){
      this.notebookAttributes = [];

      let curNotebook = this.getNotebook;
      let state = this.getAllPageStates.find(
        (p) => p.page === this.getSelectedPage._id
      );

      if (this.getSelectedPage){
        this.notebookAttributes.push(
          {
            icon: lexicon.page.icon ,
            header: "Current",
            description:"The current page in the notebook",
            attribute: this.getSelectedPage.name,
          }
        )
      }
      if (this.getNotebook){
        this.notebookAttributes.push(
          {
            icon: icons.create ,
            header: "Created",
            description:"The date and time when the page was created",
            attribute: new Date(curNotebook.createdAt).toLocaleString(),
          }
        )
      }
      if (state){
        this.notebookAttributes.push(
          {
            icon: icons.update ,
            header: "Updated",
            description:"The date and time when the page was last updated",
            attribute: new Date(state.updatedAt).toLocaleString(),
          }
        )
      }
      this.notebookAttributes.push(
        {
        icon: lexicon.data.icon ,
        header: "Dataset",
        description:"The Dataset applied to the current Page",
        attribute: this.getSelectedPageDataset ? this.getSelectedPageDataset.name : "-",
        },
        {
          icon: lexicon.dataset.icon ,
          header: "Datasets",
          description:"The total number of Datasets in the Notebook",
          attribute: this.getAllAssetsInNotebook.datasets.length > 0 ? this.getAllAssetsInNotebook.datasets.length : "No Datasets",
        },
        {
          icon: lexicon.model.icon ,
          header: "Models",
          description:"The Models applied to the current Page / the total Models in the Notebook",
          attribute: this.getSelectedPageModels.length + " / " + this.getAllAssetsInNotebook.models.length,
        },
        {
          icon: lexicon.drawing.icon ,
          header: "Drawings",
          description:"The Drawings applied to the current Page / the total Drawings in the Notebook",
          attribute: this.getSelectedPageDrawings.length + " / " + this.getAllAssetsInNotebook.drawings.length,
        }
      );
      if (this.getAttrHeaderNames) {
        let curHeaders = this.getAttrHeaderNames;
        if (curHeaders) {
          this.notebookAttributes.push(
            {
              icon: icons.categories ,
              header: "Categories",
              description:"The total number of unique attributes shared between elements in the Page",
              attribute: curHeaders.length,
            }
          )
        }
      }
      if (this.getAttrData) {
        let curAttr = this.getAttrData;
        if (curAttr) {
          this.notebookAttributes.push(
            {
              icon: icons.properties ,
              header: "Entries",
              description:"The total number of Entries in the selected Dataset",
              attribute: curAttr.length,
            }

          )
        }
      }
  },
  clickOnNavigationTab(selectedTab) {
    this.notebookPropsStore.$patch({selectedNavigationTab: selectedTab});
  },
  clearColorByChip() {
    this.colorByAttribute("None");
  },
  clearSelection(){
    this.notebookPropsStore.$patch({selectedElements: []})
  },
  clearLabelByChip() {
    this.labelByAttribute("None");
    this.labelByChip = false;
  },
  getWidgetAssetTabAttributes(content){
    const contentTypes = {
      viewer3d: {
        name: "Models",
        icon: lexicon.model.icon ,
        description: "Models to display in this Widget",
      },
      viewer2d: {
        name: "Drawings",
        icon: lexicon.drawing.icon ,
        description: "Drawings to display in this Widget",
      },
      viewerMap: {
        name: "GIS",
        icon: lexicon.map.icon ,
        description: "Gis Layers to display in this Widget",
      },
    }
    return contentTypes[content];
  },
  updateColorByChip() {
    var status = false;
    if (this.getGlobalColorByAttribute) {
      if (this.getGlobalColorByAttribute != "None") status = true;
    }
  },
  updateLabelByChip() {
    var status = false;
    if (this.getLabelByAttribute) {
      if (this.getLabelByAttribute != "None") status = true;
    }
    this.labelByChip = status;
  },
  updateFilterChip() {
    this.filterChip = this.isFiltered
  },
  colorByAttribute(attr) {
    //If attribute is new, update store value
    if (attr !== this.getGlobalColorByAttribute) {
      this.updateColorByProperties({attribute: attr});
    }
  },
  labelByAttribute(attr) {
    //If attribute is new, update store value
    if (attr !== this.getLabelByAttribute){
      var colors = ["rgb(255, 255, 255)", "rgb(255, 255, 255)"];
      let labelByProps = {
        attribute: attr,
        colors: colors,
      }
      this.updateLabelByProperties(labelByProps);
    }
  },
  setBackgroundColor() {
    this.notebookPropsStore.$patch({globalPropertyUpdated: true})
  },
  setForegroundColor() {
    this.notebookPropsStore.$patch({globalPropertyUpdated: true})
    let a = this.getWidgets.map((e) => {
      e.local_setting.foreground_color =
        this.getGlobalProperty.global_setting.foreground_color;
      return e;
    });
    this.setWidgets(a)
  },
  setHighlightColor(color) {
    this.notebookPropsStore.$patch({globalPropertyUpdated: true})
    this.setGlobalHighlightColor(color)
  },
  setSelectionColor(color) {
    this.notebookPropsStore.$patch({globalPropertyUpdated: true})
    this.setGlobalSelectionColor(color)
  },
  setSignificantDigits(digits) {
    this.notebookPropsStore.$patch({globalPropertyUpdated: true})
    this.setGlobalSignificantDigits(digits)
  },
},
}
</script>
<style scoped>

.v-navigation-drawer__content {
  height: 100%;
  overflow-y: hidden !important;
  border-radius: 0px !important;
}

.v-tab {
  min-width: 10px !important;
  max-width: 70px !important;
  padding: 0 15px 0 15px !important;
}

.v-tab-sub {
  min-width: 10px !important;
  max-width: 90px !important;
  padding: 0 0 0 0 !important;
}

.property-header {
  font-size: 13px;
  font-weight: 500;
  color: rgb(var(--v-theme-darkGrey));
  min-width: 80px !important;
  max-width: 80px !important;
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
  min-width: 185px !important;
  max-width: 185px !important;
}

.sidePanelTabIcon {
  font-size: 30px;
}

.paneltab {
  font-size: 11px;
  color:rgb(var(--v-theme-background),0.7);
}
.selectedTab {
  font-size: 11px;
  color:white;
}
.selectedPagePropTab{
  color: rgb(var(--v-theme-primary));
}
.v-slide-group :deep(.v-slide-group__prev){
  min-width: 8px !important;
  flex-basis: 0px !important
}
.v-slide-group :deep(.v-slide-group__next){
  min-width: 0px !important;
  flex-basis: 0px !important
}

.properties-header{
  font-weight:500;
  color:rgb(var(--v-theme-darkSlate));
}
</style>
<template>
  <v-footer elevation=2 density="compact" height="30" app>
    <!-- <div class="pl-5">
      <v-img
        width="20"
        height="20"
        contain
        src="../assets/imgs/Ellipse_Clear_128.png"
      >
      </v-img>
    </div>
    <div class="text-uppercase align-center text-subtitle pl-1">ellipse</div> -->
    <span  style="font-size:small" class="pl-2" >
      &#169; Thornton Tomasetti | CORE studio {{ new Date().getFullYear() }}</span>
    <v-spacer></v-spacer>
    <span style="font-size:small" class="pr-2">      
      <a
        class="linkStyle pl-2"
        style="text-decoration:none;"
        href="https://coresso.thorntontomasetti.com/eula"
        target="_blank"
      >
        <span>License</span>
      </a>

<!-- <span> &nbsp; | </span>
      <a
        class="linkStyle pl-2"
        style="text-decoration:none;"
        href="https://www.thorntontomasetti.com/legal-privacy-policies"
        target="_blank"
      >
        <span>Privacy Statement</span>
      </a> -->
    </span>
  </v-footer>
</template>

<script>
export default {
  mounted() {
    const logoPath = require('@/assets/imgs/core-logo.svg');
    fetch(logoPath)
      .then(response => response.text())
      .catch(error => console.error('Error loading logo:', error));
  }
};
</script>
<style scoped>
footer {
  align-content: center;
  height: 30px;
  bottom: 0px;
}
.linkStyle {
  color: rgb(var(--v-theme-darkGrey));
}
</style>

<template>
  <v-dialog :model-value="dialog" max-width="500">
    <v-card>
      <v-card-title>
          <slot></slot>
      </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-white"
            color="darkGrey"
            @click="$emit('toggle')"
            >{{ lexicon.cancel }}</v-btn
          >
          <v-btn
            dark
            color="danger"
            @click="$emit('actionClicked')"
            :disabled="disabled"
            :loading="loading"
            >{{ lexicon.del }}</v-btn
          >
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";

export default {
setup(){
  return { lexicon };
},
props: {
  dialog: {
    type: Boolean,
    required: true
  },
  disabled: {
      type: Boolean,
      required: false
  },
  loading: {
      type: Boolean,
      required: true
  }
}
}
</script>
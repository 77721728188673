<template>
  <v-table v-if="getAttrData.length > 0" density="compact" style="width: 100%;">
    <tbody>
      <v-menu origin="auto" v-for="(item, idx) in getSelectedAttributesTable" :key="idx">
        <template v-slot:activator="{ props }">

          <tr v-bind="props" style="width: 100%; cursor:pointer;" class="property-row">

            <!--Property Icon. -->
            <td style="padding-left: 4px; padding-right:0px; width:22px; min-width: 22px; max-width: 22px;">
              <v-icon size="x-small" class="property-icon pa-0 ma-0" color="darkSlate">
                {{ item["icon"] }}
              </v-icon>
            </td>

            <!--Property Header. -->
            <td style="padding-left: 5px; padding-right:0px; white-space:nowrap;">
              <span class="d-inline-block property-header" :class="truncate ? 'text-truncate' : ''">
                {{ item["header"] }}
                <v-tooltip activator="parent" location="top">{{ item["header"] }}</v-tooltip>
              </span>
            </td>

            <!--Property Value. -->
            <td style="padding-left: 10px; padding-right:0px;">
              <span class="d-inline-block property-item" :class="truncate ? 'text-truncate' : ''">
                <span :class="isUnique(item.attribute) ? 'variedText' : ''">
                  {{ item["attribute"] }}
                </span>
                <v-tooltip activator="parent" location="top">{{ item["attribute"] }}</v-tooltip>
              </span>
            </td>
          </tr>
        </template>

        <!-- Property Row Click Menu. -->
        <v-list density="compact">
          <v-list-item @click="colorByAttribute(item.header)">
            <template v-slot:prepend>
              <v-icon>{{ icons.colorBy}}</v-icon>
            </template>
            <v-list-item-title>Color By</v-list-item-title>
          </v-list-item>
          <v-list-item @click="labelByAttribute(item.header)">
            <template v-slot:prepend>
              <v-icon>{{ icons.labelBy}}</v-icon>
            </template>
            <v-list-item-title>Label By</v-list-item-title>
          </v-list-item>
          <v-list-item @click="copyTextToClipboard(item.attribute)">
            <template v-slot:prepend>
              <v-icon>{{ icons.duplicate }}</v-icon>
            </template>
            <v-list-item-title>Copy Text</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </tbody>
  </v-table>
</template>
<script>

import * as utils from "@/utilities"

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useAssetsStore } from "@/store/AssetsStore.js";
import * as icons from "@/utilities/EllipseIcons.js";


export default {
  props: ["truncate", "variable"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getSelectedElements,
      getGlobalColorByAttribute,
      getEditMode,
      getDatasetLoading
    } = storeToRefs(notebookPropsStore)
    const {
      getFilterByData,
      getAttrData,
      getAttrHeaders,
    } = storeToRefs(dataGraphicsStore)
    const {
      updateColorByProperties,
      updateLabelByProperties
    } = dataGraphicsStore
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    return {
      dataGraphicsStore,
      getEditMode,
      getFilterByData,
      getAttrData,
      getAttrHeaders,
      getSelectedPageDatasets,
      getSelectedElements,
      getDatasetLoading,
      updateColorByProperties,
      getGlobalColorByAttribute,
      updateLabelByProperties,
      icons
    }
  },
  data: () => ({
  }),
  mounted() {
  },
  watch: {
  },
  computed: {
    attrData() {
      this.datasetSwitchingLoading = false;
      return this.getAttrData;
    },
    getSelectedElementsAttributes() {
      let idSet = new Set(this.getSelectedElements);
      // Filter the based on the presence of the ID in the Set
      return this.getAttrData.filter((obj) => idSet.has(obj.ellipseId));
    },
    getSelectedAttributesTable() {
      return utils.tabulateAttributes(this.getSelectedElementsAttributes, this.getAttrHeaders, this.variable);
    },
  },
  methods: {
    isUnique(value) {
      return value === this.variable;
    },
    colorByAttribute(attr) {
      //If attribute is new, updated value in store
      if (attr !== this.getGlobalColorByAttribute) {
        this.updateColorByProperties({ attribute: attr });
      }
    },
    labelByAttribute(attr) {
      //If attribute is new, updated value in store 
      if (attr !== this.getLabelByAttribute) {
        var colors = ["rgb(255, 255, 255)", "rgb(255, 255, 255)"];
        let labelByProps = {
          attribute: attr,
          colors: colors,
        }
        this.updateLabelByProperties(labelByProps);
      }
    },
    copyTextToClipboard(value) {
      navigator.clipboard.writeText(value);
    },
    copySelectedElementsToClipboard() {
      const textToCopy = this.getSelectedElements.join("\n");
      navigator.clipboard.writeText(textToCopy).then(
        () => {
          console.log("Selected elements copied to clipboard.");
        },
        (err) => {
          console.error("Error copying selected elements to clipboard:", err);
        }
      );
    },
  },
}
</script>
  
<style scoped>
.variedText {
  color: rgb(var(--v-theme-background));
  font-style: italic;
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 500;
  color: rgb(var(--v-theme-darkSlate));
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkSlate));
}

.property-row:hover {
  background: rgb(var(--v-theme-background));
  border-bottom-color: rgb(var(--v-theme-error));
  border-bottom: 2px;
  border-bottom-width: 2px;
}
</style>   
<template>
  <v-card style="position: relative; overflow-y: auto">
    <!-- Header. -->
    <v-card-title> {{messages.layoutEdit}} </v-card-title>

    <!-- Content. -->
    <v-card-text style="height: 75vh; overflow-y: auto">
      <grid-layout
        ref="gridlayout"
        :layout="layoutToEdit.widgets"
        :col-num="12"
        :row-height="12"
        :is-draggable="true"
        :is-resizable="true"
        :responsive="false"
        :use-css-transforms="true"
        :preventCollision="false"
        :verticalCompact="false"
        :margin="[5, 5]"
      >
        <!-- Widgets. -->
        <grid-item
          v-for="item in layoutToEdit.widgets"
          :key="item.i"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :id="item._id"
          class="d-flex justify-center align-center"
          style="border: 1px dashed; color: rgb(var(--v-theme-darkGrey))"
        >
          <!-- Widget Icon. -->
          <WidgetIcon 
            :iconsize="widgetSize" 
            :show="showWidget" 
            :icon="widgetIcon(item.content)" 
            :text="widgetName(item.content)">
          </WidgetIcon>

          <!-- Widget Name. -->
          <div class="text-h6">
            {{ widgetName(item.content) }}
          </div>
        </grid-item>
      </grid-layout>
    </v-card-text>

    <!-- Action Buttons. -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- Cancel. -->
      <v-btn 
        @click="cancelEdit" 
        class="mx-2 text-white"  
        color="darkGrey"
      >
        {{lexicon.cancel}}
      </v-btn>

      <!-- Save. -->
      <v-btn
        :loading="editlayoutLoading"
        @click="editLayout"
        color="secondary"
      >
        {{lexicon.save}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import { GridLayout, GridItem } from "vue3-grid-layout-next";
import WidgetIcon from "../components/ui/WidgetIcon.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: {
    GridLayout,
    GridItem,
    WidgetIcon
    
  },
  setup(){
    const ellipseStore = useEllipseStore();
    const { 
      getUser
    } = storeToRefs(ellipseStore);
    const {
      setLayouts
    } = ellipseStore;
    return { getUser,
    setLayouts, lexicon, messages };
  },
  props: {
    layoutToEdit: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      editlayoutLoading: false,
      showWidget:true,
      widgetSize:'30'
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async editLayout() {
      console.log("this.layoutToEdit", this.layoutToEdit);
      this.editlayoutLoading = true;
      let updateLayout = await this.$auth.$api.post(
        `/api/layout/update_layout`,
        this.layoutToEdit
      );
      console.log("updateLayout", updateLayout.data);
      await this.setLayouts(this.getUser._id);

      this.editlayoutLoading = false;
      this.$emit("toggle1");
    },
    async cancelEdit() {
      this.$emit("toggle1");
      await this.setLayouts(this.getUser._id);
    },
    widgetIcon(content) {
      let result = WidgetsCollection.returnWidgetDetails(content);
      return result.icon;
    },
    widgetName(content) {
      let result = WidgetsCollection.returnWidgetDetails(content);
      return result.text;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="widgetPadding pa-0">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <div style="height: 100%" v-html="iframeAddress()">

    </div>
  </div>
</template>
  
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  components: { WidgetIcon , NoDataWarning, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
    } = notebookPropsStore
    return {getEditMode, getGlobalProperty}
  },
  data() {
    return {
      isEmbed: true,
      address: "https://time.is/clock",
      embed: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.4323731060313!2d-74.0128027843192!3d40.708497345766084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a34b51d273%3A0xae09a82059af713c!2sThornton%20Tomasetti!5e0!3m2!1sen!2sus!4v1676618559427!5m2!1sen!2sus" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">',
    };
  },
  created() {
    this.verifyInstanceSettings();
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  mounted() {
    this.verifyInstanceSettings();
  },
  methods: {
    verifyInstanceSettings() {
      
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!this.grid_item.instance_setting.data.isEmbed) this.grid_item.instance_setting.data.isEmbed = this.isEmbed;
      if (!this.grid_item.instance_setting.data.address) this.grid_item.instance_setting.data.address = this.address;
      if (!this.grid_item.instance_setting.data.embed) this.grid_item.instance_setting.data.embed = this.embed;

      if (!this.grid_item._id) {
        
      };

    },
    iframeAddress() {
      if (this.grid_item.instance_setting.data.isEmbed) {
        return this.grid_item.instance_setting.data.embed;
      }
      else {
        return '<iframe src="' + this.grid_item.instance_setting.data.address + '" width="100%" height="100%" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade">'
      }

    }
  }
};
</script>
<style scoped></style>
  
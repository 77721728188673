<template>        
  <div class="spinnerStyle">
    <v-progress-circular
      v-if="show"
      indeterminate
      :size="60"
      width="7"
      color="primary"
    >
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        required: true
    }
  }
}
</script>

<style scoped>
.spinnerStyle {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
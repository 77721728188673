<template>
  <div>
    <v-dialog v-model="sharingDialog" width="700">
      <template v-slot:activator="{ props }">

        <v-btn v-if="itemtype == 'button'" v-bind="props" :disabled="shareIconState" size="x-small" variant="text" icon>
          <v-icon color="darkSlate" size="small">
            {{ numUsers > 1 ? lexicon.user.icon : lexicon.user.iconNone  }}
          </v-icon>
        </v-btn>

        <v-list-item v-if="itemtype == 'list'" @click.stop="sharingDialog=true;">
          <template v-slot:prepend>
            <v-icon >
              {{ numUsers > 1 ? lexicon.user.icon : lexicon.user.iconNone }}
            </v-icon>
          </template>
          <v-list-item-title>{{ messages.notebookShare }}</v-list-item-title>
        </v-list-item>

      </template>

      <v-card class="pa-3">
        <v-card-title>
          <v-icon class="mr-2" size="x-small" color="primary">{{ lexicon.user.iconAdd }}</v-icon>
          {{ messages.userAdd }}
        </v-card-title>
        <div class="d-flex align-center mt-5">
          <v-text-field v-model="email" label="Add People" placeholder="Add People" :rules="emailRules" clearable
            clear-icon= icons.circle class="ml-12" variant="underlined">
          </v-text-field>

          <v-tooltip location="top" :disabled="!emailCheck" text="Please enter valid email">
            <template v-slot:activator="{ props }">
              <div v-bind="props">
                <v-btn :disabled="emailCheck" :loading="addUserLoading" class="mr-8 ml-2" icon elevation="0"
                  size="x-small" :color="emailCheck ? 'darkSlate' : 'primary'" @click="addUser"><v-icon>{{ icons.plus }}</v-icon>
                </v-btn>
              </div>
            </template>
          </v-tooltip>

        </div>

        <div class="mx-5">
          <v-table style="min-height: 10vh; max-height: 50vh; overflow-y: auto" density="compact">
            <tr v-for="(user, index) in formattedUsers" :key="user.name" class="d-flex align-center justify-center">
              <td style="width: 25px" class="d-flex align-center justify-center">
                <v-icon size="x-small" color="primary">{{ lexicon.user.iconAdd }}</v-icon>
              </td>
              <td class="pt-2" style="width: 400px">
                <div class="pl-3 pb-3 font-weight-light" :class="user.delete ? 'text-decoration-line-through' : ''">
                  <v-text-field :disabled="(user.user ? user.user._id : user._id) ===
                    (notebook.createdBy.user._id &&
                      user.roles === 'Admin') || checkPermissionFunc(user.roles) ||
                    user.roles === 'Admin'
                    " variant="solo" flat hide-details="true" density="compact" v-model="user.email"
                    :rules="emailRules">
                  </v-text-field>
                </div>
              </td>
              <td style="width: 150px; cursor:pointer !important;">
                <v-btn :disabled="(user.user ? user.user._id : user._id) ===
                  (notebook.createdBy.user._id &&
                    user.roles === 'Admin') ||
                  checkPermissionFunc(user.roles) ||
                  user.roles === 'Admin'
                  " variant="plain" text append-icon= lexicon.user.iconAdmin 
                  id="menu-activator">
                  {{ user.roles }}
                  <v-menu 
                    activator="parent">
                    <v-list>
                      <v-list-item style="cursor:pointer;" 
                        v-for="(item, index) in roleArrayState" 
                        :key="index"
                        :disabled="item.disable"
                        :title="item.name"
                        @click="updateRole(user, item.name)">
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-btn>
              </td>
              <td>
                <v-layout justify-center>
              <td>
                <v-btn :disabled="(user.user ? user.user._id : user._id) ===
                  (notebook.createdBy.user._id &&
                    user.roles === 'Admin') ||
                  checkPermissionFunc(user.roles) ||
                  user.roles === 'Admin'
                  " variant="plain" class="ml-2" @click="toggleDeleteUser(props, index, user)"
                  icon><v-icon>{{ user.delete ? icons.refresh : icons.cancel }}</v-icon>
                </v-btn>
              </td>
              </v-layout>
              </td>
            </tr>
          </v-table>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="saveUserLoading" @click="saveUsers" color="primary">
            {{ lexicon.save }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="timeout" absolute location="bottom" color="primary">
        {{ message }}

        <template v-slot:action="{ props }">
          <v-btn v-bind="props" color="success" text @click="snackbar = false">
            <v-icon>{{ icons.cancel }}</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog persistent v-model="warningDialog" width="400">
        <v-card>
          <v-card-title class="text-h5"> Update Admin Role </v-card-title>
          <v-card-text>Are you sure you want to update Admin rights for the notebook?
            Your role will also be updated to Editor once you confirm.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkSlay" @click="cancelUpdatedRoles"> {{lexicon.cancel}} </v-btn>
            <v-btn color="accent" @click="updateRoleFunction"> {{lexicon.confirm}} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import * as roles from "@/utilities/roles.js"
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  props: {
    notebook: {
      type: Object,
    },
    itemtype: {
      type: String,
      default: 'button'
    }
  },
  components: { },
  setup() {
    const ellipseStore = useEllipseStore()
    const {
      getNotebooks,
      getUser,
    } = storeToRefs(ellipseStore)
    const {
      setNotebooks
    } = ellipseStore
    return {
      getNotebooks, getUser,
      setNotebooks, lexicon, messages, icons
    }
  },
  data() {
    return {
      snackbar: false,
      timeout: 2000,
      sharingDialog: false,
      addUserLoading: false,
      saveUserLoading: false,
      formattedUsers: [],
      editedItemIndex: -1,
      message: "",
      emailRules: [
        (v) =>
          v === "" ||
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) ||
          "E-mail must be valid",
      ],
      email: "",
      nameRules: [(v) => !!v || "Name is required"],
      roleArray: [],
      sharingHeader: [
        { title: "", align: "center", key: "icon" },
        { title: "Email", align: "center", key: "email", width: "70%" },
        { title: "Roles", align: "center", key: "roles" },
        { title: "", align: "center", key: "action" },
      ],
      warningDialog: false,
      selectedUser: {}
    };
  },
  computed: {
    emailCheck() {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.email)) {
        return false;
      } else return true;
    },
    shareIconState() {
      if (this.notebook.userPermissions.permissions >= 200) {
        return false;
      } else return true;
    },
    numUsers() {
      return this.notebook.numUsers[0].count
    },
    roleArrayState() {
      if (this.notebook) {
        return roles.permissions[this.notebook.userPermissions.permissions]
      }
    },
  },
  async mounted() {
    await this.getFormattedUsers();
  },
  methods: {
    async updateRole(user, role) {
      user.roles = role;
      if (user.roles === "Admin") {
        this.selectedUser = user;
        this.warningDialog = true;
      } else {
        this.selectedUser = user;
        await this.assignUpdatedRoles();
      }
    },
    async updateRoleFunction() {
      await this.assignUpdatedRoles();
      // downgrade admin role one step down
      let index1 = this.notebook.users.findIndex(
        (pUser) => pUser.user._id === this.getUser._id
      );
      this.notebook.users[index1].permissions = 400;
      this.notebook.userPermissions.permissions = 400;
      this.getFormattedUsers();
      this.warningDialog = false;
    },
    cancelUpdatedRoles() {
      this.warningDialog = false;
      this.getFormattedUsers();
    },
    assignUpdatedRoles() {
      var objectToArray = Object.entries(roles.roles);
      let result = objectToArray.find((s) => s[0] === this.selectedUser.roles);
      let index = this.notebook.users.findIndex(
        (pUser) => pUser._id === this.selectedUser._id
      );
      this.notebook.users[index].permissions = result[1];
    },

    checkPermissionFunc(data) {
      let q = this.roleArray.some((e) => e.name === data && e.disable);
      return q;
    },
    getFormattedUsers() {
      this.formattedUsers = [];
      this.notebook.users.forEach((e) => {
        let objectToArray = Object.entries(roles.roles);
        let result = objectToArray.find((s) => s[1] === e.permissions);
        let obj = {
          user: e.user ? e.user : e._id,
          email: e.user ? e.user.email : e.email,
          roles: result[0],
          _id: e._id,
        };
        if (e.add && e.delete) {
          obj.delete = true;
        } else {
          if (e.add) {
            obj.add = true;
          }
          if (e.delete) {
            obj.delete = true;
          }
        }
        this.formattedUsers.push(obj);
      });
    },

    async addUser() {
      this.addUserLoading = true;
      var temp = this.notebook.users.find((u) => {
        if (u.email) {
          if (u.email === this.email) return true;
        } else if (u.user.email === this.email) return true;
        else return false;
      });
      if (!temp) {
        let newUser = await this.$auth.$api.post(
          `/api/notebook/${this.notebook._id}/add-user`,
          {
            email: this.email.toLowerCase(),
          }
        );

        newUser.data.data.add = true;
        this.notebook.users.push(newUser.data.data);

        this.email = "";
        this.getFormattedUsers();
        this.message = "Assign Roles To Added User";
        this.snackbar = true;
      } else {
        this.email = "";
        this.message = "The user is already added";
        this.snackbar = true;
      }
      this.addUserLoading = false;
    },
    toggleDeleteUser(item, index) {
      this.editedItemIndex = index;
      this.notebook.users[this.editedItemIndex].delete =
        !!!this.notebook.users[this.editedItemIndex].delete;
      this.getFormattedUsers();
      if (this.notebook.users[this.editedItemIndex].delete) {
        delete this.notebook.users[this.editedItemIndex].add;
      } else {
        delete this.notebook.users[this.editedItemIndex].delete;
        this.notebook.users[this.editedItemIndex].add = true;
      }
    },
    async saveUsers() {
      this.saveUserLoading = true;

      let usersToSave = {
        users: this.notebook.users,
      };

      let pSaved = await this.$auth.$api.post(
        `/api/notebook/${this.notebook._id}/save-users`,
        usersToSave
      );

      this.message = "The users were saved successfully";
      this.snackbar = true;
      await this.setNotebooks()
      this.getFormattedUsers();
      this.saveUserLoading = false;
      this.sharingDialog = false;
    },
  },
};
</script>

<style scoped>
.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
  min-height: 25px !important;
}
</style>

<template>
  <div class="sidePanelMargin" style="font-weight: 500">
    <div>
      <div class="sidePanelHeader">
        <div class="sidePanelHeaderInner">
          <span class="header">Page Settings <HelpButton :lexiconItem="lexicon.page"/></span>
        </div>
      </div>
      <div class="divider">
        <v-divider></v-divider>
      </div>
    </div>

    <div>
      <v-container fluid class="pb-0">
        <v-row>
          <v-col cols="6" class="pa-0"> Numeric Rounding </v-col>
          <v-col cols="6" class="pa-0">
            <v-slider
              min="0"
              max="24"
              step="1"
              show-ticks="always"
              tick-size="2"
              inverse-label
              hide-details
              color="primary"
              :thumb-size="16"
              :label="getGlobalProperty.global_setting.significant_digits.toString()"
              :model-value="getGlobalProperty.global_setting.significant_digits"
              @update:modelValue="setSignificantDigits($event)"
            ></v-slider>
          </v-col>
        </v-row>
        
      <v-row width="100%" class="font-weight-bold pt-3"> Permissions </v-row>
      <v-divider class="my-2"></v-divider>
      <v-container>
        <v-row>
            <v-switch
              v-model="getGlobalProperty.global_setting.permissions_data_switch"
              @update:modelValue="setUserDataSwitch($event)"
              label="Switch Data"
            ></v-switch>
        </v-row>

      </v-container>

      <v-row width="100%" class="font-weight-bold pt-3"> Layout Editor </v-row>
      <v-divider class="mt-2"></v-divider>
      <v-container>
        <v-row>
            <v-switch
              v-model="getGlobalProperty.global_setting.is_locked"
              @update:modelValue="lockGrids()"
              label="Lock Layout"
            ></v-switch>
        </v-row>
        <v-row>
            <v-switch
              v-model="getGlobalProperty.global_setting.snap_to_grid"
              @update:modelValue="snapGrids()"
              label="Snap to Top"
            ></v-switch>
        </v-row>
        <v-row>
            <v-switch
              v-model="getGlobalProperty.global_setting.is_moveable"
              @update:modelValue="snapGrids()"
              label="Fix Positions"
            ></v-switch>
        </v-row>
      </v-container>
      <v-row width="100%" class="font-weight-bold"> Appearance </v-row>
      <v-divider class="my-2"></v-divider>
    </v-container>
    <v-row class="px-6">
      <v-col cols="8" class="d-flex align-center"> Show Widget Titles </v-col>
      <v-col cols="4" class="d-flex justify-end">
        <v-switch
          v-model="getGlobalProperty.global_setting.show_widget_titles"
        ></v-switch>
      </v-col>
    </v-row>
    <v-expansion-panels multiple class="pb-1 pt-1" density="compact">
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="dense-expansion-panel"
          >Background Color
          <template v-slot:actions>
            <v-icon
              :color="getGlobalProperty.global_setting.background_color"
              size="large"
            >
              {{ icons.circle }}
            </v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="dense-expansion-panel">
          <v-color-picker
            v-model="getGlobalProperty.global_setting.background_color"
            dot-size="4"
            mode="hexa"
            canvas-height="80"
            width="252"
            swatches-max-height="10"
            @update:modelValue="setBackgroundColor()"
          ></v-color-picker>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="dense-expansion-panel"
          >Foreground Color
          <template v-slot:actions>
            <v-icon
              :color="getGlobalProperty.global_setting.foreground_color"
              size="large"
            >
              {{ icons.circle }}
            </v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="dense-expansion-panel">
          <v-color-picker
            v-model="getGlobalProperty.global_setting.foreground_color"
            dot-size="4"
            mode="hexa"
            canvas-height="80"
            width="252"
            swatches-max-height="10"
            @update:modelValue="setForegroundColor()"
          ></v-color-picker>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="dense-expansion-panel"
          >Highlight Color
          <template v-slot:actions>
            <v-icon
              :color="getGlobalProperty.global_setting.highlight_color"
              size="large"
            >
              {{ icons.circle }}
            </v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="dense-expansion-panel">
          <v-color-picker
            :model-value="getGlobalProperty.global_setting.highlight_color"
            dot-size="4"
            mode="hexa"
            canvas-height="80"
            width="252"
            swatches-max-height="10"
            @update:modelValue="setHighlightColor($event)"
          ></v-color-picker>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="dense-expansion-panel"
          >Selection Color
          <template v-slot:actions>
            <v-icon
              :color="getGlobalProperty.global_setting.selection_color"
              size="large"
            >
              {{ icons.circle }}
            </v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="dense-expansion-panel">
          <v-color-picker
            :model-value="getGlobalProperty.global_setting.selection_color"
            dot-size="4"
            mode="hexa"
            canvas-height="80"
            width="252"
            swatches-max-height="10"
            @update:modelValue="setSelectionColor($event)"
          ></v-color-picker>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    </div>
  </div>
</template>
<script>

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import HelpButton from "../components/ui/HelpButton.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  components: {
    HelpButton,
  },
  data() {
    return {
      alert: false,
    };
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getWidgets,
      getGlobalPropertyUpdated,
      getGlobalProperty,
    } = storeToRefs(notebookPropsStore)
    const {
      setWidgets,
      setGlobalHighlightColor,
      setGlobalSelectionColor,
      setGlobalSignificantDigits,
      setGlobalPermissionsDataSwitch
    } = notebookPropsStore
    return {notebookPropsStore, 
    getWidgets, getGlobalPropertyUpdated, getGlobalProperty,
    setWidgets, setGlobalHighlightColor, setGlobalSelectionColor, setGlobalSignificantDigits, setGlobalPermissionsDataSwitch, lexicon, icons}
  },
  computed: {},
  methods: {
    refreshComments() {},
    setBackgroundColor() {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
    },
    setForegroundColor() {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      let a = this.getWidgets.map((e) => {
        e.local_setting.foreground_color =
          this.getGlobalProperty.global_setting.foreground_color;
        return e;
      });
      this.setWidgets(a)
    },
    setHighlightColor(color) {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      this.setGlobalHighlightColor(color)
    },
    setSelectionColor(color) {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      this.setGlobalSelectionColor(color)
    },
    setSignificantDigits(digits) {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      this.setGlobalSignificantDigits(digits)
    },
    setUserDataSwitch(status) {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      this.setGlobalPermissionsDataSwitch(status)
    },
    async lockGrids() {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
      if (this.getGlobalProperty.global_setting.is_locked)
        this.widgets = this.getWidgets.map(function (e) {
          e.local_setting.is_locked = true;
          return e;
        });
      else
        this.widgets = this.getWidgets.map(function (e) {
          e.local_setting.is_locked = false;
          return e;
        });
      await this.setWidgets(this.widgets)
    },
    snapGrids() {
      this.notebookPropsStore.$patch({globalPropertyUpdated: true})
    },
  },
};
</script>
<style scoped lang="scss">
.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.v-expansion-panel:not(:first-child)::after{
  border-top-style:none !important
}
</style>

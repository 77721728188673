<template>
  <div class="px-5 py-3" style="height: 100%">

    <span class="header"> {{ lexicon.layout.plural }}
      <HelpButton :lexiconItem="lexicon.layout"/>
        </span>

    <v-sheet>
    <v-container py-0 px-0 v-if="!viewList" fluid>
      <v-row class="d-flex flex-wrap" v-if="getLayouts">
        <v-col cols="12" xl="2" lg="3" md="4" sm="6" :style="`cursor: pointer;`" class="px-2 py-2"
          v-for="layout in getLayouts.slice().reverse()" :key="layout._id" :min-width="`250px`">
          <LayoutCard :layout="layout" />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
  </div>
</template>
<script>
import LayoutCard from "../components/LayoutCard.vue";
import NotebookItem from "../components/NotebookItem.vue";
import HelpButton from "../components/ui/HelpButton.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  components: { LayoutCard, NotebookItem, HelpButton },
  data() {
    return {
      layouts: [],
      createNewNotebookDialog: false,
      notebookName: "",
      notebookDescription: "",
      createNotebookLoading: false,
      viewList: false,
    };
  },
  setup() {
    const ellipseStore = useEllipseStore()
    const {
      getLayouts,
      getUser
    } = storeToRefs(ellipseStore)
    const {
      setLayouts
    } = ellipseStore
    return {
      getLayouts, 
      getUser,
      setLayouts, 
      lexicon,
      icons
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.setLayouts(this.getUser._id)
  },
  methods: {
  },
};
</script>

<style scoped></style>

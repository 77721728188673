/*
 * List of all possible "Messages" organized as
 * 'Messages' objects. This array is used
 * to instantiate the Message standardization across
 * the Ellipse App to main consistency.
 */

import * as lexicon from "@/utilities/EllipseLexicon.js";

//PAGES
export const publish = "Publish";//DONE
export const unpublish = "Unpublish";//DONE
export const addSubPage = "Add Sub-"+lexicon.page.single;//DONE

//NOTEBOOKS
export const notebookNew = "New "+lexicon.notebook.single;//DONE
export const notebookAdd = "Add "+lexicon.notebook.single;
export const notebookShare = "Share "+lexicon.notebook.single;
export const notebookName = lexicon.notebook.single+" Name";
export const notebookDescription = lexicon.notebook.single+" Description";
export const notebookDelete = "Are you sure you want to delete this "+lexicon.notebook.single+"?";

//TEMPLATES
export const templateSave = "Save As "+lexicon.template.single;
export const templateSelect = "Select "+lexicon.template.single;
export const templateCreate = "Create New "+lexicon.template.single+" from this "+lexicon.notebook.single;

//LAYOUTS
export const layoutRename = "Rename "+lexicon.layout.single;
export const layoutEdit = "Edit "+lexicon.layout.single;
export const layoutDelete = "Are you sure you want to delete this "+lexicon.layout.single+"?";
export const layoutSave = "Save "+lexicon.layout.single;
export const layoutApply = "Are you sure want to apply this "+lexicon.layout.single+"?";
export const layoutName = lexicon.layout.single+" Name";
export const layoutDescription = lexicon.layout.single+" Description";
export const layoutAlert = "Click a "+lexicon.layout.single+" tile below to override the current Dashboard.";

//USERS
export const userAdd = "Add "+lexicon.user.plural;

//WIDGETS
export const widgetEmpty = {memo: "This " + lexicon.page.single + " does not have any " , icon: "fa-solid fa-triangle-exclamation" }
export const widgetAlert = "Click and drag a "+lexicon.widget.single+" tile below over the Canvas area to the right to add it to the "+lexicon.layout.single+".";

//ASSETS
export const uploadAlert = "Upload and modify "+lexicon.data.plural+", "+lexicon.model.plural+", "+lexicon.drawing.plural+", "+lexicon.map.plural+", "+lexicon.image.plural+", and "+lexicon.file.plural+". These "+lexicon.asset.plural+" will be accessible to all pages.";
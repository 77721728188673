<template>
  <!-- Data Table Widget properties -->
  <div>
    <v-container>    

      <v-row width="100%"><div class="text-caption pt-0"><strong>Plot Attributes</strong></div><v-divider/></v-row>

      <!-- Data Set Size -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <ChipGroup
          :options="getAttrHeaderNames" 
          :selections="selection" 
          @table-changed="updatedSelection" 
        />
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>User Inputs</strong></div><v-divider/></v-row>
      <v-row class="mt-4">
        <v-select 
          label="Scrub Action"
          :items = "scrubActionOptions"
          v-model="getSelectedWidget.instance_setting.data.scrubAction">
        </v-select>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>Display Settings</strong></div><v-divider/></v-row>

      <!-- include filtered values in results -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.respondToFiltering"
          label="Respond to Filtering"
        />
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";

import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  components:{
    ChipGroup,
  },
  props:{
    getSelectedWidget:{},
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderNames
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderNames}
  },
  data:()=>({
    selection: [],
    data_headers: [],
    loaded: false,
    scrubActionOptions: ["Filter", "Select", "User Preference"]
  }),
  computed:{
  },
  async mounted(){
    if(this.getSelectedWidget.content == 'chartParallel'){
      await this.loadTableHeaders();
      this.selection = this.data_headers.slice(0, 3).map(header => header.name);
    }
  },
  watch:{
    'getSelectedWidget':{
      deep: true,
      immediate: true,
      handler: function() {
          if(this.getSelectedWidget.content == 'chartParallel'){
              this.loadTableHeaders();
          }       
      }
    }
  },
  methods:{
    updatedSelection(selected){
      this.selection = selected;
      this.getSelectedWidget.instance_setting.data.selectedAttrs = selected
    },
    async loadTableHeaders(){
      this.loaded = false;
      let i = 0;
      this.data_headers = this.getAttrHeaderNames.map((e) => {
        return { id: i++, name: e };
      });

      this.items = [
        {
          name: "Data Headers",
          children: this.data_headers,
        },
      ];

      if (this.getSelectedWidget.instance_setting.data) { 
        if (this.getSelectedWidget.instance_setting.data.selectedAttrs.length) {
          this.selection =
          this.getSelectedWidget.instance_setting.data.selectedAttrs;
        }
      } else {
        this.selection = this.getAttrHeaderNames;
      }
      this.loaded = true;
    }
  }
}
</script>

<style scoped>

</style>
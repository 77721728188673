<template>
  <div>
    <div v-if="panelWidgetsExist">
      <div v-for="(item, i) in getPanelWidgets"
        :key="i"
      >
        <v-row align-content="center">
          <!-- Widget Template. -->
          <v-col center>
            <component v-bind:is="item.content" :grid_item="item" />
          </v-col>
        </v-row>
        <v-divider ></v-divider>
      </div>
    </div>
  </div>
</template>
<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import ColorBySelector from "@/components/widgets/ColorBySelector.vue";
import DisplayScoreCard from "@/components/widgets/DisplayScoreCard.vue";
import DisplayStatusCount from "@/components/widgets/DisplayStatusCount.vue";
import FilterByCheck from "@/components/widgets/FilterByCheck.vue";
import FilterByCards from "@/components/widgets/FilterByCards.vue";
import FilterByDropdown from "@/components/widgets/FilterByDropdown.vue";
import FilterByRange from "@/components/widgets/FilterByRange.vue";
import FilterBySearch from "@/components/widgets/FilterBySearch.vue";
import ViewLegend from "@/components/widgets/ViewLegend.vue";
import FilterBySelection from '@/components/widgets/FilterBySelection.vue';

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  components:{
    ColorBySelector,
    DisplayScoreCard,
    DisplayStatusCount,
    FilterByCheck,
    FilterByCards,
    FilterByDropdown,
    FilterByRange,
    FilterBySearch,
    ViewLegend,
    FilterBySelection,
  },
  props:[],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getWidgets,
      getGlobalProperty,
      getSelectedPageStateId
    } = storeToRefs(notebookPropsStore)
    const {
      setWidgets
    } = notebookPropsStore
    return {notebookPropsStore, 
      getWidgets, getSelectedPageStateId, getGlobalProperty,
      setWidgets}
  },
  data(){
    return {
      panelWidgets:[],
      defaultPanelWidgets:['filterBySelection', 'filterBySearch','colorBySelector', 'viewLegend'],
    };
  },
  created(){
    if (!this.panelWidgetsExist){
      let panelWidgets = this.createDefaultPanelWidgets();
      this.addWidgets(panelWidgets);
    };
  },
  computed:{
    /**
     * Returns a subset of State Widgets to be used in the Side Panel. 
     */
    getPanelWidgets(){
      if(this.getWidgets){
        return this.getWidgets.filter((widget)=>{
          if(widget.instance_setting.isPanelWidget == true){
            return widget;
          }
        })
      } else{
        return [];
      } 
    },
    panelWidgetsExist(){
      let responce = this.getPanelWidgets.length > 0 ? true : false;
      return responce
    },
  },
  methods:{
    createDefaultPanelWidgets(){
      let defaultWidgets = this.createWidgets(this.defaultPanelWidgets);
      return defaultWidgets;
    },
    createWidgets(widgetTypes){
      let newWidgets = widgetTypes.map((type)=>{
        return {...this.genericNewWidget(), ...this.getWidgetDetails(type)}
      })
      return newWidgets;
    },
    getWidgetDetails(contentName){
      let generalWidgetInfo = WidgetsCollection.ui_element_widgets.find(
          (widget) => widget.content === contentName
      );
      return generalWidgetInfo;
    },
    genericNewWidget(){
      return {
        x:99,
        y:99,
        w:300, 
        h:0,
        minW:0,
        minH:0,
        content:"",
        i: new Date().getTime(),
        lock_color:'darkGrey',
        local_setting:{
          isLocked:false,
          foreground_color:this.getGlobalProperty.global_setting.foreground_color,
        },
        instance_setting:{
          data:null,
          isPanelWidget:true,
        },
        new:true,
        page_state:this.getSelectedPageStateId,
      }
    },
    async addWidgets(newWidgets){
      let widgets = this.getWidgets;
      widgets.push( ...newWidgets);
      await this.setWidgets(widgets)
    },
  }
}
</script>
<style scoped>

</style>
<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <!-- Widget Content -->
    <div v-if="getAttrData.length > 0" class="flexColumn">
      <!-- Header Description -->
      <v-container class="px-1 py-0 d-flex justify-space-between">
        <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader">
          {{ grid_item.instance_setting.data.selectedGroupAttribute }}
        </h5>

        <h5 v-if="grid_item.instance_setting.data" class="WidgetSubHeader pr-1">
          <small>
            <span> {{ grid_item.instance_setting.data.selectedCalc }}</span>
            <span v-if="grid_item.instance_setting.data.selectedCalc != 'Count'">
              of
              <strong>
                {{ grid_item.instance_setting.data.selectedCalcAttribute }}</strong>
            </span>
          </small>
        </h5>

      </v-container>
      <!-- Results -->
      <v-container 
        class="ma-0 pa-0 pl-1 mt-2" 
        style="font-size: smaller; overflow-y: auto" 
        v-if="grid_item.instance_setting.data"
      >
        <template v-if="grid_item.instance_setting.data.summaryObjects">
          <template v-if="grid_item.instance_setting.data.summaryObjects.length">
            <v-infinite-scroll 
              :items="dataChunk" 
              @load="loadMoreData" 
              direction="vertical" 
              style="overflow-y:hidden; overflow-x:hidden"
            >
              <div>
                <v-chip v-for="(item, index) in dataChunk" :key="index" 
                  size="small" label selectedClass="selected-chip"
                  class="default-chip elevation-1 mt-0 mb-1 mr-1"
                  :class="grid_item.instance_setting.data.filterValues[index] ? grid_item.instance_setting.data.filterValues[index].show ? 'selected-chip' : 'default-chip' : 'default-chip'"
                  :style="!grid_item.instance_setting.data.viewChips ? 'width:100%;' : ''"
                  @click="toggleFilterValue(index)"
                >
                  <span class="font-weight-bold mr-1"
                    :style="!grid_item.instance_setting.data.viewChips ? 'right:10px; position: absolute;' : ''">
                    {{ formatValue(item.number) }}
                  </span>
                  <span class="pr-1">
                    {{ item.name }}
                  </span>
                </v-chip>
              </div>
              <template v-slot:empty>
              </template>
            </v-infinite-scroll>
          </template>
        </template>
      </v-container>

    </div>
  </div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate, group, utilites } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import Templates from '../../pages/Templates.vue';

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, Templates, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeadersCategorical,
      getFilterByData
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore, getGlobalProperty,
    getEditMode, getAttrData, getAttrHeadersCategorical, getSelectedPageDatasets, getDatasetLoading,
    getFilterByData, addFilter, updateFilterByProperties}
  },
  data() {
    return {
      selectedGroupAttribute: "Group",
      selectedCalcAttribute: "Value",
      selectedCalc: "Count",
      summaryObjects:[],
      filterValues: [],
      filterType: "Value",
      viewChips: false,
      includeFilteredValues: false,
      datasetSwitchingLoading:false,
      dataChunk: [],
      itemsPerChunk: 50
    };
  },
  created() {
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
    if (!this.grid_item._id) {
      this.calculateSummary();
      this.updateSelectedAttribute()
      this.filterByValues();
    } else {
      this.selectedAttributeCheck();
      this.filterByValues();
    }
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdateEvent()
        }
      })
    })
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateFilterByProperties') {
          this.calculateSummary();
          this.resetDataChunk()
        }
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedGroupAttribute": function() {
      this.counterPropsUpdateEvent()
    },
    "grid_item.instance_setting.data.selectedCalc": function() {
      this.counterPropsUpdateEvent()
    },
    "grid_item.instance_setting.data.selectedCalcAttribute": function() {
      this.counterPropsUpdateEvent()
    },
    "grid_item.instance_setting.data.includeFilteredValues": function() {
      this.counterPropsUpdateEvent()
    }
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    formatValue(data) {
        return utils.setDigits(data);
    },
    verifyInstanceSettings() {
      
      if(!this.grid_item.instance_setting.data)this.grid_item.instance_setting.data={};

      if(!this.grid_item.instance_setting.data.selectedGroupAttribute)this.grid_item.instance_setting.data.selectedGroupAttribute = this.getAttrHeadersCategorical[0];
      if(!this.grid_item.instance_setting.data.selectedCalcAttribute)this.grid_item.instance_setting.data.selectedCalcAttribute = this.selectedCalcAttribute;
      if(!this.grid_item.instance_setting.data.selectedCalc)this.grid_item.instance_setting.data.selectedCalc = this.selectedCalc;
      if(!this.grid_item.instance_setting.data.summaryObjects)this.grid_item.instance_setting.data.summaryObjects = this.summaryObjects;
      if(!this.grid_item.instance_setting.data.viewChips)this.grid_item.instance_setting.data.viewChips = this.viewChips;
      if(!this.grid_item.instance_setting.data.includeFilteredValues) this.grid_item.instance_setting.data.includeFilteredValues = this.includeFilteredValues
      if(!this.grid_item.instance_setting.data.filterValues)this.grid_item.instance_setting.data.filterValues = this.filterValues;
      if(!this.grid_item.instance_setting.data.filterType)this.grid_item.instance_setting.data.filterType = this.filterType;

      if (!this.grid_item._id) {

      };
      this.resetDataChunk()
    },
    counterPropsUpdateEvent() {
      this.calculateSummary();
      this.updateSelectedAttribute();
      this.filterByValues();
      this.resetDataChunk()
    },
    datasetUpdateEvent() {
      this.selectedAttributeCheck();
      this.updateSelectedAttribute();
      this.filterByValues();
      this.resetDataChunk()
    },
    resetDataChunk(){
      if (this.grid_item.instance_setting.data.summaryObjects){
        this.dataChunk = []
        this.dataChunk = this.grid_item.instance_setting.data.summaryObjects.slice(0, this.itemsPerChunk)
      }
    },
    toggleFilterValue(index){
      if (this.grid_item.instance_setting.data.filterValues[index]){
        this.grid_item.instance_setting.data.filterValues[index].show = !this.grid_item.instance_setting.data.filterValues[index].show
        this.filterByValues()
      }
    },
    async selectedAttributeCheck(){
      if(this.grid_item.instance_setting.data.selectedGroupAttribute){
        let result =  this.getAttrHeadersCategorical.filter(a => a === this.grid_item.instance_setting.data.selectedGroupAttribute);
        if(result.length === 0){
          this.grid_item.instance_setting.data.selectedGroupAttribute =  this.getAttrHeadersCategorical[0];
          this.grid_item.instance_setting.data.filterValues = [];
          this.grid_item.instance_setting.filters = [];
        }
      }
      this.calculateSummary();
    },
    updateSelectedAttribute(){
      this.selectedAttributeCheck();
      let val = this.grid_item.instance_setting.data.selectedGroupAttribute;
      // clear Filter
      if (val === "None") {
        this.grid_item.instance_setting.data.filterValues = [];
      } else {
        let elemIds = [];
        let elemVals = [];
        this.getAttrData.forEach((attr) => {
          elemIds.push(attr.ellipseId);
          elemVals.push(attr[val]);
        });

        let tempVal = evaluate.getUniqueVals(elemVals);
        this.grid_item.instance_setting.data.filterValues = tempVal.map((item) => {
          const filter = {value:"", show:true};
          filter.value = item;
          return filter;
        });
        //clear filter selection and sync
        this.filterSelection = [];
      }
    },
    async filterByValues() {
      let filterObject = {
        filterType: "Value",
        field: this.grid_item.instance_setting.data.selectedGroupAttribute,
        values: this.grid_item.instance_setting.data.filterValues,
      };
      this.grid_item.instance_setting.filters = [];
      this.grid_item.instance_setting.filters = [filterObject];
      
      let updateGlobalFilters
      if (this.grid_item.instance_setting.data.selectedGroupAttribute == 'None'){
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject}, true)
      }
      else if (filterObject.values.length > 0 && this.grid_item.instance_setting.data.selectedGroupAttribute != null) {
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
      }
      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    loadMoreData({done}){
      if (this.dataChunk.length == this.grid_item.instance_setting.data.summaryObjects.length){
        done('empty')
        return
      }
      const startIndex = this.dataChunk.length
      const endIndex = startIndex + this.itemsPerChunk
      const newData = this.grid_item.instance_setting.data.summaryObjects.slice(startIndex, endIndex)
      this.dataChunk = this.dataChunk.concat(newData)
      done('ok')
    },
    calculateSummary() {
      let summaryVals = [];
      let includeFilteredValues = this.grid_item.instance_setting.data.includeFilteredValues
      let currentData = (this.getFilterByData.graphicData &&  includeFilteredValues) ? this.getFilterByData.graphicData : this.getAttrData
      switch (this.grid_item.instance_setting.data.selectedCalc) {
        case "Count":
          summaryVals = group.simpleGroupSize(
            currentData,
            this.grid_item.instance_setting.data.selectedGroupAttribute
          );
          break;
        case "Sum":
          summaryVals = group.simpleGroupSum(
            currentData,
            this.grid_item.instance_setting.data.selectedGroupAttribute,
            this.grid_item.instance_setting.data.selectedCalcAttribute
          );
          break;
        case "Mean":
          summaryVals = group.simpleGroupMean(
            currentData,
            this.grid_item.instance_setting.data.selectedGroupAttribute,
            this.grid_item.instance_setting.data.selectedCalcAttribute
          );
          break;
        case "Median":
          summaryVals = group.simpleGroupMedian(
            currentData,
            this.grid_item.instance_setting.data.selectedGroupAttribute,
            this.grid_item.instance_setting.data.selectedCalcAttribute
          );
          break;
        case "Min":
          summaryVals = group.simpleGroupMin(
            currentData,
            this.grid_item.instance_setting.data.selectedGroupAttribute,
            this.grid_item.instance_setting.data.selectedCalcAttribute
          );
          break;
        case "Max":
          summaryVals = group.simpleGroupMax(
            currentData,
            this.grid_item.instance_setting.data.selectedGroupAttribute,
            this.grid_item.instance_setting.data.selectedCalcAttribute
          );
          break;
        default:
          summaryVals = [{ name: "Check Inputs", number: 0 }];
      }
      summaryVals = summaryVals.map((obj) => {
        obj["number"] = evaluate.roundIfNeeded(obj["number"], 2);
        return obj;
      });
      summaryVals =group.sortGroups(summaryVals,'name');

      let summaryValNames = summaryVals.map(val => val.name)
      let filterByValues = this.grid_item.instance_setting.data.filterValues
      filterByValues.forEach((val, index) => {
        if (!summaryValNames.includes(val.value)){
          summaryVals.splice(index, 0, {name: val.value, number: 0});
        }
      })
      this.grid_item.instance_setting.data.summaryObjects = summaryVals;
    },
    resetFilter(){
      this.updateSelectedAttribute()
      this.grid_item.instance_setting.filters[0].values = this.grid_item.instance_setting.data.filterValues
      this.resetDataChunk()
    }
  },
};
</script>
<style scoped>
  .default-chip {
    background-color: white;
    color:  rgb(var(--v-theme-darkSlate));
  }
  .selected-chip {
    background-color: rgb(var(--v-theme-darkSlate));
    color: white;
  }
</style>

<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="false"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <v-row
      v-if="getAttrData.length > 0 && getGlobalColorByAttribute"
      class="d-flex justify-start"
    >
      <!-- Attribute Selection -->
      <v-col 
        :cols="grid_item.instance_setting.isPanelWidget ? 12 : 6"
      >
        <v-combobox
          :class="grid_item.instance_setting.isPanelWidget ? 'pr-0 pt-1' : 'pr-1 pt-1'"
          variant="outlined"
          :model-value="getGlobalColorByAttribute"
          :items="getAttrHeaderOptions"
          label="Color By"
          @update:modelValue="setNewColorbyAttribute"
          hide-details="true"
        >
        </v-combobox>
      </v-col>

      <!-- Gradient Selection. -->
      <v-col :cols="grid_item.instance_setting.isPanelWidget ? 12 : 6" class="d-flex">
        <GlobalGradientSelection
         :denseMode="grid_item.instance_setting.isPanelWidget==true"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue"
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import GlobalGradientSelection from "../StateManagers/GlobalGradientSelection.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { 
    Spinner, 
    WidgetIcon, 
    GlobalGradientSelection,
    NoDataWarning,
    WidgetTitle
  },
  props: ["grid_item"],
  data: () => ({
    selectedAttribute: "None",
    selectedPallet:[]
  }),
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getGlobalColorByAttribute,
      getGlobalColorByGradient,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaders,
      getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const { 
      updateColorByProperties,
    } = dataGraphicsStore
    return {dataGraphicsStore, notebookPropsStore, assetsStore,
    getEditMode, getGlobalColorByAttribute, getGlobalColorByGradient, getGlobalProperty,
    getAttrData, getAttrHeaders, getAttrHeaderOptions, getSelectedPageDatasets,
    updateColorByProperties}
  },
  async created(){
    this.verifyInstanceSettings();
    this.updateData();
  },
  async mounted() {
    this.verifyInstanceSettings();
    //If this is a new instance of the widget. 
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateColorByData') {
          this.syncFromGlobal()
        }
      })
    })
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.selectedAttributeCheck()
        }
      })
    })
  },
  beforeUnmount() {
  },
  watch:{
    getGlobalColorByAttribute(attr){
      this.grid_item.instance_setting.data.selectedAttribute = attr;
    }
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {
      if (this.getGlobalColorByAttribute !== null) this.selectedAttribute = this.getGlobalColorByAttribute;
      if(!this.grid_item.instance_setting.data)this.grid_item.instance_setting.data={};
      if(!this.grid_item.instance_setting.data.selectedAttribute) this.grid_item.instance_setting.data.selectedAttribute = this.selectedAttribute;
      if(!this.grid_item.instance_setting.data.selectedPallet) this.grid_item.instance_setting.data.selectedPallet = this.getGlobalColorByGradient;
      if(this.grid_item.instance_setting.isPanelWidget) this.grid_item.instance_setting.data.selectedAttribute = this.getGlobalColorByAttribute;
      if (!this.grid_item._id) {
        
      }
    },
    /**
     * Check to make sure the selected attribute
     * is present in the current dataset. 
     */
    selectedAttributeCheck(){
      if(this.getGlobalColorByAttribute){
       let result =  this.getAttrHeaderOptions.filter(a => a === this.getGlobalColorByAttribute);
        if(result.length === 0){
          this.setNewColorbyAttribute(this.getAttrHeaderOptions[0]);
        }
      }
    },
    setNewColorbyAttribute(attr){
      if (attr !== this.getGlobalColorByAttribute){
        this.updateColorByProperties({attribute: attr});
      }
    },
    /**
     * Checks the current gradient against the global gradient
     * and updates teh global gradient if different. 
     * This should only be used at startup to re-load prior
     * pallet settings. 
     */
    setPalletOnMounted(){
      if (this.grid_item.instance_setting.data.selectedPallet !== this.getGlobalColorByGradient){
        this.grid_item.instance_setting.data.selectedPallet = this.getGlobalColorByGradient;
      }
    },
    /**
     * Subscribes to the color pallet and stores it for reuse on load. 
     * This componenet does not activly manage the pallet exept to 
     * re-load prior selected pallets. 
     */
    syncFromGlobal(){
      this.grid_item.instance_setting.data.selectedPallet = this.getGlobalColorByGradient;
      this.grid_item.instance_setting.data.selectedAttribute = this.getGlobalColorByAttribute;
    },
    arrayEquals(a, b) {
      return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
    },
    updateData(){
      if (!this.grid_item._id) {
          //if a gradient has already been set globaly use thate. 
          if(this.getGlobalColorByAttribute){
            this.grid_item.instance_setting.data = {
              selectedAttribute: this.getGlobalColorByAttribute,
              selectedPallet: this.getGlobalColorByGradient
            }
          } else {
            //otherwise use defualts. 
            this.grid_item.instance_setting.data = {
              selectedAttribute: this.selectedAttribute,
              selectedPallet: this.getGlobalColorByGradient
            }
            this.setNewColorbyAttribute(this.selectedAttribute);
          }  
      } else {
        //If this is not a new widget instance. 
        //Than relaod the saved pallet from instance settings. 
        this.setPalletOnMounted();
        this.setNewColorbyAttribute(this.grid_item.instance_setting.data.selectedAttribute)
      }
    }
  },
};
</script>
<style scoped>
.col{
  padding: 0px !important
}
</style>

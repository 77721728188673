<template>
  <div class="widgetPadding" style="height: 100%; width: 100%; overflow-y: scroll;">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <span class="property-header pb-4">
        <v-icon size="x-small" class="property-icon mr-1" color="darkGrey">fa-solid fa-arrows-left-right-to-line</v-icon>
        {{ grid_item.instance_setting.filters.length }} Range Sliders
    </span>
    
    <NoDataWarning :show="grid_item.instance_setting.filters.length === 0" :icon="widgetIcon.icon">Add Range Sliders from Widget Settings</NoDataWarning>

    <div class="pb-0"></div>
      <v-row class="pt-0" style="height:100vh; overflow-x: hidden; overflow-y: auto;">
        <v-col>
          <div
          v-for="(item, index) in grid_item.instance_setting.filters"
          :key="index">
          
           
          <h5 v-if="grid_item.instance_setting.data.inputVisible && item !== 'None'">{{item.field}}</h5>

          <v-range-slider
            v-model="item.range"
            :max="item.max"
            :min="item.min"
            hide-details
            density="compact"
            class="align-center pt-1 pb-3 pl-2 pr-3 mb-0"
            strict
            color="darkSlate"
            track-color="darkGrey"
            @update:modelValue="filterByRange"
          >

          <!-- Left Side Min. -->
          <template v-slot:prepend v-if="grid_item.instance_setting.data.inputVisible">
            <v-text-field
              v-model="item.range[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              density="compact"
              variant="underlined"
              type="number"
              style="width: 60px"
              :rules="[v => (v >= item.min && v <= item.max) || `Value must be between ${item.min} and ${item.max}`]"
              @update:model-value="filterByRange"
            ></v-text-field>
          </template>

          <template v-slot:prepend v-else>
            <h5 class="ml-0" style="width: 90px" >{{item.field}}<br> 
              <span class="font-weight-regular">{{item.range[0].toFixed(2)}} | {{item.range[1].toFixed(2)}}</span>
            </h5>
          </template>


          <!-- Right Side Max. -->
          <template v-slot:append v-if="grid_item.instance_setting.data.inputVisible">
            <v-text-field
              v-model="item.range[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              density="compact"
              variant="underlined"
              type="number"
              style="width: 60px"
              :rules="[v => (v >= item.min && v <= item.max) || `Value must be between ${item.min} and ${item.max}`]"
              @update:model-value="filterByRange"
            ></v-text-field>
          </template>

          </v-range-slider>
          
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import {isNullOrUndefined} from "@/utilities"

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {Spinner, NoDataWarning, WidgetIcon, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeadersNumerical, getUniqueAttrByNumericalHeader,
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      addFilter,
      updateFilterByProperties
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore, getGlobalProperty,
    getEditMode, getAttrData, getAttrHeadersNumerical, getSelectedPageDatasets, getDatasetLoading,
    updateFilterByProperties, getUniqueAttrByNumericalHeader, addFilter, updateFilterByProperties, }
  },
  data() {
    return {
      inputVisible:false,
      filterEmptyEntries: false,
      sliderValues: [],
      filterObjects: [],
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  watch: {
    "grid_item.instance_setting.data.filteredHeaders": function () {
      this.setSliderValues();
      this.filterByRange();
    },

    "grid_item.instance_setting.data.filterEmptyEntries": function () {
      this.filterByRange();
    },
	},
  mounted() {
    this.verifyInstanceSettings();
    this.filterByRange();
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.grid_item.instance_setting.data.filteredHeaders.forEach((item) => {
            this.evaluateSelectedField();
          });
        }
      })
    })
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetRange()
        }
      })
    })
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {
      //If a value is missing, populate with the default value. 
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!('filters' in this.grid_item.instance_setting)) this.grid_item.instance_setting.filters = [];
      if (!('filteredHeaders' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filteredHeaders = this.getAttrHeadersNumerical;
      if (!('sliderValues' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.sliderValues = [];
      this.setSliderValues();
    },
    datasetUpdated() {
      this.grid_item.instance_setting.data.filteredHeaders.forEach((item) => {
        this.evaluateSelectedField(item);
      });
    },
    setSliderValues() {
      let arrValues = [];
      this.getAttrHeadersNumerical.forEach((item) => {
        let range = evaluate.getRange(this.getUniqueAttrByNumericalHeader[item]);
        let sliderValues = {
          name: item,
          type: "Range",
          range: range,
          min: range[0],
          max: range[1],
        };
        arrValues.push(sliderValues);
      });
      this.grid_item.instance_setting.data.sliderValues = arrValues;
    },
    async filterByRange() {
      for (let header of this.grid_item.instance_setting.data.filteredHeaders) {
        // Find the corresponding sliderValue for the current item
        const i = this.grid_item.instance_setting.filters.findIndex(filter => filter.field === header);
        
        let filterObject 
        //Filter exists in filters
        if (i != -1) {
          filterObject = this.grid_item.instance_setting.filters[i]
        }
        //If slider does not exist in filters, add it
        else {
          const sliderValue = this.grid_item.instance_setting.data.sliderValues.find(slider => slider.name === header);
          filterObject = {
            filterType: "Range",
            field: sliderValue.name,
            range: sliderValue.range,
            min: sliderValue.min,
            max: sliderValue.max,
            filterEmptyEntries: this.grid_item.instance_setting.data.filterEmptyEntries
          };
          this.grid_item.instance_setting.filters.push(filterObject)
        }
        if (!isNullOrUndefined(filterObject.range[0]) && !isNullOrUndefined(filterObject.range[1])) {
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
          if (shouldUpdate) await this.updateFilterByProperties()
        }
      }
      // Iterate through all the current filters and remove any that are not included in the current filtered headers
      this.grid_item.instance_setting.filters.forEach(async (filter, index) => {
        if (!this.grid_item.instance_setting.data.filteredHeaders.includes(filter.field)){
          //Remove filter from data graphics store
          let shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: filter}, true)
          if (shouldUpdate) await this.updateFilterByProperties()
          // Remove the filter from the filters array at the current index
          this.grid_item.instance_setting.filters.splice(index, 1)
        }
      })
    },
    resetRange(){
      this.grid_item.instance_setting.filters.forEach((slider) => {
        slider.range = [slider.min, slider.max];
      });
    },
  },
  async unmounted() {
  },
};
</script>
<style scoped>
.col{
  padding: 0px !important
}
.row{
  margin: 0px !important
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.space {
  margin-right: 5px;
  /* Adjust as needed */
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}
</style>

<template>  
    <v-menu v-if="hasDropdown">
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          dark
          v-bind="props"
          prepend-icon="mdi:mdi-download"
          variant="outlined"
        >
        Download
        </v-btn>
      </template>
      <v-list >
        <v-list-item 
        prepend-icon="fa-solid fa-file-csv"
          @click="downloadCsv()"
        >
          <v-list-item-title>Download CSV</v-list-item-title>
        </v-list-item>
        <v-list-item 
        prepend-icon="fa-solid fa-file-code"
          @click="downloadJson()"
        >
          <v-list-item-title>Download JSON</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn v-else prepend-icon="mdi:mdi-download" @click="downloadData('json')"> {{ text }} </v-btn>
</template>

<script>
import * as utils from "@/utilities";
import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useEllipseStore } from "@/store/EllipseStore.js";

export default {
  name: 'DownloadData',
  props: {
    text: {
      type: String,
      required: false,
      default: "Download Data"
    },
    isCsv: {
        type: Boolean,
        required: false,
        default: false
    },
    hasDropdown: {
      type: Boolean,
      required: false,
      default: false
    },
    source: {
      type: String,
      required: false,
      default: "all"
    },
  },

  setup() {
    const ellipseStore = useEllipseStore();
    const dataGraphicsStore = useDataGraphicsStore();
    const notebookPropsStore = useNotebookPropsStore();

    const { getNotebook } = storeToRefs(ellipseStore);
    const { getFilterByData, getAttrData } = storeToRefs(dataGraphicsStore);
    const { getSelectedElements, selectedPage } = storeToRefs(notebookPropsStore);    

    return {
      getFilterByData,
      getAttrData,
      getSelectedElements,
      selectedPage,
      getNotebook,
    };
  },

  methods: {
    downloadData  () {      
      if (this.isCsv === true ) {  
        this.downloadCsv()
      } else {    
        this.downloadJson()
      }
    },

    getFileName() {
      return this.getNotebook.name + "-" + this.selectedPage.name + "-" + this.source + "-"+utils.timeStamp();
    },

    downloadCsv () {
      const data = this.getData()
      let fileContent = this.convertToCSV(data);
      this.triggerDownload(fileContent, this.getFileName()+'.csv', 'text/csv');
    },

    downloadJson () {
      const data = this.getData()
      let fileContent = JSON.stringify(data, null, 2);
      this.triggerDownload(fileContent, this.getFileName()+'.json', 'application/json');
    },

    getData() {
      switch (this.source) {
        case 'all':
          return this.getAllData();
        case 'filtered':
          return this.getFilteredData();
        case 'selected':
          return this.getSelectedData();
      }
    },

    getAllData (){
      return this.getAttrData
    },

    getFilteredData (){
      return this.getFilterByData.graphicData
    },

    getSelectedData (){
      const filteredData = this.getSelectedElements.map(id => {
      return this.getAttrData.find(attr => attr.ellipseId === id).value;
      });
      return filteredData
    },

    convertToCSV  (data)  {
      const headers = Object.keys(data[0]);
      const csvRows = data.map(row =>
        headers.map(header => JSON.stringify(row[header], this.replacer)).join(',')
      );
      return [headers.join(','), ...csvRows].join('\r\n');
    },

    triggerDownload  (content, fileName, mimeType) {
      const blob = new Blob([content], { type: mimeType });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },

    replacer  (key, value) {      
      return (value === null ? '' : value);     
    }, 
  },
};
</script>

<style>

</style>
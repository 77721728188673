export const GlobalFilters = {
    momentTimestamp(date){
      return moment(date).format("MMM Do YYYY, HH:mm:ss");
    },
    momentTimestampDDMMYYYY(date){
      return moment(date).format("DD/MM/YYYY hh:mm a");
    },
    round(value, digits){
      if (!value) return "";
      return Number(value).toFixed(digits);
    },
    momentConvertToKb(size){
      return (size / 1024).toFixed(3) + "KB"
    }
  }
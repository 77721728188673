<template>
  <div class="sidePanelMargin">
    <!-- Header Section -->
    <div class="sidePanelHeader">
      <v-col class="pa-0">
        <v-row>
          <h2 class="text-capitalize sidePanelInner header">
            {{ lexicon.asset.plural }} <HelpButton :lexiconItem="lexicon.dataset" />
          </h2>
        </v-row>
        <div class="divider">
          <v-divider></v-divider>
        </div>
        <HelpAlert :message="messages.uploadAlert" class="mb-2" />
        <!-- <BulkUploadDialog /> -->
        <OverlayUploadDialoge />

      </v-col>
    </div>

    <!-- Expansion Panels for Each Asset Type -->
    <v-expansion-panels 
      multiple
      flat
      v-model="panelValues"
    >
      <!-- Data Section -->
      <v-expansion-panel 
        variant="accordion" 
        flat
        static
        value="data"
        :disabled="dataNotebookAssetsCount === 0"
      >
        <v-expansion-panel-title
          collapse-icon="mdi:mdi-menu-down" 
          expand-icon="mdi:mdi-menu-up"
        >
          <h4 class="text-capitalize sidePanelInner">
            <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.dataset.icon }}</v-icon>
            Data
            <span class="asset-count">({{ dataPageAssetsCount }} / {{ dataNotebookAssetsCount }})</span>
          </h4>
        </v-expansion-panel-title>
        <v-expansion-panel-text class="pa-0 ma-0">
          <AssetsManage :assetType="'data'" :icon="lexicon.dataset.icon" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-divider></v-divider>

      <!-- 3D Models Section -->
      <v-expansion-panel 
        variant="accordion" 
        flat
        static
        value="3D"
        :disabled="modelNotebookAssetsCount === 0"
      >
        <v-expansion-panel-title
          collapse-icon="mdi:mdi-menu-down" 
          expand-icon="mdi:mdi-menu-up"
        >
          <h4 class="text-capitalize sidePanelInner">
            <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.model.icon }}</v-icon>
            3D Models
            <span class="asset-count">({{ modelPageAssetsCount }} / {{ modelNotebookAssetsCount }})</span>
          </h4>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <AssetsManage :assetType="'3D'" :icon="lexicon.model.icon" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-divider></v-divider>

      <!-- 2D Drawings Section -->
      <v-expansion-panel 
        variant="accordion" 
        flat
        static
        value="2D"
        :disabled="drawingNotebookAssetsCount === 0"
      >
        <v-expansion-panel-title
          collapse-icon="mdi:mdi-menu-down" 
          expand-icon="mdi:mdi-menu-up"
        >
          <h4 class="text-capitalize sidePanelInner">
            <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.drawing.icon }}</v-icon>
            2D Drawings
            <span class="asset-count">({{ drawingPageAssetsCount }} / {{ drawingNotebookAssetsCount }})</span>
          </h4>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <AssetsManage :assetType="'2D'" :icon="lexicon.drawing.icon" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-divider></v-divider>

      <!-- GIS Layers Section -->
      <v-expansion-panel 
        variant="accordion" 
        flat
        static
        value="GIS"
        :disabled="gisNotebookAssetsCount === 0"
      >
        <v-expansion-panel-title
          collapse-icon="mdi:mdi-menu-down" 
          expand-icon="mdi:mdi-menu-up"
        >
          <h4 class="text-capitalize sidePanelInner">
            <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.map.icon }}</v-icon>
            GIS Layers
            <span class="asset-count">({{ gisPageAssetsCount }} / {{ gisNotebookAssetsCount }})</span>
          </h4>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <AssetsManage :assetType="'GIS'" :icon="lexicon.map.icon" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-divider></v-divider>

      <!-- Data Images Section -->
      <v-expansion-panel 
        variant="accordion" 
        flat
        static
        value="IMG"
        :disabled="imageNotebookAssetsCount === 0"
      >
        <v-expansion-panel-title
          collapse-icon="mdi:mdi-menu-down" 
          expand-icon="mdi:mdi-menu-up"
        >
          <h4 class="text-capitalize sidePanelInner">
            <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.image.icon }}</v-icon>
            Data Images
            <span class="asset-count">({{ imagePageAssetsCount }} / {{ imageNotebookAssetsCount }})</span>
          </h4>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <AssetsManage :assetType="'IMG'" :icon="lexicon.image.icon" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-divider></v-divider>

      <!-- Files Section -->
      <v-expansion-panel 
        variant="accordion" 
        flat
        static
        value="file"
        :disabled="fileNotebookAssetsCount === 0"
      >
        <v-expansion-panel-title
          collapse-icon="mdi:mdi-menu-down" 
          expand-icon="mdi:mdi-menu-up"
        >
          <h4 class="text-capitalize sidePanelInner">
            <v-icon size="x-small" class="ml-1 mr-2">{{ lexicon.file.icon }}</v-icon>
            Files
            <span class="asset-count">({{ filePageAssetsCount }} / {{ fileNotebookAssetsCount }})</span>
          </h4>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <AllFiles />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>


<script>
import { computed, ref, watch } from 'vue';
import AssetsManage from "./SidePanelAssetsManage.vue";
import AllFiles from "./SidePanelAssetsAllFiles.vue";
import BulkUploadDialog from "./SidePanelAssetsBulkUploadDialog.vue";
import OverlayUploadDialoge from './OverlayUploadDialoge.vue';
import HelpButton from "../components/ui/HelpButton.vue";
import HelpAlert from "../components/ui/HelpAlert.vue";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import { storeToRefs } from 'pinia';
import { useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {
    AssetsManage,
    AllFiles,
    BulkUploadDialog,
    OverlayUploadDialoge,
    HelpButton,
    HelpAlert,
  },
  setup() {
    const assetsStore = useAssetsStore();
    const {
      getAllAssetsInNotebook,
      getAllNotebookFiles,
      getSelectedPageDatasets,
      getSelectedPageModels,
      getSelectedPageDrawings,
      getSelectedPageMaps,
      getSelectedPageImages,
      getSelectedPageFiles,
    } = storeToRefs(assetsStore);

    // Compute counts for each asset type based on the selected page assets
    const dataPageAssetsCount = computed(() => getSelectedPageDatasets.value.length);
    const dataNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.datasets?.length || 0);

    const modelPageAssetsCount = computed(() => getSelectedPageModels.value.length);
    const modelNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.models?.length || 0);

    const drawingPageAssetsCount = computed(() => getSelectedPageDrawings.value.length);
    const drawingNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.drawings?.length || 0);

    const gisPageAssetsCount = computed(() => getSelectedPageMaps.value.length);
    const gisNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.maps?.length || 0);

    const imagePageAssetsCount = computed(() => getSelectedPageImages.value.length);
    const imageNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.images?.length || 0);


    const filePageAssetsCount = computed(() => getSelectedPageFiles.value.length);
    const fileNotebookAssetsCount = computed(() => getAllAssetsInNotebook.value.files?.length || 0);

    // Initialize panelValues (you can adjust this as needed)
    const panelValues = ref([]);

    return {
      lexicon,
      messages,
      // Counts for Data
      dataPageAssetsCount,
      dataNotebookAssetsCount,
      // Counts for 3D Models
      modelPageAssetsCount,
      modelNotebookAssetsCount,
      // Counts for 2D Drawings
      drawingPageAssetsCount,
      drawingNotebookAssetsCount,
      // Counts for GIS Layers
      gisPageAssetsCount,
      gisNotebookAssetsCount,
      // Counts for Data Images
      imagePageAssetsCount,
      imageNotebookAssetsCount,
      // Counts for Files
      filePageAssetsCount,
      fileNotebookAssetsCount,
      // Panel Values
      panelValues,
    };
  },
};
</script>

<style scoped>
/* Adjusting Expansion Panel Styles */
.v-expansion-panel-title {
  padding: 16px 5px !important;
}

.v-expansion-panel-text {
  padding: 0 !important;
}

.sidePanelMargin {
  overflow-y: auto;
  max-height: calc(100vh - 200px); /* Adjust as needed */
}

.divider {
  margin-bottom: 16px;
}

.text-primary {
  font-weight: bold;
}

.expansion-panel-text-padding {
  padding: 0px !important;
}

.v-expansion-panel-text__wrapper {
  padding: 0px !important;
}

::v-deep .v-expansion-panel-text__wrapper {
  padding-top: 0px;
  padding-right: 0px; 
  padding-bottom: 0px; 
  padding-left: 0px;
}

::v-deep .v-expansion-panel.v-expansion-panel--active {
  margin-top: 0px;
}

/* New style for smaller font in counts */
.asset-count {
  font-size: 0.8em;
  margin-left: 8px; /* Add space between the name and the count */
}
</style>

<template>
  <div>

    <span class="property-header">
      <v-icon 
        size="x-small" 
        class="property-icon mr-1"
        color="darkGrey"
      > 
        fa-sharp fa-solid fa-filter
      </v-icon>
      {{ processFilteredRules.length }} Filters Applied
    </span>

    <v-table 
      density="compact" 
    >
      <tbody>
        <!-- Filters -->
        <tr v-for="(result, index) in processFilteredRules" :key="index">
          <td 
            class="truncate header-column pa-0 pl-2" 
            style="width:30%;"
          >
            <v-icon 
              size="x-small" 
              class="property-icon mr-1" 
              color="darkGrey"
            >
              {{ result.icon }}
            </v-icon>
            <span class="property-header space">
              {{ result.title }}
            </span>
          </td>
          <td class="truncate property-item pa-0" style="width:50%;">
            <span class="space">{{ result.value }}</span>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import * as icons from "@/utilities/EllipseIcons.js";
import * as utils from "@/utilities";

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

import { storeToRefs } from 'pinia';
import { useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
  props: {
    switchValueResult: {
      type: Boolean,
      required: true,
      default: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
      default: true,
    },
    showRange: {
      type: Boolean,
      required: true,
      default: true,
    },
    showValue: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  watch: {
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const ellipseStore = useEllipseStore()
    const {
    } = storeToRefs(notebookPropsStore)
    const {
      getAllFilters,
    } = storeToRefs(dataGraphicsStore)
    const {
      getUser,
    } = storeToRefs(ellipseStore)
    return {
      getAllFilters,
      dataGraphicsStore,
      notebookPropsStore,
      getUser,
      icons,
      lexicon, 
      messages, 
    }
  },
  data: () => ({
    filterResults: [],
  }),
  created() {
  },
  mounted() {
  },
  computed: {
    processFilteredRules() {
      let filterResults = [];
      this.getAllFilters.forEach(filter => {
            let filterType = filter.filter.filterType;
            let filterValue;
            let displayNumberOfValues = 4;

            if (filterType === "Value" & this.showValue === true) {
                let arrProp = filter.filter.values
                  .filter(value => value.show === this.switchValueResult)
                  .map(value =>  this.formatValue(value.value))
                  .slice(0, displayNumberOfValues);

                filterValue = arrProp.join(", ");
                if (arrProp.length === displayNumberOfValues) filterValue += "...";

                filterResults.push({
                  icon: icons.numerical,
                  title: filter.filter.field,
                  value: filterValue
                });
              }

            if (filterType === "Search" && this.showSearch === true) {
              if (!(filterValue === "None")) {
                filterResults.push({
                  icon: icons.search,
                  title: "Search",
                  value: filter.filter.search
                });
              }
            }

            if (filterType === "Range" && this.showRange === true) {
              filterValue = this.formatValue(filter.filter.range[0]) + " ≤ x ≤ " + this.formatValue(filter.filter.range[1]);
              filterResults.push({
                icon: icons.range,
                title: filter.filter.field,
                value: filterValue
              });
            }
          });
      return filterResults;
    },
  },

  methods: {
    formatValue(data) {
      return utils.setDigits(data);
    },

  },
};
</script>

<style scoped>
.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-column {
  white-space: nowrap;
  color: rgb(var(--v-theme-darkGrey));
}

.property-icon {
  color: rgb(var(--v-theme-darkSlate));
}

.property-header {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}

.space {
  margin-right: 5px;
  /* Adjust as needed */
}

.property-item {
  font-size: 13px;
  font-weight: 400;
  color: rgb(var(--v-theme-darkGrey));
}
</style>
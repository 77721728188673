<template>
  <!-- Chart Widget Properties -->
  <div>
    <v-container>

      <v-row width="100%"><div class="text-caption pt-0"><strong>Plot Attributes</strong></div><v-divider/></v-row>
      
      <!-- Attribute to Summarize Selection Dropdown -->
      <v-row width="100%" class="pt-4 pl-2">
        <v-combobox 
        v-model="getSelectedWidget.instance_setting.data.selectedAttr" 
        :items="getAttrHeaderOptions" item-title="name"
          :label="'Select Attribute (' + getAttrHeaderOptions.length + ')'"
          />
      </v-row>
      
      <!-- Count of Elements in Dataset. -->
      <v-row width="100%"  class="pt-4 pb-0 pl-2">
        <v-combobox dense hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.summaryAttribute"
          :items="getAttrHeadersNumerical" 
          :label="'Select Summary Attribute (' + getAttrHeadersNumerical.length + ')'"
          />
      </v-row>

      <v-row width="100%"><div class="text-caption pt-6"><strong>Plot Display</strong></div><v-divider/></v-row>

      <v-row width="100%" class="pl-2">
        <!-- Flip. -->
        <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.isFlipped"
          label="Vertical" 
        />
      </v-row>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Plot Decorators</strong></div><v-divider/></v-row>

      <!-- Grid Lines -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- X Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.gridX"
            label="Grid X" />
        </v-col>
        <!-- Y Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.gridY"
            label="Grid Y" />
        </v-col>
      </v-row>

      <!-- Axis Lines -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- X Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.axisX"
            label="Axis X" />
        </v-col>
        <!-- Y Axis -->
        <v-col lg="6"
          class="pb-0">
          <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.displaySettings.axisY"
            label="Axis Y" />
        </v-col>
      </v-row>

      <!-- Axis Label Angle -->
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <v-col lg="12">
          <div class="text-caption pb-0">Label Angle | {{ getSelectedWidget.instance_setting.data.displaySettings.axisAngle }}</div>
          <v-slider
            v-model="getSelectedWidget.instance_setting.data.displaySettings.axisAngle"
            prepend-icon="mdi:mdi-rotate-left"
            min="0"
            max="90"
            step="15"
            thumb-label
            show-ticks="always"
            hide-details
            color="primary"
          ></v-slider>
        </v-col>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>Display Settings</strong></div><v-divider/></v-row>

      <!-- include filtered values in results -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.respondToFiltering"
          label="Respond to Filtering"
        />
      </v-row>
  
    </v-container>
  </div>
</template>
  
<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
    props: {
        getSelectedWidget: {}
    },
    setup() {
      const dataGraphicsStore = useDataGraphicsStore()
      const {
        getAttrHeaderOptions,
        getAttrHeadersNumerical
      } = storeToRefs(dataGraphicsStore)
      return {getAttrHeaderOptions,getAttrHeadersNumerical}
    },
    computed: {
      attrHeaders(){
        let i = 0;
        let newHeaders = this.getAttrHeaderOptions.map((e) => {
            return { id: i++, name: e };
        });
        return newHeaders;
      }
    },
    mounted(){
        //TODO - this should happen at created, then listen for updates to storeG
        //this.loadTableHeders();
    },
    methods: {}
}
</script>
  
<style scoped>
.dense-expansion-panel {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 1 !important;
    padding-right: 1 !important;
}
</style>
<template>
  <!-- Chart Widget Properties -->
  <div>

    <v-container>

<v-row width="100%"><div class="text-caption pt-0"><strong>Plot Attributes</strong></div><v-divider/></v-row>

      <!-- Attribute to Summarize Selection Dropdown -->
      <v-row width="100%" class="pt-4 pb-0 pl-2">
          <v-combobox 
          v-model="getSelectedWidget.instance_setting.data.selectedAttr" 
          :items="getAttrHeaderOptions"
          :label="'Select Attribute (' + attrHeaders.length + ')'"
          />
      </v-row>

      <v-row width="100%"  class="pt-4 pb-0 pl-2">
        <!-- Count of Elements in Dataset. -->
        <v-combobox 
          v-model="getSelectedWidget.instance_setting.data.displaySettings.summaryAttribute"
          :items="getAttrHeadersNumerical" 
          :label="'Select Summary Attribute (' + attrHeaders.length + ')'"
          />
      </v-row>

      <v-row width="100%"><div class="text-caption pt-4"><strong>Display Settings</strong></div><v-divider/></v-row>

      <!-- include filtered values in results -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.respondToFiltering"
          label="Respond to Filtering"
        />
      </v-row>


    </v-container>
  </div>
</template>
  
<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
    props: {
        getSelectedWidget: {}
    },
    setup() {
      const dataGraphicsStore = useDataGraphicsStore()
      const {
        getAttrHeaderNames,
        getAttrHeadersNumerical,
        getAttrHeaderOptions
      } = storeToRefs(dataGraphicsStore)
      return {getAttrHeaderNames,getAttrHeadersNumerical, getAttrHeaderOptions}
    },
    computed: {
      attrHeaders(){
        let i = 0;
        let newHeaders = this.getAttrHeaderOptions.map((e) => {
            return { id: i++, name: e };
        });
        return newHeaders;
      }
    },
    mounted(){
        //TODO - this should happen at created, then listen for updates to storeG
        //this.loadTableHeders();
    },
    methods: {}
}
</script>
  
<style scoped>
.dense-expansion-panel {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 1 !important;
    padding-right: 1 !important;
}
</style>
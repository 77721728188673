<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="widgetIcon && !getEditMode && grid_item.instance_setting.data.content==''" :icon="widgetIcon.icon">No content provided</NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <!-- Rich Text Content.  -->
    <div
      class="ql-editor"
      v-html="grid_item.instance_setting.data.content"
    ></div>

  </div>
</template>

<script>
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  components: {WidgetIcon, NoDataWarning,WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getEditMode,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
    } = notebookPropsStore
    return {getEditMode, getGlobalProperty}
  },
  data() {
    return {
      content:"",
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  mounted(){
    this.verifyInstanceSettings();
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods:{
    verifyInstanceSettings() {

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!this.grid_item.instance_setting.data.content) this.grid_item.instance_setting.data.content = this.content;

      if (!this.grid_item._id) {
        
      }

    },
  }
};
</script>
<style scoped>
.ql-editor{
  padding: 8px;
}
</style>


/**
 * List of all possible widgets organized as
 * 'widget details' objects. This array is used
 * to instantiate the widget selectuion UI and 
 * each element in the array must have corisponding 
 * wiget template and stettings .vue files. 
 */

import * as lexicon from "@/utilities/EllipseLexicon.js";

export const widget_categories = {
  Viewers: { name: "Viewers", description: "coming soon" },
  Charts: { name: "Charts", description: "coming soon" },
  Controls: { name: "Controls", description: "coming soon" },
  Readouts: { name: "Status Readouts", description: "coming soon" },
  Static: { name: "Custom Content", description: "coming soon" },
}

export const ui_element_widgets = [
  {
    name: "viewItemList",
    text: "Value List",
    icon: "mdi:mdi-list-box-outline",
    iconsize: 26,
    w: 3,
    h: 18,
    minW: 1,
    minH: 5,
    content: "viewItemList",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#value-list",
    description: "List of all the " + lexicon.entry.plural + " in a " + lexicon.field.single + ".",
  },
  {
    name: "viewDataTable",
    icon: "mdi:mdi-table",
    iconsize: 26,
    text: "Data Table",
    w: 6,
    h: 18,
    minW: 4,
    minH: 5,
    content: "viewDataTable",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#data-table",
    description: "Table of all the " + lexicon.entry.plural + " from a list of " + lexicon.field.plural + ".",
  },
  {
    name: "viewer3d",
    icon: "mdi:mdi-cube-outline",
    iconsize: 26,
    text: "3D Viewer",
    w: 6,
    h: 18,
    minW: 4,
    minH: 5,
    content: "viewer3d",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#id-3d-viewer",
    description: "Interactive 3d Model Viewer.",
  },
  {
    name: "viewer2d",
    icon: "mdi:mdi-floor-plan",
    iconsize: 25,
    text: "2D Viewer",
    w: 6,
    h: 18,
    minW: 4,
    minH: 5,
    content: "viewer2d",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#id-2d-viewer",
    description: "Interactive 3d Model Viewer.",
  },
  {
    name: "viewerMap",
    icon: "mdi:mdi-map",
    iconsize: 25,
    text: "Map Viewer",
    w: 6,
    h: 18,
    minW: 4,
    minH: 5,
    content: "viewerMap",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#map-viewer",
    description: "Interactive GIS Map Viewer.",
  },
  {
    name: "viewImageGrid",
    icon: "fa-solid fa-grip",
    iconsize: 20,
    text: "Image Grid",
    w: 6,
    h: 18,
    minW: 4,
    minH: 8,
    content: "viewImageGrid",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#image-grid",
    description: "A grid of images corresponding to " + lexicon.entry.plural + ".",
  },
  {
    name: "viewFileAttachments",
    icon: "fa-solid fa-file",
    iconsize: 20,
    text: "Attachments",
    w: 6,
    h: 18,
    minW: 4,
    minH: 8,
    content: "viewFileAttachments",
    type: widget_categories.Viewers,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#image-grid",
    description: "A table of file attachments pined to " + lexicon.entry.plural + ".",
  },
  {
    name: "colorBySelector",
    icon: "fa-solid fa-fill-drip",
    iconsize: 20,
    text: "Color By",
    w: 4,
    h: 4,
    minW: 3,
    minH: 4,
    content: "colorBySelector",
    type: widget_categories.Controls,
    hasSettings:false,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#color-by",
    description: "Color " + lexicon.entry.plural + " by the " + lexicon.field.single + " value.",
  },
  {
    name: "viewLegend",
    icon: "mdi:mdi-format-list-bulleted-type",
    iconsize: 26,
    text: "Legend",
    w: 3,
    h: 8,
    minW: 0,
    minH: 3,
    content: "viewLegend",
    type: widget_categories.Readouts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#legend",
    description: "Display the values corresponding to colors when a Color By is applied",
  },
  {
    name: "filterByCheck",
    icon: "mdi:mdi-order-bool-ascending-variant",
    iconsize: 26,
    text: "Check Boxes",
    w: 3,
    h: 18,
    minW: 2,
    minH: 5,
    content: "filterByCheck",
    type: widget_categories.Controls,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#check-boxes",
    description: "Filter " + lexicon.entry.single + " values by " + lexicon.field.single + " with a check box",
  },
  {
    name: "filterByCards",
    icon: "mdi:mdi-wall",
    iconsize: 26,
    text: "Toggle Grid",
    w: 3,
    h: 18,
    minW: 1,
    minH: 5,
    content: "filterByCards",
    type: widget_categories.Controls,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#toggle-grid",
    description: "Filter " + lexicon.entry.single + " values by " + lexicon.field.single + " with a toggle button",
  },
  {
    name: "filterBySelection",
    icon: "mdi:mdi-cursor-default-click ",
    iconsize: 26,
    text: "Select", 
    w: 4,
    h: 3,
    minW: 1,
    minH: 1,
    content: "filterBySelection",
    type: widget_categories.Controls,
    hasSettings:false,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#select",
    description: "Select All / None and Filter / Release Filters on selections",
  },
  {
    name: "filterByRange",
    icon: "fa-solid fa-arrows-left-right-to-line",
    iconsize: 20,
    text: "Range Sliders",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "filterByRange",
    type: widget_categories.Controls,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#filter-status",
    description: "Filter a range of " + lexicon.entry.plural + " in a " + lexicon.field.single + " with a slider.",
  },
  {
    name: "filterByValue",
    icon: "fa-solid fa-sliders",
    iconsize: 20,
    text: "Value Sliders",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "filterByValue",
    type: widget_categories.Controls,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#filter-status",
    description: "Filter a " + lexicon.value.plural + " in a " + lexicon.field.single + " with a slider.",
  },
  {
    name: "filterByDropdown",
    icon: "mdi:mdi-form-select",
    iconsize: 24,
    text: "Dropdown",
    w: 4,
    h: 4,
    minW: 2,
    minH: 4,
    content: "filterByDropdown",
    type: widget_categories.Controls,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#dropdown",
    description: "Filter by unique single " + lexicon.entry.single + " from a " + lexicon.field.single + ".",
  },
  {
    name: "filterBySearch",
    icon: "fas fa-search",
    iconsize: 20,
    text: "Search",
    w: 4,
    h: 4,
    minW: 3,
    minH: 4,
    content: "filterBySearch",
    type: widget_categories.Controls,
    hasSettings:false,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#search",
    description: "Filter by a search term",
  },
  {
    name: "staticRichText",
    icon: "mdi:mdi-text-long",
    iconsize: 20,
    text: "Rich Text",
    w: 4,
    h: 12,
    minW: 2,
    minH: 4,
    content: "staticRichText",
    type: widget_categories.Static,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#rich-text",
    description: "Display custom formatted text",
  },
  {
    name: "staticIframe",
    icon: "mdi:mdi-application-brackets",
    iconsize: 20,
    text: "Embed",
    w: 6,
    h: 18,
    minW: 4,
    minH: 5,
    content: "staticIframe",
    type: widget_categories.Static,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#embed",
    description: "Embed an iframe embed code or link.",
  },
  {
    name: "staticImage",
    icon: "fa-solid fa-image",
    iconsize: 20,
    text: "Image",
    w: 4,
    h: 12,
    minW: 2,
    minH: 5,
    content: "staticImage",
    type: widget_categories.Static,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#image",
    description: "Display an image.",
  },
  {
    name: "staticSpacer",
    icon: "mdi:mdi-rectangle-outline",
    iconsize: 24,
    text: "Spacer",
    w: 4,
    h: 4,
    minW: 1,
    minH: 1,
    content: "staticSpacer",
    type: widget_categories.Static,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#spacer",
    description: "Place a hidden spacer.",
  },
  {
    name: "staticCarousel",
    icon: "fa-solid fa-panorama fa-fw",
    iconsize: 20,
    text: "Carousel",
    w: 4,
    h: 12,
    minW: 2,
    minH: 5,
    content: "staticCarousel",
    type: widget_categories.Static,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#carousel",
    description: "Display a series of images in a carousel.",
  },
  {
    name: "filterByCounter",
    icon: "fa-solid fa-stopwatch-20",
    iconsize: 24,
    text: "Counter",
    w: 3,
    h: 18,
    minW: 1,
    minH: 5,
    content: "filterByCounter",
    type: widget_categories.Readouts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#counter",
    description: "Display the summary values of a " + lexicon.field.single + " grouped by another " + lexicon.field.single + ".",
  },
  {
    name: "displayStatusCount",
    icon: "fa-solid fa-hashtag",
    iconsize: 20,
    text: "Filter Status",
    w: 3,
    h: 6,
    minW: 2,
    minH: 5,
    content: "displayStatusCount",
    type: widget_categories.Readouts,
    hasSettings:false,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#filter-status",
    description: "Display the total number of unfilted " + lexicon.entry.plural + " / total " + lexicon.entry.plural + ".",
  },
  {
    name: "displayAttributes",
    icon: "mdi:mdi-tag-text-outline",
    iconsize: 20,
    text: "Attributes",
    w: 3,
    h: 18,
    minW: 1,
    minH: 5,
    content: "displayAttributes",
    type: widget_categories.Readouts,
    hasSettings:false,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#attributes",
    description: "Display the attributes for a selected " + lexicon.entry.single + " or multiple " + lexicon.entry.plural + ".",
  },
  {
    name: "displayStatistics",
    icon: "mdi:mdi-file-chart-outline",
    iconsize: 20,
    text: "Page State",
    w: 3,
    h: 18,
    minW: 1,
    minH: 5,
    content: "displayStatistics",
    type: widget_categories.Readouts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#page-state",
    description: "Display top level summaries for the state of the " + lexicon.page.single + ".",
  },
  {
    name: "displayScoreCard",
    icon: "mdi:mdi-scoreboard",
    iconsize: 20,
    text: "Score Card",
    w: 3,
    h: 6,
    minW: 2,
    minH: 5,
    content: "displayScoreCard",
    type: widget_categories.Readouts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#score-card",
    description: "Display a top level metric for a " + lexicon.field.single + ".",
  },
  {
    name: "displayFilterStatus",
    icon: "mdi:mdi-filter-multiple",
    iconsize: 20,
    text: "Filter List",
    w: 3,
    h: 18,
    minW: 1,
    minH: 5,
    content: "displayFilterStatus",
    type: widget_categories.Readouts,
    hasSettings:true,
    documentation:"",
  },
  {
    name: "chartBar",
    icon: "mdi:mdi-chart-bar-stacked",
    iconsize: 20,
    text: "Bar Chart",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "chartBar",
    type: widget_categories.Charts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#bar-chart",
    description: "Plot the value of a numeric " + lexicon.field.single + " grouped by a text " + lexicon.field.single + ".",
  },
  {
    name: "chartDonut",
    icon: "mdi:mdi-chart-donut",
    iconsize: 20,
    text: "Donut Chart",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "chartDonut",
    type: widget_categories.Charts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#donut-chart",
    description: "Plot the ratio of a " + lexicon.field.single + " grouped by the values of another " + lexicon.field.single + ".",
  },
  {
    name: "chartHistogram",
    icon: "mdi:mdi-chart-histogram",
    iconsize: 20,
    text: "Histogram",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "chartHistogram",
    type: widget_categories.Charts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#histogram",
    description: "Plot a numeric " + lexicon.field.single + "'s values grouped by the quantities that fall within a sub domain.",
  },
  {
    name: "chartLine",
    icon: "mdi:mdi-chart-line",
    iconsize: 20,
    text: "Line Chart",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "chartLine",
    type: widget_categories.Charts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#line-chart",
    description: "Plot a list of " + lexicon.field.plural + " in the Y axis ordered by a " + lexicon.field.single + " in the X axis.",
  },
  {
    name: "chartParallel",
    icon: "mdi:mdi-chart-sankey",
    iconsize: 20,
    text: "Parallel Coord",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "chartParallel",
    type: widget_categories.Charts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#parallel-coord",
    description: "Plot a list of " + lexicon.field.plural + " to columns connected by a line for each correlated " + lexicon.entry.plural + ".",
  },
  {
    name: "chartScatter",
    icon: "mdi:mdi-chart-scatter-plot",
    iconsize: 20,
    text: "Scatter Chart",
    w: 3,
    h: 22,
    minW: 2,
    minH: 5,
    content: "chartScatter",
    type: widget_categories.Charts,
    hasSettings:true,
    documentation:"https://core-studio.gitbook.io/ellipse/getting-started/widgets#scatter-chart",
    description: "Plot the relationship between two " + lexicon.field.plural + " in a cartesian grid.",
  },
];

export const defaultWidget = {
  name: "unknown",
  icon: "mdi:mdi-view-dashboard",
  iconsize: 24,
  text: "Unknown",
  w: 2,
  h: 4,
  minW: 2,
  minH: 4,
  content: "unknown",
  type: widget_categories.Static,
  hasSettings:true,
  documentation:"",
  description:"",
};

/**
 * Creates a generic base widget.
 * @returns {} single widget object. 
 */
export const genericNewWidget = function (){
  return {
    x:0,
    y:0,
    w:0, 
    h:0,
    minW:0,
    minH:0,
    content:"",
    hasSettings:false,
    documentation:"",
    description:"",
    i: null,
    local_setting:{
      isLocked:false,
      foreground_color:"#FFFFFF",
    },
    instance_setting:{
      data:null,
      isPanelWidget:false,
    },
    new:true,
    page_state:null
  };
};

/**
 * Returns a widgets Icon based on it's name. 
 * TODO should be based on 'e.content' not 'e.name'
 */
export const returnWidgetDetails = function (widgetName) {
  let result = ui_element_widgets.find((e) => e.name === widgetName);
  if(!result) return defaultWidget;
  return result;
};

/**
 * Returns a widgets Name based on it's content. 
 * TODO should be based on 'e.content' not 'e.name'
 */
export const returnWidgetName = function (widgetName) {
  let result = ui_element_widgets.find((e) => e.name === widgetName);
  if(!result) return "unknown";
  return result;
};

/**
 * Gets the ui_element_widgets widget object based on contentName.
 * @returns {} single widget details object. 
 */
export const getWidgetDetails= function (contentName){
  let generalWidgetInfo = ui_element_widgets.find(
      (e) => e.content === contentName
  );
  return generalWidgetInfo;
};

/**
 * Creates a generic widget based on type. 
 * @param {[String]} widgetType - widget content string
 * @returns {} - Reruns a single Widget Object of the content types provided. 
 */
export const createWidget= function(widgetTypes){
  return {...this.genericNewWidget(), ...this.getWidgetDetails(widgetTypes)};
};

/**
 * Creates a set of widgets based on type. 
 * @param {[String]} widgetTypes - array of widget content string's
 * @returns {[{}]} - Reruns an array of Widget Objects of the types provided. 
 */
export const createWidgets= function(widgetTypes){
  let newWidgets = widgetTypes.map((type)=>{
    return {...this.genericNewWidget(), ...this.getWidgetDetails(type)}
  })
  return newWidgets;
};

export default {};

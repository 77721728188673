<template>
  <div class="sidePanelMargin">
    <!-- Pages Tab Headder Bar. -->
    <div class="sidePanelHeader">
      <div class="sidePanelHeaderInner">
        <!-- Headder. -->
        <span class="header">Pages</span>

        <!-- Pages Tab Info Popup. -->
        <v-menu offset-y :close-on-content-click="false" open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn size="x-small" variant="plain" icon v-bind:href="lexicon.page.documentation" target="_blank">
              <v-icon v-bind="props" size="16" color="secondary">{{ icons.information }}</v-icon>
              <v-tooltip activator="parent" location="top"> Page State Information</v-tooltip>
            </v-btn>
          </template>

          <!-- Popup Key Table for Page Icons. -->
          <v-card>
            <v-table density="compact">
              <tbody style="font-size:12px">
                <tr class="text-center">
                  <td style="width: 50px" class="font-weight-medium">
                    Page States
                  </td>
                  <td class="font-weight-medium">Owned</td>
                  <td class="font-weight-medium">Shared</td>
                </tr>
                <tr class="text-center">
                  <td style="width: 50px">Unpublished</td>
                  <td>
                    <v-icon size="20" color="primary">{{ lexicon.page.iconUnPublished}}</v-icon>
                      <v-tooltip location="top" activator="parent"><span>Private page you own, not visible to others</span></v-tooltip>
                  </td>
                  <td>
                      <v-tooltip location="top" activator="parent"><span>Private page someone else owns, not visible to others</span></v-tooltip>
                    <v-icon size="20" color="darkSlate">{{ lexicon.page.iconUnPublished}}</v-icon>
                  </td>
                </tr>
                <tr class="text-center">
                  <td style="width: 50px">Published</td>
                  <td>
                      <v-tooltip location="top" activator="parent"><span>Public page you own, visible to everyone</span></v-tooltip>
                    <v-icon size="20" color="primary">{{ lexicon.page.iconPublished}}</v-icon>
                  </td>
                  <td>
                      <v-tooltip location="top" activator="parent"><span>Public page someone else owns, visible to everyone</span></v-tooltip>
                    <v-icon size="20" color="darkSlate">{{ lexicon.page.iconPublished}}</v-icon>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card>
        </v-menu>
      </div>

      <!-- Add Section Button. -->
      <div>
        <v-btn :disabled="getNotebook.userPermissions.permissions <= 100" size="small" color="primary" class="mt-1" :show="true" @click="clickOnAddPage()">
          <v-icon small>{{ lexicon.page.iconAdd }}</v-icon>&nbsp; Add Page
        </v-btn>
      </div>
    </div>

    <!-- Header Divider. -->
    <v-divider class="divider"></v-divider>

    <!-- 0th Parent Page Divier -->
    <div
      id="P0"
      class="pageDivider"
      :class="currentDividerIndex == 'P0' ? 'over' : ''"
      @dragenter="dividerDragEnter"
      @dragover="dividerDragOver"
      @dragleave="dividerDragLeave"
      @drop="dividerDrop" 
    ></div>

    <!-- Parent Pages -->
    <template v-for="(page, parentIndex) in pages" :key="parentIndex">
      <div             
          class="pageWrapper"
          :id="`P${parentIndex}`"
          :draggable="page.createdBy.user._id == getUser._id"
          @dragstart="handleDragStart"
          @dragenter="handleDragEnter"
          @dragover="handleDragOver"
          @dragleave="handleDragLeave"
          @drop="handleDrop"
          @dragend="handleDragEnd">
        <Page 
          :page="page"
          :isExpanded="isExpanded[parentIndex]"
          @toggleExpand="pageExpandToggled(parentIndex)"
        >
          {{ page.parent.name }}
        </Page>
      </div>
      
      <!-- Children Pages -->
      <template v-if="page.childNodes && page.childNodes.length > 0 && isExpanded[parentIndex]">
        <!-- Child Page Dividers -->
        <div
          :id="`P${parentIndex}-C0`"
          class="pageDivider nested"
          :class="currentDividerIndex == `P${parentIndex}-C0` ? 'over' : ''"
          @dragenter="dividerDragEnter"
          @dragover="dividerDragOver"
          @dragleave="dividerDragLeave"
          @drop="dividerDrop"
        ></div>

        <template v-for="(child, childIndex) in page.childNodes" :key="childIndex"> 
          <div
            class="pageWrapper nested"
            :id="`P${parentIndex}-C${childIndex}`"
            :draggable="page.createdBy.user._id == getUser._id"
            @dragstart="handleDragStart"
            @dragenter="handleDragEnter"
            @dragover="handleDragOver"
            @dragleave="handleDragLeave"
            @drop="handleDrop"
            @dragend="handleDragEnd"
          >
            <Page :page="child" @toggleExpand="pageExpandToggled(parentIndex)">
              {{child.name}}
            </Page>
          </div>
        
          <!-- Child Page Dividers -->
          <div
            :id="`P${parentIndex}-C${childIndex+1}`"
            class="pageDivider nested"
            :class="currentDividerIndex == `P${parentIndex}-C${childIndex+1}` ? 'over' : ''"
            @dragenter="dividerDragEnter"
            @dragover="dividerDragOver"
            @dragleave="dividerDragLeave"
            @drop="dividerDrop"
          ></div>
        </template>
      </template>

      <!-- Parent Page Dividers -->
      <div
        :id="`P${parentIndex+1}`"
        class="pageDivider"
        :class="currentDividerIndex == `P${parentIndex+1}` ? 'over' : ''"
        @dragenter="dividerDragEnter"
        @dragover="dividerDragOver"
        @dragleave="dividerDragLeave"
        @drop="dividerDrop"
      ></div>
    </template>
    
  </div>

  <!-- Create New Page Popup Dialog. -->
  <EditData 
    :dialog="dialog"
    @toggle="dialog = !dialog"
    @actionClicked="dialogAction"
    :actionName="'Add'"
    :textLabel="'Page Name'"
    :loading="dialogLoading"
  >{{dialogTitle}}
  </EditData>
  
  <!-- Success Message. -->
  <v-snackbar 
    v-model="snackbar" 
    timeout="2000" 
    location="bottom" 
    color="primary"
    >
    <div class="text-center">
      {{ snackbarMessage }}
    </div>
  </v-snackbar>

</template>

<script>
import PageSharing from "../components/popUps/PageSharing.vue";
import draggable from "vuedraggable";
import PillButton from "../components/ui/PillButton.vue";
import ProgressCircular from "../components/ui/ProgressCircular.vue";
import Page from "../components/ui/Page.vue"
import EditData from "@/components/popUps/EditData.vue"
import * as utils from "@/utilities";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: {
    PageSharing,
    draggable,
    PillButton,
    ProgressCircular,
    Page,
    EditData
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const assetsStore = useAssetsStore()
    const ellipseStore = useEllipseStore()
    const {
      getGlobalProperty,
      getPagesStructuredData,
      getAllPageStates,
      getWidgets,
    } = storeToRefs(notebookPropsStore)
    const {
      getSelectedLayoutId,
      getUser,
      getNotebook
    } = storeToRefs(ellipseStore)
    const {
      getSelectedPageDrawings,
      getSelectedPageModels,
    } = storeToRefs(assetsStore)
    const {
      setWidgets,
      setGlobalProperty,
      setSelectedPage,
      setPagesStructuredData,
      setLoadingScreen
    } = notebookPropsStore
    const {
      setAllAssetsInNotebook,
      setSelectedPageDataset
    } = assetsStore
    const {
      setSelectedLayoutId
    } = ellipseStore
    return {notebookPropsStore, assetsStore,
      getGlobalProperty, getSelectedPageDrawings, getSelectedPageModels, getPagesStructuredData,
      getAllPageStates, getNotebook, getWidgets, getSelectedLayoutId, getUser,
      setWidgets, setGlobalProperty, setAllAssetsInNotebook, setSelectedPage,  
      setSelectedLayoutId, setPagesStructuredData, setLoadingScreen, setSelectedPageDataset, lexicon, messages, icons}
  },
  data() {
    return {
      pages: null,
      currentDividerIndex: null,
      draggedItemIndex: null,
      pageDropNotAllowed: false,
      dividerDropNotAllowed: false,

      snackbar: false,
      snackbarMessage: "",

      dialog: false,
      dialogTitle: "",
      dialogAction: null,
      dialogActionName: "",
      dialogTextLabel: "",
      dialogLoading: false,

      isExpanded: {},

      draggedItemType: null,
      draggedParentIndex: null,
      draggedChildIndex: null,
      draggedPageId: null
    };
  },
  computed: {},
  created() {
    this.selectedPageId = this.$route.params.pageId;
  },
  async mounted() { 
    this.pages = this.getPagesStructuredData
    this.notebookPropsStore.$onAction(({name, after}) => {
      after(() => {
        if (name == 'setPagesStructuredData'){
          this.pages = this.getPagesStructuredData
        }
      })
    })
  },
  methods: {
    handleDragStart(e) {
      e.currentTarget.style.opacity = '0.4';
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.currentTarget.innerHTML);
      this.draggedItemIndex = e.srcElement.id;
      this.draggedItemType = this.draggedItemIndex.includes('C') ? 'child' : 'parent'
      this.draggedParentIndex =this. draggedItemIndex.match(/P(\d+)/)[0][1]
      this.draggedChildIndex = this.draggedItemIndex.includes('C') ? this.draggedItemIndex.match(/C(\d+)/)[0][1] : null
      this.draggedPageId = this.draggedItemType == 'parent' ? 
        this.pages[this.draggedParentIndex]._id : 
        this.pages[this.draggedParentIndex].childNodes[this.draggedChildIndex]._id
    },
    handleDragEnter(e) {
      e.preventDefault()
      e.stopPropagation()
     
     //Prevent dropping item on itself
     if (this.draggedItemIndex == e.currentTarget.id) {
      this.pageDropNotAllowed = true
      return 
     }

      //Prevent dropping item on child page
      let draggedPageHasChildren = this.pages[this.draggedParentIndex].childNodes && this.pages[this.draggedParentIndex].childNodes.length > 0
      let destinationIsChild = e.currentTarget.id.includes('C')

      if ((this.draggedItemType == 'parent' && draggedPageHasChildren) || destinationIsChild){
        this.pageDropNotAllowed = true
        return
      }

      this.pageDropNotAllowed = false
      e.currentTarget.classList.add('over');
    },
    handleDragOver(e) {
      // Necessary. Allows us to drop.
      e.preventDefault ? e.preventDefault() : '' 
      e.stopPropagation ? e.stopPropagation() : '' 
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragLeave(e) {
      e.preventDefault ? e.preventDefault() : '' 
      e.stopPropagation ? e.stopPropagation() : '' 

      if (this.draggedItemIndex == e.currentTarget.id) return 

      e.currentTarget.classList.remove('over');
      e.dataTransfer.dropEffect = 'move'; 
      this.pageDropNotAllowed = false
    },
    handleDragEnd(e) {
      e.target.style.opacity = '1';
    },
    async handleDrop(e) {
      e.preventDefault ? e.preventDefault() : '' 
      e.stopPropagation ? e.stopPropagation() : '' 

      if (this.pageDropNotAllowed) {
        this.pageDropNotAllowed = false
        e.currentTarget.classList.remove('over');
        return 
      }

      // Determine the type and indices of the destination
      let destinationId = e.currentTarget.id
      let destinationItemType = destinationId.includes('C') ? 'child' : 'parent'
      let destinationParentIndex = destinationId.match(/P(\d+)/)[0][1]

      //Reorder pages
      if (this.draggedItemType == 'parent'){
        let pageToMove = this.pages.splice(this.draggedParentIndex, 1)[0]
        let insertionIndex = this.pages[destinationParentIndex] ? destinationParentIndex : destinationParentIndex-1
        pageToMove.parent = this.pages[insertionIndex]._id
        if (destinationItemType == 'parent'){
          let pageId = this.pages[this.draggedParentIndex]
          if (this.isExpanded[pageId]) delete this.isExpanded[pageId]
          this.pages[insertionIndex].childNodes.push(pageToMove)
        }
      }
      if (this.draggedItemType == 'child'){
        let pageToMove = this.pages[this.draggedParentIndex].childNodes.splice(this.draggedChildIndex, 1)[0]
        if (destinationItemType == 'parent'){
          pageToMove.parent = null
          if (this.pages[destinationParentIndex].childNodes){
            this.pages[destinationParentIndex].childNodes.push(pageToMove)
          } else {
            this.pages[destinationParentIndex].childNodes = [pageToMove]
          }
        }
      }
      e.currentTarget.classList.remove('over')
      this.recalcPageOrderNumbers()
      let flattenedPages = this.flattenPages()
      let updatePageOrders = await this.$auth.$api.post(
        `/api/pages/update-orders`,
        {pages: flattenedPages}
      );
      this.snackbarMessage = "Page Order Updated"
      this.snackbar = true

      this.draggedItemIndex = null;
      this.draggedItemType = null
      this.draggedParentIndex = null
      this.draggedChildIndex = null
      this.draggedPageId = null
    },
    
    dividerDragEnter(e) {
      let currentDividerIndex = e.currentTarget.id
      let destinationItemType = currentDividerIndex.includes('C') ? 'child' : 'parent'
      let destinationParentIndex = currentDividerIndex.match(/P(\d+)/)[0][1]
      let destinationChildIndex = currentDividerIndex.includes('C') ? currentDividerIndex.match(/C(\d+)/)[0][1] : null

      //Check if the item can be dropped on the given divider
      //We don't allow item to be dropped on a divider that it right next to the page because it wouldn't change page order
      if (this.draggedItemType == 'parent'){
        if (destinationItemType == 'parent'){
          if (destinationParentIndex > this.draggedParentIndex){
            if (destinationParentIndex - this.draggedParentIndex < 2) {
              this.dividerDropNotAllowed = true
            }
          }
          if (destinationParentIndex <= this.draggedParentIndex){
            if (this.draggedParentIndex - destinationParentIndex < 1){
              this.dividerDropNotAllowed = true
            }
          }
        }
        if (destinationItemType == 'child'){
          if (this.pages[this.draggedParentIndex].childNodes && this.pages[this.draggedParentIndex].childNodes.length > 0) {
            this.dividerDropNotAllowed = true
          }
        }
      }
      if (this.draggedItemType == 'child'){
        if ((this.draggedParentIndex == destinationParentIndex) && destinationItemType == 'child'){
          
          if (destinationChildIndex > this.draggedChildIndex){
            if (destinationChildIndex - this.draggedChildIndex < 2) {
              this.dividerDropNotAllowed = true
            }
          }
          if (destinationChildIndex <= this.draggedChildIndex){
            if (this.draggedChildIndex - destinationChildIndex < 1){
              this.dividerDropNotAllowed = true
            }
          }
        }
      }

      if (this.dividerDropNotAllowed) return 
      e.currentTarget.classList.add('over');
      this.currentDividerIndex = e.currentTarget.id
    },
    dividerDragOver(e) {
      e.preventDefault();
    },
    dividerDragLeave(e) {
      e.currentTarget.classList.remove('over');
      this.currentDividerIndex = null;
      this.dividerDropNotAllowed = false
    },
    async dividerDrop(e) {
      if (this.dividerDropNotAllowed) {
        e.currentTarget.classList.remove('over');
        this.dividerDropNotAllowed = false
        return 
      }

      // Determine the type and indices of the destination
      let destinationItemType = this.currentDividerIndex.includes('C') ? 'child' : 'parent'
      let destinationParentIndex = this.currentDividerIndex.match(/P(\d+)/)[0][1]
      let destinationChildIndex = this.currentDividerIndex.includes('C') ? this.currentDividerIndex.match(/C(\d+)/)[0][1] : null

      //Update pages order
      if (this.draggedItemType == 'parent') {
        let pageInsertionIndex
        if (destinationItemType == 'parent'){
          if (destinationParentIndex > this.draggedParentIndex) pageInsertionIndex = destinationParentIndex - 1
          if (destinationParentIndex < this.draggedParentIndex) pageInsertionIndex = destinationParentIndex
          if (pageInsertionIndex){
            if (pageInsertionIndex != this.draggedParentIndex){
              let pageToMove = this.pages.splice(this.draggedParentIndex, 1)[0]
              this.pages.splice(pageInsertionIndex, 0, pageToMove)
            }
          }
        }
        if (destinationItemType == 'child'){
          let pageId = this.pages[this.draggedParentIndex]
          if (this.isExpanded[pageId]) delete this.isExpanded[pageId]
          let pageToMove = this.pages.splice(this.draggedParentIndex, 1)[0]
          let insertionIndex = this.pages[destinationParentIndex - 1] ? destinationParentIndex - 1 : destinationParentIndex 
          pageToMove.parent = this.pages[insertionIndex]._id
          this.pages[insertionIndex].childNodes.splice(destinationChildIndex, 0, pageToMove)
        }
      }
      if (this.draggedItemType == 'child'){
        //Drag child into a parent position
        if (destinationItemType == 'parent'){
          let pageToMove = this.pages[this.draggedParentIndex].childNodes.splice(this.draggedChildIndex, 1)[0]
          pageToMove.parent = null
          this.pages.splice(destinationParentIndex, 0, pageToMove)
        }
        if (destinationItemType == 'child'){
          let pageToMove = this.pages[this.draggedParentIndex].childNodes.splice(this.draggedChildIndex, 1)[0]
          //Moving child page from one parent to another parent
          if (this.draggedParentIndex != destinationParentIndex){
            pageToMove.parent = this.pages[destinationParentIndex]._id
            this.pages[destinationParentIndex].childNodes.splice(destinationChildIndex, 0, pageToMove)
          } 
          //Moving child page within same parent
          else {
            let pageInsertionIndex
            if (destinationChildIndex > this.draggedChildIndex) pageInsertionIndex = destinationChildIndex - 1
            if (destinationChildIndex < this.draggedChildIndex) pageInsertionIndex = destinationChildIndex
            if (pageInsertionIndex){
              this.pages[destinationParentIndex].childNodes.splice(pageInsertionIndex, 0, pageToMove)
            }
          }
        }
      }
      e.currentTarget.classList.remove('over');
      this.recalcPageOrderNumbers()
      let flattenedPages = this.flattenPages()
      let updatePageOrders = await this.$auth.$api.post(
        `/api/pages/update-orders`,
        {pages: flattenedPages}
      );

      this.currentDividerIndex = null;
      this.draggedItemIndex = null;
      this.draggedItemType = null
      this.draggedParentIndex = null
      this.draggedChildIndex = null
      this.draggedPageId = null
      this.snackbarMessage = "Page Order Updated"
      this.snackbar = true
    },
    recalcPageOrderNumbers(){
      this.pages.forEach((page, index) => {
        page.order = this.pages.length - index
        page.childNodes.forEach((childPage, index) => {
          childPage.order = page.childNodes.length - index
        })
      });
    },
    flattenPages(){
      //When updating page order, `/api/pages/update-orders` only takes a flattened list of pages
      let flattenedPages = []
      this.pages.forEach(page => {
        flattenedPages.push(page)
        page.childNodes.forEach(childPage => flattenedPages.push(childPage))
      })
      return flattenedPages
    },
    pageExpandToggled(pageIndex){
      if (!this.isExpanded[pageIndex]) this.isExpanded[pageIndex] = true
      else this.isExpanded[pageIndex] = false
    },
    async clickOnAddPage() {
      this.dialog = true;
      this.dialogTitle = "Create New Page"
      this.dialogAction = this.createNewPage
      this.dialogActionName = "Add"
      this.dialogTextLabel = "Page Name"
    },
    async createNewPage(newPageName) {
      this.dialogLoading = true;
      let postData = {
        name: newPageName,
        clone: false,
        parent: null,
        notebook: this.$route.params.id,
      };
      let newPageOrSection = await this.$auth.$api.post(`/api/pages/new`, postData);
      utils.emptyPreviousState();
      await this.setPagesStructuredData(this.$route.params.id);
      await this.clickOnNewPage(newPageOrSection.data.data);
      this.dialogLoading = false;
      this.dialog = false;
      this.dialogTitle = ""
    },
    async clickOnNewPage(newPage) {
      let pageType = this.$route.name;
      this.$router
        .push("/notebook/" + this.getNotebook._id + '/' + pageType + '/' + newPage._id)
        .catch((err) => {});
      this.selectedPageId = newPage._id;
      await utils.clickOnPageFunction(newPage);
    },
  },
};
</script>

<style scoped>

.pageWrapper {
  cursor: pointer;
  position: relative;
}
.pageWrapper.over {
  border-radius: 5px;
  border: 2px solid rgb(var(--v-theme-primary));
}
.pageDivider {
  height: 15px;
  background: white;
}
.nested {
  width: 85%;
  margin-left: auto
}
.pageDivider.over {
  height: 20px; 
  background: linear-gradient(to bottom, transparent 8px, rgb(var(--v-theme-primary)) 8px, rgb(var(--v-theme-primary)) 11px, transparent 11px);
}
</style>

<template>

    <div>
        <v-container>    
    </v-container>
    </div>

</template>

<script>

export default {
  components: {  },
    props: {
        notebook: {},
        getSelectedWidget: {}
    },
    data: () => ({
    }),
    created() {
    },
    mounted() {
        // console.log("Data", this.getSelectedWidget.instance_setting.data);
    },
    methods: {
        
    }
}
</script>

<style scoped>

</style>
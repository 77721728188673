<template>
  <div class="widgetPadding pa-0">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner :show="(updatingWidgets || getDatasetLoading) && notbookHasDrawings"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning
      :show="widgetIcon && grid_item.instance_setting.data && grid_item.instance_setting.data.assets.length === 0"
      :icon= messages.widgetEmpty.icon>{{ messages.widgetEmpty.memo }}{{lexicon.map.plural}}</NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>
        
      <!-- Zoom Controls Overlay-->
      <div class="d-flex align-self-center align-self-end zoomsStyle" v-if="grid_item.instance_setting">
        <ViewerControls :showZoom="grid_item.instance_setting.data.uiSettings.showZoom"
          :showFullScreen="grid_item.instance_setting.data.uiSettings.showFullscreen"
          :showMultiSelect="grid_item.instance_setting.data.uiSettings.showMultiSel"
          :showDownloads="grid_item.instance_setting.data.uiSettings.showDownloads"
          :showLayers="grid_item.instance_setting.layers && 
            grid_item.instance_setting.data.uiSettings.showLayers"
              :layers="grid_item.instance_setting.layers.layers"  
          v-on:zoomExtents="zoomExtents()"
          v-on:zoomSelected="zoomSelected()" 
          v-on:zoomByPercentage="zoomByPercentage($event)" 
          v-on:download="downloadObj()"
          v-on:fullscreen="toggleFullScreen()" 
          v-on:multiSelect="multiSelectClick()"
          v-on:layersUpdated="layersUpdated($event)"
          v-on:layersLocked="layersLocked($event)" 
          />
      </div>  

    <!-- MAP. -->
    <div
      ref="viewerMap"
      :id="mapId()"
      style="height: 100%; width:100%"
    >
    </div>

    
  </div>
</template> 

<script>
  import mapboxgl from "mapbox-gl";
  import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
  import Spinner from "../../components/ui/Spinner.vue";
  import NoDataWarning from "../../components/ui/NoDataWarning.vue";
  import WidgetIcon from "../../components/ui/WidgetIcon.vue";
  import WidgetTitle from "../../components/ui/WidgetTitle.vue";
  import bus from "../../plugins/bus.js";
  
  import ViewerControls from "../viewerSettings/ViewerControls.vue";

  import * as lexicon from "@/utilities/EllipseLexicon.js";
  import * as messages from "@/utilities/EllipseMessages.js";

  import { storeToRefs } from 'pinia';
  import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
  import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
  import {useAssetsStore } from "@/store/AssetsStore.js";
  import {useEllipseStore } from "@/store/EllipseStore.js";
  import { MapStyleManager } from '@/utilities/MapStyleManager';
  
  export default {
    props: ["grid_item"],
    setup() {
      const notebookPropsStore = useNotebookPropsStore()
      const dataGraphicsStore = useDataGraphicsStore()
      const assetsStore = useAssetsStore()
      const ellipseStore = useEllipseStore()
      const {
        getEditMode,
        getHighlightedElements,
        getSelectedElements,
        getGlobalProperty,
        getDatasetLoading
      } = storeToRefs(notebookPropsStore)
      const {
        getColorByData,
        getLabelByData,
        getFilterByData,
        getAttrData,
        getAttrHeaders,
        getAttrHeadersCategorical
      } = storeToRefs(dataGraphicsStore)
      const {
        getSelectedPageMaps
      } = storeToRefs(assetsStore) 
      const {
        getNotebook
      } = storeToRefs(ellipseStore)
      const {
        setHighlightedElements,
        addToSelectedElements,
        removeFromSelectedElements,
      } = notebookPropsStore
  
      return {
        assetsStore,
        dataGraphicsStore,
        notebookPropsStore, 
        getEditMode, 
        getDatasetLoading,
        getGlobalProperty,
        getHighlightedElements,
        getSelectedElements,
        getNotebook,
        getColorByData,
        getLabelByData,
        getFilterByData,
        getAttrData,
        getAttrHeaders,
        getAttrHeadersCategorical,
        getSelectedPageMaps,
        setHighlightedElements,
        addToSelectedElements,
        removeFromSelectedElements,
        lexicon,
        messages
      }
    },
    data() {
      return {
        updatingWidgets: false,
        isFullScreen:false,
        selectedColor: "#808080",
        colorByWidth:0,
        mouseSensitivity:1,
        labelSize:12,
        showFullscreen: true,
        showDownloads: false,
        showLayers: true,
        showModels: true,
        showZoom: true,
        showMultiSel: false,
        multiSelectActive: false,
        map:null,
        ResizeObserver:null,
        selectedFeatures: [],
        MapStyleManager:null,
      };
    },
    components: {
      ViewerControls,
      Spinner,
      NoDataWarning,
      WidgetIcon,
      WidgetTitle
    },
    computed: {
      widgetIcon() {
        let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
        return result;
      },
      instanceAssets() {
        if (!this.grid_item.instance_setting.data) return [];
        return this.grid_item.instance_setting.data.assets;
      },
      layers(){
        if(!this.grid_item.instance_setting.data.layers) return [];
        return this.grid_item.instance_setting.layers.layers;
      }
    },
    async created() {
      this.verifyInstanceSettings();
      this.registerEventListeners();
    },
    async mounted() {
      this.verifyInstanceSettings();
      this.createStyleManager();
      await this.createMapbox();
      await this.loadAllLayers();
      await this.syncAssetsToLayers().then(()=>{
        if(this.map){
          this.onNewColorByUpdated();
          this.onNewLabelByUpdated();
          this.onNewFilterByUpdated();
        }
      });


      //Listen for changes to the notebookPropsStore
      this.notebookPropsStore.$onAction(({name, args}) => {
        if (name === 'setHighlightedElements') {
          this.onNewElementHighlighted()
        }
        if (name === 'setGlobalHighlightColor'){
          if(this.MapStyleManager){
            this.MapStyleManager.setHighlightColor(args[0])
          }
        }
        if (name === 'setGlobalSelectionColor'){
          if(this.MapStyleManager){
            this.MapStyleManager.setSelectedColor(args[0])
          }
        }
        if (name === 'setGlobalSignificantDigits'){
          //this.globalSignificantDigitsEvent(args[0])
        }
      })
      //Listen for changes to the dataGraphicsStore
      this.dataGraphicsStore.$onAction(({name, after}) => {
        after(() => {
          if (name === 'updateColorByData') {
            if(this.map){
              this.onNewColorByUpdated()
            }
            console.log('map viewer colorby triggered')
          }
          if (name === 'updateLabelByProperties') {
            if(this.map){
              this.onNewLabelByUpdated()
            }
          }
          if (name === 'updateFilterByProperties') {
            if(this.map){
              this.onNewFilterByUpdated();
            }
          }
        })
      })
      this.assetsStore.$onAction(({name, after}) => {
        after(() => {
          if (name === 'setSelectedPageDataset') {
            //this.datasetUpdateEvent()
          }
        })
      })
    },
    beforeUnmount() {
      this.removeEventListeners();

      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      if (this.map) {
        this.map.remove();
      }
    },
    watch:{
      // "grid_item.instance_setting.data.colorByWidth": function() {
      //   if(viewer2DInstances[this.grid_item.i]) this.updateColorByParameters();
      // },
      // "grid_item.instance_setting.data.labelSize": function() {
      //   if(viewer2DInstances[this.grid_item.i]) viewer2DInstances[this.grid_item.i].updateAllLabels(this.grid_item.instance_setting.data.labelSize);
      // },
      getSelectedElements: {
        deep: true,
        handler() {
          if(this.map){
            this.onNewSelectedElements();
          }
        }
      },
      instanceAssets: {
        deep: true,
        immediate: true,
        handler() {
          this.assetRemovedFromWidgetEvent()
        }
      },
    },
    methods: {
      verifyInstanceSettings() {
  
        if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
  
        if (!this.grid_item.instance_setting.data.displaySettings)this.grid_item.instance_setting.data.displaySettings = {};
        if (!this.grid_item.instance_setting.data.colorByWidth) this.grid_item.instance_setting.data.colorByWidth = this.colorByWidth;
        if (!this.grid_item.instance_setting.data.mouseSensitivity) this.grid_item.instance_setting.data.mouseSensitivity = this.mouseSensitivity;
        if (!this.grid_item.instance_setting.data.labelSize) this.grid_item.instance_setting.data.labelSize = this.labelSize;
        if (!this.grid_item.instance_setting.data.zoom) this.grid_item.instance_setting.data.zoom = 16;
        if (!this.grid_item.instance_setting.data.latitude) this.grid_item.instance_setting.data.latitude = 40.708397;
        if (!this.grid_item.instance_setting.data.longitude) this.grid_item.instance_setting.data.longitude =-74.011581;
        if (!this.grid_item.instance_setting.layers) this.grid_item.instance_setting.layers = {layerBy: 'geojsons', layers: []}
        if (!this.grid_item.instance_setting.layers.layers) this.grid_item.instance_setting.layers = {layerBy: 'geojsons', layers: []}
  
        if (!this.grid_item.instance_setting.data.uiSettings) this.grid_item.instance_setting.data.uiSettings = {};
        if (!('showFullscreen' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showFullscreen = this.showFullscreen;
        if (!('showDownloads' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showDownloads = this.showDownloads;
        if (!('showLayers' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showLayers = this.showLayers;
        if (!('showModels' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showModels = this.showModels;
        if (!('showZoom' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showZoom = this.showZoom;
        if (!('showMultiSel' in this.grid_item.instance_setting.data.uiSettings)) this.grid_item.instance_setting.data.uiSettings.showMultiSel = this.showMultiSel;
  
        if (!this.grid_item.instance_setting.data.assets) {
          this.grid_item.instance_setting.data.assets = JSON.parse(JSON.stringify(this.getSelectedPageMaps))
        }
          
        this.colorByWidth=this.grid_item.instance_setting.data.colorByWidth;
        this.mouseSensitivity=this.grid_item.instance_setting.data.mouseSensitivity;
        this.labelSize=this.grid_item.instance_setting.data.labelSize;      
        this.showFullscreen=this.grid_item.instance_setting.data.uiSettings.showFullscreen;
        this.showDownloads=this.grid_item.instance_setting.data.uiSettings.showDownloads;
        this.showLayers=this.grid_item.instance_setting.data.uiSettings.showLayers;
        this.showModels=this.grid_item.instance_setting.data.uiSettings.showModels;
        this.showZoom=this.grid_item.instance_setting.data.uiSettings.showZoom;
        this.showMultiSel=this.grid_item.instance_setting.data.uiSettings.showMultiSel;
  
      },
      registerEventListeners(){
        bus.on("set-map-center", this.setMapCenter)
      },
      removeEventListeners(){
        bus.off("set-map-center", this.setMapCenter)
      },
      handleResize() {
        if (this.map) {
          this.map.resize();
        }
      },
      createStyleManager(){
        this.MapStyleManager = new MapStyleManager();
        this.MapStyleManager.setDefaultWidth(this.grid_item.instance_setting.data.colorByWidth);
        this.MapStyleManager.setDefaultColor(this.selectedColor);
        this.MapStyleManager.setHighlightColor(this.getGlobalProperty.global_setting.highlight_color);
        this.MapStyleManager.setSelectedColor(this.getGlobalProperty.global_setting.selection_color);
      },
      async createMapbox(){
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
        let mapContainer = this.mapId();
        let center = [this.grid_item.instance_setting.data.longitude, this.grid_item.instance_setting.data.latitude];
        let zoom = this.grid_item.instance_setting.data.zoom;

        return new Promise((resolve, reject) => {
          this.map = new mapboxgl.Map({
            container: mapContainer,
            style: "mapbox://styles/mapbox/streets-v11",
            center: center,
            zoom: zoom,
          });

          this.map.on('load', () => {
            // Attach other events and functionalities once the map is loaded
            this.map.on('click', this.elementSelectEvent);
            this.map.on('mousemove', this.onMouseMoveHandler);

            // Initialize and attach the ResizeObserver
            this.resizeObserver = new ResizeObserver(this.handleResize);
            this.resizeObserver.observe(this.$refs.viewerMap);

            // Now resolve the promise, signaling that the map is fully loaded
            resolve();
          });

          this.map.on('error', (err) => {
            // Handle map loading errors if any
            reject(err);
          });
        });
      },
      // globalSignificantDigitsEvent(significant_digits){
      //   if (viewer2DInstances[this.grid_item.i]) {
      //     viewer2DInstances[this.grid_item.i].digits = significant_digits;
      //   }
      // },
      async assetRemovedFromWidgetEvent(){
        //Check assets and Update layers. 
        if (this.map){
          //only sync layers if there are changes
          let widgetAssets = this.grid_item.instance_setting.data.assets.map(asset => asset._id);
          let widgetLayerIds = this.grid_item.instance_setting.layers.layers.map(layers => layers.id);
          if (widgetAssets.length != widgetLayerIds.length){
            this.syncAssetsToLayers();
          }
        }
      },
      mapId(){
        return "map_"+this.grid_item.i;
      },
      async loadAllLayers(){
        let assets = this.grid_item.instance_setting.data.assets;
        let currentLayers = this.grid_item.instance_setting.layers.layers;

        for (const layer of currentLayers) {
          //check layer is in assets
          const layerAsset = assets.filter(asset => asset._id === layer.id);
          //if layer is in assets add it to the map
          if (layerAsset) {
            //Load Geojson
            let geojson = await this.loadGeosonAsset(layerAsset[0])
            //Add Layer Geojson to Map
            await this.addGeoJSONLayer(geojson, layer);
          } 
          //else remove it from currentLayers array
          else {
            const index = currentLayers.indexOf(layer);
            currentLayers.splice(index, 1);
          }
        }

        //zoom to extents
        this.zoomExtents();
      },
      async syncAssetsToLayers() {
        // Assets are the geojson datasources
        let assets = this.grid_item.instance_setting.data.assets;
        let currentLayers = this.grid_item.instance_setting.layers.layers;

        // Add Missing layers
        for (const asset of assets) {
          const layerExists = currentLayers.some(layer => layer.id === asset._id);
          if (!layerExists) {
            await this.addLayer(asset);
          }
        }

        // Refresh the reference to currentLayers after potential additions
        currentLayers = this.grid_item.instance_setting.layers.layers;

        // Remove layers that are no longer in the assets
        for (const layer of currentLayers) {
          const assetExists = assets.some(asset => asset._id === layer.id);
          if (!assetExists) {
            await this.removeLayer(layer);
          }
        }

        this.onNewSelectedElements();
      },
      /**
       * This adds a layer to the widget and its corrisponding geojson to the map. 
       * @param {Object} asset - a map asset object.  
       */
      async addLayer(asset){
        // Create the layer object
        let layer = {
          id: asset._id,
          sourceId: asset._id+'-source',
          s3_pointers: asset.s3_pointers,
          name: asset.name,
          isVisible: true,
          isLocked: false,
          opacity: 1,
          elemIds: [],
        };

        //Load Geojson
        let geojson = await this.loadGeosonAsset(asset)

        //Evaluate elemIds in geojson
        layer.elemIds = geojson.features
          .filter(feature => feature.properties && feature.properties.ellipseId)
          .map(feature => feature.properties.ellipseId);

        //Add Layer Geojson to Map
        await this.addGeoJSONLayer(geojson, layer);

        //Add Layer to Widget
        this.grid_item.instance_setting.layers.layers.push(layer);
        //console.log('Loaded layer:', layer)
      },
      async removeLayer(layer){
        // Remove the layer from the map
        this.removeGeoJSONLayer(layer);

        // Remove the layer from the widget
        const index = this.grid_item.instance_setting.layers.layers.indexOf(layer);
        this.grid_item.instance_setting.layers.layers.splice(index, 1);
      },
      setLayerVisibility(layer){
        if (layer.isVisible) {
          this.showLayer(layer);
        } else {
          this.hideLayer(layer);
        }
      },
      showLayer(layer){
        this.map.setLayoutProperty(layer.id, 'visibility', 'visible');
        this.map.setLayoutProperty(layer.id+'-label', 'visibility', 'visible');
      },
      hideLayer(layer){
        this.map.setLayoutProperty(layer.id, 'visibility', 'none');
        this.map.setLayoutProperty(layer.id+'-label', 'visibility', 'none');
      },
      async loadGeosonAsset(asset){
        let geojsonDataResponce = await this.$auth.$api.post(
          `/api/maps/load-map-data-for-notebook/?urlType=${this.$route.name}`,
          {
            s3_key: asset.s3_pointers[0],
            notebookId: this.getNotebook._id,
          }
        );
        let data = JSON.parse(geojsonDataResponce.data.data.map_data).data;
        let geojson = JSON.parse(data);
        return geojson;
      },
      async addGeoJSONLayer(geojsonData, layer) {
        let layerId = layer.id;
        let sourceId = layer.sourceId;
        let mapLayerKeys = Object.keys(this.map.style._layers);
        //Check that layer is not already on the map
        if (mapLayerKeys.includes(layer.id)) {
          return;
        }
        // Check if geojsonData has features and if those features have a geometry
        if (!geojsonData.features || !geojsonData.features[0].geometry) {
          console.error('Invalid GeoJSON data');
          return;
        }

        // Get the geometry type from the first feature
        // (assuming all features have the same geometry type)
        const geometryType = geojsonData.features[0].geometry.type;

        // Get the appropriate style from the default styles
        const layerType = this.MapStyleManager.getMapboxLayerType(geometryType);
        const style = this.MapStyleManager.getPaint('default', layerType);

        if (!style) {
          console.error('Style not defined for geometry type:', geometryType);
          return;
        }

        // Add the GeoJSON data as a source to the map
        await this.map.addSource(sourceId, {
          type: 'geojson',
          data: geojsonData
        });

        // Add a new layer to the map using the source
        await this.map.addLayer({
          id: layerId,
          type: layerType,
          source: sourceId,
          paint: style,
        });

        //Creaty a 'Symbol' Layer for the labels
        await this.map.addLayer({
          id: layerId + '-label',
          type: 'symbol',
          source: sourceId,
          layout: {
            'text-field': '', // Initially, you can set it as an empty string or some default value
            'text-offset': [0, 0.6],
            'text-anchor': 'center',
            'visibility': 'none', // defaults to off, can be set to on with 'visible'
          },
        })

        //set layer visiblity
        this.setLayerVisibility(layer);

      },
      async removeGeoJSONLayer(layer){
        let layerId = layer.id;
        let sourceId = layer.sourceId;
        let mapLayerKeys = Object.keys(this.map.style._layers);
        
        // Check if the layer exists in map
        if (!mapLayerKeys.includes(layerId)) return
        
        // Remove the layer from the map
        this.map.removeLayer(layerId);
        this.map.removeLayer(layerId+'-label');
        this.map.removeSource(sourceId);
      },
      onMouseMoveHandler(e){
        // Get the list of relevant layers from your data structure
        let relevantLayerIds = this.grid_item.instance_setting.layers.layers.map(layer => layer.id);

        // Query for the features under the pointer, but only from the relevant layers.
        let features = this.map.queryRenderedFeatures(e.point, { layers: relevantLayerIds });

        if (features.length) {
            // If there's a feature under the pointer
            this.onFeatureHover({ features: features });
        } else {
            // If there's no feature under the pointer, reset the cursor and highlighted elements
            this.onFeatureLeave();
        }
      },
      setMapCenter(){
        //GET CENTER OF CURRENT MAP VIEW
        let center = this.map.getCenter();

        //set widget center
        this.grid_item.instance_setting.data.latitude = center.lat;
        this.grid_item.instance_setting.data.longitude = center.lng;
      },
      // updateColorByParameters(){
      //   viewer2DInstances[this.grid_item.i].updateLineWidth(this.grid_item.instance_setting.data.colorByWidth);
      // },
      datasetUpdateEvent(){
      },
      // Layers Settings Methods -
      layersUpdated: function (updated) {
        //console.log("INDEX", updated);
        if (!this.map) return;
        this.grid_item.instance_setting.layers.layers = updated.layers;

        //updates the index layer
        this.setLayerVisibility(updated.layers[updated.index]);

        //Update state
        this.updateWidget();
      },
      // Layers Settings Methods -
      layersLocked: function (updated) {
        console.log("INDEX", updated.layers[updated.index].isLocked);
        
        if (!this.map) return;
        this.grid_item.instance_setting.layers.layers =  updated.layers;

        //Update state
        this.updateWidget();
      },
      // Zoom Options Methods -
      zoomByPercentage: function (percentage) {
        // Clamp the percentage between 0 and 200
        percentage = Math.max(0, Math.min(percentage, 200));

        const minZoom = this.map.getMaxZoom();  // This is 22 in a typical setup
        //const maxZoom = this.map.getMinZoom();  // This is 0 in a typical setup
        const maxZoom = 10;

        // Calculate the desired zoom level based on the percentage
        // The formula is inversed given the requirements
        const newZoom = minZoom - (minZoom - maxZoom) * (percentage / 200);

        // Set the new zoom level
        this.map.setZoom(newZoom);
      },
      zoomExtents: function () {
          try {
              let allBounds = new mapboxgl.LngLatBounds();
              let layers = this.grid_item.instance_setting.layers.layers;
              
              layers.forEach(layer => {
                  const source = this.map.getSource(layer.sourceId);
                  
                  if (source && source.type === 'geojson') {
                      const features = source._data.features;

                      features.forEach(feature => {
                          switch (feature.geometry.type) {
                              case 'Point':
                                  allBounds.extend(feature.geometry.coordinates.slice(0, 2));
                                  break;
                              case 'MultiPoint':
                                  feature.geometry.coordinates.forEach(coord => {
                                      allBounds.extend(coord.slice(0, 2));
                                  });
                                  break;
                              case 'LineString':
                              case 'Polygon':
                                  const flatCoordinates = feature.geometry.type === 'Polygon'
                                      ? feature.geometry.coordinates[0] // Assuming no holes in the polygon
                                      : feature.geometry.coordinates;
                                  flatCoordinates.forEach(coord => {
                                      allBounds.extend(coord.slice(0, 2));
                                  });
                                  break;
                              case 'MultiLineString':
                              case 'MultiPolygon':
                                  const allCoords = this.flattenDeep(feature.geometry.coordinates);
                                  for (let i = 0; i < allCoords.length; i += 2) {
                                      allBounds.extend([allCoords[i], allCoords[i + 1]]);
                                  }
                                  break;
                          }
                      });
                  }
              });

              // Only fit to bounds if it has valid coordinates
              if (allBounds.getNorthEast() && (allBounds.getNorthEast().lat !== Infinity)) {
                  this.map.fitBounds(allBounds, {
                      padding: 20 // padding in pixels
                  });
              } else {
                  console.warn('No valid bounds found to fit.');
              }
          } catch (err) {
              console.error(err);
          }
      },

      zoomSelected: function () {
        if (this.getSelectedElements.length >0){
          let ellipseIds = this.getSelectedElements;

          try {
            let bounds = new mapboxgl.LngLatBounds();
            let layers = this.grid_item.instance_setting.layers.layers;

            layers.forEach(layer => {
              const source = this.map.getSource(layer.sourceId);

              if (source && source.type === 'geojson') {
                const features = source._data.features;

                // Filter features by the provided ellipseIds
                const relevantFeatures = features.filter(feature => ellipseIds.includes(feature.properties.ellipseId));

                relevantFeatures.forEach(feature => {
                  // // If it's a point, use it directly
                  // if (feature.geometry.type === 'Point') {
                  //     bounds.extend(feature.geometry.coordinates);
                  // } 
                  // // If it's a LineString or Polygon, use the coordinates to extend the bounds
                  // else if (['LineString', 'Polygon'].includes(feature.geometry.type)) {
                  //     const coordinates = feature.geometry.coordinates;
                  //     const flatCoordinates = feature.geometry.type === 'Polygon'
                  //       ? coordinates[0] // Assuming no holes in the polygon
                  //       : coordinates;

                  //     flatCoordinates.forEach(coord => {
                  //       bounds.extend(coord);
                  //     });
                  // }
                   switch (feature.geometry.type) {
                      case 'Point':
                          bounds.extend(feature.geometry.coordinates.slice(0, 2));
                          break;
                      case 'MultiPoint':
                          feature.geometry.coordinates.forEach(coord => {
                              bounds.extend(coord.slice(0, 2));
                          });
                          break;
                      case 'LineString':
                            feature.geometry.coordinates.forEach(coord => {
                                bounds.extend(coord.slice(0, 2));
                            });
                            break;
                      case 'Polygon':
                          const flatCoordinates = feature.geometry.type === 'Polygon'
                              ? feature.geometry.coordinates[0] // Assuming no holes in the polygon
                              : feature.geometry.coordinates;
                          flatCoordinates.forEach(coord => {
                              bounds.extend(coord.slice(0, 2));
                          });
                          break;
                      case 'MultiLineString':
                      case 'MultiPolygon':
                          const allCoords = this.flattenDeep(feature.geometry.coordinates);
                          for (let i = 0; i < allCoords.length; i += 2) {
                              bounds.extend([allCoords[i], allCoords[i + 1]]);
                          }
                          break;
                  }
                    // Add additional handling for MultiPoint, MultiLineString, MultiPolygon, etc., if needed
                });
              }
            });

            // Only fit to bounds if it has valid coordinates
            if (bounds.getNorthEast().lat !== Infinity) {
                this.map.fitBounds(bounds, {
                    padding: 20 // padding in pixels
                });
            } else {
                console.warn('No valid bounds found to fit.');
            }
          } catch (err) {
              console.error(err);
          }
        } 
      },
      flattenDeep: function (arr) {
          return arr.reduce((flat, toFlatten) => {
              if (Array.isArray(toFlatten)) {
                  // If it's an array of coordinates, it should be a length of at least 2
                  if (typeof toFlatten[0] === 'number' && typeof toFlatten[1] === 'number') {
                      flat.push(toFlatten[0], toFlatten[1]);
                  } else {
                      flat = flat.concat(this.flattenDeep(toFlatten));
                  }
              } else {
                  flat.push(toFlatten);
              }
              return flat;
          }, []);
      },
      elementSelectEvent(event) {

        // Set `bbox` to widget sensativity
        // const sensativity = this.grid_item.instance_setting.data.mouseSensitivity;

        // const bbox = [
        //   [event.point.x - sensativity, event.point.y - sensativity],
        //   [event.point.x + sensativity, event.point.y + sensativity]
        // ];

        const bbox = [event.point.x, event.point.y];

        // User Layers to Check
        const layerIds = this.grid_item.instance_setting.layers.layers
          .filter(layer => !layer.isLocked 
            && layer.elemIds 
            && layer.elemIds.length>0 
            && layer.isVisible)
          .map(layer => layer.id);

        // Query for the rendered features under the clicked point
        const features = this.map.queryRenderedFeatures(bbox,  { layers: layerIds });

        if (features.length > 0) {
          const selectedFeature = features[0];  // Select the top-most feature
          const ellipseId = selectedFeature.properties.ellipseId;
          if( ellipseId != "" && ellipseId != undefined){
            this.updateSelected([ellipseId]);
          }
        }
        else{
          this.updateSelected([]);
        }
      },
      updateSelected(ellipseIds) {
        if (ellipseIds.length === 0) {
          this.notebookPropsStore.$patch({selectedElements: []})
        }
        else{
          for (let ellipseId of ellipseIds) {
            if(this.multiSelectActive){
              if(this.getSelectedElements.includes(ellipseId)){
                this.removeFromSelectedElements(ellipseId)
              }else{
                this.addToSelectedElements(ellipseId)
              }
              if (ellipseId === undefined) {
                this.notebookPropsStore.$patch({selectedElements: []})
              }
            } else {
              if(ellipseId === "" || ellipseId === undefined){
                this.notebookPropsStore.$patch({selectedElements: []})
              }
              else{
                this.notebookPropsStore.$patch({selectedElements: [ellipseId]})
              }
            }
          }
        }

      },
      /** 
       * updates global highlightedElements if any
       * include the ellipseId property
       * @param {Object} event - Mapbox event object
       */
      onFeatureHover(event) {
        // Change the cursor style to pointer (or any other style)
        this.map.getCanvas().style.cursor = 'pointer';

        // Check if feature exists
        if (event.features.length > 0) {

          let hoveredFeature = event.features[0];
          let layerId = hoveredFeature.layer.id;
          let isLocked = this.grid_item.instance_setting.layers.layers.find(layer => layer.id === layerId).isLocked;
          let ellipseId = hoveredFeature.properties.ellipseId;

          if ((ellipseId !== undefined || ellipseId == "") && !isLocked) {
            // Set Global highlightedElements
            this.setHighlightedElements([ellipseId]);
          }
        }
      },
      /**
       * clears the global highlightedElements
       */
      onFeatureLeave() {
        // Reset the cursor style
        this.map.getCanvas().style.cursor = '';

        // Clear global highlightedElements
        this.setHighlightedElements([]);
      },
      /**
       * gets all features with matching ellipseIds
       * @param {Array} ellipseIds 
       * @returns {Array} - Array of features 
       */
      getFeaturesByEllipseIds(ellipseIds) {
        // First, filter the layers that have the ellipseIds property and that it's not empty
        let relevantLayers = this.grid_item.instance_setting.layers.layers.filter(layer => {
            return layer.elemIds && layer.elemIds.length;
        });

        let foundFeatures = [];

        relevantLayers.forEach(layer => {
            // Check if the layer's ellipseIds has any matching id from the given ellipseIds
            let matchingIds = ellipseIds.filter(id => layer.elemIds.includes(id));
            
            // If there are matching IDs, query for the features
            if (matchingIds.length) {
                let filterExpression = ["in", ["get", "ellipseId"], ["literal", ...matchingIds]];
                
                const layerFeatures = this.map.querySourceFeatures(layer.id, {
                    filter: filterExpression
                });
                
                foundFeatures.push(...layerFeatures);
            }
        });

        return foundFeatures;
      },
      /**
       * gets the visible features by ellipseIds
       * @param {*} ellipseIds 
       * @returns {Array} - Array of features
       */
      getVisibleFeaturesByEllipseIds(ellipseIds){
        // First, filter the layers that have the ellipseIds property and that it's not empty
        let relevantLayers = this.grid_item.instance_setting.layers.layers.filter(layer => {
            return layer.elemIds && layer.elemIds.length && layer.isVisible;
        });

        let foundFeatures = [];

        relevantLayers.forEach(layer => {
            // Check if the layer's ellipseIds has any matching id from the given ellipseIds
            let matchingIds = ellipseIds.filter(id => layer.elemIds.includes(id));
            
            // If there are matching IDs, query for the features
            if (matchingIds.length) {
                //let filterExpression = ["in", ["get", "ellipseId"], ["literal", ...matchingIds]];
                let filterExpression = ["in", ["get", "ellipseId"], ["literal", matchingIds]];

                const layerFeatures = this.map.queryRenderedFeatures({
                    layers:[layer.id], 
                    filter: filterExpression
                });
                
                foundFeatures.push(...layerFeatures);
            }
        });

        return foundFeatures;
      },
      setOverlayLayer(features, overlayType){
        //this aligns with 
        const validTypes = this.MapStyleManager.validTypes;

        // Check if the overlayType is valid. 
        if (validTypes.includes(overlayType)){
          // Group the features by their layer type
          let groupedFeatures = this.groupFeaturesByType(features);

          // For each layer type, create a new layer with the selected features
          Object.entries(groupedFeatures).forEach(([layerType, features]) => {
              let selectedLayerId = `ellipse-overlay-${layerType}-${overlayType}`; // using layerType to identify

              // Convert grouped features to GeoJSON
              let geojson = {
                  type: 'FeatureCollection',
                  features: features
              };

              // Check if the source already exists, if so update it, if not create it
              if (this.map.getSource(selectedLayerId)) {
                  this.map.getSource(selectedLayerId).setData(geojson);
              } else {
                  this.map.addSource(selectedLayerId, {
                      type: 'geojson',
                      data: geojson
                  });
              }

              //Using the mapStyleManager to get the appropriate style
              let paintProps = this.MapStyleManager.getStyleType(layerType, overlayType);

              // Check if the layer already exists, if so update its style, if not create it
              if (this.map.getLayer(selectedLayerId)) {
                for (let prop in paintProps) {
                  this.map.setPaintProperty(selectedLayerId, prop, paintProps[prop]);
                }
              } else {
                this.map.addLayer({
                    id: selectedLayerId,
                    type: layerType,
                    source: selectedLayerId,
                    paint: paintProps
                });
              }
          });
        } else {
          console.error(`Invalid map overlay type [${overlayType}] valid types are [${validTypes}]`);
        }
      },
      clearOverlayLayer(overlayType){
          const validTypes = this.MapStyleManager.validTypes;

          if (validTypes.includes(overlayType) && this.map){
            // Directly iterate over map's layers
            if(this.map.getStyle()){
            let layers = this.map.getStyle().layers;
            layers.forEach(layer => {
                if (layer.id.includes('ellipse-overlay') && layer.id.includes(overlayType)) {
                    // If the layer follows our naming convention, remove it
                    this.map.removeLayer(layer.id);
                    this.map.removeSource(layer.id);
                }
            });
          }
          }
      },
      /**
       * Groups an array of features by their layer type.
       * 
       * @param {Array} features - The array of features to group.
       * @return {Object} An object where each key represents a layer type 
       *                  (like 'fill', 'circle', 'line', etc.), and each value 
       *                  is an array of features of that type.
       */
      groupFeaturesByType(features) {
          // The reduce function processes each feature and accumulates the result into an object.
          return features.reduce((acc, feature) => {
              // For each feature, get its layer type.
              let type = feature.layer.type;

              // If the current type doesn't exist as a key in the accumulator object, add it with an empty array.
              if (!acc[type]) acc[type] = [];

              // Push the current feature into the array corresponding to its layer type.
              acc[type].push(feature);

              // Return the updated accumulator for the next iteration.
              return acc;
          }, {}); // Start with an empty object as the accumulator.
      },
      async onNewSelectedElements(){
        const overlayType = 'selected';
        const ellipseIds = this.getSelectedElements;
        this.clearOverlayLayer(overlayType);
        if(ellipseIds.length != 0){
          const features = this.getVisibleFeaturesByEllipseIds(ellipseIds);
          if(features.length != 0){
            this.setOverlayLayer(features, overlayType);
          }
        }
      },
      async onNewElementHighlighted() {
        
        const overlayType = 'highlighted';
        const ellipseIds = this.getHighlightedElements;
        this.clearOverlayLayer(overlayType);

        if(ellipseIds.length != 0 && ellipseIds != ""){
          const features = this.getVisibleFeaturesByEllipseIds(ellipseIds);
          if(features.length != 0){
            this.setOverlayLayer(features, overlayType);
          }
        }

      },
      async onNewColorByUpdated() {

        let opts = this.getColorByData.graphicData;
        if (!opts) return;
 
        let layers = this.grid_item.instance_setting.layers.layers;
        if (!this.map) {
            console.warn('Map is not initialized.');
            return; // If map is not initialized, exit the function.
        }
        let self = this;
        // For each layer
        layers.forEach(userLayer => {
          try{
            //check for map or if its undefined
            if (!self.map) return;
            let layer = self.map.getLayer(userLayer.id);
            // Only perform for layers of types that can have a 'fill-color' or 'circle-color' etc.
            if (['fill', 'circle', 'line'].includes(layer.type)) {
              
              let pairedArray = [];
              opts.ids.forEach((id, index) => {
                pairedArray.push(id, opts.colors[index]);
              });
              // Define the coloring function based on the 'ellipseId' property
              const expression = ['match', ['get', 'ellipseId'], ...pairedArray, this.MapStyleManager.defaultColor];

              // Assign the color based on the layer type
              switch (layer.type) {
                case 'fill':
                  this.map.setPaintProperty(layer.id, 'fill-color', expression);
                  break;
                case 'circle':
                  this.map.setPaintProperty(layer.id, 'circle-color', expression);
                  break;
                case 'line':
                  this.map.setPaintProperty(layer.id, 'line-color', expression);
                  break;
              }
            }
          } catch(err){
            console.warn(err);
          }
        });
      },
      async onNewLabelByUpdated() {

        let layers = this.grid_item.instance_setting.layers.layers;

        //Turn off Labels if None is selected
        if(this.getLabelByData.attribute == "None"){
          layers.forEach(layer => {
            let mapLayer = this.map.getLayer(layer.id+'-label');
            if(!mapLayer) return;
            this.map.setLayoutProperty(mapLayer.id, 'visibility', 'none');
          });
          return;
        }

        //Get label Settings
        let opts = this.getLabelByData.graphicData;
        if (!opts) return;

        // Default label to show if none matches (can be empty or some default text)
        const defaultLabel = '';

        // Construct the paired array as we did with the color method
        let pairedArray = [];
        opts.ids.forEach((id, index) => {
          pairedArray.push(id,String(opts.values[index]));
        });

        // For each layer
        layers.forEach(layer => {

          let mapLayer = this.map.getLayer(layer.id+'-label');
          if(!mapLayer) return;

          // Define the labeling function based on the 'ellipseId' property
          const expression = ['match', ['get', 'ellipseId'], ...pairedArray, defaultLabel];

          // Assign the label
          this.map.setLayoutProperty(mapLayer.id, 'text-field', expression);
          this.map.setLayoutProperty(mapLayer.id, 'visibility', 'visible');
        });
      },
      async onNewFilterByUpdated() {
        let data = this.getFilterByData.graphicData ? this.getFilterByData.graphicData : this.getAttrData
        const ellipseIdsInRange = data.map(d => d.ellipseId)

        let layers = this.grid_item.instance_setting.layers.layers;

        // For each layer show and hide any feature that has ellipseId.
        layers.forEach((layer) => {
          if (layer.elemIds.length>0 && this.map){
            let ellipseIdsOfVisibleLayers = layer.elemIds
            let idsToShow = ellipseIdsInRange ? [...new Set([...ellipseIdsInRange], ...ellipseIdsOfVisibleLayers)] : ellipseIdsOfVisibleLayers
            const filterExpressionToShow = ['match', ['get', 'ellipseId'], idsToShow, true, false];
            this.map.setFilter(layer.id, filterExpressionToShow);
          }
        })
      },

      // async globalHighlightColorEvent(highlight_color){
      //   if(viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas){
      //       viewer2DInstances[this.grid_item.i].highlightColor = highlight_color;
      //   }
      // },
      // async globalSelectionColorEvent(selection_color){
      //   if(viewer2DInstances[this.grid_item.i] && viewer2DInstances[this.grid_item.i].canvas){
      //     viewer2DInstances[this.grid_item.i].pickColor = selection_color;
      //   }
      // },
      async updateWidget() {
        let postData = {
          widgets: [this.grid_item],
        };

        let update_widgets = await this.$auth.$api.post(
          `/api/widget/update_widgets/?urlType=${this.$route.name}`,
          postData
        );
      },
      toggleFullScreen() {
        if (this.isFullscreen) {
          this.closeFullscreen();
        }
        else {
          this.openFullscreen();
        }
      },
      openFullscreen() {
      const elem = document.getElementById(this.grid_item._id);
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
        this.isFullScreen = false;
      },
      closeFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
        this.isFullScreen = true;
      },
      downloadSvg() {
        var svg = viewer2DInstances[this.grid_item.i].toSvgString();
        const blob = new Blob([svg], {
          type: 'image/svg+xml' // or whatever your Content-Type is
        });
        filesaver.saveAs(blob, "ellipse.svg");
      },
    },
  };
</script>
  
<style scoped>
.v-input:deep(.v-select__selection){
    display: flex;
    align-items: center;
}
.v-input:deep(.v-select__selection-text){
    font-size: 14px !important;
    width: 170px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px
}
</style>
  
<template>
  <div class="d-flex">        
    <v-progress-circular
      :size="30"
      color="darkSlate"
      indeterminate
    ></v-progress-circular>
    <div class="pl-2 text-h6 black--text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
</script>

<style scoped></style>
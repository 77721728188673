<template>
    <!-- Statistics Widget Properties -->
    <div>

        <v-container>
<v-row width="100%"><div class="text-caption pt-0"><strong>Content</strong></div><v-divider/></v-row>

            <!-- Categories -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasCategory" label="Categories" />
                </v-col>
            </v-row>

            <!-- Elements -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasElement" label="Elements" />
                </v-col>
            </v-row>

            <!-- Color By -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasColor" label="Color By" />
                </v-col>
            </v-row>

            <!-- Label By -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasLabel" label="Label By"/>
                </v-col>
            </v-row>

            <!-- Highlights -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasHighlight"
                        label="Highlighted"/>
                </v-col>
            </v-row>

            <!-- Selection -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasSelect" label="Selected" />
                </v-col>
            </v-row>

            <!-- Filter -->
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.hasFilter" label="Filtered"/>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        getSelectedWidget: {}
    },
    setup() { },
    data: () => ({
    }),
    computed: {
    },
    methods: {
    }
}
</script>

<style scoped></style>
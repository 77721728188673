<template>
  <div v-if="show" class="widgetIconStyle">
    <v-tooltip location="right" light>
      <template v-slot:activator="{ props}">
          <v-icon
            v-bind="props"  
            icon 
            :size="iconsize" 
            light 
            right>
              {{ icon }}
          </v-icon>
      </template>
      <span>{{text}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import * as lexicon from "@/utilities/EllipseLexicon.js";

export default {
setup() {
  return {
    lexicon
  }
},
props: {
  show: {
      type: Boolean,
      required: true
  },
  icon: {
      type: String,
      required: true,
      default: lexicon.layout.icon ,
  },
  text: {
      type: String,
      required: false
  },
  iconsize:{
      type: String,
      required: false
  }
}
}
</script>

<style scoped>
.widgetIconStyle {
position: absolute;
z-index: 10;
left: 0.5em;
top: 1em;
}
.widgetIcon{
  padding: 6px;
  border-radius: 30px;
  height: auto;
  border: 1px solid rgb(var(--v-theme-darkGrey));
}
</style>
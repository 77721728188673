<template>
  <div>
    <div style="text-align: center">
      <h3>Not Authorized</h3>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped></style>

<template>
  <!-- Image Widget Properties -->
  <div>
    <v-container>    
<v-row width="100%"><div class="text-caption pt-0"><strong>Content</strong></div><v-divider/></v-row>
      <!-- Count of Elements in Dataset. -->

      <!-- Select Attribute to List for each Element. -->
      <v-row class="pt-4 pl-2" width="100%">
        <v-col>
          <!-- Image Selection Dropdown. -->
          <v-select 
            class="pb-6"
            v-model="getSelectedWidget.instance_setting.data.image" 
            :items="file_images" 
            item-title="name"
            :label="'Image ('+file_images.length+')'" 
            return-object 
            @update:modelValue="selectImage($event)">
          </v-select>
        </v-col>
      </v-row>
      <v-row class="pt-0 pl-2" width="100%">
        <v-col>
          <v-select 
            v-model="getSelectedWidget.instance_setting.data.selectedFit"
            :items="fitting" 
            item-title="name" 
            label="Fitting" 
            return-object
            >
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
    </v-card-actions>
    <!-- Aspect Ratio Settings. -->
  </div>
</template>

<script>

export default {
  components: {  },
    props: {
      notebook: {},
      getSelectedWidget: {}
    },
    data: () => ({
      file_images: [],
        fitting: [
          { name: "Fit", style: "fit-a" },//contain
          { name: "Fit Height", style: "fit-h" },
          { name: "Fit Width", style: "fit-w" },
          { name: "Fill", style: "fill" },//cover
          { name: "Stretch", style: "stretch" },//fill
          { name: "Original", style: "original" },//none
        ],
      //allImgFiles: [],
      //selectedImage: "",
      //isImageSelected: false,
    }),
    created() {
      this.loadFiles();
    },
    mounted() {
      // console.log("Data", this.getSelectedWidget.instance_setting.data);
    },

      methods: {
        async loadFiles() {
            // extract note book information and its Files
            let params = {urlType: this.$route.name};
            let rootfolderInfo = await this.$auth.$api.get(
                `/api/notebook/${this.notebook._id}/folder/${this.notebook.rootFolder}/child-folders`, {params}
            );
            let allFiles = rootfolderInfo.data.data;

        // filter and initialize file_images
        const allowedFileType = ["jpg", "png", "gif", "tiff"];
        if(allFiles){
          for (let file of allFiles) {
            let fileExtension = file.name.split(".").pop().toLowerCase();
            let fileName = file.name.split(".")[0];

            if (allowedFileType.includes(fileExtension)) {
              // let viewFilePath =`/api/notebook/${this.notebook._id}/file/view?s3Key=${file.s3Key}`;

              // let viewFilePath = await this.$auth.$api.get(`/api/notebook/${this.notebook._id}/file/view?s3Key=${file.s3Key}`);
              // console.log("viewFilePath",viewFilePath.data);

              let curImageFile = {
                name: fileName,
                s3Key:file.s3Key
              };
              this.file_images.push(curImageFile);
            }
            // console.log("this.file_images",this.file_images);
          }
        }
      },
        async selectImage(e){
            let params = {urlType: this.$route.name};
            let viewFilePath = await this.$auth.$api.get(`/api/notebook/${this.notebook._id}/file/view?s3Key=${this.getSelectedWidget.instance_setting.data.image.s3Key}`, {params});
            this.getSelectedWidget.instance_setting.data.image.link = viewFilePath.data;
        }
    }
}
</script>

<style scoped>

</style>
<template>
  <div class="widgetPadding pt-3">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner  :show="(getAttrData.length === 0 && getSelectedPageDatasets.length != 0 ) || getDatasetLoading" ></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <div v-if="getAttrData.length > 0">
      <v-text-field
        variant="outlined"
        density="compact"
        hide-details
        clearable
        :label="submitedSearch ? 'Search' : 'Search: press enter to submit'"
        prepend-inner-icon="fas fa-search"
        v-model="keywords"
        :loading="searchLoading"
        @keydown="onChange"
        @click:prependInner="submitSearch"
        @keydown.enter="submitSearch()"
        @click:clear="submitSearch(true)"
      ></v-text-field>
    </div>
  </div>
</template>

<script>;
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../ui/WidgetIcon.vue"
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: {WidgetIcon, NoDataWarning, Spinner, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      addFilter,
      updateFilterByProperties,
    } = dataGraphicsStore
    return {dataGraphicsStore, getEditMode, getAttrData, getSelectedPageDatasets, getDatasetLoading,
      getGlobalProperty, addFilter, updateFilterByProperties}
  },
  data() {
    return {
      filterType: "Search",
      keywords: "",
      searchLoading:false,
      datasetSwitchingLoading:false,
      filterObject: {
        filterType:"Search",
        search: this.keywords,
      },
      searchTimeout:null,
      submitedSearch:true,
    };
  },
  created(){
    this.verifyInstanceSettings();
  },
  mounted(){
    this.verifyInstanceSettings();
    this.submitSearch()

    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'updateFilterByProperties') {
          this.UpdateSearchLoading()
        }
        if (name === "removeAllFilters"){
          this.removeFilter()
        }
      })
    })
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods:{
    verifyInstanceSettings() {
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!this.grid_item.instance_setting.filters) this.grid_item.instance_setting.filters = [this.filterObject];
      this.filterObject = this.grid_item.instance_setting.filters[0];
      this.keywords = this.filterObject.search;
      if (!this.grid_item._id) {

      };
      
    },
    onChange(){
      this.submitedSearch = false;
    },
    async submitSearch(clear=false){
      this.searchLoading = true;
      this.filterObject.search = this.keywords;
      let shouldUpdate
      if (clear){
        this.filterObject.search = "";
        shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: this.filterObject}, true)
      } else {
        shouldUpdate = await this.addFilter({widget: this.grid_item.i, filter: this.filterObject})
      }
      this.grid_item.instance_setting.filters = [this.filterObject];
      if (shouldUpdate) await this.updateFilterByProperties()
      this.submitedSearch = true;
      this.searchLoading = false
    },
    UpdateSearchLoading(){
      this.searchLoading = false;
    },
    removeFilter(){
      this.keywords = ""
      this.filterObject.search = this.keywords
      this.grid_item.instance_setting.filters = [this.filterObject];
    }
  },
  async unmounted(){
  }
};
</script>
<style scoped>
</style>

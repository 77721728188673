<template>
  <!-- Map Widget Properties -->
  <div>
    <v-expansion-panels multiple flat density="compact" hover>
      <v-expansion-panel elevation="0">
        <v-expansion-panel-title class="dense-expansion-panel">
          <span class="font-weight-bold ma-0 pa-0">
            <v-icon class="pa-0 ma-0 pr-2">{{ lexicon.widget.icon}}</v-icon>
            Widget Controls
          </span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row width="100%" class="pb-2 pl-0 ma-0">
            <!-- Fullscreen. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="
                  getSelectedWidget.instance_setting.data.uiSettings
                    .showFullscreen
                "
                label="Fullscreen"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
            <!-- Downloads. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="
                  getSelectedWidget.instance_setting.data.uiSettings
                    .showDownloads
                "
                label="Downloads"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
          </v-row>
          <v-row width="100%" class="pb-0 pl-0 ma-0">
            <!-- Layers. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="
                  getSelectedWidget.instance_setting.data.uiSettings.showLayers
                "
                label="Layers"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
            <!-- Models. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="
                  getSelectedWidget.instance_setting.data.uiSettings.showModels
                "
                label="Drawings"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
          </v-row>
          <v-row width="100%" class="pb-2 pl-0 ma-0">
            <!-- Zoom. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="
                  getSelectedWidget.instance_setting.data.uiSettings.showZoom
                "
                label="Zoom"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
            <!-- Multi. -->
            <v-col lg="6">
              <v-switch
                hide-details
                v-model="
                  getSelectedWidget.instance_setting.data.uiSettings
                    .showMultiSel
                "
                label="Multi Sel"
                class="pa-0 ma-0 pt-2"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container>
      <!-- Map Centerpoint. -->
      <v-row width="100%"><div class="text-caption pt-0"><strong>Map Location</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <v-col>
          <v-text-field
            class="pa-0 pt-3"
            v-model="getSelectedWidget.instance_setting.data.latitude"
            variant="outlined"
            density="compact"
            type="number"
            hide-details
            :prepend-inner-icon="icons.latitude"
            label="Latitude"
          />
        </v-col>
        <v-col>
          <v-text-field
            class="pa-0 pt-3"
            v-model="getSelectedWidget.instance_setting.data.longitude"
            variant="outlined"
            density="compact"
            type="number"
            hide-details
            :prepend-inner-icon="icons.longitude"
            label="Longitude"
          />

        </v-col>
      </v-row>
      <!-- Set Map Center to Current button. -->
      <v-row class="pl-2 pt-1">
          <v-btn variant="outlined" density="compact" @click="setMapCenter">Set</v-btn>
          <small class="pl-2">Map center point on load</small>

      </v-row>

      <!-- Map Zoom. -->
      <v-row width="100%" class="pa-0 pl-2 ma-0 mt-2">
        <v-col>
          <v-text-field
            class="pa-0 pt-2"
            v-model="getSelectedWidget.instance_setting.data.zoom"
            variant="outlined"
            density="compact"
            type="number"
            hide-details
            :prepend-inner-icon="icons.zoomPercentage"
            label="Zoom Level"
          />
          <small>Sets the default zoom level (0-22)</small
          >
        </v-col>
      </v-row>

      <!-- Layer Selection Headder-->
      <template v-if="getSelectedWidget.instance_setting.layers">
        <!--
        <v-divider class="mt-0 mb-4"></v-divider>
         <v-row width="100%">
          <v-col class="font-weight-bold pb-1">Layers</v-col>
          <v-col style="text-align: right">
            <small> Attibutes: {{ getAttrHeadersCategorical.length }} </small>
          </v-col>
        </v-row> -->
        <!-- Layer Selection Dropdown-->
        <!-- <v-row width="100%"
          ><v-col>
            <v-select
              class="pt-2"
              v-model="getSelectedWidget.instance_setting.layers.layerBy"
              :items="getAttrHeadersCategorical"
              item-title="name"
              return-object
              dense
              hide-details
              label="Select Attribute"
              @update:modelValue="onSelectLayerBy()"
            ></v-select>
          </v-col>
        </v-row> -->
      </template>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Color By</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <v-col>
          <v-text-field
            class="pa-0 pt-4"
            v-model="getSelectedWidget.instance_setting.data.colorByWidth"
            variant="outlined"
            density="compact"
            type="number"
            hide-details
            :prepend-inner-icon="icons.lineWeight"
            label="Line Weight Override"
          />
          <small
            >Optionally overrides the color by lineweight when the value is
            greater than 0.</small
          >
        </v-col>
      </v-row>

      <v-row width="100%"><div class="text-caption pt-2"><strong>Viewer</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- Mouse Sensativity. -->
        <!-- <v-col>
          <v-text-field
            class="pa-0 pt-2"
            v-model="getSelectedWidget.instance_setting.data.mouseSensitivity"
            variant="outlined"
            density="compact"
            type="number"
            hide-details
            prepend-inner-icon="mdi:mdi-cursor-default-click"
            label="Mouse Sensitivity"
          />
        </v-col> -->
      </v-row>
      <v-row width="100%" class="pa-0 pl-2 ma-0">
        <!-- Label Size. -->
        <v-col>
          <v-text-field
            class="pa-0 pt-4"
            v-model="getSelectedWidget.instance_setting.data.labelSize"
            variant="outlined"
            density="compact"
            type="number"
            hide-details
            :prepend-inner-icon="icons.labelSize"
            label="Label Size"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { evaluate } from "@ttcorestudio/data-processor";
import { storeToRefs } from "pinia";
import { useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import bus from "@/plugins/bus";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

export default {
  props: {
    getSelectedWidget: {},
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore();
    const { getAttrData, getAttrHeadersCategorical } =
      storeToRefs(dataGraphicsStore);
    return { getAttrData, getAttrHeadersCategorical, lexicon, icons };
  },
  mounted() {

  },
  computed: {
    layerBy() {
      try {
        return this.getSelectedWidget.instance_setting.layers.length > 0
          ? this.grid_item.instance_setting.layers.layerBy
          : "None";
      } catch {
        return "None";
      }
    },
  },
  methods: {
    registerEventListeners() {
    },
    onSelectLayerBy() {
      console.log("select");
      // set the layers object get unique values and add isVisible:, isLocked, opacity values:
      let key = this.getSelectedWidget.instance_setting.layers.layerBy;
      //console.log(key, this.getAttrData);

      let elemVals = [];
      this.getAttrData.forEach((attr) => {
        elemVals.push(attr[key]);
      });

      let tempVal = evaluate.getUniqueVals(elemVals);

      this.getSelectedWidget.instance_setting.layers.layers = tempVal.map(
        (item) => {
          const layer = {};
          layer.name = item;
          layer.isVisible = true;
          layer.isLocked = false;
          layer.opacity = 1;
          layer.elemIds = this.getAttrData.reduce(function (acc, att) {
            if (att.hasOwnProperty(key) && att[key] === item)
              acc.push(att.ellipseId);
            return acc;
          }, []);
          return layer;
        }
      );
      console.log(this.getSelectedWidget.instance_setting);
    },
    setMapCenter() {
      bus.emit("set-map-center");
    },
  },
};
</script>

<style scoped>
.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
}
</style>

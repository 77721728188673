<template>
  <!-- Data Table Widget properties -->
  <div>
    <v-container>    

      <v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>

      <!-- Data Set Size -->
      <v-row width="100%" class="pl-2" v-if="loaded">
        <ChipGroup
          :options="getAttrHeaderNames" 
          :selections="selection" 
          @table-changed="updatedSelection" 
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";

import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  components:{
    ChipGroup,
  },
  props:{
    getSelectedWidget:{},
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore() 
    const {
      getAttrHeaderNames
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderNames}
  },
  data:()=>({
    selection: [],
    data_headers: [],
    loaded: false,
  }),
  computed:{
  },
  async mounted(){
    if(this.getSelectedWidget.content == 'viewDataTable'){
      await this.loadTableHeaders();
    }
  },
  watch:{
    'getSelectedWidget':{
      deep: true,
      immediate: true,
      handler: function() {
        if(this.getSelectedWidget.content == 'viewDataTable'){
          this.loadTableHeaders();
        }       
      }
    }
  },
  methods:{
    updatedSelection(selected){
      this.selection = selected;
      this.getSelectedWidget.instance_setting.data.selectedAttrs = selected
    },
    async loadTableHeaders(){
      this.loaded = false;
      let i = 0;
      this.data_headers = this.getAttrHeaderNames.map((e) => {
        return { id: i++, name: e };
      });

      this.items = [
        {
          name: "Data Headers",
          children: this.data_headers,
        },
      ];

      if (this.getSelectedWidget.instance_setting.data) { 
        if (this.getSelectedWidget.instance_setting.data.selectedAttrs.length) {
          this.selection =
          this.getSelectedWidget.instance_setting.data.selectedAttrs;
        }
      } else {
        this.selection = this.getAttrHeaderNames;
      }
      this.loaded = true;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container fluid class="ma-0 pa-0">
    <v-alert
      class="mb-0"
      v-model="alert"
      border="start"
      closable
      close-label="Close Alert"
      color="secondary"
      prominent
      icon="mdi:mdi-rocket-launch"
    >
      <b>Thank you for your interest in Ellipse !</b>
      <br>
      <i>This application is currently a free public alpha. </i>
      <br>
      During this time, you can sign up and access all the features as they are developed and made public. 
      However, this application is in alpha, and under active development. 
      Features and functionality may be added and removed during this process and your experierience using the application may change on a daily basis. 
      We welcome your feedback and hope you enjoy Ellipse.
      <br>
      <small>~ The <b>Thornton Tomasetti | CORE studio </b> team</small>
    </v-alert>
    
    <v-row>
      <v-img
        lazy-src="../assets/imgs/banner.jpg"
        height="450"
        src="../assets/imgs/banner.jpg"
        cover
      ></v-img>
    </v-row>

    <br />

    <v-container style="height: 100%;">
      <br />
      <h1 class="text-h2">Ellipse</h1>
      <br />
      <h4 class="text-h4">AEC data studio</h4>
      <br />
      <v-row class="pb-12 px-4" >
        <v-col cols="12" sm="8" class="ma-0 pa-0">
          <p class="text-h5 text-grey text-justify">
            Bring together data, models, and drawings in a lightweight digital
            twin with Ellipse. Merge content from multiple project stakeholders,
            software platforms, and data sources into a flexible collaborative
            website. Encourage vertical integration through scope-specific
            portals to a live centralized data source that evolves through the
            lifespan of a project.
          </p>
        </v-col>

        <v-col cols="12" sm="1"></v-col>

        <v-col cols="12" sm="3" class="pb-8 d-flex justify-center align-center">
          <v-btn
            block
            class="px-4"
            rounded
            color="primary"
            elevation="5"
            href="/about"
            >
            <v-icon small> fa-solid fa-carrot</v-icon>
            &nbsp; Learn More
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center px-8">
          <v-img
            lazy-src="../assets/imgs/Integrate-01.png"
            height="250"
            width="250"
            contain
            src="../assets/imgs/Integrate-01.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="8" align-self="center">
          <h4 class="text-h4 text-left">Integrate</h4>
          <br />
          <p class="text-h5 text-grey text-left">
            Assemble BIM and CAD models with 2D drawings, diagrams, and sheets
            linked to project data sourced from software and databases for fully
            cross-connected interaction in an online platform.
          </p>
        </v-col>
        <v-col cols="12" sm="1"></v-col>
      </v-row>

      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-divider cols="12" sm="10"></v-divider>
        <v-col cols="12" sm="1"></v-col>
      </v-row>

      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-col cols="12" sm="8" align-self="center">
          <h4 class="text-h4 text-right">Explore</h4>
          <br />
          <p class="text-h5 text-grey text-right">
            Filter, query, compare, and analyze project data its associated 2D
            and 3D representations to unlock project insights and graphically
            communicate value through intuitive data visualization.
          </p>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center px-8">
          <v-img
            lazy-src="../assets/imgs/Explore-01.png"
            height="250"
            width="250"
            contain
            src="../assets/imgs/Explore-01.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="1"></v-col>
      </v-row>

      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-divider cols="12" sm="10"></v-divider>
        <v-col cols="12" sm="1"></v-col>
      </v-row>

      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center px-8">
          <v-img
            lazy-src="../assets/imgs/Curate-01.png"
            height="250"
            width="250"
            contain
            src="../assets/imgs/Curate-01.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="8" align-self="center">
          <h4 class="text-h4 text-left">Curate</h4>
          <br />
          <p class="text-h5 text-grey text-left">
            Build a custom site with analytic dashboards, interactive tools, and
            dynamic presentations to tell your unique project story for each
            audience.
          </p>
        </v-col>
        <v-col cols="12" sm="1"> </v-col>
      </v-row>

      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-divider cols="12" sm="10"></v-divider>
        <v-col cols="12" sm="1"></v-col>
      </v-row>

      <v-row class="pa-4">
        <v-col cols="12" sm="1"></v-col>
        <v-col cols="12" sm="8" align-self="center">
          <h4 class="text-h4 text-right">Collaborate</h4>
          <br />
          <p class="text-h5 text-grey text-right">
            Bring teams together to help build a project, create their own
            pages, contribute through comments, or just view a presentation, you
            control access.
          </p>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center px-8">
          <v-img
            lazy-src="../assets/imgs/Collaborate-01.png"
            height="250"
            width="250"
            contain
            src="../assets/imgs/Collaborate-01.png"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="1"></v-col>
      </v-row>
    </v-container>
    
  </v-container>
</template>

<script>
import bus from "../plugins/bus";
export default {
  data() {
    return {
      alert:true,
      colors: ["darkGrey", "darkGrey", "darkGrey"],
      slides: ["First", "Second", "Third"],
    };
  },
  created() {
    //bus.on("my_little_start_event", ()=>{console.error("my_little_start_event")});
  },
  mounted() {
    //bus.emit("my_little_start_event");
  },
};
</script>

<style scoped>
.v-btn {
  font-size: 20px;
}
</style>

<template>
    <div>

        <v-row width="100%">
            <v-col class="font-weight-light d-flex justify-space-between">
                <small class="d-flex align-center" > {{ selection.length }} / {{ options.length }}</small>
                <div>
                    
                    <v-btn-group density="compact" variant="flat" >

                    <v-btn icon size="default" @click="selectNone">
                        <v-icon color="darkGrey" >mdi:mdi-select-remove </v-icon>
                        <v-tooltip activator="parent" location="top">Select None</v-tooltip>
                    </v-btn>

                    <v-btn icon size="default" @click="selectAll">
                        <v-icon color="darkGrey" >mdi:mdi-select-all</v-icon>
                        <v-tooltip activator="parent" location="top">Select All</v-tooltip>
                    </v-btn>

                    </v-btn-group>
                    
                </div>
            </v-col>
        </v-row>

        <!-- Data Headers-->
        <v-row width="100%">
            <v-chip-group 
            multiple 
            active-class="text-primary" 
            column 
            variant="tonal"
            v-model="selection"
                @update:modelValue="onSelected">
                <v-chip 
                    v-for="item in options" :key="item" 
                    :value="item"  
                    draggable 
                    density="compact"
                    variant="flat"
                    color="primary"
                    rounded="xl"
                    class="label py-3 px-2 ma-0 ml-1 mb-1"
                    >{{ item }}
                </v-chip>
            </v-chip-group>
        </v-row>

    </div>
</template>
  
<script>
export default {
    props: ["options", "selections"],
    data: () => ({
        selection: [],
        toggle: null,
    }),
    mounted() {
        this.selection = this.selections;
    },    
    watch:{
        'selections':{
            deep: true,
            immediate: true,
            handler: function() {
                this.selection = this.selections;
            }
        }
    },
    methods: {
        onSelected() {
            this.$emit("table-changed", this.selection);
        },
        selectAll() {
            this.selection = this.options;
            this.onSelected();
        },
        selectNone() {
            this.selection = [];
            this.onSelected();
        },
    },

};
</script>
  
<style scoped>
.label {
    font-size: small;
}
</style>    
import { useEllipseStore } from "../store/EllipseStore";

function pipeline(to, from, final, guards) {
  let idx = -1;
  let nxt = args => {
    if (typeof args !== "undefined") return final(args);
    if (idx === guards.length - 1) return final();
    idx++;
    return guards[idx](to, from, nxt);
  };
  return nxt();
}

//if user is not logged in
// function authGuard(to, from, next) {
//   if (store.state.user) return next();
//   return next("/");
// }

//if logged in user is not Admin
// function adminGuard(to, from, next) {
//   console.log("store.state.user.permissions.admin",store.state.user.permissions.admin)
//   if (store.state.user && store.state.user.permissions.admin) return next();
//   else return next("/401");
// }

//redirect to /notebboks page after loggged in
function redirectToDashboard(to, from, next) {
  const ellipseStore = useEllipseStore();
  if (ellipseStore.getUser) return next("/dashboard/notebooks");
  return next();
}

export default {
  pipeline,
  // authGuard,
  // adminGuard,
  redirectToDashboard
};

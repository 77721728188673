<template>
    <!-- Filter By Card Properties -->
  <div>
    <v-container>
      <v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>
        <v-row width="100%" class="pl-2">
          <ChipGroup
            :options="getAttrHeadersNumerical" 
            :selections="this.getSelectedWidget.instance_setting.data.filteredHeaders" 
            @table-changed="updatedSelection"
          />
        </v-row>
      <v-row width="100%"><div class="text-caption pt-4"><strong>User Inputs</strong></div><v-divider/></v-row>
      <v-row width="100%" class="pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.inputVisible"
          label="Min / Max Inputs"
        />
      </v-row>
      <v-row width="100%" class="pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.filterEmptyEntries"
          label="Filter Empty Entries"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ChipGroup from "../ui/ChipGroup.vue";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  setup() {
    return {icons}
  },
  components:{
    ChipGroup,
  },
  props:{
    getSelectedWidget:{}
  },
  data:()=>({
      data_headers: [],
      loaded: false,
    }),
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderNames,
      getAttrHeadersNumerical,
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderNames, getAttrHeadersNumerical}
  },
  created(){
  },
  computed:{
  },
  methods:{
    updatedSelection(selected){
      this.getSelectedWidget.instance_setting.data.filteredHeaders = selected
    },
  }
}
</script>
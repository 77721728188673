import * as base from "./colors.base";

export const grayScale = [
	base.desktop.lightGray,
	base.desktop.darkGray
];

export const blueRedYellow = [
	base.desktop.blue,
	base.desktop.red,
	base.desktop.yellow
];

/*
████████ ██   ██ ███████ ███    ███ ███████ ██████
   ██    ██   ██ ██      ████  ████ ██      ██   ██
   ██    ███████ █████   ██ ████ ██ █████   ██   ██
   ██    ██   ██ ██      ██  ██  ██ ██      ██   ██
   ██    ██   ██ ███████ ██      ██ ███████ ██████
*/


export const metro = [
	"rgb(60, 162, 222)",
	"rgb(52, 140, 191)",
	"rgb(75, 163, 153)",
	"rgb(84, 196, 163)",
	"rgb(247, 211, 104)",
	"rgb(250, 160, 95)",
	"rgb(204, 81, 65)",
	"rgb(235, 93, 80)"
];

export const plasma = [
	"rgb(13, 8, 135)",
	"rgb(65, 4, 157)",
	"rgb(106, 0, 168)",
	"rgb(143, 13, 164)",
	"rgb(177, 42, 144)",
	"rgb(204, 71, 120)",
	"rgb(225, 100, 98)",
	"rgb(242, 132, 75)",
	"rgb(252, 166, 54)",
	"rgb(252, 206, 37)",
	"rgb(240, 249, 33)"
];

export const spectral = [
	"rgb(158, 1, 66)",
	"rgb(209, 60, 75)",
	"rgb(240, 112, 74)",
	"rgb(252, 172, 99)",
	"rgb(254, 221, 141)",
	"rgb(251, 248, 176)",
	"rgb(224, 243, 161)",
	"rgb(169, 221, 162)",
	"rgb(105, 189, 169)",
	"rgb(66, 136, 181)",
	"rgb(94, 79, 162)"
];

export const warm = [
	"rgb(110, 64, 170)",
	"rgb(160, 61, 179)",
	"rgb(210, 62, 167)",
	"rgb(249, 72, 138)",
	"rgb(255, 94, 99)",
	"rgb(255, 127, 65)",
	"rgb(239, 166, 47)",
	"rgb(205, 207, 55)",
	"rgb(175, 240, 90)"
];

export const jet = [
	"rgb(37, 16, 158)",
	"rgb(57, 51, 254)",
	"rgb(0, 199, 255)",
	"rgb(98, 252, 174)",
	"rgb(250, 255, 18)",
	"rgb(255, 134, 0)",
	"rgb(218, 44, 0)",
	"rgb(146, 29, 0)"
];

export const cyclical = [
	"rgb(110, 64, 170)",
	"rgb(210, 62, 167)",
	"rgb(255, 94, 99)",
	"rgb(239, 167, 47)",
	"rgb(175, 240, 91)",
	"rgb(64, 243, 115)",
	"rgb(26, 200, 193)",
	"rgb(65, 126, 224)",
	"rgb(110, 64, 171)"
];

export const cool = [
	"rgb(110, 64, 170)",
	"rgb(92, 90, 206)",
	"rgb(65, 125, 224)",
	"rgb(39, 163, 220)",
	"rgb(26, 199, 194)",
	"rgb(32, 227, 155)",
	"rgb(63, 243, 116)",
	"rgb(114, 246, 90)",
	"rgb(175, 240, 90)"
];

export const vidris = [
	"rgb(68, 1, 84)",
	"rgb(72, 36, 117)",
	"rgb(65, 68, 135)",
	"rgb(53, 95, 141)",
	"rgb(42, 120, 142)",
	"rgb(33, 145, 140)",
	"rgb(34, 168, 132)",
	"rgb(68, 191, 112)",
	"rgb(122, 209, 81)",
	"rgb(189, 223, 38)",
	"rgb(253, 231, 37)"
];

export const inferno = [
	"rgb(0, 0, 4)",
	"rgb(22, 11, 57)",
	"rgb(66, 10, 104)",
	"rgb(106, 23, 110)",
	"rgb(147, 38, 103)",
	"rgb(188, 55, 84)",
	"rgb(221, 81, 58)",
	"rgb(243, 120, 25)",
	"rgb(252, 165, 10)",
	"rgb(246, 215, 70)",
	"rgb(252, 255, 164)"
];

export const magma = [
	"rgb(0, 0, 4)",
	"rgb(20, 14, 54)",
	"rgb(59, 15, 112)",
	"rgb(100, 26, 128)",
	"rgb(140, 41, 129)",
	"rgb(183, 55, 121)",
	"rgb(222, 73, 104)",
	"rgb(247, 112, 92)",
	"rgb(254, 159, 109)",
	"rgb(254, 207, 146)",
	"rgb(252, 253, 191)"
];


export const purpleOrange = [
	"rgb(45, 0, 75)",
	"rgb(85, 45, 132)",
	"rgb(129, 112, 172)",
	"rgb(176, 170, 208)",
	"rgb(215, 215, 233)",
	"rgb(243, 238, 234)",
	"rgb(253, 221, 179)",
	"rgb(248, 182, 100)",
	"rgb(221, 132, 31)",
	"rgb(178, 90, 9)",
	"rgb(127, 59, 8)"
];

export const redYellowGreen = [
	"rgb(165, 0, 38)",
	"rgb(212, 50, 44)",
	"rgb(241, 110, 67)",
	"rgb(252, 172, 99)",
	"rgb(254, 221, 141)",
	"rgb(249, 247, 174)",
	"rgb(215, 238, 142)",
	"rgb(164, 216, 110)",
	"rgb(100, 188, 97)",
	"rgb(34, 150, 79)",
	"rgb(0, 104, 55)"
];

export const redYellowBlue = [
	"rgb(165, 0, 38)",
	"rgb(212, 50, 44)",
	"rgb(241, 110, 67)",
	"rgb(252, 172, 100)",
	"rgb(254, 221, 144)",
	"rgb(250, 248, 193)",
	"rgb(220, 241, 236)",
	"rgb(171, 214, 232)",
	"rgb(117, 171, 208)",
	"rgb(74, 116, 180)",
	"rgb(49, 54, 149)"
];

export const pinkYellowGreen = [
	"rgb(142, 1, 82)",
	"rgb(192, 38, 126)",
	"rgb(221, 114, 173)",
	"rgb(240, 179, 214)",
	"rgb(250, 221, 237)",
	"rgb(245, 243, 239)",
	"rgb(225, 242, 202)",
	"rgb(182, 222, 135)",
	"rgb(128, 187, 71)",
	"rgb(79, 145, 37)",
	"rgb(39, 100, 25)"
];




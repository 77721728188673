<template>
  <div>
    <v-row class="pt-10">
      <v-col cols="2"></v-col>
      <v-col cols="2">
        <div>
          <v-avatar rounded="1" size="100" :image="loggedInUser.picture">
          </v-avatar>
        </div>
      </v-col>

        <v-col cols="6" class="px-5">
          <div class="text-h3">{{ loggedInUser.name }}</div>
          <small><i>{{ loggedInUser.email }}</i></small>
          <div class="text-base">{{ loggedInUser.org_name }}</div>
      </v-col>
    </v-row>
    
    <v-row class="pt-6">
      <v-col cols="2"></v-col>
      <v-col cols="2">
        <div class="pt-3" v-if="loggedInUser.social_media">
          <small>Social Media</small>
          <v-text-field class="py-1" label="Linkedin" variant="outlined" hide-details density="compact"
            v-model="loggedInUser.social_media.linkedin"></v-text-field>
          <v-text-field class="py-1" label="Twitter" variant="outlined" hide-details density="compact"
            v-model="loggedInUser.social_media.twitter"></v-text-field>
          <v-text-field class="py-1" label="Facebook" variant="outlined" hide-details density="compact"
            v-model="loggedInUser.social_media.facebook"></v-text-field>
          <v-text-field class="py-1" label="Instagram" variant="outlined" hide-details density="compact"
            v-model="loggedInUser.social_media.instagram"></v-text-field>
        </div>
      </v-col>
      <v-col class="pt-5" cols="6">
        <div class="px-5">
          <v-row class="pt-5">
            <v-col cols="6">
              <v-text-field :disabled="hasOrg()" label="Name" class="pr-1" variant="outlined" hide-details 
                v-model="loggedInUser.name"></v-text-field></v-col>
            <v-col cols="6">
              <v-text-field label="Website" class="pl-1" variant="outlined" hide-details 
                v-model="loggedInUser.website"></v-text-field></v-col>
          </v-row>
          <v-row class="pt-2">
            <v-col cols="6">
              <v-text-field :disabled="hasOrg()" label="Company / Organization" class="pr-1" variant="outlined" hide-details
               v-model="loggedInUser.org_name"></v-text-field></v-col>
            <v-col cols="6">
              <v-text-field :disabled="hasOrg()" class="pl-1" label="Title" variant="outlined" hide-details
                 v-model="loggedInUser.title">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-2">
            <v-col cols="6"><v-select class="pr-1 text-capitalize" style="text-transform: capitalize;" label="Country"
                variant="outlined" hide-details  v-model="loggedInUser.country" :items="contriesName"></v-select></v-col>
            <v-col cols="6"><v-select class="pl-1" label="Category" variant="outlined" hide-details :items="categories"
                v-model="loggedInUser.category"></v-select></v-col>
          </v-row>
          <v-textarea class="pt-2" label="Bio" variant="outlined" hide-details auto-grow rows="5" row-height="20"
            v-model="loggedInUser.bio"></v-textarea>
        </div>
        <div class="float-right pt-3 pr-5">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary text-white" :loading="updateUserLoading" @click="updateUser">
              Save
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>
    </v-row>
    <v-snackbar 
      v-model="snackbar" 
      location="bottom" 
      absolute 
      color= "secondary" 
      timeout="2000" 
      class="pb-8">
      <div class="text-center">
        {{confirmationMessage}}
      </div>
    </v-snackbar>

  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

import wcc from "world-countries-capitals";
export default {
  setup() {
    const ellipseStore = useEllipseStore()
    const {
      getUser,
    } = storeToRefs(ellipseStore)
    const {setUser} = ellipseStore
    return {getUser, setUser}
  },
  data() {
    return {
      categories: [
        "Other",
        "Design",
        "Architecture",
        "Engineering",
        "Apparel",
        "Automotive",
        "Product",
        "Construction",
        "Consultant",
        "Contractor",
        "Software Development",
        "Education",
        "Fabrication",
        "Municipal",
        "Multinational",
        "Personal",
        "Realty",
        "Supplier",
      ],
      contriesName: [],
      loggedInUser: {},
      snackbar:false,
      confirmationMessage:"User Profile Updated",
      updateUserLoading: false,
    };
  },
  mounted() {
    this.getLoggedInUser();
    const contries = wcc.getAllCountries();
    contries.forEach(element => {
      let newStr = element.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
      this.contriesName.push(newStr)
    });

    // console.log(this.contriesName);
  },
  computed: {
    active: {
      get() {
        if (this.getUser.permissions.admin) {
          return 0;
        } else {
          return 1;
        }
      },
      set(newValue) {
        return;
      },
    },
  },
  methods: {
    hasOrg(){
      return this.getUser.org_id!=null;
    },
    async getLoggedInUser() {
      this.loggedInUser = {
        name: this.getUser.name,
        email: this.getUser.email,
        location: this.getUser.location ? this.getUser.location : "",
        picture: this.getUser.picture ? this.getUser.picture : "",
        title: this.getUser.title ? this.getUser.title : "",
        org_name: this.getUser.org_name ? this.getUser.org_name : "",
        website: this.getUser.website ? this.getUser.website : "",
        country: this.getUser.country ? this.getUser.country : "",
        category: this.getUser.category ? this.getUser.category : "",
        bio: this.getUser.bio ? this.getUser.bio : "",
      };
      // console.log(this.loggedInUser)
      if (this.getUser.social_media) {
        let social_media = {
          linkedin: this.getUser.social_media.linkedin,
          twitter: this.getUser.social_media.twitter,
          facebook: this.getUser.social_media.facebook,
          instagram: this.getUser.social_media.instagram,
        };
        this.loggedInUser.social_media = social_media;
      } else {
        let social_media = {
          linkedin: "",
          twitter: "",
          facebook: "",
          instagram: "",
        };
        this.loggedInUser.social_media = social_media;
      }
      // console.log("loggedInUser", this.loggedInUser);
    },
    async updateUser() {
      this.updateUserLoading = true;
      let postBody = {
        user: this.loggedInUser,
      };
      // console.log("postBody", postBody);
      let updateUser = await this.$auth.$api.post(
        `/api/user/${this.getUser._id}/edit-info`,
        postBody
      );
      // console.log("updateUser", updateUser.data.data);
      await this.setUser(updateUser.data.data);
      await this.getLoggedInUser();
      this.updateUserLoading = false;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped></style>

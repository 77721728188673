<template>
  <div>
    <v-card 
      class="commentCard" 
      :class="commentId ? '' : 'addOutline'"
      elevation="0"
      @click="setSelectedComment()">

      <v-textarea
        color="primary"
        density="compact"
        placeholder="Add a comment here..."
        rows="1"
        v-model="newCommentText"
        auto-grow
        flat
        hide-details
        variant="outlined"
      ></v-textarea>

      <SaveCancel
        @save="saveComment"
        @cancel="$emit('cancelClick')"
        :loading="addCommentLoading"
      ></SaveCancel>
    </v-card>
  </div> 
</template>

<script>
import UserInfo from "@/components/ui/UserInfo.vue";
import SaveCancel from "@/components/ui/SaveCancel.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useCommentsStore} from "@/store/CommentsStore.js";
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: {
    UserInfo,
    SaveCancel,
  },
  props: ['commentId'],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const commentsStore = useCommentsStore()
    const ellipseStore = useEllipseStore()
    const {
      getSelectedElements,
      getSelectedPage,
    } = storeToRefs(notebookPropsStore)
    const {
      getAllComments,
      getSelectedComment,
      getSelectedCommentFromFeed,
    } = storeToRefs(commentsStore)
    const {
      getUser,
      getNotebook,
    } = storeToRefs(ellipseStore)
    const { 
      updateAllCommentsInStore,
      setSelectedComment,
      setSelectedCommentFromFeed,
    } = commentsStore
    return {notebookPropsStore, commentsStore,
      getSelectedElements, getSelectedPage, getNotebook, getUser,
      getSelectedComment, getAllComments, getSelectedCommentFromFeed,
      updateAllCommentsInStore, setSelectedComment, setSelectedCommentFromFeed,
    }
  },
  data() {
    return {
      newCommentText: "",
      addCommentLoading: false,                                                                                                           
    };
  },
  computed: {
    getPresentTimeAndDate() {
      var now = new Date();
      let timeAndDate = moment(now).format("MMMM Do YYYY");
      return timeAndDate;
    },
  },
  async created() {
  },
  mounted(){
  },
  watch:{
  },
  methods: {
    async saveComment(){
      this.addCommentLoading = true
      let postData = {
        notebook: this.getNotebook._id,
        page: this.getSelectedPage._id,
        comments: this.newCommentText,
        parent: this.commentId ? this.commentId : null,
      };
      let addedComment = await this.$auth.$api.post(`/api/comments/new`, postData);
      this.addCommentLoading = false
      this.$emit('commentAdded')
    },
    setSelectedComment(){
      this.setSelectedComment(null)
    }
  },
};
</script>

<style scoped>
  .commentCard {
    margin-bottom: 4px !important;
    padding: 2px !important;
    padding-top: 2px !important;
  }
  .commentCard.addOutline{
    border: 1px solid rgb(var(--v-theme-darkGrey));
  }
</style>
<template>
    <!-- List Widget Properties -->
  <div>
    <v-container>
<v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>
      <!-- Select Attribute to List for each Element. -->
      <v-row width="100%" class="pt-4 pl-2">
          <v-combobox
            v-model="getSelectedWidget.instance_setting.data.selectedItem"
            :items="getAttrHeaderNames"
            :label="'Select Attribute ('+getAttrHeaderNames.length+')'" 
          ></v-combobox>
      </v-row>
<v-row width="100%"><div class="text-caption pt-4"><strong>Display Settings</strong></div><v-divider/></v-row>
      <!-- Set to Chip View. -->
      <v-row width="100%" class="pt-0 pl-2">
          <v-switch
            v-model="getSelectedWidget.instance_setting.data.viewChips"
            label="Tiles"
          />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  props:{
    getSelectedWidget:{},
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderNames
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderNames}
  },
  data:()=>({
    data_headers:[],
  }),
  mounted(){
      //TODO - this should happen at created, then listen for updates to storeG
      //this.loadTableHeders();
  },
  computed:{
    attrHeaders(){
      let i = 0;
      let newHeaders = this.getAttrHeaderNames.map((e) => {
        return { id: i++, name: e };
      });
      return newHeaders;
    }
  },
  methods:{
    async loadTableHeders(){
      let i = 0;
      this.data_headers = this.getTableHeader
    },
  }
}
</script>
<template>
  <div class="px-5">
    <AssetsManage :assetType="'map_widget'"  :description="description" />
  </div>
</template>

<script>
import AssetsManage from "./SidePanelAssetsManage.vue";

export default {
  components: { AssetsManage },
  props: {
    description:{
      type:String, 
      default:""
    }
  },
  setup() {},
};
</script>

<template>
  <div style="border-right: 1px solid #9e9e9e !important; height: 100%">
    <v-list
      style="overflow-y: auto"
    >
      <v-list-item to="/dashboard/notebooks">
        <template v-slot:prepend>
          <v-icon>{{ lexicon.notebook.icon }}</v-icon>
        </template>
        <v-list-item-title>Notebooks</v-list-item-title>
      </v-list-item>
      <v-list-item to="/dashboard/templates">
        <template v-slot:prepend>
          <v-icon>{{ lexicon.template.icon }}</v-icon>
        </template>
        <v-list-item-title>Templates</v-list-item-title>
      </v-list-item>
      <v-list-item to="/dashboard/layouts">
        <template v-slot:prepend>
          <v-icon>{{ lexicon.layout.icon }}</v-icon>
        </template>
        <v-list-item-title>Page Layouts</v-list-item-title>
      </v-list-item>
    </v-list>

    <div class="feedsStyle pa-1">
      <v-divider class="mx-2"></v-divider>
      <v-card flat class="px-0 mb-2 mx-0">
        <v-card-title class="defaultText pt-2 pb-1 pl-3" style="font-weight: 400; color:rgb(var(--v-theme-darkSlate));">
          <div class="text-truncate">
        <v-icon medium color="darkGrey">{{ lexicon.mention.icon }}</v-icon>
        <span class="pl-4">Mentions<HelpButton :lexiconItem="lexicon.mention"/></span>
      </div>
      </v-card-title>
      <v-card-text class="pl-4 px-0 mx-0 pb-1">
        
      <small class="">Click a comment to open its page</small>
          <v-hover v-for="mention in allMentions" :key="mention._id" 
        v-slot="{ hover }">
        <v-card flat variant="outlined" color="secondary" @click="clickOnFeed(mention)" class="text-truncate commentCard mr-4 ml-0 my-1  " 
          
          >
          <UserInfo
            :avatar="mention.createdBy.picture"
            :name="mention.createdBy.name"
            :timeStamp="$filters.momentTimestamp(mention.createdAt)"
          ></UserInfo>
        <v-card-text class="text-truncate py-0 px-2 ma-0"> 
          <span class="text-caption">
              {{ mention.comments }}
          </span>
        </v-card-text>
        </v-card>
        </v-hover>
      </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/ui/UserInfo.vue"
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";
import HelpButton from "../components/ui/HelpButton.vue";

import { storeToRefs } from 'pinia';
import {useCommentsStore} from "@/store/CommentsStore.js";

export default {
  components: { UserInfo, HelpButton },
  setup() {
    const commentsStore = useCommentsStore()
    const {
      setSelectedCommentFromFeed
    } = commentsStore
    return {
      setSelectedCommentFromFeed, 
      lexicon, 
      icons
    }
  },
  data() {
    return {
      selectedItem: 0,
      showFeed: true,
      allMentions: [],
    };
  },
  created() {},
  async mounted() {
    let mentions = await this.$auth.$api.get(`/api/comments/get-all-mentioned-comments`);
    if(mentions.data.data.length > 0){
      let sortMentions = mentions.data.data.reverse();
      this.allMentions = sortMentions;
    }
  },
  methods: {
    async clickOnFeed(item) {
      let notebookId = item.notebook;
      let comment = item;
      await this.setSelectedCommentFromFeed(comment);
      this.$router.push(`/notebook/${notebookId}`);
    },
  },
};
</script>

<style scoped>
.feedsStyle {
  height: 70%;
  overflow-y: auto;
}
.feedsStyle::-webkit-scrollbar {
  display: none !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 15px !important;
}
</style>

<template>
    <div>
        <v-row class="pt-10">
            <v-col cols="2"></v-col>
            <v-col cols="2">
                <div>
                    <v-avatar rounded="1" size="100" :image="displayUser.picture">
                    </v-avatar>
                </div>
            </v-col>

            <v-col cols="6" class="px-5">
                <div class="text-h3">{{ displayUser.name }}</div>
                <small><i>{{ displayUser.email }}</i></small>
            </v-col>
        </v-row>

        <v-row class="pt-10">
            <v-col cols="2"></v-col>
            <v-col cols="8">
                <v-list>
                    <h3>Application Preferences</h3>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-checkbox v-model="loggedInUser.preferences.helpers"
                            label="Show Help and Information Hints"></v-checkbox>
                    </v-list-item>
                </v-list>
                <v-card variant="flat">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="updateUserLoading" @click="updateUser">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" location="bottom" absolute color="secondary" timeout="2000" class="pb-8">
            <div class="text-center">
                {{ confirmationMessage }}
            </div>
        </v-snackbar>

    </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useEllipseStore } from "@/store/EllipseStore.js";

export default {
    setup() {
        const ellipseStore = useEllipseStore()
        const {
            getUser,
        } = storeToRefs(ellipseStore)
        const { setUser } = ellipseStore
        return { getUser, setUser }
    },
    data() {
        return {
            loggedInUser: {},
            displayUser: {},
            snackbar: false,
            confirmationMessage: "User Preferences Updated",
            updateUserLoading: false,
        };
    },
    created() {
        this.getLoggedInUser();
    },
    mounted() {
        this.getLoggedInUser();
    },
    computed: {
        active: {
            get() {
                if (this.getUser.permissions.admin) {
                    return 0;
                } else {
                    return 1;
                }
            },
            set(newValue) {
                return;
            },
        },
    },
    methods: {
        async getLoggedInUser() {

            this.displayUser = {
                name: this.getUser.name,
                email: this.getUser.email,
                picture: this.getUser.picture ? this.getUser.picture : "",
            };

            this.loggedInUser.preferences = !('preferences' in this.getUser) ? {} : this.getUser.preferences
            this.loggedInUser.preferences.helpers = !('helpers' in this.getUser) ? true : this.getUser.preferences.helpers

        },
        async updateUser() {
            this.updateUserLoading = true;
            let postBody = {
                user: this.loggedInUser,
            };
            let updateUser = await this.$auth.$api.post(
                `/api/user/${this.getUser._id}/edit-info`,
                postBody
            );
            await this.setUser(updateUser.data.data);
            await this.getLoggedInUser();
            this.updateUserLoading = false;
            this.snackbar = true;
        },
    },
};
</script>

<style scoped></style>
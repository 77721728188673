<template>
    <!-- Filter By Card Properties -->
  <div >
    <v-container>    
<v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>
      <!-- Count of Elements in Dataset. -->
      <v-row width="100%" class="pt-4 pl-2">
        <!-- Attribute to Group Selection Dropdown -->
        <v-combobox
          v-model="getSelectedWidget.instance_setting.data.selectedAttribute"
          :items="getAttrHeaderOptions"
          :label="'Select Attribute ('+getAttrHeaderOptions.length+')'"
        />
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  props:{
    getSelectedWidget:{}
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderOptions}
  },
  computed:{
  },
  methods:{}
}
</script>
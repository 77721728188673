<template>
    <!-- Score Card Widget Properties -->
    <div >

      <v-container>    
        <v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>
            
        <!-- Attribute to Summarize Selection Dropdown -->
        <v-row width="100%" class="pt-4 pl-2">
          <v-col>
            <v-combobox
              v-model="getSelectedWidget.instance_setting.data.selectedAttr"
              :items="getAttrHeaderOptions"
              :label="'Select Attribute ('+getAttrHeaderOptions.length+')'" 
            ></v-combobox>
          </v-col>
        </v-row >

        <!-- Summary Type Selection Dropdown -->
        <v-row width="100%" class="pt-4 pl-2">
          <v-combobox
            v-model="getSelectedWidget.instance_setting.data.selectedScoreType"
            :items="getSelectedWidget.instance_setting.data.scoreTypes"
            :disabled="getSelectedWidget.instance_setting.data.selectedAttrType =='String'"
            label="Summary Type"
          />
        </v-row>

        <v-row width="100%"><div class="text-caption pt-2"><strong>User Input</strong></div><v-divider/></v-row>
        
        <!-- Toggle to Show in Vue Mode -->
        <v-row class="pl-2">
            <v-switch
            color="primary"
            v-model="getSelectedWidget.instance_setting.data.showFieldsSelectors"
            label="Change in Viewer"
            />
        </v-row>

        <!-- include filtered values in results -->
        <v-row width="100%" class="pt-0 pl-2">
          <v-switch
            v-model="getSelectedWidget.instance_setting.data.includeFilteredValues"
            label="Include Filtered Values"
          />
        </v-row>
      </v-container>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";

export default {
  components: {
  },
  props:{
    getSelectedWidget:{}
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderOptions
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeaderOptions}
  },
  data:()=>({
  }),
  computed:{
  },
  methods:{}
}
</script>

<style scoped>

</style>
<template>        
    <v-container v-if="(item.name != item.content && item.name != '')" class="px-0 py-0 d-flex justify-center">
      <span class="WidgetHeader pl-0 pt-0">{{ item.name }}</span>
      <v-tooltip location="bottom" activator="parent">{{item.description}}</v-tooltip>
    </v-container>
</template>

<script>
export default {
  props: {
    item: {
        type: Object,
        required: true
    }
  }
}
</script>

<style scoped>
</style>
<template>
  <div class="d-flex py-1">
    <v-spacer></v-spacer>
    <v-btn
      size="x-small"
      variant="outlined"
      color="darkGrey"
      class=" outline rounded mr-1"
      @click="$emit('cancel')"
    >
      Cancel
    </v-btn>
    <v-btn
      size="x-small"
      variant="outlined"
      color="primary"
      class="rounded"
      @click="$emit('save')"
      :loading="loading"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
      type: Boolean,
      required: true,
    } 
  },
};
</script>

<style scoped>
</style>

import { getInstance } from "./index";
import { useEllipseStore } from "@/store/EllipseStore.js";

export const authGuard = async (to, from) => {
  const ellipseStore = useEllipseStore();
  const authService = getInstance();
  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if (authService.userDB) {
        ellipseStore.setUser(authService.userDB);
        return true;
      }
      else {
        if (to.name === "Visitor") {
          return true;
        } else {   
          await authService.updateStateVars();
          await authService.$api
            .post("/api/users", authService.user)
            .then((returnedUser) => {
              authService.userDB = returnedUser.data;
              ellipseStore.setUser(authService.userDB)
              
              return true;
            })
            .catch((error) => {
              console.error("Error while hitting a backend api", error);
            });
        }
      }
    } else {
       return '/';
      }
  };

  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};

export const adminGuard = async (to, from) => {
  const authService = getInstance();
  const ellipseStore = useEllipseStore();
  if(authService.isAuthenticated){
    if (authService.userDB) {
      if(authService.userDB.permissions.admin){
        ellipseStore.setUser(authService.userDB);
        return true;
      }else{
        return '/401';
      }
      }else{
          await authService.updateStateVars();
          await authService.$api.post('/api/users', authService.user).then(returnedUser => {
          authService.userDB = returnedUser.data})
          if(authService.userDB.permissions.admin){
            ellipseStore.setUser(authService.userDB);
            return true;
          }else{
            return '/401';
          }
      }
    }else{
    return '/401';
    }
};

export const redirectToDashboard = async (to, from) => {
  const authService = getInstance();
  await authService.updateStateVars();
  if (authService.isAuthenticated) {
    return '/dashboard/notebooks';
  } else {
     return true;
    }
};

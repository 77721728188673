<template>

    <v-alert v-if="getUser.preferences.helpers" 
    class=" text-caption" 
    close-label="Close Alert" 
    
    density="compact"
    closable
    color="secondary" 
    border="start" 
    :icon="icon">
        <small>
            {{ message }}
        </small>
    </v-alert>

</template>

<script>
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
props: {
icon: {
  type: String,
  required: false,
  default: null,
},
message: {
  type: String,
  required: false,
  default: "Coming Soon..."
},
title: {
  type: String,
  required: false,
  default:"",
},
small: {
  type: Boolean,
  required: false,
  default: false,
},
},
setup(){
const ellipseStore = useEllipseStore()
const {
  getUser,
} = storeToRefs(ellipseStore)
return {
  icons,getUser
}
},
mounted() {
},    
watch:{
},
methods: {
},

};
</script>
<style>
</style>
<template>
  <div >
    <v-container>    
      <v-row width="100%"><div class="text-caption pt-0"><strong>Attributes</strong></div><v-divider/></v-row>

      <v-row width="100%" class="pt-4 pl-2">
        <!-- Attribute to Group Selection Dropdown -->
        <v-combobox
          v-model="getSelectedWidget.instance_setting.data.selectedGroupAttribute"
          :items="getAttrHeadersCategorical"
          :label="'Select Attribute ('+getAttrHeaderOptions.length+')'"
        />
      </v-row>
      <v-row width="100%" class="pt-4 pl-2">
        <!-- Calculation Type Selection Dropdown -->
        <v-combobox
          v-model="getSelectedWidget.instance_setting.data.selectedCalc"
          :items="calcTypes"
          label="Calculation Type"
        />
      </v-row>
      <v-row width="100%" class="pt-4 pl-2">
        <!-- Attribute to Calculate Selection Dropdown -->
        <v-combobox
          v-model="getSelectedWidget.instance_setting.data.selectedCalcAttribute"
          :items="getAttrHeadersNumerical"
          :disabled="getSelectedWidget.instance_setting.data.selectedCalc == 'Count'"
          label="Select Calculation Field"
        />
      </v-row>
      <v-row width="100%"><div class="text-caption pt-4"><strong>Display Settings</strong></div><v-divider/></v-row>

      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.viewChips"
          label="Display Tiles"
        />
      </v-row>

      <!-- include filtered values in results -->
      <v-row width="100%" class="pt-0 pl-2">
        <v-switch
          v-model="getSelectedWidget.instance_setting.data.includeFilteredValues"
          label="Include Filtered Values"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";

export default {
  components: {
  },
  setup() {
    const dataGraphicsStore = useDataGraphicsStore()
    const {
      getAttrHeaderOptions,
      getAttrHeadersCategorical,
      getAttrHeadersNumerical,
    } = storeToRefs(dataGraphicsStore)
    return {getAttrHeadersCategorical, getAttrHeaderOptions, getAttrHeadersNumerical}
  },
  props:{
    getSelectedWidget:{}
  },
  data(){
    return {
      calcTypes: ["Sum", "Count", "Mean", "Median", "Min", "Max"],
    };
  },
  computed:{
  },
  methods:{}
}
</script>

<style scoped>

</style>
<template>
  <div class="sidePanelMargin">
    <div class="sidePanelHeader">
      <div class="sidePanelHeaderInner">
        <span class="header">Widget Settings <HelpButton :lexiconItem="lexicon.widget"/></span>
      </div>
      <div>
        <v-btn variant="text" size="small" 
          icon @click="goBackToGlobalProperties()" 
        >
          <v-icon size="medium"
            color="darkSlate">{{ icons.cancel}}
          </v-icon>
          <v-tooltip activator="parent" location="bottom">Deselect Widget</v-tooltip>
        </v-btn>
      </div>
    </div>
    <div class="divider">
      <v-divider></v-divider>
    </div>

    <v-expansion-panels multiple flat density="compact">
      <v-expansion-panel elevation="0">
            <v-menu offset-y :close-on-content-click="false" open-on-hover>
          <template v-slot:activator="{ props }">
        <v-expansion-panel-title v-bind="props" class="font-weight-medium dense-expansion-panel" style="align-content:left;">
          <span>

            <!-- Widget Button. -->
          <v-icon v-if="widgetIcon.icon" :size="widgetIcon.iconsize" mb2 class=" pl-0 ml-0 py-0 my-0" color="primary">{{
            widgetIcon.icon
          }}</v-icon>

        
          <h3 v-else>{{ widgetIcon.text }}</h3>
          <span class="pl-2">{{ widgetIcon.text }} Settings</span>
        </span>
        </v-expansion-panel-title>

          </template>

          <v-card :title="widgetIcon.text" :subtitle="widgetIcon.description">
            <v-card-actions>
              <v-btn color="secondary" :href="widgetIcon.documentation" target="_blank"> Learn More</v-btn>
            </v-card-actions>
          </v-card>

        </v-menu>

        <v-expansion-panel-text class="">

          <v-row class="align-center py-0 my-0">
            <v-col>
              <v-text-field hide-details variant="underlined" v-model="getSelectedWidget.name" density="compact" flat class="py-0 my-0"
                placeholder="Widget Name"></v-text-field></v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea hide-details v-model="getSelectedWidget.description" variant="underlined" density="compact" rows="1" auto-grow flat
                class="py-0 my-0" placeholder="Widget Description"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="py-2">
            <v-col cols="3">
              <v-text-field label="X" type="number" v-model.number="getSelectedWidget.x" variant="outlined" density="compact" hide-details
                class="pr-1 py-1 my-0"></v-text-field></v-col>
            <v-col cols="3">
              <v-text-field label="Y" type="number" v-model.number="getSelectedWidget.y" variant="outlined" density="compact" hide-details
                class="pr-1 py-1 my-0"></v-text-field></v-col>
            <v-col cols="3">
              <v-text-field label="W" type="number" v-model.number="getSelectedWidget.w" variant="outlined" density="compact" hide-details
                class="pr-1 py-1 my-0"></v-text-field></v-col>
            <v-col cols="3">
              <v-text-field label="H" type="number" v-model.number="getSelectedWidget.h" variant="outlined" density="compact" hide-details
                class="pr-1 py-1 my-0"></v-text-field></v-col>
          </v-row>
          <v-divider dark class="my-1"></v-divider>
          <v-row>
            <v-col cols="10" class="d-flex align-center"> Lock Grid</v-col>
            <v-col cols="2">
              <v-switch v-model="getSelectedWidget.local_setting.is_locked" color="primary"
                @click="lockGrid()">
              </v-switch>
            </v-col>
          </v-row>

          <v-divider dark class="mt-2 mb-1"></v-divider>

          <v-expansion-panels flat density="compact" hover>
            <v-expansion-panel elevation="0">
              <v-expansion-panel-title class="font-weight-medium"><span style="margin-left: -22px">Foreground Color</span>
                <template v-slot:actions>
                  <v-icon :color="getSelectedWidget.local_setting.foreground_color" large class="elevation-4;"> 
                    {{ icons.circle}}
                  </v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text style="margin-left: -15px">
                <v-color-picker class="px-0 mx-0" v-model="getSelectedWidget.local_setting.foreground_color" dot-size="3" canvas-height="80"
                  width="230" mode="hexa" swatches-max-height="10"></v-color-picker>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-divider dark class="mt-1 py-0"></v-divider>
    <WidgetInstance />
  </div>
</template>
<script>
import WidgetInstance from "@/components/SidePanelSettingsWidgetInstance.vue";
import HelpButton from "../components/ui/HelpButton.vue";

import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  components: {
    WidgetInstance,
    HelpButton,
  },
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const {
      getSelectedWidget,
      getWidgets
    } = storeToRefs(notebookPropsStore)
    const { 
      setWidgets
    } = notebookPropsStore
    return {notebookPropsStore, getSelectedWidget, getWidgets, setWidgets, lexicon, icons}
  },
  data() {
    return {
      lock: false,
      filter: false,
      filterable: false,
      highlight: false,
      highlightable: false,
      select: false,
      selectable: false,
      alert: false,
    };
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(
        this.getSelectedWidget.content
      );
      return result;
    },
  },

  methods: {
    goBackToGlobalProperties() {
      this.notebookPropsStore.$patch({selectedWidget: null})
    },
    lockGrid() {
      const index = this.getWidgets.findIndex(
        (e) => e.i == this.getSelectedWidget.i
      );
      this.getWidgets[index].local_setting.is_locked =
        this.getSelectedWidget.local_setting.is_locked;
      this.getWidgets[index].lock_color =
        this.getSelectedWidget.local_setting.is_locked == true
          ? "primary"
          : "darkGrey";
      this.setWidgets(this.getWidgets)
    },
  },
};
</script>
<style scoped lang="scss">
.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.row {
  padding-top: 8px !important;
}

.dense-expansion-panel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 1 !important;
  padding-right: 1 !important;
}
.v-expansion-panel-text__wrapper{
  padding: 0px !important;
}
</style>

<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner -->
    <Spinner  :show="getDatasetLoading" ></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <div v-if="getAttrData.length > 0">
      <!-- Button Row. -->
      <v-row>

        <v-col class="d-flex ">

          <!-- Label -->
          <v-btn
            :size="isPanel ? 'x-small' : 'small'"
            variant="outlined"
            prepend-icon= "mdi:mdi-cursor-default-click"
            :color="isPanel ? 'secondary' : 'darkSlate'"
            @click="selectAll()"
            class="mr-1"
            style="min-width: 24%;"
          >
            <span>
              {{remainingElements.length-getSelectedElements.length}}
            </span>

            <v-tooltip activator="parent" location="top">
              <span>Select  {{remainingElements.length-getSelectedElements.length }} Remaining Elements</span>
            </v-tooltip>

          </v-btn>

          <v-btn
            :size="isPanel ? 'x-small' : 'small'"
            variant="outlined"
            prepend-icon="mdi:mdi-close"
            color="darkGrey"
            @click="clearSelection()"
            class="mr-1"
            style="min-width: 24%;"
          >
            <span>
              {{getSelectedElements.length }}
            </span>

            <v-tooltip activator="parent" location="top">
              <span>Clear {{getSelectedElements.length }} Selected Elements</span>
            </v-tooltip>
          </v-btn>

          <v-spacer></v-spacer>

        <!-- Hide Selection Button. -->
          <v-btn
            :size="isPanel ? 'x-small' : 'small'"
            :prepend-icon="hideButtonOutlined ? icons.notVisible : icons.visible "
            :color="hideButtonOutlined ? 'darkSlate' : 'darkGrey'"
            @click="addHideFilter()"
            class="mr-1"
            style="min-width: 24%;"
          >
            <span v-if="hideButtonOutlined">
              Hide
            </span>
            <span v-else>
              Show 
            </span>

            <v-tooltip activator="parent" location="top">
            <span v-if="hideButtonOutlined">
              Hide {{ getSelectedElements.length }} Elements
            </span>
            <span v-else>
              Show {{ filterElements.length }} Elements
            </span>
            </v-tooltip>

          </v-btn>
          <!-- Isolate By Selection Button. -->
        
          <v-btn
            :size="isPanel ? 'x-small' : 'small'"
            :prepend-icon="isolateButtonOutlined ? icons.check : icons.cancel "
            :color="isolateButtonOutlined ? 'darkSlate' : 'darkGrey'"
            
            @click="addIsolateFilter()"
            style="min-width: 24%;"
          >
            <span v-if="isolateButtonOutlined">
              Isolate
            </span>
            <span v-else>
              Release
            </span>
            
            <v-tooltip activator="parent" location="top">
            <span v-if="isolateButtonOutlined">
              Isolate {{ getSelectedElements.length }} Elements
            </span>
            <span v-else>
              Release {{ filterElements.length }} Elements
            </span>
            </v-tooltip>
          </v-btn>

        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import WidgetIcon from "../ui/WidgetIcon.vue"
import WidgetTitle from "../../components/ui/WidgetTitle.vue";
import Spinner from "../ui/Spinner.vue";
import NoDataWarning from "../ui/NoDataWarning.vue";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore} from "@/store/DataGraphicsStore.js";
import {useAssetsStore} from "@/store/AssetsStore.js";

export default {
  components: {WidgetIcon, NoDataWarning, Spinner, WidgetTitle},
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getSelectedElements, 
      getDatasetLoading,
      getGlobalProperty 
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getFilterByData
    } = storeToRefs(dataGraphicsStore)
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore
    return {notebookPropsStore, dataGraphicsStore, getGlobalProperty,
    getEditMode, getSelectedElements, getAttrData, getFilterByData, getSelectedPageDatasets, getDatasetLoading,
    addFilter, updateFilterByProperties, icons}
  },
  data() {
    return {
      filterType: "Elements",
      filterElements:[],
      isIsolate: false,
      datasetSwitchingLoading:false,
      isPanel: false,
      filterObject: {
        filterType:this.filterType,
        isolate: this.isIsolate,
        elements: this.filterElements,
      },
    };
  },
  created(){
    if(this.grid_item.instance_setting.isPanelWidget) this.isPanel = this.grid_item.instance_setting.isPanelWidget;

    this.verifyInstanceSettings();
  },
  mounted(){
    this.verifyInstanceSettings();

    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
  },
  computed: {
    hideButtonColor(){
      if(this.getSelectedElements.length === 0 && this.filterElements.length===0){
        return 'darkGrey'
      }else if(this.getSelectedElements.length >0 && this.filterElements.length===0){
        return 'primary'
      } else if(this.filterElements.length>0 && this.isIsolate==false) {
        return 'primary'
      } else {
        return 'darkGrey'
      }
    },
    isolateButtonColor(){
      if(this.getSelectedElements.length === 0 && this.filterElements.length===0){
        return 'darkGrey'
      }else if(this.getSelectedElements.length >0 && this.filterElements.length===0){
        return 'primary'
      } else if(this.filterElements.length>0 && this.isIsolate==true) {
        return 'primary'
      } else {
        return 'darkGrey'
      }
    },
    hideButtonOutlined(){
      if(this.getSelectedPageDatasets === 0 && this.filterElements.length===0){
        return true
      } else if(this.filterElements.length>0 && this.isIsolate==false) {
        return false
      } else {
        return true
      }
    },
    isolateButtonOutlined(){
      if(this.getSelectedPageDatasets === 0 && this.filterElements.length===0){
        return true
      } else if(this.filterElements.length>0 && this.isIsolate==true) {
        return false
      } else {
        return true
      }
    },
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
    remainingElements(){
      let remainingElements = this.getFilterByData.graphicData ? this.getFilterByData.graphicData : this.getAttrData
      return remainingElements
    }
  },
  methods:{
    verifyInstanceSettings() {
      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};
      if (!this.grid_item.instance_setting.filters){
          this.filterObject= {
            filterType:this.filterType,
            isolate: this.isIsolate,
            elements: this.filterElements,
          },
          this.grid_item.instance_setting.filters = []
      }else if (this.grid_item.instance_setting.filters.length > 0){
        this.filterObject = this.grid_item.instance_setting.filters[0];
        if(this.filterObject.elements) this.filterElements = this.filterObject.elements;
        if(this.filterObject.isolate) this.isIsolate = this.filterObject.isolate;
      }
    },
    async addIsolateFilter(){
      //esacape if no elements are selected.
      if (this.getSelectedElements.length === 0 && this.filterElements.length === 0 ) return
      
      //If Isolate Filter is active / diactivate it and add the filter to current selection.
      if (this.getSelectedElements.length === 0 && this.filterElements.length > 0 && this.isIsolate === true){
        this.notebookPropsStore.$patch({selectedElements: [...this.filterElements]})
        this.clearSelectionFilter();
        return
      } 
      // Else Create a new Filter
      this.filterElements = [...this.getSelectedElements];
      this.isIsolate = true;

      this.filterObject= {
        filterType: this.filterType,
        isolate: this.isIsolate,
        elements: this.filterElements,
      }
      this.grid_item.instance_setting.filters = [this.filterObject];
      this.notebookPropsStore.$patch({selectedElements: []})

      let updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: this.filterObject})
      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    async addHideFilter(){
      //esacape if no elements are selected.
      if (this.getSelectedElements.length === 0 && this.filterElements.length === 0 )return
      
      //If Hide Filter is active / diactivate and nothing new is selected it and add the filter to current selection.
      if (this.getSelectedElements.length === 0 && this.filterElements.length > 0 && this.isIsolate === false){
        this.notebookPropsStore.$patch({selectedElements: [...this.filterElements]})
        this.clearSelectionFilter();
        return
      }
      // Else Create a new Filter
      this.filterElements = [...this.getSelectedElements];
      this.isIsolate = false;

      this.filterObject= {
        filterType:this.filterType,
        isolate: this.isIsolate,
        elements: this.filterElements,
      }
      this.grid_item.instance_setting.filters = [this.filterObject];
      this.notebookPropsStore.$patch({selectedElements: []})
      
      let updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: this.filterObject})
      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    selectAll(){
      const result = this.remainingElements.map(a => a.ellipseId);
      this.notebookPropsStore.$patch({selectedElements: result})
    },
    clearSelection(){
      this.notebookPropsStore.$patch({selectedElements: []})
    },
    async clearSelectionFilter(){
      this.filterElements = [];
      this.isIsolate = false;
      this.filterObject = {
        filterType:this.filterType,
        isolate: this.isIsolate,
        elements: this.filterElements,
      }
      this.grid_item.instance_setting.filters = [];
      let updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: this.filterObject}, true)
      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    resetFilter(){
      this.grid_item.instance_setting.filters = []
    }
  },
  async unmounted(){
  }
};
</script>
<style scoped>
</style>

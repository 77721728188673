<template>
    <div>
        <v-container>
            <v-row width="100%">
                <div class="text-caption pt-0">
                    <strong>Filter Status Display</strong>
                </div>
                <v-divider/>
            </v-row>
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.showSearch" label="Display Search Filters" />
                </v-col>
            </v-row>
            <v-divider/>
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.showRange" label="Display Range Filters" />
                </v-col>
            </v-row>
            <v-divider/>
            <v-row width="100%" class="pa-0 pl-1 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.showValue" label="Display Value Filters" />
                </v-col>
            </v-row>
            <v-row width="100%" class="pa-0 pl-10 ma-0">
                <v-col lg="12" class="pb-0">
                    <v-switch hide-details v-model="getSelectedWidget.instance_setting.data.switchValueResult" label="Display Excluded Values" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
//import { storeToRefs } from 'pinia';
import StatusFilter from "../../components/ui/StatusFilter.vue";

export default {
    components: { StatusFilter,
    },
    props: {
        getSelectedWidget:{},
    },
    setup() { 
    },
    data() {
        return{
        }
    },
    computed: {
    },
    methods: {
  },
}
</script>

<style scoped>
</style>
<template>        
    <div
      v-if="show"
      class="warningStyleClass defaultText text-center d-flex align-content-center flex-column"
    >
    <div><v-icon small>{{messages.widgetEmpty.icon}}</v-icon></div>
    <div class="description"><slot>{{ messages.widgetEmpty.memo }}{{lexicon.dataset.plural}}</slot></div>
    </div>
</template>

<script>

import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";

export default {
  setup(){
    return { lexicon, messages };
  },
  props: {
    show: {
        type: Boolean,
        required: true
    },
    icon: {
        type: String,
        default:"fa-solid fa-triangle-exclamation",
        required: false
    },
  }
}
</script>

<style scoped>
.description {
  color: rgb(var(--v-theme-darkGrey));
  font-weight: 400;
  font-size:smaller;
}
</style>
//remove - remove from page, but not from all notebook assets
//delete - delete from page and from all notebook assets if it is not used in any other page

export const drawingAssetInfo = {
  addAssetUrl: "/api/drawings/add_drawing",
  uploadUrl: "/api/drawings/upload-drawing",
  downloadUrl: "/api/drawings/download-drawing",
  removeUrl: "/api/drawings/remove_drawing",
  editUrl: "/api/drawing/",
  deleteUrl: "/api/drawing",
  name: "drawings",
  assetData: [],
  extention: ".svg",
}

export const modelAssetInfo = {
  addAssetUrl: "/api/models/add_model",
  uploadUrl: "/api/models/upload-model",
  downloadUrl: "/api/models/download-model",
  removeUrl: "/api/models/remove_model",
  deleteUrl: "/api/model",
  editUrl: "/api/model/edit-info",
  name: "models",
  assetData: [],
  extention: ".3dm",
}

export const dataAssetInfo = {
  uploadUrl: "/api/datasets/upload-dataset",
  downloadUrl: "/api/datasets/download-dataset",
  removeUrl: "/api/datasets",
  deleteUrl: "/api/dataset",
  name: "datasets",
  addAssetUrl: "/api/datasets/",
  removeAsserUrl: "",
  assetData: [],
  extention: ".json, .csv",
}

export const mapAssetInfo = {
  addAssetUrl: "/api/maps/add_map",
  uploadUrl: "/api/maps/upload-map",
  downloadUrl: "/api/maps/download-map",
  removeUrl: "/api/maps/remove_map",
  deleteUrl: "/api/map",
  editUrl: "/api/map/",
  name: "maps",
  assetData: [],
  extention: ".geojson",
}

export const imageAssetInfo = {
  addAssetUrl: "/api/images/add_image",
  uploadUrl: "/api/images/upload-image",
  downloadUrl: "/api/images/download-image",
  removeUrl: "/api/images/remove_image",
  deleteUrl: "/api/image",
  editUrl: "/api/image/",
  name: "images",
  assetData: [],
  extension: ".jpg",
  extentions: [".png", ".jpg", ".jpeg", ".gif"],
}
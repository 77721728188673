<template>
  <div class="widgetPadding">
    <!-- Corner Icons for View and Edit Mode. -->
    <WidgetIcon :show="widgetIcon && getEditMode" :icon="widgetIcon.icon" :text="widgetIcon.text"></WidgetIcon>
    <!-- Loading Spinner. -->
    <Spinner :show="getDatasetLoading"></Spinner>
    <!-- No Dataset Warning -->
    <NoDataWarning :show="getSelectedPageDatasets.length === 0" :icon="widgetIcon.icon"></NoDataWarning>
    <!-- Widget Title Header -->
    <WidgetTitle v-if="grid_item.name && grid_item.content && getGlobalProperty.global_setting.show_widget_titles" :item="grid_item"></WidgetTitle>

    <!-- Widget  Content -->
    <div v-if="getAttrData.length > 0 && grid_item.instance_setting.data" class="flexColumn">

      <!-- Select All/None Toggle. -->
      <v-container class="px-0 py-0 d-flex justify-space-between">
      
        <!-- Attribute Title-->
        <div>
          <span class="WidgetSubHeader mr-3">{{ this.grid_item.instance_setting.data.selectedAttribute }}</span>
        </div>
        <v-btn-group density="compact" variant="flat" >

          <v-btn icon size="small" @click="selectNone">
              <v-icon color="darkGrey" >mdi:mdi-select-remove </v-icon>
              <v-tooltip activator="parent" location="top">Select None</v-tooltip>
          </v-btn>

          <v-btn icon size="small" @click="selectAll">
              <v-icon color="darkGrey" >mdi:mdi-select-all</v-icon>
              <v-tooltip activator="parent" location="top">Select All</v-tooltip>
          </v-btn>

        </v-btn-group>
      </v-container>

      <v-container class="ma-0 pa-0 pt-0" style="font-size: smaller; overflow-y: auto; height: 100%">
        <!-- Filter Attribute Cards-->
        <v-infinite-scroll :items="dataChunk" @load="loadMoreData" direction="vertical" style="overflow-y:hidden">
          <div>
            <v-chip v-for="(item, index ) in dataChunk" 
              :key="index" 
              size="small" 
              label
              class="elevation-1 pa-1 ml-0 mr-1 mt-0 mb-1"
              :class="item.show ? 'selected-chip' : 'default-chip'"
              @click="toggleFilterValue(index)"
            >
              {{ formatValue(item.value) }}
            </v-chip>
          </div>
          <template v-slot:empty>
          </template>
        </v-infinite-scroll>
      </v-container>
    </div>
  </div>
</template>

<script>
import * as utils from "@/utilities";
import { evaluate } from "@ttcorestudio/data-processor";
import * as WidgetsCollection from "@/utilities/WidgetsCollection.js";
import Spinner from "../../components/ui/Spinner.vue";
import NoDataWarning from "../../components/ui/NoDataWarning.vue";
import WidgetIcon from "../../components/ui/WidgetIcon.vue";
import WidgetTitle from "../../components/ui/WidgetTitle.vue";

import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";
import {useDataGraphicsStore } from "@/store/DataGraphicsStore.js";
import {useAssetsStore } from "@/store/AssetsStore.js";

export default {
  components: { Spinner, NoDataWarning, WidgetIcon, WidgetTitle },
  props: ["grid_item"],
  setup() {
    const notebookPropsStore = useNotebookPropsStore()
    const dataGraphicsStore = useDataGraphicsStore()
    const assetsStore = useAssetsStore()
    const {
      getEditMode,
      getSelectedWidget,
      getDatasetLoading,
      getGlobalProperty
    } = storeToRefs(notebookPropsStore)
    const {
      getAttrData,
      getAttrHeaders,
      getAttrHeaderOptions,
    } = storeToRefs(dataGraphicsStore)  
    const {
      getSelectedPageDatasets
    } = storeToRefs(assetsStore)
    const {
      updateFilterByProperties,
      addFilter
    } = dataGraphicsStore
    return {assetsStore, dataGraphicsStore, getGlobalProperty,
      getEditMode, getAttrData, getAttrData, getAttrHeaders, getAttrHeaderOptions,
      getSelectedPageDatasets, getSelectedWidget, getDatasetLoading, addFilter,
      updateFilterByProperties}
  },
  data() {
    return {
      //Selected header to filter by.
      selectedAttribute: 'None',
      //Index of FilterValues to NOT filter out.
      filterSelection:[],
      //All Unique Values for selectedAttribute.
      //Schema: [{show:boolean, value:string}]
      filterType: "Value",
      toggle: null,
      dataChunk: [],
      itemsPerChunk: 50
    };
  },
  created() {
    this.verifyInstanceSettings();
  },
  mounted() {
    this.verifyInstanceSettings();
    if (!this.grid_item._id) {
      this.updateSelectedAttribute()
      this.filterByValues()
    } else {
      this.filterByValues()
    }
    this.assetsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === 'setSelectedPageDataset') {
          this.datasetUpdate()
        }
      })
    })
    this.dataGraphicsStore.$onAction(({name, after}) => {
      after(() => {
        if (name === "removeAllFilters"){
          this.resetFilter()
        }
      })
    })
  },
  watch: {
    "grid_item.instance_setting.data.selectedAttribute": function() {
      this.propsUpdatedEvent()
    },
  },
  async unmounted() {
  },
  computed: {
    widgetIcon() {
      let result = WidgetsCollection.returnWidgetDetails(this.grid_item.content);
      return result;
    },
  },
  methods: {
    verifyInstanceSettings() {

      if (!this.grid_item._id) {
        this.grid_item.instance_setting.data = {
          selectedAttribute: this.getAttrHeaderOptions[0],
          filterValues: this.filterValues,
          filterType: this.filterType,
        };
      }

      if (!this.grid_item.instance_setting.data) this.grid_item.instance_setting.data = {};

      if (!('selectedAttribute' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.selectedAttribute = this.selectedAttribute;
      if (!('filterValues' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filterValues = this.filterValues;
      if (!('filterType' in this.grid_item.instance_setting.data)) this.grid_item.instance_setting.data.filterType = this.filterType;

      this.resetDataChunk()
    },
    propsUpdatedEvent() {
      if (this.getSelectedWidget &&
        this.getSelectedWidget.i === this.grid_item.i){
        this.updateSelectedAttribute();
        this.filterByValues();
        this.resetDataChunk()
      }
    },
    resetDataChunk(){
      if (this.grid_item.instance_setting.data.filterValues){
        this.dataChunk = []
        this.dataChunk = this.grid_item.instance_setting.data.filterValues.slice(0, this.itemsPerChunk)
      }
    },
    datasetUpdate() {
      this.updateSelectedAttribute();
      this.filterByValues();
      this.resetDataChunk()
    },
    formatValue(data) {
      return utils.setDigits(data);
    },
    toggleFilterValue(index){
      this.grid_item.instance_setting.data.filterValues[index].show = !this.grid_item.instance_setting.data.filterValues[index].show;
      if (this.dataChunk[index]) {
        this.dataChunk[index].show = this.grid_item.instance_setting.data.filterValues[index].show;
      }
      this.filterByValues()
    },
    /**
     * Checks if the selectedAttribute is
     * still in the list of attributes,
     * and resets to 'None' if not.
     */
    selectedAttributeCheck(){
      if(this.grid_item.instance_setting.data.selectedAttribute){
        let result =  this.getAttrHeaderOptions.filter(a => a === this.grid_item.instance_setting.data.selectedAttribute);
        if(result.length === 0){
          if (!this.attrHeaderOptions)this.his.grid_item.instance_setting.data.selectedAttribute=this.selectedAttribute
          else this.grid_item.instance_setting.data.selectedAttribute =  this.attrHeaderOptions[0];
          this.grid_item.instance_setting.data.filterValues = [];
          this.grid_item.instance_setting.filters = [];
        }
      }
    },
    loadMoreData({done}){
      if (this.dataChunk.length == this.grid_item.instance_setting.data.filterValues.length){
        done('empty')
        return
      }
      const startIndex = this.dataChunk.length
      const endIndex = startIndex + this.itemsPerChunk
      const newData = this.grid_item.instance_setting.data.filterValues.slice(startIndex, endIndex)
      this.dataChunk = this.dataChunk.concat(newData)
      done('ok')
    },
    /** 
     * Update the Filter Selection options based 
     * on the selected attribute and calls 
     * the filterByValues event.
     */
    async updateSelectedAttribute(){
      this.selectedAttributeCheck();
      let val = this.grid_item.instance_setting.data.selectedAttribute;
      // if None, Empty the filterValues. 
      if (val === "None") {
        this.grid_item.instance_setting.data.filterValues = [];
      } 
      // Get unique values for the selected attribute
      // and update the Filter Selection options. 
      else {
        let elemIds = [];
        let elemVals = [];
        this.getAttrData.forEach((attr) => {
          elemIds.push(attr.ellipseId);
          elemVals.push(attr[val]);
        });

        let tempVal = evaluate.getUniqueVals(elemVals);
        this.grid_item.instance_setting.data.filterValues = tempVal.map((item) => {
          const filter = { value: "", show: true };
          filter.value = item;
          return filter;
        });
      }
    },
    /** 
     * Builds a Filter Object and emits a filter update event
     * to ellipseBus to update Global Graphic Settings. 
     */
    async filterByValues() {
      let filterObject = {
        filterType: "Value",
        field: this.grid_item.instance_setting.data.selectedAttribute,
        values: this.grid_item.instance_setting.data.filterValues,
      };
      this.grid_item.instance_setting.filters = [];
      this.grid_item.instance_setting.filters = [filterObject];

      let updateGlobalFilters
      if (this.grid_item.instance_setting.data.selectedAttribute == 'None'){
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject}, true)
      }
      else if (filterObject.values.length > 0 && this.selectedAttribute != null) {
        updateGlobalFilters = await this.addFilter({widget: this.grid_item.i, filter: filterObject})
      }
      if (updateGlobalFilters) await this.updateFilterByProperties()
    },
    selectAll() {
      var len = this.grid_item.instance_setting.data.filterValues.length;
      for (let i = 0; i < len; i++) {
        this.grid_item.instance_setting.data.filterValues[i].show = true;
        if (this.dataChunk[i]) this.dataChunk[i].show = true
      }
      this.filterByValues();
    },
    selectNone() {
      var len = this.grid_item.instance_setting.data.filterValues.length;
      for (let i = 0; i < len; i++) {
        this.grid_item.instance_setting.data.filterValues[i].show = false;
        if (this.dataChunk[i]) this.dataChunk[i].show = false
      }
      this.filterByValues();
    },
    resetFilter(){
      this.updateSelectedAttribute()
      this.grid_item.instance_setting.filters[0].values = this.grid_item.instance_setting.data.filterValues
      this.resetDataChunk()
    }
  },
};
</script>
<style scoped>
.default-chip {
  background-color: white;
  color:  rgb(var(--v-theme-darkSlate));
}
.selected-chip {
  background-color: rgb(var(--v-theme-darkSlate));
  color: white;
}
</style>

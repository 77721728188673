<template>
    <v-sheet class="WidgetWrapper" color='white'>

      <v-overlay
        v-if="getEditMode"
        :class="getEditMode && !isSelectedWidget ? 'dashedBorder' : 
          isSelectedWidget && getEditMode ? 'dashedBorderSelected' : ''"
        :opacity="getEditMode && !isSelectedWidget ? '0.8' : 
          isSelectedWidget && getEditMode ? '0.3' : '0'"
        :scrim="getEditMode && !isSelectedWidget ? 'white' : 
          isSelectedWidget && getEditMode ? 'background' : ''"
        :model-value="true"
        persistent
        contained
        height="100%"
        width="100%"
      >
        <!-- Lock/Trash Buttons -->
        <span class="lockIcon">
          <v-btn
            style="background-color: white"
            icon
            size="x-small"
            @click="$emit('lock', grid_item)"
          >
            <v-icon :color="item.local_setting.is_locked ? 'darkSlate' : 'darkGrey'">{{ icons.lock }}</v-icon>
          </v-btn>
        </span>
        <span class="removeIcon">
          <v-btn
            style="background-color: white"
            icon
            size="x-small"
            @click="$emit('remove', item)"
          >
            <v-icon color="darkSlate">{{ icons.remove }}</v-icon></v-btn
          >
        </span>
      </v-overlay>

      <!-- Widget Template. -->
      <component v-bind:is="item.content" :grid_item="item" />

    </v-sheet>
</template>
<script>
import ChartBar from "@/components/widgets/ChartBar.vue";
import ChartDonut from "@/components/widgets/ChartDonut.vue";
import ChartHistogram from "@/components/widgets/ChartHistogram.vue";
import ChartLine from "@/components/widgets/ChartLine.vue";
import ChartParallel from "@/components/widgets/ChartParallel.vue";
import ChartScatter from "@/components/widgets/ChartScatter.vue";
import ColorBySelector from "@/components/widgets/ColorBySelector.vue";
import DisplayAttributes from "@/components/widgets/DisplayAttributes.vue";
import DisplayScoreCard from "@/components/widgets/DisplayScoreCard.vue";
import DisplayStatistics from "@/components/widgets/DisplayStatistics.vue";
import DisplayStatusCount from "@/components/widgets/DisplayStatusCount.vue";
import DisplayFilterStatus from "@/components/widgets/DisplayFilterStatus.vue";
import FilterByCheck from "@/components/widgets/FilterByCheck.vue";
import FilterByCards from "@/components/widgets/FilterByCards.vue";
import FilterByDropdown from "@/components/widgets/FilterByDropdown.vue";
import FilterByRange from "@/components/widgets/FilterByRange.vue";
import FilterByValue from "@/components/widgets/FilterByValue.vue";
import FilterBySearch from "@/components/widgets/FilterBySearch.vue";
import FilterByCounter from "@/components/widgets/FilterByCounter.vue";
import FilterBySelection from "@/components/widgets/FilterBySelection.vue";
import StaticCarousel from "@/components/widgets/StaticCarousel.vue";
import StaticRichText from "@/components/widgets/StaticRichText.vue";
import StaticSpacer from "@/components/widgets/StaticSpacer.vue";
import StaticIframe from "@/components/widgets/StaticIframe.vue";
import StaticImage from "@/components/widgets/StaticImage.vue";
import Viewer3d from "@/components/widgets/Viewer3D.vue";
import Viewer2d from "@/components/widgets/Viewer2D.vue";
import ViewerMap from "./widgets/ViewerMap.vue";
import ViewDataTable from "@/components/widgets/ViewDataTable.vue";
import ViewFileAttachments from "./widgets/ViewFileAttachments.vue";
import ViewImageGrid from "../components/widgets/ViewImageGrid.vue";
import ViewItemList from "@/components/widgets/ViewItemList.vue";
import ViewLegend from "@/components/widgets/ViewLegend.vue";

import * as icons from "@/utilities/EllipseIcons.js";


import { storeToRefs } from 'pinia';
import {useNotebookPropsStore } from "@/store/NotebookPropsStore.js";

export default {
  components: {
    ChartBar,
    ChartDonut,
    ChartHistogram,
    ChartLine,
    ChartParallel,
    ChartScatter,
    ColorBySelector,
    DisplayAttributes,
    DisplayScoreCard,
    DisplayStatistics,
    DisplayStatusCount,
    DisplayFilterStatus,
    FilterByCards,
    FilterByCheck,
    FilterByCounter,
    FilterByDropdown,
    FilterByRange,
    FilterBySearch,
    FilterBySelection,
    FilterByValue,
    StaticCarousel,
    StaticIframe,
    StaticImage,
    StaticRichText,
    StaticSpacer,
    Viewer2d,
    Viewer3d,
    ViewDataTable,
    ViewItemList,
    ViewLegend,
    ViewImageGrid,
    ViewerMap,
    ViewFileAttachments,
  },
  props: {
    grid_item: {
      type: Object,
    },
  },
  setup() {
  const notebookPropsStore = useNotebookPropsStore()
  const {
    getEditMode,
    getSelectedWidget
  } = storeToRefs(notebookPropsStore)
  const {
  } = notebookPropsStore
  return {notebookPropsStore, getEditMode, getSelectedWidget, icons }
  },
  watch: {
    grid_item: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.item = newVal;
      },
    },
  },
  data() {
    return {
      selectedItem: null,
      item: this.grid_item,
      checked: false,
      selectedItem: {},
    };
  },
  mounted() {
  },
  computed: {
    isSelectedWidget() {
      if (this.getSelectedWidget){
        return this.getSelectedWidget.i == this.grid_item.i;
      }
    },
  },
  methods: {},
};
</script>
<style scoped>
.lockIcon {
  position: absolute;
  right: 2.75em;
  top: 0.25em;
  cursor: pointer;
  z-index: 20;
}
.removeIcon {
  position: absolute;
  right: 0.25em;
  top: 0.25em;
  cursor: pointer;
  z-index: 20;
}
.layoutJSON {
  background: rgb(var(--v-theme-background));
  border: 1px solid rgb(var(--v-theme-darkSlate));
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.selectedRow {
  background-color: rgb(var(--v-theme-darkSlate));
  font-weight: bold;
  color: white;
}
.dashedBorder {
  border-style: dotted;
  border-color: rgb(var(--v-theme-darkGrey));
  border-width: 2px;
}
.dashedBorderSelected {
  border-style: dotted;
  border-color: rgb(var(--v-theme-accent));
  border-width: 3px;
}
.WidgetWrapper {
  height: 100%;
  overflow-y: hidden;
}
</style>

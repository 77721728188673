<template>
  <tr @click="$emit('onNotebookClick')" style="cursor: pointer; font-size:14px">
    <td>
      <v-avatar class="rounded-lg" height="32">
        <v-img v-if="imageURL" :src="imageURL" cover></v-img>
        <v-img v-else src="../assets/imgs/notebookCardPlaceholder.png" cover></v-img>
      </v-avatar>
    </td>
    <td ><h4>{{ notebook.name }}</h4> </td>
    <td>
      <span><Sharing :notebook="notebook" /></span>
    </td>
    <td >{{ notebook.createdBy.user.name }} </td>
    <td>{{ $filters.momentTimestampDDMMYYYY(notebook.createdAt) }} </td>
    <td class="text-right">
      <span>
        <v-menu offset-y>
          <template v-slot:activator="{ props}">
            <v-btn v-bind="props" icon size="x-small" variant="text" >
              <v-icon size="small" color="darkSlate">{{ icons.menu }}</v-icon>
            </v-btn
            >
          </template>
          <div>
            <v-card
              class="mx-auto"
              max-width="250"
            >
              <v-list density="compact">
                <v-list-item
                  v-for="(item, i) in optionsFilteredByPermission"
                  :key="i"
                  :value="item"
                  @click="$emit(item.action)"
                >
                  <template v-slot:prepend>
                  <v-icon :icon="item.icon"></v-icon>
                  </template>
                  <v-list-item-title>{{item.text}}</v-list-item-title>
                </v-list-item>
                <Sharing :notebook="notebook" :itemtype="'list'"/>
              </v-list>
            </v-card>
          </div>
        </v-menu>
      </span>
    </td>

  </tr>
</template>

<script>
import Sharing from "./popUps/NotebookCardSharing.vue";
import AreYourSurePopUp from "../components/popUps/AreYouSure.vue";
import * as lexicon from "@/utilities/EllipseLexicon.js";
import * as messages from "@/utilities/EllipseMessages.js";
import * as icons from "@/utilities/EllipseIcons.js";

import { storeToRefs } from 'pinia';
import {useEllipseStore} from "@/store/EllipseStore.js";

export default {
  components: { Sharing, AreYourSurePopUp},
  props: ["notebook"],
  setup(){
    const ellipseStore = useEllipseStore();
    const { 
      getNotebooks 
    } = storeToRefs(ellipseStore);
    const {
      setNotebooks,
      setTemplates
    } = ellipseStore;
    return { getNotebooks,
    setNotebooks, setTemplates, lexicon, messages, icons };
  },
  data() {
    return {
      options: [
      {text: lexicon.rename , icon: icons.rename , action: 'onEditNotebookClick', minPermissionRequired: 300},
        {text: lexicon.duplicate , icon: icons.duplicate , action: 'OnCopyNotebookClick', minPermissionRequired: 300},
        {text: lexicon.del , icon: icons.remove , action: 'onDeleteNotebookClick', minPermissionRequired: 500},
        {text: messages.templateSave , icon: lexicon.template.icon, action: 'onCreateTemplateClick', minPermissionRequired: 100},
        {text: 'Set Cover Image' , icon: lexicon.image.icon, action: 'onSetImageClick', minPermissionRequired: 300},
        {text: 'Make Public' , icon: icons.shareURL, action: 'onMakePublic', minPermissionRequired: 300},
      ],
      imageURL: null
    };
  },
  watch: {
    'notebook.coverImage': {
      immediate: true, // Run on component mount
      handler() {
        this.updateImageURL();
      }
    }
  },
  computed: {
    optionsFilteredByPermission(){
      let userPermissions = this.notebook.userPermissions.permissions
      return this.options.filter(option => userPermissions >= option.minPermissionRequired)
    }
  },
  methods: {
    async updateImageURL() {
      if (this.notebook.coverImage){
        try {
          let s3Key = this.notebook.coverImage.s3Key;
          let params = { urlType: this.$route.name };
          let response = await this.$auth.$api.get(
            `/api/notebook/${this.notebook._id}/file/view?s3Key=${s3Key}`,
            { params }
          );
          this.imageURL = response.data; // Set the resolved URL
        } catch (error) {
          console.error("Error fetching image URL:", error);
          this.imageURL = "../assets/imgs/notebookCardPlaceholder.png"; // Fallback
        }
      }
    }
  },
};
</script>

<style scoped>
  .notebook-property{
    font-size: 12px;
    padding-left: 4px;
    font-weight: 300;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  .notebook-property-div{
    padding-top: 4px;
    display: flex;
  }
</style>
